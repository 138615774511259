import { UiSchema } from "@rjsf/utils";
import { Mediatype } from "models/dataset.model";
import { VisualisationTypeEnum } from "models/pipelines.model";
import { SubsetTypeModel } from "models/global.model";

export type UICategoriesType = keyof typeof UICategoriesEnum;

export enum UICategoriesEnum {
  "QUESTION" = "Ask a question",
  "BB" = "Draw a bounding box",
  "KEYPOINT" = "Draw a keypoint",
}

export enum UIsEnum {
  binary_2d = "binary_2d",
  discrete_2d = "discrete_2d",
  slider_2d = "slider_2d",
  bounding_box_2d = "bounding_box_2d",
  keypoint_2d = "keypoint_2d",
  keypoint_3d = "keypoint_3d",
  marker_3d = "marker_3d",
}

export type UICategoriesModel = {
  [key in UICategoriesType]: UIsEnum[];
};

export type AnnotationUIsModel = {
  [key in UIsEnum]: {
    name: string;
    key: UIsEnum;
    postProcessingUiKey: LegacyUIsEnum;
    uiSchema: UiSchema;
    formData: Record<string, any>;
    mediaTypeOptions: {
      [key in Mediatype]?: {
        [key in VisualisationTypeEnum]?: SubsetTypeModel[];
      };
    };
  };
};

export enum LegacyUIsEnum {
  binary_2d = "hermes-binaryquestion-3.0",
  discrete_2d = "hermes-discreteanswer-3.0",
  slider_2d = "hermes-slider-3.0",
  bounding_box_2d = "hermes-2dbb-3.0",
  keypoint_2d = "hermes-keypoints-3.0",
  keypoint_3d = "hermes-lidarviewer-keypoints-3.0",
  marker_3d = "hermes-lidarviewer-3dmarkers-3.0",
}
