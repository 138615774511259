import { useAppSelector } from "store/hooks";
import _ from "lodash";

import { ReactComponent as ArrowLongLeftIcon } from "assets/arrow_long_left.svg";
import { determineActiveSortKeyFromParam } from "store/sortDataSlice";
import { determineFilterKeyFromParam } from "store/filterDataSlice";

interface Props {
  shouldWhiteSpaceNoWrap?: boolean;
}

const SortList = ({ shouldWhiteSpaceNoWrap }: Props) => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);

  const filterData = useAppSelector((state) => state.filterDataSlice);
  const sortData = useAppSelector((state) => state.sortDataSlice);

  const renderSort = () => {
    const sorterKey = determineActiveSortKeyFromParam(selectedView);
    if (!sorterKey) return renderNoSort();

    const sorter = sortData?.[sorterKey]?.[0];
    if (_.isEmpty(sorter)) return renderNoSort();

    const { field, order } = sorter;

    const filters = filterData?.[determineFilterKeyFromParam(selectedView)];
    const attribute = filters?.[field];

    return (
      <div>
        <button
          className={`button-layer text-sm 
          ${shouldWhiteSpaceNoWrap ? "whitespace-nowrap" : ""}
          `}
        >
          {`${attribute?.attribute_name} - ${attribute?.filter_name}`}
          <div
            className={` transition-all duration-300 ${
              order === "asc" ? "rotate-90" : "-rotate-90"
            } `}
          >
            <ArrowLongLeftIcon width={12} height={12} />
          </div>
        </button>
      </div>
    );
  };

  const renderNoSort = () => {
    return <div className="pl-2 text-sm">No sorting applied</div>;
  };

  return renderSort();
};

export default SortList;
