import _ from "lodash";
import { AttributeMetadataModel } from "models/attributes.model";

/**
 * Get an attribute metadata object from an array of attributes metadata
 * @param attributesMeta The array of attributes metadata
 * @param attributeID The attribute ID to find in the array
 * @returns The attribute metadata object if found, otherwise undefined
 */
const getAttributeFromAttributesMeta = (
  attributesMeta: AttributeMetadataModel[],
  attributeID: string
) => {
  return _.find(attributesMeta, (att) => att?.id === attributeID);
};

export default getAttributeFromAttributesMeta;
