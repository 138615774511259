import { SubsetTypeModel, VisibilityStatus } from "models/global.model";

export interface DatasetModel {
  id: string;
  name: string;
  parent_dataset: string | null;
  owner: string | null;
  num_medias: number;
  num_media_objects: number;
  num_instances: number;
  done_percentage: number | null;
  creation_timestamp: string;
  color: string;
  subset_type: SubsetTypeModel;
  mediatype: Mediatype;
  is_anonymized: boolean;
  object_category: boolean;
  export_id: string;
  subsets: DatasetModel[];
  config_id: string | null;
  // The count of the media/media object we get from filter buckets
  count?: number;
  license?: string;
  visibility_status: VisibilityStatus;
}

export enum Mediatype {
  image = "image",
  video = "video",
  point_cloud = "point_cloud",
}
