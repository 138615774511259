import _ from "lodash";
import {
  FiltersModel,
  FilterModel,
  FilterCategoryEnum,
} from "models/filter.model";

/**
 * A helper function to group annotation_attribute or ml_annotation_attribute filters by attribute_id
 * @param filters All filters, including annotation_attribute and ml_annotation_attribute filters
 * @returns The annotation_attribute or ml_annotation_attribute filters grouped by attribute_id
 */
const groupMLAttributesOrAnnotatedAttributes = (
  filters: FiltersModel,
  group:
    | FilterCategoryEnum.Annotation_Attribute
    | FilterCategoryEnum.ML_Annotation_Attribute,
) => {
  // Group by attribute_id
  const annotatedAttributeGrouped = _.chain(filters)
    .filter(["attribute_group", group])
    .groupBy("attribute_id")
    .value();

  // Get first item of each group
  let annotatedAttribute: FilterModel[] = [];
  _.forEach(annotatedAttributeGrouped, (value) => {
    const firstItem = _.head(value);
    if (firstItem) {
      annotatedAttribute = [...annotatedAttribute, firstItem];
    }
  });

  return annotatedAttribute;
};

export default groupMLAttributesOrAnnotatedAttributes;
