import _, { replace, upperFirst } from "lodash";
import { RefObject } from "react";

// Convert snake case string to a normal readable human text.
export function snakeCaseToText(
  input_str: string,
  isFirstLetterCapital: boolean = true,
): string {
  if (isFirstLetterCapital === true) {
    // Capatalize AI
    input_str = replace(input_str, new RegExp("ai_", "g"), "AI ");
    return upperFirst(replace(input_str, new RegExp("_", "g"), " "));
  } else {
    return replace(input_str, new RegExp("_", "g"), " ");
  }
}

/**
 * This converts a string to a lower case word with a capital first letter.
 * @param input_str the string to convert
 * @returns the converted string
 */
export function lowerCamelCase(input_str: string | undefined): string {
  if (!input_str) return "";
  return _.upperFirst(input_str?.toLowerCase());
}

/**
 * This is used to convert longer strings into text that is in the format as it should be displayed in HARI. This means that only the first letter of the first word is capitalized and all other words are in lower case.
 * This rule does not apply to special cases like "ID", "QM", "Hari", "AI", "ML" which should always be capital
 * @param input_str the string to convert
 * @returns the string in the correct format
 */
export function transformString(input_str: string | undefined): string {
  // Split the input into words while keeping special characters
  if (!input_str) return "";
  const words = input_str?.split(/(\s+|[-_']+)/);
  const special_cases = ["id", "qm", "hari", "ai", "ml", "ui"];

  // Process each word
  const transformedWords = words?.map((word: string, index: number) => {
    if (special_cases.includes(word.toLowerCase())) {
      // Keep special cases capitalized
      return word.toUpperCase();
    }

    // Lower the first character of each word unless it is the first word
    if (index === 0) {
      return lowerCamelCase(word);
    }
    return word.toLowerCase();
  });

  // Join the words back together
  return transformedWords?.join("");
}
export function nFormatter(num: number): string {
  if (num < 1) return _.round(num, 2).toString();
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value)
        .toFixed(Number(process.env.REACT_APP_FRACTION_DIGIT))
        .replace(rx, "$1") + item.symbol
    : "0";
}

interface Thumbnails {
  [key: string]: string;
}

interface Dimensions {
  width: number;
  height: number;
}

/**
 * Parses a string of dimensions (e.g., "140x113") into an object containing width and height properties.
 * @param {string} dimensions - The dimensions string to parse.
 * @return {Dimensions} An object with the parsed width and height.
 */
const parseDimensions = (dimensions: string): Dimensions => {
  const [width, height] = dimensions?.split("x")?.map(Number);
  return { width, height };
};

/**
 * Given a reference to a container and an object of thumbnails, it selects the most appropriate thumbnail.
 * If there are thumbnails larger than the container, it selects the smallest among them.
 * If there are none larger, it selects the largest available thumbnail.
 * The result is returned as a key string representing the selected thumbnail's dimensions (e.g., "140x113").
 * @param {RefObject<HTMLDivElement>} ref - A reference to the container where the media will be placed.
 * @param {Thumbnails} thumbnails - An object mapping dimension strings to URLs for each available thumbnail.
 * @return {string | null} The key string of the selected thumbnail, or null if no appropriate thumbnail could be found.
 */
const findAppropriateThumbnail = (
  ref: RefObject<HTMLDivElement>,
  thumbnails: Thumbnails,
): string | null => {
  if (!ref?.current || !thumbnails) {
    return null;
  }

  const { clientWidth: divWidth, clientHeight: divHeight } = ref?.current;
  // Get the dimension keys of thumbnails
  const dimensions = _.keys(thumbnails || {});

  // Filter dimensions to those larger than the object it's being used on
  const largerDimensions = dimensions?.filter((dim) => {
    const { width, height } = parseDimensions(dim);
    return width >= divWidth && height >= divHeight;
  });

  // If there are no larger dimensions, find the largest available
  if (largerDimensions?.length === 0) {
    const largestDimension = dimensions?.reduce(
      (largest, current) => {
        const { width: largestWidth, height: largestHeight } =
          parseDimensions(largest);
        const { width: currentWidth, height: currentHeight } =
          parseDimensions(current);

        if (currentWidth * currentHeight > largestWidth * largestHeight) {
          return current;
        }

        return largest;
      },
      dimensions?.[0],
    );

    return largestDimension;
  }

  // Find the smallest dimension among larger ones
  const smallestDimension = largerDimensions?.reduce(
    (smallest, current) => {
      const { width: smallestWidth, height: smallestHeight } =
        parseDimensions(smallest);
      const { width: currentWidth, height: currentHeight } =
        parseDimensions(current);

      if (currentWidth * currentHeight < smallestWidth * smallestHeight) {
        return current;
      }

      return smallest;
    },
    largerDimensions?.[0],
  );

  return smallestDimension;
};

/**
 * Memoized version of the findAppropriateThumbnail function.
 * It utilizes a cache to remember the results of previous calls with the same arguments,
 * improving performance when the same thumbnails are requested multiple times for the same container size.
 * The cache key is constructed from the dimension keys of the thumbnails object and the dimensions of the container.
 * @param {RefObject<HTMLDivElement>} ref - A reference to the container where the media will be placed.
 * @param {Thumbnails} thumbnails - An object mapping dimension strings to URLs for each available thumbnail.
 * @return {string | null} The key string of the selected thumbnail, or null if no appropriate thumbnail could be found.
 */
export const memoizedFindAppropriateThumbnail: (
  ref: RefObject<HTMLDivElement>,
  thumbnails: Thumbnails,
) => string | null = _.memoize(
  findAppropriateThumbnail,
  (ref: RefObject<HTMLDivElement>, thumbnails: Thumbnails) => {
    const divWidth = ref?.current?.clientWidth;
    const divHeight = ref?.current?.clientHeight;

    if (!divWidth || !divHeight) return null;

    const cacheKey = `${_.keys(thumbnails)?.join(
      "-",
    )}-${divWidth}-${divHeight}`;
    return cacheKey;
  },
);
