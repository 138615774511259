type Props = {
  handleResetAll: () => void;
};

const ThresholdAnalysisReset = ({ handleResetAll }: Props) => {
  return (
    <button
      className="button-layer bg-paletteRed text-white"
      onClick={handleResetAll}
    >
      Reset
    </button>
  );
};

export default ThresholdAnalysisReset;
