import _ from "lodash";
import { FilterCategoryEnum } from "models/filter.model";
import { ReactComponent as AutoAttributeIcon } from "assets/auto_attribute.svg";
import { ReactComponent as AnnotationAttributeIcon } from "assets/attributes_lines.svg";
import { ReactComponent as InitialAttributeIcon } from "assets/box.svg";
import { ReactComponent as SavedFiltersIcon } from "assets/save.svg";
import { ReactComponent as SubsetsTagsIcon } from "assets/subsets_tags.svg";
import { ReactComponent as AIIcon } from "assets/ai.svg";
import { ReactComponent as Search } from "assets/search_filter.svg";

import { useAppSelector } from "store/hooks";
import { getFilterCategoryCount } from "components/UtilComponents/SidebarMenu/FiltersDialog/FiltersTab/FiltersTabHelperFunctions";
import { renameFilterCategory } from "helpers/functions/filters/filtersHelpers";

interface Props {
  setSelectedCategory: (category: FilterCategoryEnum) => void;
}

const FilterCategories = ({ setSelectedCategory }: Props) => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const filterData = useAppSelector((state) => state.filterDataSlice);

  const renderCategories = () => {
    return (
      <div className="w-full inline-flex flex-wrap gap-2 justify-between pr-[2px]">
        {renderCategory(FilterCategoryEnum.Subsets_And_Tags)}
        {renderCategory(FilterCategoryEnum.Auto_Attribute)}
        {renderCategory(FilterCategoryEnum.Annotation_Attribute)}
        {renderCategory(FilterCategoryEnum.Initial_Attribute)}
        {renderCategory(FilterCategoryEnum.ML_Annotation_Attribute)}
        {renderCategory(FilterCategoryEnum.Saved_Filter)}
        {renderCategory(FilterCategoryEnum.Text_Filter)}
      </div>
    );
  };

  const renderCategory = (category: FilterCategoryEnum) => {
    const label = renameFilterCategory(category);
    return (
      <button
        className=" w-[190px] button-layer pt-8 pb-7 px-4 flex flex-col gap-y-4 text-paletteGray-10 "
        onClick={() => setSelectedCategory(category)}
      >
        {renderCategoryIcon(category)}

        <div>
          <div className=" text-paletteBlack-2">{label}</div>
          <div className="flex justify-center">
            {getFilterCategoryCount(category, filterData, selectedView)}
          </div>
        </div>
      </button>
    );
  };

  const renderCategoryIcon = (category: FilterCategoryEnum) => {
    const size = 36;
    const strokeWidth = 1.2;
    switch (category) {
      case FilterCategoryEnum.Auto_Attribute:
        return (
          <AutoAttributeIcon
            width={size}
            height={size}
            strokeWidth={strokeWidth}
          />
        );
      case FilterCategoryEnum.Annotation_Attribute:
        return (
          <AnnotationAttributeIcon
            width={size}
            height={size}
            strokeWidth={strokeWidth}
          />
        );
      case FilterCategoryEnum.ML_Annotation_Attribute:
        return <AIIcon width={size} height={size} strokeWidth={strokeWidth} />;
      case FilterCategoryEnum.Initial_Attribute:
        return (
          <InitialAttributeIcon
            width={size}
            height={size}
            strokeWidth={strokeWidth}
          />
        );
      case FilterCategoryEnum.Saved_Filter:
        return (
          <SavedFiltersIcon
            width={size}
            height={size}
            strokeWidth={strokeWidth}
          />
        );
      case FilterCategoryEnum.Text_Filter:
        return <Search width={size} height={size} strokeWidth={strokeWidth} />;
      default:
        return null;
      case FilterCategoryEnum.Subsets_And_Tags:
        return (
          <SubsetsTagsIcon
            width={size}
            height={size}
            strokeWidth={strokeWidth}
          />
        );
    }
  };

  return (
    <div>
      <div className=" px-3 py-[7.5px] text-paletteGray-10 mb-1">
        Categories
      </div>
      {renderCategories()}
    </div>
  );
};

export default FilterCategories;
