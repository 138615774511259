import { UiSchema } from "@rjsf/utils";

export const ui_schema_slider_2d: UiSchema = {
  inputType: {
    Viewer3D: {
      "ui:widget": "hidden",
    },
  },
};

export const form_data_slider_2d = {
  inputType: {
    BoundingBox2DPainter: {
      displayerType: "image",
      boundingBoxProps: {
        opacity: 0,
      },
    },
    Viewer3D: {},
  },
  toolbars: [
    {
      type: "slider",
      settings: {
        max: 100,
        min: 0,
        defaultValue: 50,
        step: 1,
        valueLabelDisplay: "on",
      },
    },
    {
      type: "navigation",
      settings: {
        withUnsolvableButton: true,
        unsolvableCode: "cant_solve",
        withOptions: false,
      },
    },
  ],
};

export const answers_slider_2d = {
  toolbarKey: "slider",
  cantSolveToolbarKey: "navigation",
};
