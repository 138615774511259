import TagManager from "react-gtm-module";
import { clarity } from "react-microsoft-clarity";
import keycloak from "keycloak";
import { checkIfUserHaveRole } from "helpers/keycloakHelpers";
import { internalOnlyRoles } from "models/users.model";
import { pushToDataLayer } from "helpers/analytics/events";
import { getAcceptances } from "helpers/analytics/cookieAcceptance";
import { hashString } from "helpers/hash";

const setupAnalytics = async () => {
  const { cookieAcceptance, gaAcceptance, mcAcceptance } = getAcceptances();
  if (cookieAcceptance !== "true") {
    return;
  }

  const userEmail = keycloak?.idTokenParsed?.email;
  // Hash the email to anonymize it
  const userId = userEmail ? await hashString(userEmail) : crypto.randomUUID();
  // Internal and external users should be distinguished
  const isUserInternal = checkIfUserHaveRole(keycloak, internalOnlyRoles);

  // Initialize Google Tag Manager, if the user accepted it
  const gtmID =
    process.env.REACT_APP_GTM_ID ??
    alert("Config value 'REACT_APP_GTM_ID ' is not defined");
  if (cookieAcceptance === "true" && gaAcceptance === "true" && gtmID) {
    // Send type of the user (internal/external) and the user ID to Google Tag Manager
    pushToDataLayer("userIdentification", {
      traffic_type: isUserInternal ? "internal" : "external",
      user_id: userId,
    });
    // Initialize Google Tag Manager
    TagManager.initialize({
      gtmId: gtmID,
    });
  }

  // Initialize Microsoft Clarity, if the user accepted it
  const mcProjectID =
    process.env.REACT_APP_MC_PROJECT_ID ??
    alert("Config value 'REACT_APP_MC_PROJECT_ID ' is not defined");
  if (cookieAcceptance === "true" && mcAcceptance === "true" && mcProjectID) {
    // Initialize Microsoft Clarity and user gave consent
    clarity.init(mcProjectID);
    clarity.consent();
    // Send type of the user (internal/external) and the user ID. Note: The email is hashed by clarity itself!
    clarity.identify(userEmail, {
      userProperty: { traffic_type: isUserInternal ? "internal" : "external" },
    });
  }
};

export default setupAnalytics;
