import UnderlineTabs from "components/Internal/Tabs/UnderlineTabs";
import { SubsetsManagerScreenTabs } from "components/SubsetsManager";
import _ from "lodash";
import { DatasetModel, Mediatype } from "models/dataset.model";
import { useAppSelector } from "store/hooks";

type Props = {
  subsets: DatasetModel[];
  selectedTabValue: string;
  onTabClick: (tab: string) => void;
};

const SubsetTabs = ({ subsets, selectedTabValue, onTabClick }: Props) => {
  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype,
  );

  const tabs = [
    {
      label: "All",
      value: "all",
      count: subsets?.length,
    },
    {
      label: mediatype === Mediatype.video ? "Video subsets" : "Media subsets",
      value: "media",
      count: _.filter(subsets, { subset_type: "media" })?.length,
    },
    {
      label: "Instance subsets",
      value: "instance",
      count: _.filter(subsets, { subset_type: "instance" })?.length,
    },
    {
      label: "Object subsets",
      value: "media_object",
      count: _.filter(subsets, { subset_type: "media_object" })?.length,
    },
  ];

  return (
    <div className="w-full pb-4">
      <UnderlineTabs
        tabs={tabs}
        selectedTabValue={selectedTabValue}
        onTabClick={(tab) => onTabClick(tab?.value as SubsetsManagerScreenTabs)}
      />
    </div>
  );
};

export default SubsetTabs;
