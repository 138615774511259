import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  statisticsStateTypes,
  setMediasAndObjects,
} from "store/statisticsSlice";
import BarChartComp, {
  BarChartCompDataModel,
} from "components/Statistics/Charts/BarChartComp";

interface Props {
  showInputs: boolean;
}

const MediasAndObjects = ({ showInputs }: Props) => {
  const dispatch = useAppDispatch();

  const numOfMedias = useAppSelector((state) => state.metaDataSlice.mediaCount);

  const numOfInstances = useAppSelector(
    (state) => state.metaDataSlice.mediaObjectsCount
  );

  const chartData = useAppSelector(
    (state) => state.statisticsSlice.charts.medias_and_objects
  );

  useEffect(() => {
    const mediasAndInstances = [
      { name: "Medias", count: numOfMedias.total_count },
      { name: "Media Objects", count: numOfInstances.total_count },
    ];

    dispatch(
      setMediasAndObjects({
        ...chartData,
        data: mediasAndInstances,
      })
    );
  }, [numOfMedias]);

  const renderCharts = () => {
    const data: BarChartCompDataModel = chartData?.data;

    return (
      <BarChartComp
        data={data}
        width={95}
        showLegend={false}
        showLabels={true}
      />
    );
  };

  const chartID: keyof statisticsStateTypes["charts"] = "medias_and_objects";

  return (
    <div className="w-full h-full flex p-3">
      {showInputs ? (
        <div
          className="w-[300px] h-full text-sm flex flex-col gap-y-3 overflow-y-auto animate-fade"
          style={{
            transition: "width 0.1s",
          }}
        >
          {""}
        </div>
      ) : (
        <div
          className="w-[0px]"
          style={{
            transition: "width 0.1s",
          }}
        ></div>
      )}
      <div
        id={chartID}
        className="h-full flex"
        style={{
          width: showInputs ? "calc(100% - 300px)" : "100%",
          transition: "width 0.1s",
        }}
      >
        {renderCharts()}
      </div>
    </div>
  );
};

export default MediasAndObjects;
