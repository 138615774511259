import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  fetchSubsets,
  fetchVisualisationConfiguration,
} from "store/datasetSlice";
import { resetStoreOnSubsetChange } from "store/util/resetStore";

import { Close } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import SingleSliderInput from "components/Internal/Inputs/Form/SingleSliderInput";

import {
  AnnotatableEnum,
  SelectedItemsTypeModel,
  SubsetTypeModel,
} from "models/global.model";
import { useParams } from "react-router-dom";
import { ExplorationScreenRouteModel } from "models/routes.model";
import { SendFilterModel } from "models/filter.model";
import getQueryBasedOnType from "helpers/functions/getQueryBasedOnType";
import { postCreateSubset } from "helpers/apis/subsets";
import TextInput from "components/Internal/Inputs/Form/TextInput";
import {
  NumberFieldModel,
  SelectFieldModel,
  SingleSliderFieldModel,
  TextFieldModel,
} from "models/form.model";
import Loading from "components/UtilComponents/Loading";
import TagsSelector from "components/Internal/Inputs/TagsSelector";
import _ from "lodash";
import {
  getSelectedItemsBasedOnSelectedView,
  getSubsetTypeFromBasedOnSelectedView,
} from "helpers/functions/selectedViewHelpers";
import { determineCountKeyFromParam } from "store/metaDataSlice";
import { getExplorationRouteFromSelectedView } from "routes/routesHelper";
import NumberInput from "components/Internal/Inputs/Form/NumberInput";
import SimpleSelect from "components/Internal/Inputs/Form/SimpleSelect";
import { postCreateVisualisationConfiguration } from "helpers/apis/configurations";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import { useKeycloak } from "@react-keycloak/web";

export type NewSubsetOptionModel = "normal" | "random" | "specificObjects";
interface Props {
  subsetOption: NewSubsetOptionModel;
  selectedItemsType: SelectedItemsTypeModel;
  openCreateSubset: boolean;
  setOpenCreateSubset: (value: boolean) => void;
  handleCloseSubsetsDialog?: () => void;
}

const NewSubsetDialog = ({
  subsetOption,
  selectedItemsType,
  openCreateSubset,
  setOpenCreateSubset,
  handleCloseSubsetsDialog,
}: Props) => {
  const params: ExplorationScreenRouteModel = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const dataSetData = useAppSelector((state) => state.datasetSlice);
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const metaDataSlice = useAppSelector((state) => state.metaDataSlice);
  const selectedMediaIDs = useAppSelector(
    (state) => state.explorationMediaSlice.selectedMediaIDs,
  );
  const selectedMediaObjectsIDs = useAppSelector(
    (state) => state.explorationMediaObjectsSlice.selectedMediaObjectsIDs,
  );
  const selectedInstancesIDs = useAppSelector(
    (state) => state.explorationInstanceSlice.selectedInstanceIDs,
  );
  const allSubsets = useAppSelector((state) =>
    _.keyBy(state.datasetSlice?.subSets, "name"),
  );

  const pushHistory = (datasetID: string, subsetID: string): void => {
    resetStoreOnSubsetChange(dispatch);
    const explorationRoute = getExplorationRouteFromSelectedView(selectedView, {
      id: datasetID,
      subset_id: subsetID,
    });
    history.push(explorationRoute);
    handleCloseSubsetsDialog && handleCloseSubsetsDialog();
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Create new subset logic
  const [subsetName, setSubsetName] = useState<TextFieldModel>({
    type: "text",
    key: "name",
    placeholder: "At least 3 letters",
    value: "",
    settings: {
      minimumTextLength: 3,
    },
  });
  // Create new random subset
  const [sampleSize, setSampleSize] = useState<SingleSliderFieldModel>({
    type: "single_slider",
    key: "sampleSize",
    value: 0,
    min: 0,
    max: getSliderMaxValue(),
    disabled: isLoading,
    settings: {
      numberOfSteps: 100,
      stepInteger: true,
    },
  });
  // Create new subset from selected objects
  const [objectSubsetsToInclude, setObjectSubsetsToInclude] = useState<
    string[]
  >([]);

  const [createNewCroppingConfig, setCreateNewCroppingConfig] = useState(false);
  const [croppingConfigID, setCroppingConfigID] = useState<SelectFieldModel>({
    type: "select",
    key: "config_id",
    label: "Config ID",
    placeholder: "Select a config ID",
    value: "",
    options: [],
  });
  const [croppingName, setCroppingName] = useState<TextFieldModel>({
    type: "text",
    key: "name",
    label: "Name",
    placeholder: "At least 3 letters",
    value: "",
    settings: {
      minimumTextLength: 3,
    },
  });
  const [croppingForm, setCroppingForm] = useState<{
    minPadding: NumberFieldModel;
    percentPadding: NumberFieldModel;
  }>({
    minPadding: {
      type: "number",
      key: "minPadding",
      label: "Minimum padding (px)",
      placeholder: "Minimum padding (px)",
      value: 100,
      settings: {
        minimumValue: 0,
      },
    },
    percentPadding: {
      type: "number",
      key: "percentPadding",
      label: "Percent padding (%)",
      placeholder: "Percent padding (%)",
      value: 40,
      settings: {
        minimumValue: 0,
        maximumValue: 100,
      },
    },
  });

  useEffect(() => {
    if (dataSetData.VisualisationConfiguration) {
      const croppingConfigs = _.filter(
        dataSetData.VisualisationConfiguration,
        (config) => config?.parameters?.type === "crop",
      );

      setCroppingConfigID({
        ...croppingConfigID,
        options: _.map(croppingConfigs, (config) => ({
          label: config?.name,
          value: config?.id,
        })),
      });
    }
  }, [dataSetData.VisualisationConfiguration]);

  const handleCloseCreateSubsetDialog = () => {
    setOpenCreateSubset(false);
  };

  const handleCreateNewSubset = () => {
    if (
      selectedView === AnnotatableEnum.MediaObject &&
      createNewCroppingConfig
    ) {
      postCreateVisualisationConfiguration(
        params.id,
        croppingName.value,
        {
          type: "crop",
          padding_percent: croppingForm.percentPadding.value,
          padding_minimum: croppingForm.minPadding.value,
        },
        dispatch,
      ).then((response) => {
        const configID = response?.data?.id;
        setCroppingConfigID({
          ...croppingConfigID,
          value: configID,
        });
        // Update the visualisation configuration
        dispatch(
          fetchVisualisationConfiguration({
            query: { datasetID: params?.id },
          }),
        );
        // Create the new subset
        createNewSubset(configID);
      });
    } else {
      createNewSubset(croppingConfigID?.value);
    }
  };

  const createNewSubset = (visualisation_config_id: string) => {
    const parentDatasetID = dataSetData?.activeParentDataset?.id;
    const subsetType: SubsetTypeModel =
      getSubsetTypeFromBasedOnSelectedView(selectedView);

    const filters: { filter_options: SendFilterModel[] } = {
      filter_options: getQueryBasedOnType(
        selectedItemsType,
        selectedView,
        {
          filterData: filterData,
          subset_id: params?.subset_id,
        },
        {
          selectedMediaIDs: selectedMediaIDs,
          selectedMediaObjectsIDs: selectedMediaObjectsIDs,
          selectedInstancesIDs: selectedInstancesIDs,
        },
      ),
    };

    if (parentDatasetID && subsetType) {
      let body: {
        filter_options: SendFilterModel[];
        secondary_filter_options?: SendFilterModel[];
      } = {
        filter_options: filters?.filter_options,
      };
      if (subsetOption === "specificObjects") {
        body = {
          ...body,
          secondary_filter_options: [
            {
              attribute: "subset_ids",
              query_operator: "in",
              value: objectSubsetsToInclude,
            },
          ],
        };
      }
      postCreateSubset(
        {
          parentDatasetID: parentDatasetID,
          subsetType: subsetType,
          newName: subsetName?.value,
          sampleSize: sampleSize.value,
          visualisation_config_id: visualisation_config_id,
        },
        body,
        dispatch,
        setIsLoading,
      ).then((newSubsetID) => {
        dispatch(
          fetchSubsets({
            query: { parentDataSetID: parentDatasetID || "" },
          }),
        );
        pushHistory(parentDatasetID, newSubsetID);
        setOpenCreateSubset(false);
      });
    }
  };
  const renderCreatingSubset = () => {
    return (
      <div className="my-2 flex gap-x-3 justify-center text-2xl text-paletteGray-10">
        Creating subset...
        <div>
          <Loading size={20} />
        </div>
      </div>
    );
  };

  const renderNewSubset = () => {
    return (
      <div className="pb-6" data-test="new_subset_name">
        <div className="text-paletteGray-10 py-2">Name your new subset</div>
        <TextInput
          field={{ ...subsetName, disabled: isLoading }}
          value={subsetName?.value}
          handleOnChange={(e, field, isErrored, error) =>
            setSubsetName({
              ...subsetName,
              value: e.target.value,
              isErrored: isErrored || false,
              error: error || null,
            })
          }
        />
      </div>
    );
  };

  const renderSlider = () => {
    if (subsetOption === "random") {
      const maxValue = getSliderMaxValue();
      return (
        <div className="pb-6 text-paletteGray-10 pr-4">
          Sample size:
          <div className="w-full flex gap-x-3 items-center py-2">
            <input
              type="number"
              data-test="subset_size_input"
              value={sampleSize.value}
              min={0}
              max={maxValue}
              onChange={(event: React.FormEvent<HTMLInputElement>) => {
                event.currentTarget.value = String(
                  parseInt(event?.currentTarget?.value, 10),
                );
                const numericValue = parseInt(event.currentTarget.value, 10);
                if (numericValue >= maxValue) {
                  setSampleSize({ ...sampleSize, value: maxValue });
                } else if (numericValue <= 0 || isNaN(numericValue)) {
                  setSampleSize({ ...sampleSize, value: 0 });
                } else {
                  setSampleSize({ ...sampleSize, value: numericValue });
                }
              }}
              className="input-text !w-1/5"
              disabled={isLoading}
            />
            <div className="flex-1" data-test="subset_size_slider">
              <SingleSliderInput
                field={{ ...sampleSize, disabled: isLoading }}
                value={sampleSize.value}
                handleOnChange={(event, field, isErrored, error) => {
                  setSampleSize({
                    ...sampleSize,
                    value: parseFloat(event.target.value),
                    isErrored: isErrored || false,
                    error: error || null,
                  });
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  function getSliderMaxValue() {
    // If all are selected, then the max value is the total count
    if (selectedItemsType === SelectedItemsTypeModel.all) {
      const countKey = determineCountKeyFromParam(selectedView);
      if (!countKey) return 0;
      return metaDataSlice?.[countKey]?.total_count;
    }
    // If not all are selected, then the max value is the number of selected items
    else {
      return getSelectedItemsBasedOnSelectedView(
        selectedView,
        selectedMediaIDs,
        selectedMediaObjectsIDs,
        selectedInstancesIDs,
      )?.length;
    }
  }

  const renderCategoriesSelection = () => {
    if (subsetOption === "specificObjects") {
      return (
        <>
          <div className="text-md font-bold pb-1">Select object subsets:</div>
          <TagsSelector
            items={allSubsets}
            selectedItems={_.pickBy(allSubsets, (tag) =>
              _.includes(objectSubsetsToInclude, tag?.id),
            )}
            handleAddTag={(tag) =>
              setObjectSubsetsToInclude([...objectSubsetsToInclude, tag?.id])
            }
            handleRemoveTag={(tag) => {
              setObjectSubsetsToInclude(
                _.difference(objectSubsetsToInclude, [tag?.id]),
              );
            }}
            addLabel="Add category"
            disabled={isLoading}
          />
        </>
      );
    }
  };

  const renderCroppingForm = () => {
    const isUserCustomContract = checkIfUserHavePermission(
      keycloak,
      "accessCropConfigFeature",
    );
    if (selectedView !== AnnotatableEnum.MediaObject || !isUserCustomContract)
      return null;

    return (
      <div className="flex flex-col gap-y-2 mb-4">
        <div className="pb-1 mb-4 border-b-2 font-medium">
          Cropping configurations:
        </div>
        {!createNewCroppingConfig && renderCreateNewCroppingConfigSelect()}
        <button
          className={`button-layer w-1/2 ${
            createNewCroppingConfig ? "bg-paletteOrange" : ""
          }`}
          onClick={() => {
            setCroppingConfigID({
              ...croppingConfigID,
              value: "",
            });
            setCroppingName({
              ...croppingName,
              value: "",
            });
            setCreateNewCroppingConfig(!createNewCroppingConfig);
          }}
        >
          {createNewCroppingConfig
            ? "Use existing config"
            : "Create new config"}
        </button>
        {createNewCroppingConfig && renderCreateNewCroppingConfigForm()}
      </div>
    );
  };

  const renderCreateNewCroppingConfigSelect = () => {
    return (
      <SimpleSelect
        field={croppingConfigID}
        value={croppingConfigID?.value}
        handleOnChange={(e) =>
          setCroppingConfigID({
            ...croppingConfigID,
            value: e.target.value,
          })
        }
      />
    );
  };

  const renderCreateNewCroppingConfigForm = () => {
    return (
      <div>
        <TextInput
          field={croppingName}
          value={croppingName.value}
          handleOnChange={(event, field, isErrored, error) => {
            setCroppingName({
              ...croppingName,
              value: event.target.value,
              isErrored: isErrored || false,
              error: error || null,
            });
          }}
        />
        {_.map(croppingForm, (field, key) => (
          <div key={`cropping_${key}`} className="">
            <NumberInput
              field={field}
              value={field.value as number}
              handleOnChange={(event, field, isErrored, error) => {
                setCroppingForm({
                  ...croppingForm,
                  [key]: {
                    ...field,
                    value: parseFloat(event.target.value),
                    isErrored: isErrored || false,
                    error: error || null,
                  },
                });
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  const isCreateSubsetDisabled = () => {
    const isMediaObjectView = selectedView === AnnotatableEnum.MediaObject;
    return (
      subsetName?.isErrored ||
      subsetName?.value === "" ||
      subsetName?.value?.length < 3 ||
      isLoading ||
      (isMediaObjectView &&
        croppingConfigID?.value === "" &&
        (croppingName?.value === "" || croppingName?.value?.length < 3))
    );
  };
  const displayView =
    selectedView === "MediaObject" ? "object" : _.lowerFirst(selectedView);

  // ------------------------------------------- Render Component ------------------------------------
  return (
    <Dialog
      fullWidth={true}
      open={openCreateSubset}
      onClose={handleCloseCreateSubsetDialog}
    >
      <DialogContent className="w-full p-4">
        <div className="flex pb-6">
          <div className="text-lg font-medium">
            New {displayView} subset from {selectedItemsType} items
          </div>
          <Close onClick={handleCloseCreateSubsetDialog} className="ml-auto" />
        </div>

        {renderNewSubset()}
        {renderSlider()}
        {renderCategoriesSelection()}
        {renderCroppingForm()}

        <div className="w-full flex flex-row-reverse">
          <button
            className="button-layer"
            data-test="create_subset_button"
            disabled={isCreateSubsetDisabled()}
            onClick={handleCreateNewSubset}
          >
            Create and open
          </button>
        </div>

        {isLoading && renderCreatingSubset()}
      </DialogContent>
    </Dialog>
  );
};

export default NewSubsetDialog;
