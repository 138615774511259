import { ActiveFilterModel } from "../../models/filter.model";
import { AttributeMetadataModel } from "models/attributes.model";
import { DatasetModel } from "../../models/dataset.model";
import { TagModel } from "models/global.model";
import _ from "lodash";
import getSubsetName from "./getSubsetName";

/**
 * A helper function to create a display string for a filter
 * @param filter The filter to create a string for
 * @param attributesMeta The attributes metadata used to get the name of the attribute
 * @param subsets The subsets used to get the name of the subset
 * @param tags The tags used to get the name of the tag
 * @returns The display string for the filter
 */
const createFilterString = (
  filter: ActiveFilterModel,
  attributesMeta: AttributeMetadataModel[],
  subsets: DatasetModel[],
  tags: TagModel[]
): string => {
  let filterString = "";

  // Get the name of the attribute
  let name = filter?.name;
  const attributeID = _.split(filter?.key, ".")[1];
  const attribute = attributesMeta.find((i) => i.id === attributeID);
  if (attribute) {
    const attributeField = filter?.key.split(".")[2];
    name = attribute.name + " " + attributeField;
  }

  const value = filter?.value;
  switch (filter?.type) {
    case "NUMERICAL":
      if (
        _.isArray(value) &&
        _.isNumber(value?.[0]) &&
        _.isNumber(value?.[1])
      ) {
        const lowerInclude = filter?.include_edges ? "[" : "(";
        const upperInclude = filter?.include_edges ? "]" : ")";

        const mainValues =
          lowerInclude +
          _.round(value[0], 2) +
          " - " +
          _.round(value[1], 2) +
          upperInclude;

        // Create an extra explanation part for the filter
        // in the case of a NOT filter
        let explainPart = "";
        if (
          filter?.is_not &&
          _.isNumber(filter?.lower) &&
          _.isNumber(filter?.upper)
        ) {
          explainPart = "";
          let firstPart = "";
          let secondPart = "";

          // If the lower bound and upper bound are the inclusive edges
          if (lowerInclude === "[" && upperInclude === "]") {
            firstPart = `[${_.round(filter?.lower, 2)} - ${_.round(
              value[0],
              2
            )})`;
            secondPart = `(${_.round(value[1], 2)} - ${_.round(
              filter?.upper,
              2
            )}]`;
          }
          // If the lower and upper bound are the exclusive edges
          else if (lowerInclude === "(" && upperInclude === ")") {
            firstPart = `[${_.round(filter?.lower, 2)} - ${_.round(
              value[0],
              2
            )}]`;
            secondPart = `[${_.round(value[1], 2)} - ${_.round(
              filter?.upper,
              2
            )}]`;
          }

          // Only add the first part if the lower bound is not same as the filter lower
          if (filter?.lower !== value[0]) {
            explainPart += firstPart;
          }
          // Only add the second part if the upper bound is not same as the filter upper
          if (filter?.upper !== value[1]) {
            explainPart += " - " + secondPart;
          }
        }

        filterString = name + ": ";
        if (filter?.is_not === false) {
          filterString += mainValues;
        } else {
          filterString += explainPart;
        }
      }
      break;
    case "SEARCH_BY_TEXT": {
      const selected_cats = filter?.value;
      const isNotString = filter?.is_not ? " (Not)" : "";
      if (_.isArray(selected_cats)) {
        let stringToDisplay = _.join(selected_cats, " , ");
        if (selected_cats.length > 1) {
          stringToDisplay = _.join(
            [selected_cats?.[0], `+${selected_cats?.length - 1}`],
            " , "
          );
        }
        return name + isNotString + ": " + stringToDisplay;
      }
      break;
    }
    case "CATEGORICAL": {
      const selected_cats = filter?.categories_value?.selected_cats;
      const not_selected_cats = filter?.categories_value?.not_selected_cats;
      if (_.isArray(selected_cats) && _.isArray(not_selected_cats)) {
        return (
          name +
          ": " +
          "include:" +
          _.join(selected_cats, " , ") +
          " , exclude: " +
          _.join(not_selected_cats, " , ")
        );
      }
      break;
    }
    case "BOOLEAN":
      filterString = name + ": " + _.join(filter?.value as [], " , ");
      break;
    case "SUBSET":
    case "FE_SUBSET":
    case "FE_TAG":
      if (!_.isUndefined(subsets)) {
        let tagsNames: string[] = [];
        _.map(filter?.value as string[], (i) => {
          tagsNames = [...tagsNames, getSubsetName(i as string, subsets, tags)];
        });
        const not = filter?.is_not ? "(Not) " : "";
        filterString = name + not + ": " + _.join(tagsNames, " , ");
      }
      break;
    case "SELECT_ATTRIBUTE": {
      const exclude = filter?.is_not ? " (Excluded)" : "";
      filterString = attribute?.name + exclude;
      break;
    }
  }
  return filterString;
};
export default createFilterString;
