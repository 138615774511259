type Props = {
  label: string;
  color?: string;
  circleColor?: string;
  bgColor?: string;
  showCircle?: boolean;
};

const StatusLabelFilled = ({
  label,
  color = "text-white",
  circleColor = "bg-white",
  bgColor = "bg-paletteGray-13",
  showCircle = true,
}: Props) => {
  const renderCircle = () => {
    if (!showCircle) return null;

    return <div className={`w-[6px] h-[6px] rounded-full ${circleColor}`} />;
  };

  return (
    <div
      className={`px-2 py-[1.5px] rounded-xl w-fit flex flex-row 
        items-center gap-x-2
        ${bgColor}
      `}
    >
      {renderCircle()}
      <div className={`text-sm font-light ${color}`}>{label}</div>
    </div>
  );
};

export default StatusLabelFilled;
