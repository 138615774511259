import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import FormErrorLabel from "components/Internal/Inputs/Form/ErrorLabel";
import FormLabel from "components/Internal/Inputs/Form/Label";
import { validateSelectInput } from "components/Internal/Inputs/Form/formInputsHelpers";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import { SelectFieldModel, SelectFieldOptionModel } from "models/form.model";
import { ReactComponent as ArrowDown } from "assets/arrow_small_down.svg";
import TextInput from "components/Internal/Inputs/Form/TextInput";
import FormErrorCircle from "components/Internal/Inputs/Form/ErrorCircle";

type Props = {
  field: SelectFieldModel;
  value: string;
  handleOnChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
    field: SelectFieldModel,
    isErrored?: boolean,
    error?: string | null,
  ) => void;
};

const SimpleSelect = ({ field, value, handleOnChange }: Props) => {
  const addButtonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const screenSize = useWindowDimensions();

  // Dropdown states
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
    position: "down" | "up";
  }>({
    top: 0,
    left: 0,
    position: "down",
  });

  const defaultDropdownWidth = 336;
  const dropdownHeight = 250;

  const [searchValue, setSearchValue] = useState("");
  const [dropdownWidth, setDropdownWidth] = useState(defaultDropdownWidth);

  // Handle width of dropdown menu
  useEffect(() => {
    if (addButtonRef?.current) {
      const buttonWidth = addButtonRef?.current?.offsetWidth;
      buttonWidth === 0
        ? setDropdownWidth(defaultDropdownWidth)
        : setDropdownWidth(buttonWidth);
    }
  }, []);

  // Handle click away or scroll to close
  useEffect(() => {
    function handleClickOrScrollOutside(event: any) {
      if (dropdownRef?.current) {
        const temp = dropdownRef as any;
        if (
          !temp?.current?.contains(event.target) &&
          !addButtonRef?.current?.contains(event.target)
        ) {
          setShowDropdown(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOrScrollOutside);
    document.addEventListener("wheel", handleClickOrScrollOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOrScrollOutside);
      document.removeEventListener("wheel", handleClickOrScrollOutside);
    };
  }, []);

  const renderBody = () => {
    const sameLine = field?.style?.labelAndFieldOnTheSameLine;
    if (sameLine) {
      return renderSameLineAlignment();
    }

    return renderMultiLineAlignment();
  };

  const renderMultiLineAlignment = () => {
    return (
      <div key={field?.key} className={`w-full ${field?.style?.wrapper}`}>
        <FormLabel field={field} />
        {renderAddButton()}
        {showDropdown && renderDropdown()}
        <FormErrorLabel
          error={field?.error ?? null}
          validationError={validateSelectInput(field)}
        />
      </div>
    );
  };

  const renderSameLineAlignment = () => {
    return (
      <div
        key={field?.key}
        className={`flex justify-between items-center
          ${field?.style?.wrapper}
      `}
      >
        <div className="w-1/2">
          <FormLabel field={field} />
        </div>
        <div className="w-1/2 flex items-center">
          {renderAddButton()}
          {showDropdown && renderDropdown()}
          <FormErrorCircle
            error={field?.error ?? null}
            validationError={validateSelectInput(field)}
          />
        </div>
      </div>
    );
  };

  const renderAddButton = () => {
    const labelOfValue = _.find(field?.options, (item) => item.value === value)
      ?.label;
    const placeholder = field?.placeholder || "--Select item--";
    const inputClassName = field?.style?.field;
    return (
      <div
        ref={addButtonRef}
        className={`w-full h-[28] flex items-center py-2 px-4 space-x-2 text-sm
         bg-paletteGray-3 hover:bg-paletteGray-5 rounded-[8px] cursor-pointer
         ${inputClassName ? inputClassName : ""}
         `}
        data-test="cropping_configuration_selector"
        onClick={() => {
          handleOpenDropdown(addButtonRef);
        }}
      >
        <div
          id="tags-selector-add-button"
          className={`min-w-0 flex-1 ${
            field?.value?.length > 0 ? "" : "text-paletteGray-9"
          }`}
        >
          {field?.value?.length > 0 ? labelOfValue : placeholder}
        </div>
        <ArrowDown
          className={`w-4 h-4 text-paletteGray-7
              ${showDropdown ? "transform rotate-180" : "transform rotate-0"}`}
        />
      </div>
    );
  };

  const renderDropdown = () => {
    return (
      <div
        id="dropdown-tags-selector"
        data-test="selector_dropdown"
        ref={dropdownRef}
        className="fixed bg-white shadow-[0_0px_20px_rgba(0,0,0,0.2)] rounded-[8px] mb-4
          flex flex-col"
        style={{
          width: `${dropdownWidth}px`,
          maxHeight: `${dropdownHeight}px`,
          top: dropdownPosition.top,
          left: dropdownPosition.left,
          zIndex: 999,
        }}
      >
        {renderSearchInput()}
        {renderOptions()}
      </div>
    );
  };

  const renderSearchInput = () => {
    return (
      <TextInput
        field={{
          type: "text",
          key: "select_search",
          placeholder: "Search...",
          value: searchValue,
          required: false,
          error: null,
          style: {
            field: "border-none",
          },
        }}
        value={searchValue}
        handleOnChange={(event) => {
          setSearchValue(event.target.value);
        }}
        autoFocus={true}
      />
    );
  };

  const renderOptions = () => {
    let filteredOptions = field?.options;
    if (searchValue) {
      filteredOptions = _.filter(field?.options, (item) => {
        return _.includes(item?.label.toLowerCase(), searchValue.toLowerCase());
      });
    }

    return (
      <div
        id="dropdown-tags-selector-options"
        className="w-full min-h-1 flex-1 overflow-y-auto p-1"
      >
        {_.map(filteredOptions, (item) => {
          return (
            <div
              className={`button-select-layer ${
                value === item?.value ? "bg-paletteGray-3" : ""
              }`}
              key={item?.value}
              onClick={() => handleOnOptionClick(item)}
            >
              {item?.label}
            </div>
          );
        })}
      </div>
    );
  };

  const handleOnOptionClick = (item: SelectFieldOptionModel) => {
    const validationError = validateSelectInput(field);
    const isError = !_.isNull(validationError);
    handleOnChange(
      // as we are only interested in the value
      {
        target: {
          value: item.value,
        },
      } as React.ChangeEvent<HTMLSelectElement>,
      field,
      isError,
      validationError,
    );
    setSearchValue("");
    setShowDropdown(false);
  };

  const handleOpenDropdown = (clickedRef: React.RefObject<HTMLDivElement>) => {
    if (!field?.disabled) {
      if (!showDropdown) {
        if (clickedRef.current) {
          const newPosition = clickedRef?.current?.getBoundingClientRect();
          const left = newPosition?.left;
          setShowDropdown(true);

          // Render dropdown under select
          if (
            newPosition?.top + newPosition?.height + dropdownHeight <
            screenSize?.height
          ) {
            setDropdownPosition({
              top: newPosition?.top + newPosition?.height,
              left: left,
              position: "down",
            });
          }
          // Render dropdown above select
          else {
            setDropdownPosition({
              top: newPosition?.top - 16 - dropdownHeight,
              left: left,
              position: "up",
            });
          }
        }
      } else setShowDropdown(false);
    }
  };

  return renderBody();
};

export default SimpleSelect;
