import { useEffect, useState } from "react";
import _ from "lodash";
import { fetchDatasets } from "helpers/apis/datasets";
import { DatasetModel } from "models/dataset.model";

import SortButton, {
  SortButtonModel,
} from "components/Internal/Buttons/SortButton";
import Loading from "components/UtilComponents/Loading";
import DatasetSelectionTableRow from "components/Internal/Table/DatasetSelectionTable/DatasetSelectionTableRow";
import { getSubsetsForDataset } from "helpers/apis/subsets";
import { ReactComponent as BackIcon } from "assets/arrow_left.svg";
import generateDateString from "helpers/functions/generateDateString";
import { ReactComponent as SearchIcon } from "assets/search.svg";
import { ReactComponent as RightArrowIcon } from "assets/arrow_right.svg";

import { useAppDispatch } from "store/hooks";
import { Tooltip } from "@mui/material";
import RadioButton from "components/Internal/Inputs/RadioButton";
import { VisibilityStatus } from "models/global.model";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";

type Props = {
  selectedDataset: DatasetModel | null;
  setSelectedDataset: (dataset: DatasetModel | null) => void;
  selectedSubset: DatasetModel | null;
  setSelectedSubset: (subset: DatasetModel | null) => void;
  enableSearch?: boolean;
  extraKeysToSearch?: string[];
  onSubRowClick?: (params: {
    clickedDataset: DatasetModel | null;
    clickedSubset: DatasetModel | null;
  }) => void;
  disableReason?: string;
};

interface DatasetSelectionTableColumn {
  field: string;
  headerName: string;
  span?: number;
  sortable?: boolean;
  className?: string;
  cell?: (row: unknown) => JSX.Element;
}

const DatasetSelectionTable = ({
  selectedDataset,
  setSelectedDataset,
  selectedSubset,
  setSelectedSubset,
  enableSearch = true,
  extraKeysToSearch,
  onSubRowClick,
  disableReason,
}: Props) => {
  const dispatch = useAppDispatch();

  const [selectType, setSelectType] = useState<"dataset" | "subset">("dataset");
  const [isLoading, setIsLoading] = useState(false);
  const [datasets, setDatasets] = useState<DatasetModel[]>([]);
  const [subsets, setSubsets] = useState<DatasetModel[]>([]);

  const [localSearchValue, setLocalSearchValue] = useState<string>("");

  const [sortBy, setSortBy] = useState<SortButtonModel>({
    name: "name",
    direction: "asc",
  });

  useEffect(() => {
    fetchDatasets(false, [VisibilityStatus.Visible], setIsLoading).then(
      (data) => {
        setDatasets(data);
      },
    );
  }, []);

  const baseColumns: DatasetSelectionTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 40,
    },
    {
      field: "num_medias",
      headerName: "Medias",
    },
    {
      field: "num_media_objects",
      headerName: "Objects",
    },
    {
      field: "creation_timestamp",
      headerName: "Created",
      span: 20,
      cell: (file) => {
        const dataset = file as DatasetModel;
        return (
          <div className="text-sm">
            {!_?.isNull(dataset?.creation_timestamp)
              ? generateDateString(new Date(dataset?.creation_timestamp), false)
              : "-"}
          </div>
        );
      },
    },
  ];

  const datasetsColumns: DatasetSelectionTableColumn[] = [...baseColumns];

  const subsetsColumns: DatasetSelectionTableColumn[] = [
    ...baseColumns,
    {
      field: "subset_type",
      headerName: "Subset type",
      cell: (row) => {
        const subset = row as DatasetModel;
        return (
          <TooltipTruncateEllipsis>
            {subset.subset_type === "media_object"
              ? "Object"
              : _.upperFirst(subset.subset_type)}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "selected",
      headerName: "",
      sortable: false,
      span: 4,
      cell: (row) => {
        const subset = row as DatasetModel;
        return (
          <div className="flex justify-end">
            <RadioButton
              checked={subset?.id === selectedSubset?.id}
              onChange={() => handleRowClick(subset)}
            />
          </div>
        );
      },
    },
  ];

  // Render the header of the table (the column names) and the sort buttons
  const renderHeader = () => {
    const columns = selectType === "dataset" ? datasetsColumns : subsetsColumns;
    return (
      <div
        className="w-auto flex mt-3 py-[6px] px-3 pr-6 text-paletteGray-8
         bg-paletteGray-1 rounded-lg border-b border-paletteGray-3"
      >
        {_.map(columns, (col, key) => {
          const columnSpan = col?.span || 100 / columns?.length;
          return (
            <div
              key={key}
              className="flex items-center gap-x-2"
              style={{ width: `${columnSpan}%` }}
            >
              {col?.headerName}
              {_.isUndefined(col?.sortable) && renderSortIcon(col)}
            </div>
          );
        })}
      </div>
    );
  };

  // Render the sort button
  const renderSortIcon = (col: DatasetSelectionTableColumn) => {
    return (
      <SortButton
        sortBy={{ name: col?.field, direction: sortBy?.direction }}
        setSortBy={setSortBy}
        selected={sortBy?.name === col?.field}
      />
    );
  };

  // Render the rows of the table (the data)
  const renderRows = () => {
    const rows = selectType === "dataset" ? datasets : subsets;

    const filteredRows = _.filter(rows, (row) => {
      const nameMatch = _.includes(
        _.toLower(row?.name as string),
        _.toLower(localSearchValue),
      );

      const extraKeysToSearchMatch = _.some(extraKeysToSearch, (key) => {
        return _.includes(
          _.toLower(_.get(row, key)),
          _.toLower(localSearchValue),
        );
      });

      return nameMatch || extraKeysToSearchMatch;
    });

    let sortedRows = filteredRows;
    const sortName = sortBy?.name;
    if (!_.isNull(sortName) && !_.isNull(sortBy?.direction)) {
      sortedRows = _.orderBy(
        filteredRows,
        [
          (row) => {
            const sortKey = _.get(row, sortName);
            if (_.isString(sortKey)) {
              return _.toLower(sortKey);
            }
            return sortKey;
          },
        ],
        [sortBy?.direction],
      );
    }

    return _.map(sortedRows, (row) => {
      const isSelected =
        selectType === "dataset"
          ? row?.id === selectedDataset?.id
          : row?.id === selectedSubset?.id;
      return (
        <DatasetSelectionTableRow
          key={row?.id}
          row={row}
          onRowClick={handleRowClick}
          renderRowCells={renderRowCells}
          isSelected={isSelected}
        />
      );
    });
  };

  // Render the cells of the table (the data) for each row
  const renderRowCells = (row: DatasetModel) => {
    const columns = selectType === "dataset" ? datasetsColumns : subsetsColumns;
    return _.map(columns, (col, index) => {
      const columnSpan = col?.span || 100 / columns?.length;

      return (
        <div
          key={`${index}-${row.id}-${col?.field}`}
          className="flex items-center gap-x-2 text-ellipsis overflow-hidden"
          style={{
            width: `${columnSpan}%`,
          }}
        >
          {renderCellValue(row, col)}
        </div>
      );
    });
  };

  const renderCellValue = (
    row: DatasetModel,
    col: DatasetSelectionTableColumn,
  ) => {
    // If the cell is a custom cell, use the cell function to render the cell
    // Else, use the field to render the cell
    const cellValue = col?.cell ? col?.cell(row) : _.get(row, col?.field);

    if (_.isElement(cellValue)) {
      return cellValue;
    }

    if (_.isString(cellValue) || _.isNumber(cellValue)) {
      return (
        <TooltipTruncateEllipsis className="pr-3">
          {cellValue}
        </TooltipTruncateEllipsis>
      );
    }

    return cellValue;
  };

  const renderSearchBar = () => {
    if (enableSearch) {
      return (
        <div className="w-1/2 flex gap-x-1 items-center">
          <SearchIcon
            className="w-4 h-4 text-paletteGray-10 "
            strokeWidth={2}
          />
          <input
            type="text"
            id="table_search_bar"
            key={selectType}
            className="input-text flex-1 p-2 border-0 text-base 
              !bg-transparent focus:border-transparent placeholder:text-paletteGray-11"
            placeholder={
              selectType === "dataset"
                ? "Search for a dataset"
                : "Search for a subset"
            }
            value={localSearchValue}
            onChange={(e) => setLocalSearchValue(e.target.value)}
            autoFocus
          />
        </div>
      );
    }
  };

  const renderCountNumber = () => {
    const count = selectType === "dataset" ? datasets.length : subsets.length;
    const type = selectType === "dataset" ? "datasets" : "subsets";
    return (
      <div className="flex items-center text-sm text-paletteGray-11">
        {count} {type} found
      </div>
    );
  };

  const renderSelectedDataset = () => {
    if (selectType === "dataset" || selectedDataset === null) {
      return null;
    }

    return (
      <div className="pt-3 flex gap-x-2 items-center">
        <BackIcon
          className="w-[18px] h-[18px] text-paletteGray-8 cursor-pointer hover:text-paletteGray-10"
          onClick={handleBackClick}
        />
        <div className="label-layer text-paletteBlack-1 bg-paletteGray-3">
          {selectedDataset?.name}
        </div>
        <div className="text-sm text-paletteGray-9 ">
          {selectedDataset?.num_medias} media ·{" "}
          {selectedDataset?.num_media_objects} objects
        </div>
      </div>
    );
  };

  const handleBackClick = () => {
    if (selectType === "subset") {
      setSelectType("dataset");
      setSelectedDataset(null);
      setSelectedSubset(null);
      setLocalSearchValue("");
    }
  };

  const handleRowClick = (row: DatasetModel) => {
    if (selectType === "dataset") {
      setSelectType("subset");
      setSelectedDataset(row);
      setSelectedSubset(null);
      setLocalSearchValue("");
      onSubRowClick &&
        onSubRowClick({ clickedDataset: row, clickedSubset: null });
      getSubsetsForDataset(row.id, dispatch, setIsLoading).then((data) => {
        if (data !== null) {
          setSubsets(data);
        }
      });
    } else if (selectType === "subset") {
      setSelectedSubset(row);
    }
  };

  // Render the body of the table
  const renderBody = () => {
    // If the table is loading, show the loading component
    if (!_.isUndefined(isLoading) && isLoading) {
      return (
        <div className="h-1/2">
          <Loading />
        </div>
      );
    }
    // If the table is not loading and there is no data, show the no data found message
    else if (_.isEmpty(datasets)) {
      return (
        <div className="text-paletteGray-10 text-center">No data found</div>
      );
    }
    // If the table is not loading and there is data, show the table
    else {
      return (
        <div className="h-full flex flex-col">
          <div className="w-full py-3 px-5 flex justify-between border-b-[1px] border-paletteGray-4">
            {renderSearchBar()}
            {renderCountNumber()}
          </div>
          <div className="px-4 flex flex-col flex-1 min-h-0">
            {renderSelectedDataset()}
            {renderHeader()}
            <div className="mt-3 flex-1 overflow-y-scroll flex flex-col gap-y-2">
              {renderRows()}
            </div>
          </div>
        </div>
      );
    }
  };

  return renderBody();
};

export default DatasetSelectionTable;
