import _ from "lodash";
import {
  FilterModel,
  SendFilterComparisonModel,
  SendFilterLogicModel,
  SendFilterModel,
} from "models/filter.model";
import { QueryOperatorModel } from "models/global.model";

const convertFilterModelToSendFilterModel = (
  filterModel: FilterModel[]
): SendFilterModel[] => {
  let sendFilterModel: SendFilterModel[] = [];
  _.map(filterModel, (filter) => {
    switch (filter?.type) {
      case "CATEGORICAL": {
        if (!_.isEmpty(filter?.selected_cats)) {
          const categoricalFilter: SendFilterComparisonModel = {
            attribute: filter?.key,
            query_operator: "in",
            value: filter?.selected_cats,
          };
          sendFilterModel = [...sendFilterModel, categoricalFilter];
        }
        if (!_.isEmpty(filter?.not_selected_cats)) {
          const categoricalFilter: SendFilterComparisonModel = {
            attribute: filter?.key,
            query_operator: "not in",
            value: filter?.not_selected_cats,
          };
          sendFilterModel = [...sendFilterModel, categoricalFilter];
        }
        break;
      }
      case "SEARCH_BY_TEXT":
      case "SUBSET":
      case "FE_SUBSET":
      case "FE_TAG":
      case "BOOLEAN":
        if (!_.isEmpty(filter?.selected_cats)) {
          let operator: QueryOperatorModel = "in";
          if (filter?.FE_is_not === true) {
            operator = "not in";
          }
          const filterToAdd: SendFilterComparisonModel = {
            attribute: filter?.key,
            query_operator: operator,
            value: filter?.selected_cats,
          };
          sendFilterModel = [...sendFilterModel, filterToAdd];
        }
        break;
      case "NUMERICAL":
        if (
          filter?.lower_bound !== filter?.lower ||
          filter?.upper_bound !== filter?.upper
        ) {
          // If the filter is a normal filter
          if (filter?.FE_is_not === false) {
            let lowerOperator: QueryOperatorModel = ">=";
            let upperOperator: QueryOperatorModel = "<=";
            // If the filter is not including the edges
            if (filter?.FE_include_edges === false) {
              lowerOperator = ">";
              upperOperator = "<";
            }
            const lowerNumericalFilter: SendFilterComparisonModel = {
              attribute: filter?.key,
              query_operator: lowerOperator,
              value: filter?.lower_bound,
            };
            const upperNumericalFilter: SendFilterComparisonModel = {
              attribute: filter?.key,
              query_operator: upperOperator,
              value: filter?.upper_bound,
            };
            sendFilterModel = [
              ...sendFilterModel,
              lowerNumericalFilter,
              upperNumericalFilter,
            ];
          }
          // If the filter is a not filter
          else if (filter?.FE_is_not === true) {
            // We flip the operators on the NOT filter and switch the
            //  equality of the edges to get the correct NOT filter
            let lowerOperator: QueryOperatorModel = "<";
            let upperOperator: QueryOperatorModel = ">";
            // If the filter is not including the edges
            if (filter?.FE_include_edges === false) {
              lowerOperator = "<=";
              upperOperator = ">=";
            }

            const lowerNumericalFilter: SendFilterComparisonModel = {
              attribute: filter?.key,
              query_operator: lowerOperator,
              value: filter?.lower_bound,
            };
            const upperNumericalFilter: SendFilterComparisonModel = {
              attribute: filter?.key,
              query_operator: upperOperator,
              value: filter?.upper_bound,
            };

            let queries: SendFilterComparisonModel[] = [];
            if (filter?.lower_bound !== filter?.lower) {
              queries = [...queries, lowerNumericalFilter];
            }
            if (filter?.upper_bound !== filter?.upper) {
              queries = [...queries, upperNumericalFilter];
            }

            const orLogicalFilter: SendFilterLogicModel = {
              operator: "or",
              queries: queries,
            };
            sendFilterModel = [...sendFilterModel, orLogicalFilter];
          }
        }
        break;
      case "SELECT_ATTRIBUTE":
        if (filter?.FE_value && !_.isEmpty(filter?.FE_value)) {
          let operator: QueryOperatorModel = "==";
          if (filter?.FE_is_not === true) {
            operator = "!=";
          }
          const selectAttributeFilter: SendFilterComparisonModel = {
            attribute: filter?.key,
            query_operator: operator,
            value: filter?.FE_value,
          };
          sendFilterModel = [...sendFilterModel, selectAttributeFilter];
        }
        break;
    }
  });

  return sendFilterModel;
};

export default convertFilterModelToSendFilterModel;
