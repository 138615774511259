import snackbarHelper from "components/Helpers/snackbarHelperFn";
import {
  APIFetchAxios,
  APIPostWithBodyAxios,
} from "components/UtilComponents/Auth";
import _ from "lodash";
import {
  ExplorationAPIRequestModel,
  InstanceModel,
  SortingRequestModel,
} from "models/exploration.model";
import { ActiveFiltersModel, SendFilterModel } from "models/filter.model";
import { MetaDataCountModel } from "models/metaData.model";
import {
  explorationInstanceStateTypes,
  fetchInstanceData,
  updateInstanceExplorationHasMore,
  updateInstanceExplorationSkip,
} from "store/explorationInstancesSlice";
import convertActiveFilterToSendFilter from "helpers/functions/filters/convertActiveFilterToSendFilter";

const scrollLoadAmount = Number(
  process.env.REACT_APP_SCROLL_LOAD_AMOUNT ??
    alert("Config value 'scroll load amount' is not defined")
);

// Post request to instances, used to fetch instances with a query
export const fetchInstances = async (
  body: ExplorationAPIRequestModel
): Promise<InstanceModel[]> => {
  const response = await APIFetchAxios(
    `/datasets/${body?.dataset_id}/instances`,
    body
  );
  return response?.data;
};

export const fetchInstancesCount = async (
  datasetID: string,
  body: SendFilterModel[],
  dispatch?: any
): Promise<MetaDataCountModel> => {
  const response = await APIFetchAxios(
    `/datasets/${datasetID}/instances:count`,
    { query: body }
  ).catch((error) => {
    let msg = error?.details;
    if (_.isUndefined(error?.details)) {
      msg = "Something went wrong! Fetching instances count failed!";
    }
    snackbarHelper(dispatch, msg, "error");
  });
  return response?.data;
};

// Post request to one instance
export const fetchOneInstanceAPI = async (
  instanceID: string,
  datasetID: string
): Promise<InstanceModel> => {
  const response = await APIFetchAxios(
    `/datasets/${datasetID}/instances/${instanceID}`,
    { projection: null }
  );
  return response?.data;
};

export const fetchInstancesDataScroll = (
  dispatch: any,
  explorationInstanceData: explorationInstanceStateTypes,
  totalCount: number,
  datasetID: string,
  subsetID: string,
  instanceFilterData: ActiveFiltersModel,
  instanceSortData: SortingRequestModel[]
) => {
  if (
    totalCount > 0 &&
    explorationInstanceData?.skip + scrollLoadAmount >= totalCount
  ) {
    dispatch(updateInstanceExplorationHasMore(false));
    return;
  } else {
    const APIBody: ExplorationAPIRequestModel = {
      dataset_id: datasetID,
      limit: scrollLoadAmount,
      skip: explorationInstanceData?.skip + scrollLoadAmount,
      query: convertActiveFilterToSendFilter(instanceFilterData),
      sort: instanceSortData,
    };

    dispatch(
      fetchInstanceData({
        runId: datasetID,
        subSetId: subsetID,
        reqBody: APIBody,
        skipLoading: true,
      })
    );
    dispatch(
      updateInstanceExplorationSkip(
        explorationInstanceData?.skip + scrollLoadAmount
      )
    );
  }
};
