export const gaDisallow = () => {
  localStorage.setItem("ga-acceptance", "false");
};

export const mcDisallow = () => {
  localStorage.setItem("mc-acceptance", "false");
};

export const handleAcceptance = (
  isGAAllowed: boolean,
  isMCAllowed: boolean,
) => {
  const cookieValue = (boolValue: boolean) => (boolValue ? "true" : "false");

  //store cookie acceptance in local storage
  localStorage.setItem("cookie-acceptance", "true");

  //store tracking acceptance in local storage
  localStorage.setItem("ga-acceptance", cookieValue(isGAAllowed));
  localStorage.setItem("mc-acceptance", cookieValue(isMCAllowed));
};

export const handleRefusal = () => {
  localStorage.setItem("cookie-acceptance", "false");
  localStorage.setItem("ga-acceptance", "false");
  localStorage.setItem("mc-acceptance", "false");
};

export const getAcceptances = () => {
  return {
    cookieAcceptance: localStorage.getItem("cookie-acceptance"),
    gaAcceptance: localStorage.getItem("ga-acceptance"),
    mcAcceptance: localStorage.getItem("mc-acceptance"),
  };
};
