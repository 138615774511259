import { TasksRenderer } from "@quality-match/hermes-ui";
import { Suspense } from "react";

type Props = {
  taskInputs: Record<string, unknown>;
  guiSettings: Record<string, unknown>;
};

const LivePreviewComponent = ({ taskInputs, guiSettings }: Props) => {
  if (!taskInputs) return null;

  // We added the suspense here as some component (e.g the can't solve button)
  //  are having a loading issue and could case the app to crash
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TasksRenderer tasksData={taskInputs as any} uiConfig={guiSettings} />
    </Suspense>
  );
};

export default LivePreviewComponent;
