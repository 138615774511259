import { useAppDispatch } from "store/hooks";
import MoreButton from "components/Internal/Buttons/MoreButton";
import StandardPopoverWrapper from "components/Internal/Popovers/StandardPopoverWrapper";
import {
  AnnotationRunModel,
  AnnotationRunStatusEnum,
  AnnotationRunTypeEnum,
} from "models/annotationRun.model";
import { copyToClipboard } from "helpers/functions/copyToClipboard";
import { ReactComponent as DeleteIcon } from "assets/delete.svg";
import { ReactComponent as CopyLinkIcon } from "assets/copy_link.svg";
import { ReactComponent as PaperIcon } from "assets/paper.svg";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { useState } from "react";
import AiModelDialog from "Pages/AnnotationRunManager/AIModelDialog";
import { ReactComponent as AiIcon } from "assets/ai.svg";
import { useKeycloak } from "@react-keycloak/web";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import { SubsetTypeModel } from "models/global.model";
import { getDataset } from "helpers/apis/datasets";
import { fetchAnnotationRun } from "helpers/apis/annotationRun";
import { Dispatch } from "@reduxjs/toolkit";
import _ from "lodash";

export type AnnotationRunManagerPopoverConfirmDialogState = {
  show: boolean;
  action: "archive";
  annotationRunID: string | null;
};

type Props = {
  annotationRun: AnnotationRunModel;
  setConfirmDialogState: (
    newState: AnnotationRunManagerPopoverConfirmDialogState,
  ) => void;
  setRenameInputID: (id: string | null) => void;
  updateAnnotationRuns: () => void;
};

const AnnotationRunManagerTableAction = ({
  annotationRun,
  setConfirmDialogState,
  setRenameInputID,
  updateAnnotationRuns,
}: Props) => {
  const { keycloak } = useKeycloak();

  const [aiModelDialogOpen, setAiModelDialogOpen] = useState(false);

  const dispatch = useAppDispatch();

  const getActionsList = () => {
    const actions = [
      {
        label: "Copy link",
        onItemClick: () =>
          copyToClipboard(annotationRun?.annotation_run_url, dispatch),
        icon: <CopyLinkIcon className="w-[18px] h-[18px] text-paletteGray-7" />,
      },
      {
        label: "Copy project ID",
        onItemClick: () =>
          copyToClipboard(annotationRun?.goliat_project_id, dispatch),
        icon: <PaperIcon className="text-paletteGray-7" />,
      },
      {
        label: "Rename",
        onItemClick: () => setRenameInputID(annotationRun?.id),
        icon: <EditIcon className="text-paletteGray-7" strokeWidth={2} />,
      },
      {
        label: "Archive",
        onItemClick: () =>
          setConfirmDialogState({
            show: true,
            action: "archive",
            annotationRunID: annotationRun?.id,
          }),

        className: "text-paletteRed",
        icon: <DeleteIcon />,
      },
    ];
    if (annotationRun.annotation_run_type === AnnotationRunTypeEnum.AI) {
      return actions?.filter(
        (action) => !_.includes(["Copy Project ID", "Copy link"], action.label),
      );
    }
    return actions;
  };

  const renderAnnotateButton = () => {
    // If the annotation run is AI, don't show the annotate button
    if (
      annotationRun?.annotation_run_type == AnnotationRunTypeEnum.AI ||
      annotationRun.status === AnnotationRunStatusEnum.ANNOTATION_DONE ||
      annotationRun.status === AnnotationRunStatusEnum.POST_PROCESSING
    ) {
      return;
    }

    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          const newWnd = window.open(
            annotationRun?.annotation_run_url,
            "_blank",
            "noopener,noreferrer",
          );
          if (newWnd) {
            newWnd.opener = null;
          }
        }}
        className="button-layer mr-2"
        data-test="annotate_button_annotation_run_manager"
        disabled={!annotationRun?.annotation_run_url}
      >
        Annotate
      </button>
    );
  };

  const renderAiModelButton = () => {
    const isUserCustomContract = checkIfUserHavePermission(
      keycloak,
      "accessAnnotationRunAIModelFeature",
    );
    if (!isUserCustomContract) {
      return null;
    }

    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          setAiModelDialogOpen(true);
        }}
        className="button-layer mr-2"
        data-test="ai_model_button_annotation_run_manager"
        disabled={!annotationRun?.annotation_run_url}
      >
        <AiIcon className="w-[16px] h-[16px] "></AiIcon>
        <div className="hidden xl:block">AI Model</div>
      </button>
    );
  };

  const renderExploreResultsButton = () => {
    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          // TODO: Replace this method by filterToSearchParams in near future
          fetchAnnotationRun(
            {
              annotationRunID: annotationRun.id,
            },
            dispatch,
          ).then((annotationRunWithNodes) => {
            getUrlPrefix(annotationRunWithNodes?.subset_id, dispatch).then(
              (url_prefix) => {
                // AI attributes with no goliat project have the ann run node ID as attr ID
                const attributeID =
                  annotationRunWithNodes?.nodes?.[0].goliat_node_id ||
                  annotationRunWithNodes?.nodes?.[0].id;
                const exploreUrl = `${url_prefix}/${annotationRunWithNodes.dataset_id}/main_dataset?sel_attr__attributes.${attributeID}.dataset_id="${annotationRunWithNodes.dataset_id}"`;
                const newWnd = window.open(
                  exploreUrl,
                  "_blank",
                  "noopener,noreferrer",
                );
                if (newWnd) {
                  newWnd.opener = null;
                }
              },
            );
          });
        }}
        className="button-layer mr-2"
        data-test="explore_results_button_annotation_run_manager"
      >
        Explore results
      </button>
    );
  };

  const renderCopyLinkButton = () => {
    // If the annotation run is AI, don't show the copy link button
    if (annotationRun?.annotation_run_type === AnnotationRunTypeEnum.AI) {
      return;
    }
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          copyToClipboard(annotationRun?.annotation_run_url, dispatch);
        }}
        className={
          "button-layer p-0 text-paletteGray-9 hover:text-paletteGray-10"
        }
        data-test="copy_link_button_annotation_run_manager"
      >
        <CopyLinkIcon className="w-[18px] h-[18px]" />
      </div>
    );
  };

  const renderExploreAndAIModelButton = () => {
    return (
      <div className={"flex"}>
        {renderAiModelButton()}
        {renderExploreResultsButton()}
      </div>
    );
  };

  return (
    <div className="flex flex-row-reverse gap-x-2 items-center">
      <StandardPopoverWrapper
        id={annotationRun?.id}
        buttonElement={<MoreButton />}
        itemsList={getActionsList()}
      />
      {renderCopyLinkButton()}
      {annotationRun.status === AnnotationRunStatusEnum.DONE
        ? annotationRun?.annotation_run_type === AnnotationRunTypeEnum.AI
          ? renderExploreResultsButton()
          : renderExploreAndAIModelButton()
        : renderAnnotateButton()}

      {aiModelDialogOpen && (
        <AiModelDialog
          open={aiModelDialogOpen}
          onClose={() => setAiModelDialogOpen(false)}
          annotationRun={annotationRun}
          updateAnnotationRuns={updateAnnotationRuns}
        />
      )}
    </div>
  );
};

const getUrlPrefix = async (subset_id: string, dispatch: Dispatch) => {
  const data = await getDataset({ datasetID: subset_id }, dispatch);
  let urlPrefix = "/main";
  switch (data.subset_type) {
    case SubsetTypeModel.Media:
      urlPrefix += "/media";
      break;
    case SubsetTypeModel.MediaObject:
      urlPrefix += "/mediaObject";
      break;
    case SubsetTypeModel.Instance:
      urlPrefix += "/instance";
      break;
  }
  return urlPrefix;
};

export default AnnotationRunManagerTableAction;
