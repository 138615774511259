import { SendFilterModel } from "models/filter.model";
import { SubsetTypeModel } from "models/global.model";

export enum DownloadFileStatus {
  Processing = "processing",
  Uploading = "uploading",
  Done = "done",
}

export interface DownloadFileMeta {
  id: string;
  dataset_id: string;
  view_type: SubsetTypeModel;
  name: string;
  file_path: string;
  creation_timestamp: string;
  filters: SendFilterModel[];
  status: DownloadFileStatus;
  file_size: number;
}
