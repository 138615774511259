import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CategoriesFilterModel,
  CategoriesFiltersModel,
  GeometriesFiltersModel,
} from "models/detailsScreen.model";
import { AnnotatableEnum, SelectedViewModel } from "models/global.model";

interface detailsScreenStateTypes {
  objectCategories: CategoriesFiltersModel;
  geometries: GeometriesFiltersModel;
  selectedVisualization: {
    [AnnotatableEnum.Media]: null | string;
    [AnnotatableEnum.MediaObject]: null | string;
    [AnnotatableEnum.Instance]: null | string;
  };
}

const initialState = {
  objectCategories: {},
  geometries: {
    bbs_qm: true,
    bbs_reference: true,
    keypoints_qm: true,
    keypoints_reference: true,
    polylines_qm: true,
    polylines_reference: true,
    polygons_qm: true,
    polygons_reference: true,
    three_d_view: false,
    label: true,
    hide_all: false,
    color_by_class: false,
  },
  selectedVisualization: {
    [AnnotatableEnum.Media]: null,
    [AnnotatableEnum.MediaObject]: null,
    [AnnotatableEnum.Instance]: null,
  },
} as detailsScreenStateTypes;

export const detailsScreenSlice = createSlice({
  name: "detailsScreen",
  initialState,
  reducers: {
    setDetailsScreenFiltersObjectCategory: (
      state,
      action: PayloadAction<CategoriesFilterModel>
    ) => {
      const newCategory = action?.payload;
      state.objectCategories[newCategory.id] = newCategory;
    },
    setDetailsScreenGeometry: (
      state,
      action: PayloadAction<{
        key: keyof GeometriesFiltersModel;
        value: boolean;
      }>
    ) => {
      const geometryKey = action?.payload?.key;
      state.geometries[geometryKey] = action.payload.value;
    },
    setDetailsScreenSelectedVisualization: (
      state,
      action: PayloadAction<{
        key: SelectedViewModel;
        value: string | null;
      }>
    ) => {
      const visualizationKey = action?.payload?.key;
      state.selectedVisualization[visualizationKey] = action.payload.value;
    },
    resetDetailsScreenSlice: () => initialState,
  },
});

export const {
  setDetailsScreenFiltersObjectCategory,
  setDetailsScreenGeometry,
  setDetailsScreenSelectedVisualization,
  resetDetailsScreenSlice,
} = detailsScreenSlice.actions;

export default detailsScreenSlice.reducer;
