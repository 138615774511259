type Props = {
  checked: boolean;
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => void;
  size?: number;
};

const RadioButton = ({ checked, onChange }: Props) => {
  return (
    <input
      type="radio"
      checked={checked}
      onChange={(event) => {
        event.stopPropagation();
        onChange(event);
      }}
      onClick={(event) => {
        event.stopPropagation();
        onChange(event);
      }}
    />
  );
};

export default RadioButton;
