import _ from "lodash";

type Props = { error: string | null; validationError: string | null };

const FormErrorLabel = ({ error, validationError }: Props) => {
  if (!_.isNull(validationError) && error) {
    return (
      <div className="px-2 text-sm text-paletteRed">{validationError}</div>
    );
  } else {
    return null;
  }
};

export default FormErrorLabel;
