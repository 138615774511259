export enum UserRoleModel {
  Internal = "internal",
  Community = "community",
  CustomContract = "custom_contract",
}

export const allUsersRoles = [
  UserRoleModel.Community,
  UserRoleModel.CustomContract,
  UserRoleModel.Internal,
];

export const customContractRoles = [
  UserRoleModel.CustomContract,
  UserRoleModel.Internal,
];

export const internalOnlyRoles = [UserRoleModel.Internal];
