export interface PipelineModel {
  id: string;
  name: string;
  owner: string;
  user_group: string;
  revision: string;
  created_at: string;
  updated_at: string;
  archived_at: string;
  tags: string[];
  config: PipelineConfigurations;
  // nodes only included in the response when created
  nodes?: PipelineNodeModel[];
}

export interface PipelineConfigurations {
  connections: Record<string, unknown>;
  limit_tasks: number;
  shuffle_tasks: boolean;
  add_tasks_n_times: number;
}

export interface PipelineNodeModel {
  id: string;
  name: string;
  config: PipelineNodeConfigBody;
  pipeline_id: string;
}

export interface PipelineNodeConfigBody extends PipelineNodeConfiguration {
  gui_type: string | null;
  gui_settings: Record<string, unknown> | null;
  possible_answers: Record<string, string>[] | null;
  cant_solve_options: Record<string, string>[] | null;
}

export interface PipelineNodeConfiguration {
  min_repeats: number;
  max_repeats: number;
  convergence_threshold: number;
  wp_tasks: number;
  wp_timeout: number;
  wp_rd_tasks: number;
  media_type: "image" | "video";
  should_workpackage_include_task_outputs: "parallel" | "sequential";
  visualisation_type: VisualisationTypeEnum;
  auto_annotate: boolean;
}

export interface PipelineRevisionModel {
  id: string;
  pipeline_id: string;
  revision: number;
  owner: string;
  user_group: string;
  created_at: string;
  updated_at: string;
  archived_at: string;
  node_revisions: PipelineNodeModel[];
  config: PipelineConfigurations;
}

export enum PipelineDesignerConfigurationTabsEnum {
  UserInterface = "User interface",
  Configuration = "Configuration",
}

export enum VisualisationTypeEnum {
  // these are a 1:1 mapping to current task input types used in annotation UIs
  DEFAULT = "Default",
  CROP = "Crop",
  TILE = "Tile",
}
