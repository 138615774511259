export interface TilingParamsModel {
  configuration_name: string;
  num_media_tiles_rows: number;
  num_media_tiles_columns: number;
  tiles_percentage_overlap: number;
}

export enum box_type {
  qm = "QM",
  reference = "REFERENCE",
}

export interface CroppingFEParamsModel {
  padding_percent: number;
  padding_minimum: number;
  max_size_x: number;
  max_size_y: number;
  aspect_ratio_x: number;
  aspect_ratio_y: number;
  box_type_qm: boolean;
  box_type_reference: boolean;
}

export interface CroppingParamsModel {
  padding_percent: number;
  padding_minimum: number;
  max_size: [number, number];
  aspect_ratio: [number, number];
  box_type: [box_type?, box_type?];
}

export const croppingFEParamsModelToCroppingParamsModel = (
  obj: CroppingFEParamsModel
): CroppingParamsModel => {
  const box_types: [box_type?, box_type?] = [];
  if (obj.box_type_reference) {
    box_types.push(box_type.reference);
  }
  if (obj.box_type_qm) {
    box_types.push(box_type.qm);
  }

  const croppingParamsModel: CroppingParamsModel = {
    padding_percent: obj.padding_percent,
    padding_minimum: obj.padding_minimum,
    max_size: [obj.max_size_x, obj.max_size_y],
    aspect_ratio: [obj.aspect_ratio_x, obj.aspect_ratio_y],
    box_type: box_types,
  };
  return croppingParamsModel;
};
