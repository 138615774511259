import { useAppSelector } from "store/hooks";
import { Mediatype } from "models/dataset.model";
import { TagTypeModel } from "models/global.model";
import _ from "lodash";
import UnderlineTabs from "components/Internal/Tabs/UnderlineTabs";
import { TagsManagerScreenTabs } from "components/TagsManager/index";

type Props = {
  selectedTabValue: string;
  onTabClick: (tab: string) => void;
};

const TagsTabs = ({ selectedTabValue, onTabClick }: Props) => {
  const tags = useAppSelector((state) => state.datasetSlice.tags);

  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype,
  );

  const tabs = [
    {
      label: "All",
      value: "all",
      count: tags?.length,
    },
    {
      label: mediatype === Mediatype.video ? "Video tags" : "Media tags",
      value: TagTypeModel.Media,
      count: _.filter(tags, { database_object_type: TagTypeModel.Media })
        ?.length,
    },
    {
      label: "Instance tags",
      value: TagTypeModel.Instance,
      count: _.filter(tags, { database_object_type: TagTypeModel.Instance })
        ?.length,
    },
    {
      label: "Object tags",
      value: TagTypeModel.MediaObject,
      count: _.filter(tags, { database_object_type: TagTypeModel.MediaObject })
        ?.length,
    },
    {
      label: "Attribute tags",
      value: TagTypeModel.Attribute,
      count: _.filter(tags, { database_object_type: TagTypeModel.Attribute })
        ?.length,
    },
  ];

  return (
    <div className="w-full pb-4 pt-4">
      <UnderlineTabs
        tabs={tabs}
        selectedTabValue={selectedTabValue}
        onTabClick={(tab) => onTabClick(tab?.value as TagsManagerScreenTabs)}
      />
    </div>
  );
};

export default TagsTabs;
