import _ from "lodash";
import {
  FilterModelWithoutInit,
  ResponseFilterModel,
  FilterAttributeGroupEnum,
  FiltersModel,
} from "models/filter.model";
import { GeometriesEnum } from "models/geometries.model";

export const buildFiltersObject = (
  rawFilters: ResponseFilterModel[],
): FiltersModel => {
  const groupNameMap: Record<string, string> = {
    auto_attribute: "Auto Attributes",
    annotation_attribute: "Annotated Attributes",
    ml_annotation_attribute: "ML Annotated Attributes",
    initial_attribute: "Initial Attributes",
  };

  let filters: FiltersModel = {};
  _.map(rawFilters, function (filter) {
    // Remove subset filter for non annotation attributes
    const annotationAttributeEnumList = [
      FilterAttributeGroupEnum.Annotation_Attribute,
      FilterAttributeGroupEnum.ML_Annotation_Attribute,
    ];

    if (
      !_.includes(annotationAttributeEnumList, filter.attribute_group) &&
      filter.type === "SUBSET"
    )
      return;

    let key = "attributes." + filter?.attribute_id + "." + filter?.filter_name;

    // If the filter is not on an attribute use attribute_id as key
    if (filter.FE_is_not_filter_on_attribute) {
      key = filter?.attribute_id;
    }

    const filterObject: FilterModelWithoutInit = {
      ...filter,
      id: filter?.attribute_id,
      lower_bound: filter?.lower,
      upper_bound: filter?.upper,
      key: key,
      is_visible: false,
      name: createFilterName(filter),
      boolean_val: false,
      selected_cats: [],
      not_selected_cats: [],
      categories: createCategoriesFilterEntry(filter),
      histogram: createHistogramFilterEntry(filter),
      group_name: groupNameMap[filter?.attribute_group],
      FE_is_not: false,
      FE_include_edges: true,
    };
    filters = {
      ...filters,
      [key]: {
        ...filterObject,
        init: { ...filterObject },
      },
    };
  });
  return filters;
};

const createFilterName = (filter: ResponseFilterModel): string => {
  let name = filter?.attribute_name;
  if (!_.isEmpty(filter?.filter_name)) {
    name = `${filter?.attribute_name} - ${filter?.filter_name}`;
  }
  // Remove the question form the displayed label
  if (
    filter?.attribute_group === FilterAttributeGroupEnum.Annotation_Attribute ||
    filter?.attribute_group === FilterAttributeGroupEnum.ML_Annotation_Attribute
  ) {
    name = filter?.filter_name;
    // Replace subset_id with tags label
    if (filter?.type === "SUBSET") {
      name = "Tags";
    }
    if (filter?.type === "SELECT_ATTRIBUTE") {
      name = "Select attribute";
    }
  }
  return name;
};

const createHistogramFilterEntry = (filter: any) => {
  return filter?.type === "NUMERICAL"
    ? filter?.buckets?.map((bin: any) => {
        const lower_bound = +bin[0];
        const upper_bound = +bin[0] + filter?.interval;
        return {
          upper_bound: upper_bound,
          lower_bound: lower_bound,
          bin_str: `${lower_bound} - ${upper_bound}`,
          freq: bin[1],
        };
      })
    : filter?.buckets?.map((bin: any) => {
        return {
          bin_str: bin[0],
          freq: bin[1],
        };
      });
};

const createCategoriesFilterEntry = (filter: any) => {
  return filter?.type === "CATEGORICAL" || filter?.type === "SUBSET"
    ? filter?.buckets?.map((bin: any) => {
        return bin[0];
      })
    : filter?.categories;
};

export const createNewFakeSubsetsFilter = (): ResponseFilterModel => {
  return {
    FE_is_not_filter_on_attribute: true,
    attribute_name: "Subsets & classes",
    attribute_id: "subset_ids",
    attribute_group: FilterAttributeGroupEnum.Subsets_And_Tags,
    type: "FE_SUBSET",
    lower: null,
    upper: null,
    statistics: null,
    categories: null,
    num_buckets: 0,
    buckets: [],
    interval: null,
    filter_name: "",
    cant_solves: 0,
    corrupt_data: 0,
    dataset_id: "",
    subset_id: "",
  };
};

export const createNewFakeTagsFilter = (): ResponseFilterModel => {
  return {
    FE_is_not_filter_on_attribute: true,
    attribute_name: "Tags",
    attribute_id: "tags",
    attribute_group: FilterAttributeGroupEnum.Subsets_And_Tags,
    type: "FE_TAG",
    lower: null,
    upper: null,
    statistics: null,
    categories: null,
    num_buckets: 0,
    buckets: [],
    interval: null,
    filter_name: "",
    cant_solves: 0,
    corrupt_data: 0,
    dataset_id: "",
    subset_id: "",
  };
};

export const createNewFakeIDsFilter = (): ResponseFilterModel => {
  return {
    FE_is_not_filter_on_attribute: true,
    attribute_name: "ID",
    attribute_id: "id",
    attribute_group: FilterAttributeGroupEnum.Text_Filter,
    type: "SEARCH_BY_TEXT",
    lower: null,
    upper: null,
    statistics: null,
    categories: null,
    num_buckets: 0,
    buckets: [],
    interval: null,
    filter_name: "",
    cant_solves: 0,
    corrupt_data: 0,
    dataset_id: "",
    subset_id: "",
  };
};

export const createNewFakeBackReferenceFilter = (): ResponseFilterModel => {
  return {
    FE_is_not_filter_on_attribute: true,
    attribute_name: "Back reference",
    attribute_id: "back_reference",
    attribute_group: FilterAttributeGroupEnum.Text_Filter,
    type: "SEARCH_BY_TEXT",
    lower: null,
    upper: null,
    statistics: null,
    categories: null,
    num_buckets: 0,
    buckets: [],
    interval: null,
    filter_name: "",
    cant_solves: 0,
    corrupt_data: 0,
    dataset_id: "",
    subset_id: "",
  };
};

export const createNewFakeAnnotationAttributeFilter = (
  attributeName: string,
  attributeId: string,
): ResponseFilterModel => {
  return {
    attribute_name: attributeName,
    attribute_id: attributeId,
    attribute_group: FilterAttributeGroupEnum.Annotation_Attribute,
    type: "SELECT_ATTRIBUTE",
    FE_value: "",
    lower: null,
    upper: null,
    statistics: null,
    categories: null,
    num_buckets: 0,
    buckets: [],
    interval: null,
    filter_name: "dataset_id",
    cant_solves: 0,
    corrupt_data: 0,
    dataset_id: "",
    subset_id: "",
  };
};

export const createNewFakeMLAnnotationAttributeFilter = (
  attributeName: string,
  attributeId: string,
): ResponseFilterModel => {
  return {
    attribute_name: attributeName,
    attribute_id: attributeId,
    attribute_group: FilterAttributeGroupEnum.ML_Annotation_Attribute,
    type: "SELECT_ATTRIBUTE",
    FE_value: "",
    lower: null,
    upper: null,
    statistics: null,
    categories: null,
    num_buckets: 0,
    buckets: [],
    interval: null,
    filter_name: "dataset_id",
    cant_solves: 0,
    corrupt_data: 0,
    dataset_id: "",
    subset_id: "",
  };
};

// Create a fake (frontend only) filter for the source attribute
export const createNewFakeSourceFilter = (): ResponseFilterModel => {
  return {
    FE_is_not_filter_on_attribute: true,
    attribute_name: "Source",
    attribute_id: "source",
    attribute_group: FilterAttributeGroupEnum.Auto_Attribute,
    type: "CATEGORICAL",
    lower: null,
    upper: null,
    statistics: null,
    categories: null,
    num_buckets: 0,
    buckets: [
      ["QM", 0],
      ["REFERENCE", 0],
    ],
    interval: null,
    filter_name: "",
    cant_solves: 0,
    corrupt_data: 0,
    dataset_id: "",
    subset_id: "",
  };
};

export const createNewFakeMediaObjectGeometryTypeFilter =
  (): ResponseFilterModel => {
    return {
      FE_is_not_filter_on_attribute: true,
      attribute_name: "Geometry type",
      attribute_id: "media_object_type",
      attribute_group: FilterAttributeGroupEnum.Auto_Attribute,
      type: "CATEGORICAL",
      lower: null,
      upper: null,
      statistics: null,
      categories: null,
      num_buckets: 0,
      buckets: [
        [GeometriesEnum.BoundingBox2D, null],
        [GeometriesEnum.BoundingBox2DAggregation, null],
        [GeometriesEnum.Point2D, null],
        [GeometriesEnum.Point2DAggregation, null],
        [GeometriesEnum.Polyline2DFlatCoordinates, null],
        [GeometriesEnum.CuboidCenterPoint, null],
        [GeometriesEnum.Point3dXYZAggregation, null],
        [GeometriesEnum.Point3DXYZ, null],
      ],
      interval: null,
      filter_name: "",
      cant_solves: 0,
      corrupt_data: 0,
      dataset_id: "",
      subset_id: "",
    };
  };

export const createNewFakeMediaTypeFilter = (): ResponseFilterModel => {
  return {
    FE_is_not_filter_on_attribute: true,
    attribute_name: "Type",
    attribute_id: "media_type",
    attribute_group: FilterAttributeGroupEnum.Auto_Attribute,
    type: "CATEGORICAL",
    lower: null,
    upper: null,
    statistics: null,
    categories: null,
    num_buckets: 0,
    buckets: [
      ["point_cloud", null],
      ["image", null],
      ["video", null],
    ],
    interval: null,
    filter_name: "",
    cant_solves: 0,
    corrupt_data: 0,
    dataset_id: "",
    subset_id: "",
  };
};

export const createNewFakeInstancesGeometryTypeFilter =
  (): ResponseFilterModel => {
    return {
      FE_is_not_filter_on_attribute: true,
      attribute_name: "Instance type",
      attribute_id: "instance_type",
      attribute_group: FilterAttributeGroupEnum.Auto_Attribute,
      type: "CATEGORICAL",
      lower: null,
      upper: null,
      statistics: null,
      categories: null,
      num_buckets: 0,
      buckets: [
        [GeometriesEnum.BoundingBox2D, null],
        [GeometriesEnum.BoundingBox2DAggregation, null],
        [GeometriesEnum.CuboidCenterPoint, null],
        [GeometriesEnum.Point2D, null],
        [GeometriesEnum.Point2DAggregation, null],
        [GeometriesEnum.Polyline2DFlatCoordinates, null],
      ],
      interval: null,
      filter_name: "",
      cant_solves: 0,
      corrupt_data: 0,
      dataset_id: "",
      subset_id: "",
    };
  };

export const createNewFakeSceneIDFilter = (): ResponseFilterModel => {
  return {
    FE_is_not_filter_on_attribute: true,
    attribute_name: "Scene ID",
    attribute_id: "scene_id",
    attribute_group: FilterAttributeGroupEnum.Text_Filter,
    type: "SEARCH_BY_TEXT",
    lower: null,
    upper: null,
    statistics: null,
    categories: null,
    num_buckets: 0,
    buckets: [],
    interval: null,
    filter_name: "",
    cant_solves: 0,
    corrupt_data: 0,
    dataset_id: "",
    subset_id: "",
  };
};

export const createNewFakeCameraNameFilter = (): ResponseFilterModel => {
  return {
    FE_is_not_filter_on_attribute: true,
    attribute_name: "Camera name",
    attribute_id: "name",
    attribute_group: FilterAttributeGroupEnum.Text_Filter,
    type: "SEARCH_BY_TEXT",
    lower: null,
    upper: null,
    statistics: null,
    categories: null,
    num_buckets: 0,
    buckets: [],
    interval: null,
    filter_name: "",
    cant_solves: 0,
    corrupt_data: 0,
    dataset_id: "",
    subset_id: "",
  };
};

export const createNewFakeFrameIndexFilter = (): ResponseFilterModel => {
  return {
    FE_is_not_filter_on_attribute: true,
    FE_cast_value_to_number: true,
    attribute_name: "Frame index",
    attribute_id: "frame_idx",
    attribute_group: FilterAttributeGroupEnum.Text_Filter,
    type: "SEARCH_BY_TEXT",
    lower: null,
    upper: null,
    statistics: null,
    categories: null,
    num_buckets: 0,
    buckets: [],
    interval: null,
    filter_name: "",
    cant_solves: 0,
    corrupt_data: 0,
    dataset_id: "",
    subset_id: "",
  };
};
