const initialFormData = {
  schemaVersion: "2.0.0",
  question: {
    Question: {
      title: "Question text will come here.",
    },
  },
  inputType: {
    BoundingBox2DPainter: {
      displayerType: "image",
      videoPlayerSettings: {
        loop: true,
        playing: true,
        controls: false,
        light: false,
        muted: true,
      },
      zoomSettings: {
        zoomMultiplier: 1.1,
        zoomStep: 5,
      },
      disableDrawing: false,
      doubleClickZoom: false,
      interpolation: "pixelated",
      maxShapes: 99,
      mouseWheelZoom: "reverse",
      minZoomKeypoint: 2,
      minZoomTimeout: 3000,
      forceZoomKeypoint: false,
      scaleKeypointSize: 1,
      boundingBoxProps: {
        opacity: 0.1,
        opacityRules: {
          activeShapeOpacity: 1,
          tempShapeOpacity: 2,
          defaultShapeOpacity: 3,
        },
      },
    },
    Viewer3D: {},
  },
  toolbars: [],
  uiSettings: {
    Settings: {
      keyBindings: [
        {
          key: "v",
          description: "Hide the default shapes",
          function: "hideDefaultShapes",
        },
        {
          key: "b",
          description: "Hide the user drawn shapes",
          function: "hideUserDrawnShapes",
        },
        {
          key: "z",
          description: "Toggle zoom",
          function: "toggleZoom",
        },
      ],
    },
  },
  translatables: {
    Messages: {
      BLOCK_WARNING: {
        text: "The provided answers had low quality. Next time you will be blocked.",
        subText: "",
      },
      NO_TASKS: {
        text: "There are no available tasks right now.",
        subText: "",
      },
      RATE_LIMIT_REACHED: {
        text: "You can use this feature again at: ",
        subText: "",
      },
      SUBMIT_DEADLINE_REACHED: {
        text: "Time is up!",
        subText:
          "Unfortunately, you have not answered all the questions within the given time.",
      },
      THANK_YOU: {
        text: "Thank you for your contribution!",
        subText: "You can safely close this window.",
      },
      USER_IS_BLOCKED: {
        text: "Unfortunately the quality of your answers was not as expected.",
        subText:
          "We temporarily suspended sending tasks to you. You are blocked until:",
      },
      WRONG_PARAMETERS: {
        text: "",
        subText: "Parameters are wrong. Please check the parameters.",
      },
    },
  },
};

export default initialFormData;
