import emailjs from "@emailjs/browser";

const sendEmailJSForm = async (form: Record<string, unknown>) => {
  const response = emailjs.send(
    process.env.REACT_APP_SERVICE_ID || "",
    process.env.REACT_APP_TEMPLATE_ID || "",
    form,
    process.env.REACT_APP_USER_ID || ""
  );
  return response;
};

export default sendEmailJSForm;
