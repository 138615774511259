import snackbarHelper from "components/Helpers/snackbarHelperFn";
import { APIFetchAxios } from "components/UtilComponents/Auth";
import convertActiveFilterToSendFilter from "helpers/functions/filters/convertActiveFilterToSendFilter";
import _ from "lodash";
import {
  ExplorationAPIRequestModel,
  SortingRequestModel,
} from "models/exploration.model";
import { ActiveFiltersModel, SendFilterModel } from "models/filter.model";
import {
  InstancesPerMediaModel,
  MetaDataCountModel,
} from "models/metaData.model";
import {
  explorationMediaObjectsStateTypes,
  fetchMediaObjectsData,
  updateMediaObjectsExplorationHasMore,
  updateMediaObjectsExplorationSkip,
} from "store/explorationMediaObjectsSlice";

const scrollLoadAmount = Number(
  process.env.REACT_APP_SCROLL_LOAD_AMOUNT ??
    alert("Config value 'scroll load amount' is not defined"),
);

export const fetchMediaObjectsCount = async (
  datasetID: string,
  body: SendFilterModel[],
  dispatch?: any,
): Promise<MetaDataCountModel> => {
  const response = await APIFetchAxios(
    `/datasets/${datasetID}/mediaObjects:count`,
    { query: body },
  ).catch((error) => {
    let msg = error?.details;
    if (_.isUndefined(error?.details)) {
      msg = "Something went wrong! Fetching media objects count failed!";
    }
    snackbarHelper(dispatch, msg, "error");
  });
  return response?.data;
};

export const fetchMediaObjectsDataScroll = (
  dispatch: any,
  explorationMediaObjectsData: explorationMediaObjectsStateTypes,
  totalCount: number,
  datasetID: string,
  subsetID: string,
  mediaObjectsFilterData: ActiveFiltersModel,
  mediaObjectsSortData: SortingRequestModel[],
) => {
  if (
    totalCount > 0 &&
    explorationMediaObjectsData?.skip + scrollLoadAmount >= totalCount
  ) {
    dispatch(updateMediaObjectsExplorationHasMore(false));
    return;
  }
  const APIBody: ExplorationAPIRequestModel = {
    dataset_id: datasetID,
    limit: scrollLoadAmount,
    skip: explorationMediaObjectsData?.skip + scrollLoadAmount,
    query: convertActiveFilterToSendFilter(mediaObjectsFilterData),
    sort: mediaObjectsSortData,
  };

  dispatch(
    fetchMediaObjectsData({
      runId: datasetID,
      reqBody: APIBody,
      subSetId: subsetID,
      skipLoading: true,
    }),
  );
  dispatch(
    updateMediaObjectsExplorationSkip(
      explorationMediaObjectsData?.skip + scrollLoadAmount,
    ),
  );
};

export const fetchMediaObjectsFrequency = async (
  datasetID: string,
  subsetID: string,
  dispatch?: any,
): Promise<InstancesPerMediaModel> => {
  let url = `/datasets/${datasetID}/medias/mediaObjectsFrequency`;

  if (subsetID !== "main_dataset") {
    url = url + `?subset_id=${subsetID}`;
  }
  const response = await APIFetchAxios(url).catch((error) => {
    let msg = error?.details;
    if (_.isUndefined(error?.details)) {
      msg = "Something went wrong! Fetching media objects frequency failed!";
      console.error(msg);
    }
    snackbarHelper(dispatch, msg, "error");
  });
  return response?.data;
};
