import SimpleSelect from "components/Internal/Inputs/Form/SimpleSelect";
import TextInput from "components/Internal/Inputs/Form/TextInput";
import NumberInput from "components/Internal/Inputs/Form/NumberInput";
import _ from "lodash";
import { FormFieldModel, FormModel } from "models/form.model";
import React, { useEffect } from "react";
import { resetForm, submitForm } from "components/Internal/Forms/formHelper";
import SingleSliderInput from "components/Internal/Inputs/Form/SingleSliderInput";
import BooleanInput from "components/Internal/Inputs/Form/BooleanInput";

type Props = {
  formData: FormModel;
  setFormData: (formState: FormModel) => void;
  handleOnSubmit?: <Type>(formState: Type) => void;
  isLoading?: boolean;
};

const Form = ({ formData, setFormData, handleOnSubmit, isLoading }: Props) => {
  const [initFormState, setInitFormState] = React.useState<FormModel>({});

  useEffect(() => {
    setInitFormState(formData);
  }, []);

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    field: FormFieldModel,
    isErrored?: boolean,
    error?: string | null,
  ) => {
    const { key } = field;
    let newValue: string | number | boolean = event?.target?.value;
    if (field.type === "number" || field.type === "single_slider") {
      newValue = parseFloat(event.target.value);
    }
    if (field.type === "boolean") {
      const booleanEvent = event as React.ChangeEvent<HTMLInputElement>;
      newValue = booleanEvent.target.checked;
    }

    let newIsErrored = field?.isErrored;
    if (!_.isUndefined(isErrored)) {
      newIsErrored = isErrored;
    }

    let newError = field?.error;
    if (!_.isUndefined(error)) {
      newError = error;
    }
    setFormData({
      ...formData,
      [key]: {
        ...formData[key],
        value: newValue as any,
        isErrored: newIsErrored,
        error: newError,
      },
    });
  };

  const renderForm = () => {
    return _.map(formData, (field) => {
      const { value } = field;
      switch (field?.type) {
        case "text":
        case "textarea":
          return (
            <TextInput
              key={"text-input-" + field?.key}
              field={field}
              value={value as string}
              handleOnChange={handleChange}
            />
          );

        case "number":
          return (
            <NumberInput
              key={"number-input-" + field?.key}
              field={field}
              value={value as number}
              handleOnChange={handleChange}
            />
          );
        case "single_slider":
          return (
            <SingleSliderInput
              key={"single-slider-input-" + field?.key}
              field={field}
              value={value as number}
              handleOnChange={handleChange}
            />
          );
        case "select":
          return (
            <SimpleSelect
              key={"select-input-" + field?.key}
              field={field}
              value={value as string}
              handleOnChange={handleChange}
            />
          );
        case "boolean":
          return (
            <BooleanInput
              key={"boolean-input-" + field?.key}
              field={field}
              value={value as boolean}
              handleOnChange={handleChange}
            />
          );
        default:
          return <div>No form field type found!</div>;
      }
    });
  };

  const renderResetButton = () => {
    return (
      <button
        className="button-layer hover:bg-paletteRed hover:text-white"
        onClick={() => resetForm(initFormState, setFormData)}
        disabled={isLoading}
      >
        Reset
      </button>
    );
  };

  const renderSubmitButton = () => {
    return (
      <button
        className="button-layer hover:bg-paletteBlue hover:text-white"
        onClick={() =>
          submitForm(formData, setFormData, initFormState, handleOnSubmit)
        }
        disabled={isLoading}
      >
        Submit
      </button>
    );
  };

  const renderActionButtons = () => {
    if (handleOnSubmit) {
      return (
        <div className="flex flex-row-reverse gap-x-2 my-2">
          {renderSubmitButton()} {renderResetButton()}
        </div>
      );
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-y-2">{renderForm()}</div>
      {renderActionButtons()}
    </div>
  );
};

export default Form;
