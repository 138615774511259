import { useEffect, useState } from "react";
import StandardHeader from "components/Internal/Headers/StandardHeader";
import CreateSubsetPopover from "components/Internal/Popovers/CreateSubsetPopover";
import SubsetsTable from "components/SubsetsManager/SubsetsTable";
import { SubsetsManagerScreenRouteModel } from "models/routes.model";
import { useParams } from "react-router-dom";
import {
  fetchDataset,
  fetchParentDataset,
  fetchSubsets,
} from "store/datasetSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import SubsetTabs from "components/SubsetsManager/SubsetTabs";
import NewSubsetFileNotification from "components/SubsetsManager/NewSubsetFileNotification";
import { VisibilityStatus } from "models/global.model";

export enum SubsetsManagerScreenTabs {
  all = "all",
  media = "media",
  mediaObject = "media_object",
}

const SubsetsManager = () => {
  const [selectedTab, setSelectedTab] = useState<SubsetsManagerScreenTabs>(
    SubsetsManagerScreenTabs.all,
  );
  const [selectedItemsAnchorEl, setSelectedItemsAnchorEl] =
    useState<HTMLElement | null>(null);

  const params: SubsetsManagerScreenRouteModel = useParams();
  const dispatch = useAppDispatch();

  const datasetSlice = useAppSelector((state) => state.datasetSlice);

  useEffect(() => {
    dispatch(
      fetchSubsets({
        query: { parentDataSetID: params?.dataset_id },
        visibility_statuses: [
          VisibilityStatus.Visible,
          VisibilityStatus.CreatingSubset,
        ],
      }),
    );

    dispatch(fetchParentDataset({ query: { dataSetID: params?.dataset_id } }));

    let id = params?.dataset_id;
    if (params?.subset_id !== "main_dataset") {
      id = params?.subset_id;
    }
    dispatch(fetchDataset({ query: { dataSetID: id } }));
  }, [params?.dataset_id]);

  return (
    <div className="px-[72px] pt-4 mx-auto h-full bg-white flex flex-col self-center">
      <StandardHeader />
      <div className="min-h-0 pt-10 flex-1 flex flex-col">
        <div className="w-full flex justify-between pb-2">
          <div className="text-3xl">Subsets</div>
          <CreateSubsetPopover
            anchorEl={selectedItemsAnchorEl}
            handlePopoverOpen={(event) =>
              setSelectedItemsAnchorEl(event.currentTarget)
            }
            handlePopoverClose={() => setSelectedItemsAnchorEl(null)}
          />
        </div>
        <NewSubsetFileNotification />
        <SubsetTabs
          subsets={datasetSlice?.subSets}
          selectedTabValue={selectedTab}
          onTabClick={(tab) => setSelectedTab(tab as SubsetsManagerScreenTabs)}
        />
        <div className="w-full flex-1 min-h-0">
          <SubsetsTable selectedTab={selectedTab} />
        </div>
      </div>
    </div>
  );
};
export default SubsetsManager;
