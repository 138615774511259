import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";

interface Props {
  label: string | number;
  colorClass?: string;
}

const StatusLabel = ({ label, colorClass = "bg-paletteGray6" }: Props) => {
  return (
    <div className="flex items-center gap-x-2 text-paletteBlack-2 overflow-hidden">
      <div className={`w-[10px] h-[10px] rounded-full ${colorClass}`} />
      <TooltipTruncateEllipsis className="pr-3">
        {label}
      </TooltipTruncateEllipsis>
    </div>
  );
};

export default StatusLabel;
