import { useAppSelector } from "store/hooks";
import { Mediatype } from "models/dataset.model";
import { ReactComponent as PlayIcon } from "assets/play.svg";

const VideoPlayerLabel = () => {
  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype
  );

  if (mediatype === Mediatype.video) {
    return (
      <div className="absolute w-full h-full p-1 justify-center items-center flex">
        <PlayIcon width="20%" />
      </div>
    );
  } else {
    return null;
  }
};

export default VideoPlayerLabel;
