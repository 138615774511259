import _ from "lodash";
import {
  AttributesModel,
  AttributeMetadataModel,
} from "models/attributes.model";

const sortAttributesArrayAlphabeticallyAndCustom = (
  inputArray: AttributesModel[],
  attributes: AttributeMetadataModel[]
): AttributesModel[] => {
  const customSortingArr = ["width", "height"];

  const sortedArrayAlphabetically = _.sortBy(inputArray, (attribute) => {
    const name = _.find(attributes, { id: attribute?.id })?.name;
    return _.lowerCase(name);
  });

  const customSortedArray = _.sortBy(sortedArrayAlphabetically, (attribute) => {
    const name = _.find(attributes, { id: attribute?.id })?.name;
    if (_.isUndefined(name)) return 0;
    return customSortingArr?.indexOf(name);
  });
  return customSortedArray;
};

export default sortAttributesArrayAlphabeticallyAndCustom;
