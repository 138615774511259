import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationModel } from "models/notification.model";

interface UserManagementState {
  notifications: NotificationModel[];
  currentUserID: string | null;
  availableUserGroups: string[];
}

const initialState: UserManagementState = {
  notifications: [],
  currentUserID: null,
  availableUserGroups: [],
};

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NotificationModel>) => {
      state.notifications.push({ ...action.payload, seen: false });
    },
    markAsSeen: (state, action: PayloadAction<string>) => {
      const index = state.notifications.findIndex(
        (notification) => notification?.id === action.payload,
      );
      if (index !== -1) {
        state.notifications[index].seen = true;
      }
    },
    setCurrentUserID: (state, action: PayloadAction<string | null>) => {
      state.currentUserID = action.payload;
    },
    setAvailableUserGroups: (state, action: PayloadAction<string[]>) => {
      state.availableUserGroups = action.payload;
    },
    resetNotifications: (state) => {
      state.notifications = [];
    },
  },
});

export const {
  addNotification,
  markAsSeen,
  setCurrentUserID,
  setAvailableUserGroups,
  resetNotifications,
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
