import TextInput from "components/Internal/Inputs/Form/TextInput";
import StatusLabelFilled from "components/Internal/Labels/StatusLabelFilled";
import { snakeCaseToText } from "components/utilFunctions";
import { TextFieldModel } from "models/form.model";
import {
  MLAnnotationModel,
  MLAnnotationModelStatus,
} from "models/mlAnnotationModel.model";

type Props = {
  mlAnnotationModelOfAnnotationRun: MLAnnotationModel | null;
  modelName: TextFieldModel;
  setModelName: (value: TextFieldModel) => void;
};

const MLModelInformation = ({
  mlAnnotationModelOfAnnotationRun,
  modelName,
  setModelName,
}: Props) => {
  // This function is used to render the status label of the model
  const renderStatusLabel = () => {
    let label = "Not started";
    if (mlAnnotationModelOfAnnotationRun !== null) {
      label = mlAnnotationModelOfAnnotationRun?.status;
    }

    let circleColor;
    switch (mlAnnotationModelOfAnnotationRun?.status) {
      case MLAnnotationModelStatus.TRAINING:
      case MLAnnotationModelStatus.CREATED:
        circleColor = "bg-paletteYellow";
        break;
      case MLAnnotationModelStatus.TRAINING_FAILED:
        circleColor = "bg-paletteRed";
        break;
      case MLAnnotationModelStatus.TRAINING_DONE:
        circleColor = "bg-paletteGreen";
        break;
      default:
        circleColor = "bg-white";
    }
    return (
      <StatusLabelFilled
        label={snakeCaseToText(label)}
        circleColor={circleColor}
      />
    );
  };

  return (
    <div className="flex flex-col gap-y-2">
      <div className="text-paletteBlack-2">Model information</div>
      <div className="w-full flex items-center">
        <div className="w-full">
          <TextInput
            field={modelName}
            value={modelName.value}
            handleOnChange={(e, field, isErrored, error) =>
              setModelName({
                ...modelName,
                value: e.target.value,
                isErrored: isErrored,
                error: error,
              })
            }
          />
        </div>
      </div>
      <div className="w-full flex items-center">
        <div className="w-1/2">Training status</div>
        <div className="w-1/2">{renderStatusLabel()}</div>
      </div>
    </div>
  );
};

export default MLModelInformation;
