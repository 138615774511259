import Tooltip from "@mui/material/Tooltip";
import { size, truncate } from "lodash";

interface Props {
  children: string;
  title?: string;
  truncateLength?: number;
}

// Helper component used to have a tooltip on a truncated text
const TooltipTruncate = ({ children, title, truncateLength = 24 }: Props) => {
  return size(children) >= truncateLength ? (
    <Tooltip title={title ?? children} arrow>
      <div className="flex justify-center items-center">
        {truncate(children, { length: truncateLength })}
      </div>
    </Tooltip>
  ) : (
    <div className="flex justify-center items-center">{children}</div>
  );
};

export default TooltipTruncate;
