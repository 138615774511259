import SelectButton from "components/Internal/Buttons/SelectButton";
import _ from "lodash";
import {
  FilterAttributeGroupEnum,
  FilterCategoryEnum,
  FilterModel,
  SavedFilterModel,
} from "models/filter.model";
import { useAppSelector } from "store/hooks";
import { ReactComponent as AiIcon } from "assets/ai.svg";
import { renameFilterCategory } from "helpers/functions/filters/filtersHelpers";

type Props = {
  allFilters: { [key: string]: (FilterModel | SavedFilterModel)[] };
  onItemClick: (
    filter: FilterModel | SavedFilterModel,
    selectedFilterCategory: FilterCategoryEnum,
  ) => void;
};

// A component that renders a list of filters or sorters
const GroupsList = ({ allFilters, onItemClick }: Props) => {
  const allAttributes = useAppSelector(
    (state) => state.datasetSlice.attributes,
  );

  const renderGroup = (
    group: (FilterModel | SavedFilterModel)[],
    key: string,
  ) => {
    if (_.isEmpty(group)) {
      return null;
    }
    let label = renameFilterCategory(key as FilterCategoryEnum);

    if (
      _.every(group, (filter) => {
        filter = filter as FilterModel;
        return (
          filter.attribute_group ===
            FilterAttributeGroupEnum.ML_Annotation_Attribute ||
          filter.attribute_group ===
            FilterAttributeGroupEnum.Annotation_Attribute
        );
      })
    ) {
      label =
        _.find(allAttributes, ["id", group?.[0]?.id])?.name || group?.[0]?.name;
    }

    return (
      <div
        key={key}
        className="pt-2 flex flex-col gap-y-1"
        data-test="filter_list"
      >
        {label}
        {_.map(group, (filter) =>
          renderFilter(filter, key as FilterCategoryEnum),
        )}
      </div>
    );
  };

  const renderFilter = (
    filter: FilterModel | SavedFilterModel,
    selectedCategory: FilterCategoryEnum,
  ) => {
    const filterGroup = (filter as FilterModel)?.attribute_group;
    let name = filter?.name;
    if (
      selectedCategory === FilterCategoryEnum.Annotation_Attribute ||
      selectedCategory === FilterCategoryEnum.ML_Annotation_Attribute
    ) {
      const _filter = filter as FilterModel;
      name =
        _.find(allAttributes, ["id", _filter.attribute_id])?.name ||
        _filter?.attribute_name;
    }
    return (
      <SelectButton
        label={_.upperFirst(name)}
        onClick={() =>
          selectedCategory && onItemClick(filter as any, selectedCategory)
        }
        key={`filter_menu_${filter?.id}_${filter?.name}`}
        className="px-4 py-[11.5px] bg-paletteGray-2"
        icon={
          filterGroup === FilterAttributeGroupEnum.ML_Annotation_Attribute ? (
            <AiIcon className="w-3 h-3 mr-1 text-palettePurple " />
          ) : null
        }
      />
    );
  };

  return (
    <div className="w-full">
      {_.map(allFilters, (group, key) => renderGroup(group, key))}
    </div>
  );
};

export default GroupsList;
