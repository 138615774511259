import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

import DataTable, {
  DataTableColumn,
} from "components/Internal/Table/DataTable";
import { setReferenceData } from "store/pipelineDesignerSlice";
import { TagModel } from "models/global.model";
import { fetchTagsAPI } from "helpers/apis/tags";
import { Dialog } from "@mui/material";
import { SendFilterModel } from "models/filter.model";

type Props = {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
};

const SelectReferenceDataDialog = ({ openDialog, setOpenDialog }: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const datasetId = useAppSelector(
    (state) => state.pipelineDesignerSlice.dataset?.id,
  );

  const [attributeTags, setAttributeTags] = useState<TagModel[] | null>(null);

  // Fetch subsets and tags for the selected dataset
  useEffect(() => {
    if (datasetId) {
      const query: SendFilterModel[] = [
        {
          attribute: "database_object_type",
          query_operator: "==",
          value: "Attribute",
        },
      ];
      fetchTagsAPI(datasetId, query).then((data) => {
        setAttributeTags(data.data);
      });

      dispatch(setReferenceData(null));
    }
  }, [datasetId]);

  const referenceDataColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 30,
      className: "font-bold",
    },
    {
      field: "database_object_type",
      headerName: "Type",
      span: 30,
    },
  ];

  const renderReferenceDataTable = () => {
    return (
      <DataTable
        key="reference-data-table"
        rows={attributeTags as []}
        columns={referenceDataColumns}
        onRowClick={(row) => {
          const tag = row as TagModel;
          handleSelectReferenceData(tag);
        }}
        defaultSort={{ name: "name", direction: "asc" }}
        enableSearch={true}
        searchPlaceholder={"Search tags..."}
        isLoading={isLoading}
      />
    );
  };

  const handleSelectReferenceData = (selectedRD: TagModel) => {
    dispatch(
      setReferenceData({
        id: selectedRD?.id,
        name: selectedRD?.name,
      }),
    );

    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      fullWidth={true}
      maxWidth="lg"
    >
      <div className="h-full w-full flex flex-col gap-y-2">
        <div className="p-6 text-xl text-paletteBlack-2">
          Select reference data
        </div>

        <div className="h-[550px] px-6">{renderReferenceDataTable()}</div>
      </div>
    </Dialog>
  );
};

export default SelectReferenceDataDialog;
