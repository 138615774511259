import _ from "lodash";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import Loading from "components/UtilComponents/Loading";

type Props = {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  children: React.ReactNode;
  actions?: React.ReactNode;
  handleOnDialogClose?: () => void;
  title?: string;
  loading?: {
    isLoading: boolean;
    loadingText: string;
  };
  maxWidth?: DialogProps["maxWidth"];
};

const StandardDialog = ({
  openDialog,
  setOpenDialog,
  children,
  actions,
  handleOnDialogClose,
  title,
  loading,
  maxWidth = "sm",
}: Props) => {
  // Render loading
  const renderLoading = () => {
    if (_.isUndefined(loading) || !loading?.isLoading) return null;

    return (
      <div className="my-2 flex gap-x-3 justify-center text-2xl text-paletteGray-7">
        {loading?.loadingText || "Loading..."}
        <div>
          <Loading size={20} />
        </div>
      </div>
    );
  };

  const handleCloseDialog = () => {
    if (loading?.isLoading) return;
    setOpenDialog(false);
    handleOnDialogClose && handleOnDialogClose();
  };

  return (
    <Dialog
      fullWidth={true}
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth={maxWidth}
    >
      <DialogContent className="w-full p-6 pb-5">
        <div className="flex pb-6">
          <div className="text-xl text-paletteBlack-2">{title && title}</div>
          <button
            className="button-layer p-[6px] ml-auto text-paletteGray-7"
            onClick={handleCloseDialog}
          >
            <CloseIcon width={12} height={12} strokeWidth={3} />
          </button>
        </div>

        {children}
        {actions && (
          <div className="w-full flex flex-row-reverse pt-5">{actions}</div>
        )}

        {renderLoading()}
      </DialogContent>
    </Dialog>
  );
};

export default StandardDialog;
