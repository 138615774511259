import { validateForm } from "components/Internal/Forms/formHelper";
import _ from "lodash";
import { FormModel } from "models/form.model";
import {
  PipelineNodeConfiguration,
  VisualisationTypeEnum,
} from "models/pipelines.model";
import { pipelineDesignerStateTypes } from "store/pipelineDesignerSlice";
import validator from "@rjsf/validator-ajv8";
import { AnnotationUi_2_0_0_Deref } from "@quality-match/shared-types";
import { LegacyUIsEnum } from "schemas/annotationUIs.model";
import { answers_binary_2d } from "schemas/uiSchemas/binary_2d";
import { answers_discrete_2d } from "schemas/uiSchemas/discrete_2d";
import { answers_slider_2d } from "schemas/uiSchemas/slider_2d";
import { DatasetModel } from "models/dataset.model";
import { SubsetTypeModel } from "models/global.model";
import { getSubsetTypeCount } from "helpers/functions/datasets/datasetHelpers";

export const getPipelineNode = (
  pipelineDesignerData: pipelineDesignerStateTypes,
) => {
  return {
    name: pipelineDesignerData.pipelineName.value + "_node",
    config: getNodeConfigurations(
      pipelineDesignerData.uiType
        ? LegacyUIsEnum[pipelineDesignerData.uiType]
        : null,
      pipelineDesignerData.configurationsForm,
      pipelineDesignerData.guiFormData,
    ),
  };
};

export const getNodeConfigurations = (
  guiType: string | null,
  configurationsForm: FormModel,
  guiFormData: Record<string, unknown> | null,
) => {
  return {
    ...getNodeMainConfigurations(configurationsForm),
    gui_type: guiType,
    gui_settings: guiFormData,
    possible_answers: getPipelineNodeAnswers(
      guiFormData,
      guiType as LegacyUIsEnum | null,
    ),
    cant_solve_options: getPipelineNodeCanNotSolvedAnswers(
      guiFormData,
      guiType as LegacyUIsEnum | null,
    ),
  };
};

export const getNodeMainConfigurations = (configurationsForm: FormModel) => {
  let configurations: PipelineNodeConfiguration = {
    min_repeats: 3,
    max_repeats: 3,
    convergence_threshold: 0.0,
    wp_tasks: 1,
    wp_timeout: 60,
    wp_rd_tasks: 1,
    media_type: "image",
    should_workpackage_include_task_outputs: "parallel",
    visualisation_type: VisualisationTypeEnum.DEFAULT,
    auto_annotate: false,
  };
  _.map(configurationsForm, (field, key) => {
    configurations = {
      ...configurations,
      [key]: field.value,
    };
  });
  return configurations;
};

export const validateNodeConfigurations = (
  pipelineDesignerData: pipelineDesignerStateTypes,
): pipelineDesignerStateTypes["validation"] => {
  // Validate main configurations
  const fromValidation = validateForm(pipelineDesignerData.configurationsForm);

  // Validate gui configurations
  const errors = validator.validateFormData(
    pipelineDesignerData.guiFormData,
    AnnotationUi_2_0_0_Deref as Record<string, unknown>,
  );

  const isGUISettingsFormValid = errors.errors?.length === 0;

  // Validate data is selected
  const isDataSelected =
    pipelineDesignerData.dataset !== null &&
    pipelineDesignerData.subset !== null;

  // Validate geometry colors
  const isGeometryColorsSelected = pipelineDesignerData.colorMap !== null;

  // Validate UI type is selected
  const isUiTypeSelected = pipelineDesignerData.uiType !== null;

  // Validate reference data
  let isReferenceDataValid = false;
  const referenceDataNumberOfTasks = pipelineDesignerData.configurationsForm
    ?.wp_rd_tasks?.value as number;
  const workPackageNumberOfTasks = pipelineDesignerData.configurationsForm
    ?.wp_tasks?.value as number;
  // True: if reference data is selected and number of tasks is greater than 0
  if (
    pipelineDesignerData.referenceData !== null &&
    referenceDataNumberOfTasks > 0
  ) {
    isReferenceDataValid = true;
  }
  // True: if reference data is not selected and number of tasks is 0
  if (
    pipelineDesignerData.referenceData === null &&
    referenceDataNumberOfTasks === 0
  ) {
    isReferenceDataValid = true;
  }
  // False: if number of reference data tasks is greater or equal to WP tasks
  if (referenceDataNumberOfTasks >= workPackageNumberOfTasks) {
    isReferenceDataValid = false;
  }

  // Validate min number of repeats is smaller than max number of repeats
  const minRepeats = pipelineDesignerData.configurationsForm?.min_repeats
    ?.value as number;
  const maxRepeats = pipelineDesignerData.configurationsForm?.max_repeats
    ?.value as number;
  const isMinNumberSmallerOrEqualToMaxNumber = minRepeats <= maxRepeats;

  // Validate WP tasks is smaller or equal to subset count
  const isWpTasksSmallerOrEqualToSubsetCount =
    pipelineDesignerData.subset !== null &&
    (pipelineDesignerData.configurationsForm?.wp_tasks?.value as number) <=
      getSubsetTypeCount(pipelineDesignerData.subset);

  const isPipelineNameValid =
    pipelineDesignerData.pipelineName.value.length >= 3;

  return {
    isConfigurationsFormValid: fromValidation.isFormValid,
    isGUISettingsFormValid: isGUISettingsFormValid,
    isDataSelected: isDataSelected,
    isGeometryColorsSelected: isGeometryColorsSelected,
    isUiTypeSelected: isUiTypeSelected,
    isReferenceDataValid: isReferenceDataValid,
    isMinNumberSmallerOrEqualToMaxNumber: isMinNumberSmallerOrEqualToMaxNumber,
    isWpTasksSmallerOrEqualToSubsetCount: isWpTasksSmallerOrEqualToSubsetCount,
    isPipelineNameValid: isPipelineNameValid,
  };
};

export const getPipelineDesignerErrorMessages = (
  pipelineDesignerData: pipelineDesignerStateTypes,
) => {
  let errorMessageList: string[] = [];

  const wpRdTasksValue = pipelineDesignerData.configurationsForm?.wp_rd_tasks
    ?.value as number;
  const wpTasksValue = pipelineDesignerData.configurationsForm?.wp_tasks
    ?.value as number;

  //if pipeline name too short
  if (pipelineDesignerData.pipelineName.value.length < 3) {
    errorMessageList = [
      ...errorMessageList,
      "The pipeline name must be at least 3 characters long.",
    ];
  }
  //if pipeline name not set
  if (pipelineDesignerData.pipelineName.value == "") {
    errorMessageList = [...errorMessageList, "The pipeline name must be set."];
  }
  // If reference data is selected but number of tasks is 0
  if (pipelineDesignerData.referenceData !== null && wpRdTasksValue === 0) {
    errorMessageList = [
      ...errorMessageList,
      "Reference data is selected but number of tasks is 0.",
    ];
  }
  // If number of reference data tasks is greater than 0 but reference data is not selected
  if (pipelineDesignerData.referenceData === null && wpRdTasksValue > 0) {
    errorMessageList = [
      ...errorMessageList,
      "Number of reference data tasks is greater than 0 but reference data is not selected.",
    ];
  }
  // If number of reference data tasks is greater or equal to WP tasks
  if (wpRdTasksValue >= wpTasksValue) {
    errorMessageList = [
      ...errorMessageList,
      "Number of reference data tasks needs to be lower than the number of tasks per work package.",
    ];
  }
  return errorMessageList;
};

export const getPipelineNodeAnswers = (
  guiSettingsFormData: Record<string, unknown> | null,
  guiType: LegacyUIsEnum | null,
): { key: string; label: string }[] | null => {
  if (guiSettingsFormData === null || guiType === null) return null;

  switch (guiType) {
    case LegacyUIsEnum.binary_2d: {
      const foundBinaryToolbar = _.find(guiSettingsFormData?.toolbars as [], [
        "type",
        answers_binary_2d.toolbarKey,
      ]);
      if (foundBinaryToolbar) {
        return [
          { key: "yes", label: "Yes" },
          { key: "no", label: "No" },
        ];
      }
      return null;
    }
    case LegacyUIsEnum.discrete_2d: {
      let foundDiscreteToolbar: Record<string, unknown> = {};
      _.map(
        guiSettingsFormData?.toolbars as [],
        (toolbar: { type: string }) => {
          if (toolbar.type === answers_discrete_2d.toolbarKey) {
            foundDiscreteToolbar = toolbar;
          }
        },
      );
      if (foundDiscreteToolbar) {
        const answers = _.get(foundDiscreteToolbar, "settings.answers", null);
        return answers as { key: string; label: string }[];
      }
      return null;
    }
    case LegacyUIsEnum.slider_2d: {
      let foundSliderToolbar: Record<string, unknown> = {};
      _.map(
        guiSettingsFormData?.toolbars as [],
        (toolbar: { type: string }) => {
          if (toolbar.type === answers_slider_2d.toolbarKey) {
            foundSliderToolbar = toolbar;
          }
        },
      );
      if (foundSliderToolbar) {
        const settings = _.get(foundSliderToolbar, "settings", null) as Record<
          string,
          unknown
        >;
        return [
          { key: String(settings?.max), label: "Max" },
          { key: String(settings?.min), label: "Min" },
          { key: String(settings?.step), label: "Step" },
        ] as { key: string; label: string }[];
      }
      return null;
    }
    default:
      return null;
  }
};

export const getPipelineNodeCanNotSolvedAnswers = (
  guiSettingsFormData: Record<string, unknown> | null,
  guiType: LegacyUIsEnum | null,
): { key: string; label: string }[] | null => {
  if (guiSettingsFormData === null || guiType === null) return null;

  let foundUIToolbar: Record<string, unknown> = {};
  switch (guiType) {
    case LegacyUIsEnum.binary_2d: {
      const foundBinaryToolbar = _.find(guiSettingsFormData?.toolbars as [], [
        "type",
        answers_binary_2d.toolbarKey,
      ]);
      if (foundBinaryToolbar) {
        foundUIToolbar = foundBinaryToolbar;
        break;
      }
      return null;
    }
    case LegacyUIsEnum.discrete_2d: {
      let foundDiscreteToolbar: Record<string, unknown> = {};
      _.map(
        guiSettingsFormData?.toolbars as [],
        (toolbar: { type: string }) => {
          if (toolbar.type === answers_discrete_2d.toolbarKey) {
            foundDiscreteToolbar = toolbar;
          }
        },
      );
      if (foundDiscreteToolbar) {
        foundUIToolbar = foundDiscreteToolbar;
        break;
      }
      return null;
    }
    case LegacyUIsEnum.slider_2d: {
      let foundSliderToolbar: Record<string, unknown> = {};
      _.map(
        guiSettingsFormData?.toolbars as [],
        (toolbar: { type: string }) => {
          if (toolbar.type === answers_slider_2d.cantSolveToolbarKey) {
            foundSliderToolbar = toolbar;
          }
        },
      );
      if (foundSliderToolbar) {
        foundUIToolbar = foundSliderToolbar;
        break;
      }
      return null;
    }
  }

  const settings = _.get(foundUIToolbar, "settings", null) as Record<
    string,
    unknown
  >;

  if (settings?.withUnsolvableButton && settings?.withOptions) {
    return settings?.unsolvableOptions as {
      key: string;
      label: string;
    }[];
  }

  if (settings?.withUnsolvableButton && !settings?.withOptions) {
    return [
      {
        key: settings?.unsolvableCode as string,
        label: settings?.unsolvableButtonLabel as string,
      },
    ];
  }

  return null;
};

export const getEstimateNumberNanoTasks = (
  minRepeats: number | undefined,
  maxRepeats: number | undefined,
  subset: Partial<DatasetModel>,
): string => {
  const subsetType = subset?.subset_type;
  let subsetSize = 0;

  switch (subsetType) {
    case SubsetTypeModel.Media:
      subsetSize = subset?.num_medias as number;
      break;
    case SubsetTypeModel.MediaObject:
      subsetSize = subset?.num_media_objects as number;
      break;
    case SubsetTypeModel.Instance:
      subsetSize = subset?.num_instances as number;
      break;
  }

  if (minRepeats && maxRepeats) {
    return `${subsetSize * minRepeats} - ${subsetSize * maxRepeats}`;
  }
  return "";
};
