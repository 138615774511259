import { ErrorListProps, RJSFValidationError, UiSchema } from "@rjsf/utils";
const ErrorListTemplate = (props: ErrorListProps) => {
  const { errors } = props;
  return (
    <div className="text-paletteRed">
      <div>Errors:</div>
      <div>
        {errors.map((error: RJSFValidationError, i: number) => {
          return (
            <div key={i} className="text-sm text-paletteRed">
              -{error.stack}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ErrorListTemplate;
