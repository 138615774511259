import { get } from "lodash";
import { snakeCaseToText } from "../components/utilFunctions";

const acronymList = {
  false_negative_count: "FN Count",
  false_positive_count: "FP Count",
  true_positive_count: "TP Count",
  min_confidence: "Min. Confidence",
  num_bbs_qm: "No. BBs QM",
  num_bbs_reference: "No. BBs Ref.",
  qm_repeats: "QM Repeats",
  total_disagreement: "Tot. Disagreement",
};

// Get the corresponding acronym for a key/text and convert it to readable text
const getAcronym = (
  text: string,
  convertFromSnakeCaseToText: boolean = true
): string => {
  const _acronymText = get(acronymList, text, text);
  if (convertFromSnakeCaseToText) {
    return snakeCaseToText(_acronymText);
  }
  return _acronymText;
};

export default getAcronym;
