import { Popover } from "@mui/material";
import _ from "lodash";
import React from "react";

export type IsNotType = "is" | "not" | "off";
interface Props {
  anchorEl: HTMLElement | null;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handlePopoverClose: () => void;
  selectedValue: IsNotType;
  onClick: (clickedType: IsNotType) => void;
  inCludeOff?: boolean;
}

const IsNotPopover = ({
  anchorEl,
  handlePopoverOpen,
  handlePopoverClose,
  selectedValue,
  onClick,
  inCludeOff = false,
}: Props) => {
  const open = Boolean(anchorEl);
  const renderOpenButton = () => {
    return (
      <div
        className="rounded"
        onClick={(event) => {
          event.stopPropagation();
          handlePopoverOpen(event);
        }}
      >
        {renderButton(selectedValue)}
      </div>
    );
  };

  const renderItems = () => {
    let isNotTypes: IsNotType[] = ["is", "not"];
    if (inCludeOff) {
      isNotTypes = ["is", "not", "off"];
    }

    // Put the selected value at the top of the list
    const rest = _.pull(isNotTypes, selectedValue);
    const items = [selectedValue, ...rest];

    return (
      <div className="flex flex-col gap-y-[1px] bg-transparent">
        {_.map(items, (type) => {
          return (
            <div
              key={type}
              onClick={() => {
                onClick(type);
                handlePopoverClose();
              }}
            >
              {renderButton(type)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderButton = (type: IsNotType) => {
    return (
      <div
        className={`w-[44px] py-[2px] pl-1 pr-[2px] flex gap-x-[1px] justify-between 
            cursor-pointer rounded-[inherit]
        ${
          type === "off"
            ? "bg-white hover:bg-paletteGray-4"
            : // TODO: The hover color not in pallette
              "bg-paletteBlack-1 hover:bg-[#3A3A3A]"
        }`}
      >
        <div
          className={`flex-1 flex justify-center items-center ${
            type === "off" ? "text-paletteGray-10" : "text-white"
          }`}
          data-test="is_not_button"
        >
          {_.upperCase(type)}
        </div>
        <div className={`h-6 w-1 rounded ${selectColor(type)}`} />
      </div>
    );
  };

  const selectColor = (type: IsNotType) => {
    switch (type) {
      case "is":
        return "bg-paletteGreen";
      case "not":
        return "bg-paletteRed";
      case "off":
        return "bg-paletteGray-5";
      default:
        return "";
    }
  };

  return (
    <div>
      {renderOpenButton()}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => handlePopoverClose()}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        PaperProps={{
          classes: {
            root: "rounded-lg shadow-dropdown",
          },
        }}
      >
        {renderItems()}
      </Popover>
    </div>
  );
};

export default IsNotPopover;
