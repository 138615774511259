import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BasicButton from "components/Internal/Buttons/BasicButton";
import { useState } from "react";
import { Link } from "react-router-dom";
import SwitchComp from "components/Internal/Filters/Switch";
import {
  handleAcceptance,
  handleRefusal,
} from "helpers/analytics/cookieAcceptance";

const CookieWarning = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [isGAAllowed, setIsGAAllowed] = useState<boolean>(true);
  const [isMCAllowed, setIsMCAllowed] = useState<boolean>(true);

  const renderGAAcceptance = () => {
    return (
      <div className="py-4 flex flex-col gap-y-2">
        <div className="flex gap-x-2">
          <SwitchComp
            checked={isGAAllowed}
            onChange={() => setIsGAAllowed(!isGAAllowed)}
          />
          Allow Google Analytics
        </div>
        <div className="flex gap-x-2 items-center">
          <SwitchComp
            checked={isMCAllowed}
            onChange={() => setIsMCAllowed(!isMCAllowed)}
          />
          Allow Microsoft Clarity
        </div>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cookies 🍪</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We use cookies to provide you with the best possible user
            experience. Do you agree to our use of cookies and our{" "}
            <Link to="/Privacy-Policy" target="_blank">
              privacy policy
            </Link>
            ?
          </DialogContentText>
          {renderGAAcceptance()}
        </DialogContent>
        <DialogActions className="mr-6">
          <BasicButton
            className="bg-green-500 text-white border-0 h-9 w-24"
            data-test="accept_cookies_button"
            onClick={() => {
              handleAcceptance(isGAAllowed, isMCAllowed);
              setOpen(false);
            }}
            label={"Accept"}
          />
          <BasicButton
            className="bg-paletteRed text-white border-0 h-9 w-24"
            data-test="decline_cookies_button"
            onClick={() => {
              handleRefusal();
              setOpen(false);
            }}
            label={"Decline"}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CookieWarning;
