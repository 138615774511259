import { PopoverOrigin } from "@mui/material";
import StandardPopover, {
  StandardPopoverProps,
} from "components/Internal/Popovers/StandardPopover";
import { useState } from "react";

// This is a wrapper for StandardPopover that is used when we want to use the popover
// where we have a list of items that have the same popover.
interface Props {
  id: string;
  onOpen?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => void;
  onClose?: () => void;
  buttonElement?: JSX.Element;
  buttonTitle?: string;
  itemsList?: StandardPopoverProps["itemsList"];
  children?: JSX.Element;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  offSetPopover?: {
    top?: number;
    left?: number;
  };
}

const StandardPopoverWrapper = ({
  id,
  onOpen,
  onClose,
  buttonElement,
  buttonTitle = "Open popover",
  itemsList,
  children = <></>,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "right",
  },
  offSetPopover,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    onOpen && onOpen(event, id);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    onClose && onClose();
  };

  return (
    <StandardPopover
      id={id}
      anchorEl={anchorEl}
      handlePopoverOpen={handlePopoverOpen}
      handlePopoverClose={handlePopoverClose}
      buttonTitle={buttonTitle}
      buttonElement={buttonElement}
      itemsList={itemsList}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      offSetPopover={offSetPopover}
    >
      {children}
    </StandardPopover>
  );
};

export default StandardPopoverWrapper;
