import { Popover } from "@mui/material";
import { OrderSortModel } from "models/global.model";
import React from "react";
import { ReactComponent as SortAscIcon } from "assets/sort_asc.svg";
import { ReactComponent as SortDescIcon } from "assets/sort_desc.svg";

interface Props {
  anchorEl: HTMLElement | null;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handlePopoverClose: () => void;
  selectedValue: OrderSortModel;
  onClick: (clickedType: OrderSortModel) => void;
}

const SortingPopover = ({
  anchorEl,
  handlePopoverOpen,
  handlePopoverClose,
  selectedValue,
  onClick,
}: Props) => {
  const open = Boolean(anchorEl);

  const renderOpenButton = () => {
    return (
      <div
        className="rounded"
        onClick={(event) => {
          event.stopPropagation();
          handlePopoverOpen(event);
        }}
      >
        {renderButton(selectedValue)}
      </div>
    );
  };

  const renderItems = () => {
    return (
      <div className="flex flex-col bg-transparent">
        <div
          onClick={() => {
            onClick("asc");
            handlePopoverClose();
          }}
        >
          {renderButton("asc")}
        </div>
        <div
          onClick={() => {
            onClick("desc");
            handlePopoverClose();
          }}
        >
          {renderButton("desc")}
        </div>
      </div>
    );
  };

  const renderButton = (type: OrderSortModel) => {
    let label = "Ascending";
    let icon = <SortAscIcon className="w-3 h-3" />;
    if (type === "desc") {
      label = "Descending";
      icon = <SortDescIcon className="w-3 h-3" />;
    }
    return (
      <div
        className="px-2 py-[2.5px] flex items-center gap-x-1
          bg-white text-paletteBlack-2 text-sm
          cursor-pointer rounded-[inherit] 
          hover:bg-paletteGray-2"
      >
        {icon} {label}
      </div>
    );
  };

  return (
    <div>
      {renderOpenButton()}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => handlePopoverClose()}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        PaperProps={{
          classes: {
            root: "rounded-lg shadow-dropdown",
          },
        }}
      >
        {renderItems()}
      </Popover>
    </div>
  );
};

export default SortingPopover;
