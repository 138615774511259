import { SendFilterModel } from "models/filter.model";

const subsetQuery = (value: string): SendFilterModel => {
  return {
    attribute: "subset_ids",
    query_operator: "all",
    value: value,
  };
};

export default subsetQuery;
