import _ from "lodash";
import { DatasetModel } from "models/dataset.model";
import { ReactComponent as GearIcon } from "assets/gear.svg";
type Props = {
  subset: DatasetModel | null;
};

const SubsetCreationLoading = ({ subset }: Props) => {
  const renderPulsingBox = (index: number) => {
    return (
      <div
        key={`subset-creation-loading-${index}`}
        className="aspect-square bg-paletteGray-5 flex justify-center animate-pulse rounded-lg"
      >
        <div className="w-[95%] h-[80%] mx-1 mt-2 bg-paletteGray-3 rounded"></div>
      </div>
    );
  };
  return (
    <div className="w-full h-full pr-4 flex flex-col items-center">
      <div className="label-layer my-4 px-3 py-3 flex items-center justify-center gap-x-3">
        <GearIcon className="w-10 h-10 animate-spin-slow" />
        <div className="flex flex-col justify-center items-center gap-y-1">
          <div className="flex gap-x-2 items-center text-lg font-light text-paletteBlack-2">
            Creating your new subset{" "}
            <div className=" italic">{subset?.name}</div>, this may take a
            while. Feel free to explore other parts of the app in the meantime.
          </div>
        </div>
      </div>
      <div className="w-full h-fit grid grid-cols-7 gap-2  ">
        {_.map(_.range(21), (i) => renderPulsingBox(i))}
      </div>
    </div>
  );
};

export default SubsetCreationLoading;
