import { ReactComponent as ArrowBoldLeftIcon } from "assets/arrow_bold_left.svg";
import { ReactComponent as ArrowBoldRightIcon } from "assets/arrow_bold_right.svg";
import { ReactComponent as DownloadIcon } from "assets/download.svg";
import { ReactComponent as EyeChartsIcon } from "assets/eye_charts.svg";
import exportElementToMedia from "helpers/functions/exportElementToMedia";
import { statisticsStateTypes } from "store/statisticsSlice";

interface Props {
  selectedChart: keyof statisticsStateTypes["charts"] | null;
  showInputs: boolean;
  setShowInputs: (newState: boolean) => void;
}

const ChartsControls = ({
  selectedChart,
  showInputs,
  setShowInputs,
}: Props) => {
  return (
    <div className="w-full h-full p-4 flex justify-between">
      <button
        className={`button-layer px-4 py-2
          ${showInputs ? "bg-black text-white" : ""}`}
        onClick={() => setShowInputs(!showInputs)}
      >
        {showInputs ? <ArrowBoldLeftIcon /> : <ArrowBoldRightIcon />}
        Inputs
      </button>
      <div className="flex gap-x-3">
        <button
          className="button-layer px-4 py-2"
          onClick={() =>
            selectedChart && exportElementToMedia(selectedChart, selectedChart)
          }
        >
          <DownloadIcon width="16px" height="16px" />
          Download
        </button>
        <button
          className="button-layer px-4 py-2"
          // TODO: add function
          onClick={() => console.log("Display dropdown!")}
          disabled={true}
        >
          <EyeChartsIcon width="16px" height="16px" />
          Display
        </button>
      </div>
    </div>
  );
};

export default ChartsControls;
