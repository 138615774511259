import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import appSlice from "./appSlice";
import dashboardSlice from "./dashboardSlice";
import datasetSlice from "./datasetSlice";
import explorationMediaSlice from "./explorationMediaSlice";
import explorationInstancesSlice from "store/explorationInstancesSlice";
import explorationMediaObjectsSlice from "./explorationMediaObjectsSlice";
import metaDataSlice from "./metaDataSlice";
import filterDataSlice from "./filterDataSlice";
import sortDataSlice from "./sortDataSlice";
import tagFrequenciesSlice from "./tagFrequenciesSlice";
import detailsScreenSlice from "store/detailsScreenSlice";
import statisticsSlice from "store/statisticsSlice";
import userManagementSlice from "store/userManagementSlice";
import pipelineDesignerSlice from "store/pipelineDesignerSlice";
import aiManagerSlice from "store/aiManagerSlice";

export const store = configureStore({
  reducer: {
    appSlice: appSlice,
    dashboardSlice: dashboardSlice,
    datasetSlice: datasetSlice,
    explorationMediaSlice: explorationMediaSlice,
    explorationInstanceSlice: explorationInstancesSlice,
    explorationMediaObjectsSlice: explorationMediaObjectsSlice,
    metaDataSlice: metaDataSlice,
    filterDataSlice: filterDataSlice,
    sortDataSlice: sortDataSlice,
    tagFrequenciesSlice: tagFrequenciesSlice,
    detailsScreenSlice: detailsScreenSlice,
    statisticsSlice: statisticsSlice,
    userManagementSlice: userManagementSlice,
    pipelineDesignerSlice: pipelineDesignerSlice,
    aiManagerSlice: aiManagerSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof configureStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export default store;
