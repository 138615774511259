import { AnnotatableEnum, SelectedViewModel } from "models/global.model";
import {
  ExplorationScreenRouteModel,
  PipelineDesignerScreenRouteModel,
} from "models/routes.model";
import { matchPath } from "react-router-dom";
import { allUsersRoutes, customContractUsersRoutes } from "routes";

export const getExplorationRouteFromSelectedView = (
  selectedView: SelectedViewModel,
  routeParams: ExplorationScreenRouteModel,
) => {
  switch (selectedView) {
    case AnnotatableEnum.MediaObject:
      return getExplorationObjectPageRoute(routeParams);
    case AnnotatableEnum.Instance:
      return getExplorationInstancePageRoute(routeParams);
    case AnnotatableEnum.Media:
    default:
      return getExplorationMediaPageRoute(routeParams);
  }
};

export const getExplorationSelectedViewFromRoute = (
  route: string,
): SelectedViewModel => {
  if (matchPath(route, allUsersRoutes.explorationMediaPage.path)) {
    return AnnotatableEnum.Media;
  } else if (matchPath(route, allUsersRoutes.explorationObjectPage.path)) {
    return AnnotatableEnum.MediaObject;
  } else if (matchPath(route, allUsersRoutes.explorationInstancePage.path)) {
    return AnnotatableEnum.Instance;
  }
  return AnnotatableEnum.Media;
};

export const getExplorationMediaPageRoute = (
  routeModel: ExplorationScreenRouteModel,
) => {
  const baseRoute = allUsersRoutes.explorationMediaPage.path;
  return baseRoute
    .replace(":id", routeModel.id)
    .replace(":subset_id", routeModel.subset_id);
};

export const getExplorationObjectPageRoute = (
  routeModel: ExplorationScreenRouteModel,
) => {
  const baseRoute = allUsersRoutes.explorationObjectPage.path;
  return baseRoute
    .replace(":id", routeModel.id)
    .replace(":subset_id", routeModel.subset_id);
};

export const getExplorationInstancePageRoute = (
  routeModel: ExplorationScreenRouteModel,
) => {
  const baseRoute = allUsersRoutes.explorationInstancePage.path;
  return baseRoute
    .replace(":id", routeModel.id)
    .replace(":subset_id", routeModel.subset_id);
};

export const getPipelineDesignerPageRoute = (
  routeModel: PipelineDesignerScreenRouteModel,
) => {
  const baseRoute = customContractUsersRoutes.pipelineDesignerPage.path;
  return baseRoute.replace(":pipeline_id", routeModel.pipeline_id);
};
