import { AppDispatch } from "store";

import { resetExplorationMediaSlice } from "store/explorationMediaSlice";
import { resetExplorationInstanceSlice } from "store/explorationInstancesSlice";
import { resetExplorationMediaObjectsSlice } from "store/explorationMediaObjectsSlice";
import { resetFilterDataSlice } from "store/filterDataSlice";
import { resetMetaDataSlice } from "store/metaDataSlice";
import { resetSortDataSlice } from "store/sortDataSlice";
import { resetTagFrequenciesSlice } from "store/tagFrequenciesSlice";
import { resetDetailsScreenSlice } from "store/detailsScreenSlice";
import { resetStatisticsSlice } from "store/statisticsSlice";
import { resetDatasetSlice } from "store/datasetSlice";

/**
 * Reset the store when selecting or creating a dataset or a subset
 * @param dispatch Dispatch of the store
 */
export const resetStoreOnSubsetChange = (dispatch: AppDispatch) => {
  dispatch(resetExplorationMediaSlice());
  dispatch(resetExplorationInstanceSlice());
  dispatch(resetExplorationMediaObjectsSlice());
  dispatch(resetFilterDataSlice());
  dispatch(resetMetaDataSlice());
  dispatch(resetSortDataSlice());
  dispatch(resetTagFrequenciesSlice());
  dispatch(resetDetailsScreenSlice());
  dispatch(resetStatisticsSlice());
  dispatch(resetDatasetSlice());
};
