import _ from "lodash";
import { DatasetModel } from "../../models/dataset.model";
import { TagModel } from "models/global.model";

function getSubsetName(
  subset_id: string,
  subsets: DatasetModel[],
  tags?: TagModel[]
): string {
  let name = _.find(subsets, { id: subset_id })?.name;
  let output = subset_id;

  if (!_.isUndefined(tags) && _.isUndefined(name)) {
    name = _.find(tags, { id: subset_id })?.name;
  }
  if (!_.isUndefined(name)) {
    output = name;
  }
  return output;
}

export default getSubsetName;
