import { APIFetchAxios } from "components/UtilComponents/Auth";
import {
  ExplorationAPIRequestModel,
  MediaObjectRawModel,
  MediaModel,
} from "models/exploration.model";
import _ from "lodash";

// Post request to media objects, used to fetch media objects with a query
export const fetchMediaObjects = async (
  body: ExplorationAPIRequestModel
): Promise<MediaObjectRawModel[]> => {
  if (_.isUndefined(body?.projection)) {
    body = { ...body, projection: null };
  }
  const response = await APIFetchAxios(
    `/datasets/${body.dataset_id}/mediaObjects`,
    body
  );
  return response?.data;
};

// Post request to one media
export const fetchOneMediaAPI = async (
  mediaID: string,
  datasetID: string
): Promise<MediaModel> => {
  const response = await APIFetchAxios(
    `/datasets/${datasetID}/medias/${mediaID}`,
    { projection: { media_objects: false } }
  );
  return response?.data;
};

// Post request to media objects, used to fetch one media object
export const fetchOneMediaObjectAPI = async (
  mediaObjectId: string,
  datasetID: string
): Promise<MediaObjectRawModel> => {
  const response = await APIFetchAxios(
    `/datasets/${datasetID}/mediaObjects/${mediaObjectId}`,
    { projection: null }
  );
  return response?.data;
};
