import { useAppSelector } from "store/hooks";
import { useHotkeys } from "react-hotkeys-hook";
import { InstanceModel, MediaObjectModel } from "models/exploration.model";

import ToggleGeometries from "components/DetailsScreen/ActionBar/ToggleGeometries";
import StandardHeader from "components/Internal/Headers/StandardHeader";
import { useParams } from "react-router-dom";
import { MediaDetailsScreenRouteModel } from "models/routes.model";
import { getExplorationURLWithSearchParams } from "helpers/functions/filters/filtersHelpers";
import SelectVisualisation from "components/DetailsScreen/ActionBar/SelectVisualisation";
import { determineCountKeyFromParam } from "store/metaDataSlice";

interface Props {
  currentItemIndex: number;
  setCurrentItemIndex: (newIndex: number) => void;
  currentItem?: InstanceModel;
  currentMediaObjects?: MediaObjectModel[];
}

const ActionsBar = ({
  currentItemIndex,
  setCurrentItemIndex,
  currentItem,
  currentMediaObjects,
}: Props) => {
  const params: MediaDetailsScreenRouteModel = useParams();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const sortData = useAppSelector((state) => state.sortDataSlice);
  const metaData = useAppSelector((state) => state.metaDataSlice);

  const handlePrevious = () => {
    if (currentItemIndex > 0) {
      setCurrentItemIndex(currentItemIndex - 1);
    }
  };

  const handleNext = () => {
    const totalKey = determineCountKeyFromParam(selectedView);
    if (!totalKey) return;
    const totalCount = metaData[totalKey];
    if (currentItemIndex + 2 <= totalCount.total_count) {
      setCurrentItemIndex(currentItemIndex + 1);
    }
  };

  useHotkeys("left", () => handlePrevious(), [currentItemIndex]);
  useHotkeys("right", () => handleNext(), [currentItemIndex]);

  return (
    <div className="w-full p-4 pt-0 grid grid-cols-2 bg-white">
      <StandardHeader
        backTo={{
          pathname: getExplorationURLWithSearchParams(
            selectedView,
            filterData,
            sortData,
            {
              id: params?.id,
              subset_id: params?.subset_id,
            }
          ),
        }}
      />
      {/* Right Section */}
      <div className="flex gap-3 justify-end items-center">
        {currentItem && <SelectVisualisation currentItem={currentItem} />}
        <ToggleGeometries currentMediaObjects={currentMediaObjects} />
      </div>
    </div>
  );
};

export default ActionsBar;
