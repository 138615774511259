import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import _ from "lodash";
import { ReactComponent as Close } from "assets/close.svg";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import BarChartComp, {
  BarChartCompDataModel,
} from "components/Statistics/Charts/BarChartComp";
import { setQaResultsPerAttribute } from "store/statisticsSlice";
import { StatisticsScreenRouteModel } from "models/routes.model";
import { useParams } from "react-router-dom";
import { SendFilterModel } from "models/filter.model";
import addSubsetToQuery from "helpers/functions/addSubsetToQuery";
import { fetchMediasCount } from "helpers/apis/medias";
import { fetchMediaObjectsCount } from "helpers/apis/mediaObjects";
import { isAttributeAnnotationAttribute } from "helpers/functions/attributes/attributesHelpers";

interface Props {
  showInputs: boolean;
}

const ResultsPerAttributeChart = ({ showInputs }: Props) => {
  const params: StatisticsScreenRouteModel = useParams();
  const dispatch = useAppDispatch();

  const allAttributes = useAppSelector((state) =>
    _.filter(state.datasetSlice.attributes, (attributeMeta) =>
      isAttributeAnnotationAttribute(attributeMeta?.attribute_group),
    ),
  );

  const filterDataSlice = useAppSelector((state) => state.filterDataSlice);
  const chartData = useAppSelector(
    (state) => state.statisticsSlice.charts.qa_results_per_attribute.data,
  );
  const [isLoading, setIsLoading] = useState(false);

  const attributeSelector = (index: number) => {
    return (
      <div
        key={`attribute${index}`}
        className="w-full pl-3 py-1 pr-2 bg-paletteGray-3 flex items-center justify-between gap-x-3 rounded"
      >
        {`Attribute${index}`}
        <select
          className="w-3/4"
          value={chartData?.[index]?.id}
          onChange={(event) => {
            const attributeID = event?.target?.value;
            getCount(attributeID).then((response) => {
              response &&
                handleSelectOnChange(index, attributeID, response?.total_count);
            });
          }}
          disabled={isLoading}
        >
          <option value="">--Select attribute--</option>
          {_.map(allAttributes, (attribute) => (
            <option key={attribute?.id} value={attribute?.id}>
              {attribute?.annotatable_type}: {attribute?.name}
            </option>
          ))}
        </select>

        <Close
          width="12px"
          height="12px"
          onClick={() => {
            let newArray: any = [];
            _.map(chartData, (att, attIndex) => {
              if (attIndex !== index) {
                newArray = [...newArray, att];
              }
            });
            dispatch(setQaResultsPerAttribute(newArray));
          }}
        />
      </div>
    );
  };

  const addAttributeSelector = () => {
    return (
      <div
        className="button-layer px-4 py-2 gap-x-3 rounded"
        onClick={() => {
          dispatch(
            setQaResultsPerAttribute([
              ...chartData,
              { id: "", results: {}, totalCount: 1 },
            ]),
          );
        }}
      >
        <PlusIcon width="8px" height="8px" />
        "Add another attribute"
      </div>
    );
  };

  const renderCharts = () => {
    let maxValue = 1;
    _.map(chartData, (attribute) => {
      _.map(attribute?.results, (answer) => {
        if (answer > maxValue) {
          maxValue = answer;
        }
      });
    });

    return _.map(chartData, (attribute, index) => {
      const attributeName = _.find(
        allAttributes,
        (att) => att?.id === attribute?.id,
      )?.name;

      let data: BarChartCompDataModel = [];
      _.map(attribute?.results, (count, name) => {
        data = [...data, { name: name, count: count }];
      });
      return (
        <div
          key={`chart_${index}_${attribute?.id}`}
          className="h-full flex flex-col items-center"
          style={{ width: `${100 / chartData?.length}%` }}
        >
          <BarChartComp
            data={data}
            height={95}
            yAxis={{ hide: index !== 0, domain: [0, _.ceil(maxValue * 1.1)] }}
            showLegend={false}
            percentage={{ show: true, totalCount: attribute?.totalCount }}
          />
          <div className="text-sm">{attributeName}</div>
        </div>
      );
    });
  };

  const getCount = async (key: string) => {
    const attribute = _.find(allAttributes, (att) => att?.id === key);
    const sendFilters: SendFilterModel[] = [
      {
        attribute: `attributes.${key}.dataset_id`,
        query_operator: "==",
        value: params.dataset_id,
      },
    ];
    const body = addSubsetToQuery(params.subset_id, sendFilters);
    setIsLoading(true);
    let response;
    if (attribute?.annotatable_type === "Media") {
      response = fetchMediasCount(params?.dataset_id, body, dispatch);
    } else if (attribute?.annotatable_type === "MediaObject") {
      response = fetchMediaObjectsCount(params?.dataset_id, body, dispatch);
    }
    setIsLoading(false);
    return response;
  };

  const handleSelectOnChange = (
    index: number,
    attributeID: string,
    totalCount: number,
  ) => {
    // Add attribute id to index location
    let newArray: {
      id: string;
      results: { [key: string]: number };
      totalCount: number;
    }[] = [];
    _.map(chartData, (att, attIndex) => {
      if (attIndex !== index) {
        newArray = [...newArray, att];
      } else {
        // Get the attribute histograms from filters
        let answers: { [key: string]: number } = {};
        const attributeFilter = _.find(
          {
            ...filterDataSlice.mediaFilterData,
            ...filterDataSlice.annotationFilterData,
          },
          (filter) =>
            filter?.attribute_id === attributeID &&
            filter.filter_name === "value",
        );
        _.map(attributeFilter?.histogram, (hist) => {
          if (hist?.freq > 0) {
            answers = { ...answers, [hist?.bin_str]: hist?.freq };
          }
        });

        newArray = [
          ...newArray,
          {
            id: attributeID as string,
            results: answers,
            totalCount: totalCount,
          },
        ];
      }
    });
    dispatch(setQaResultsPerAttribute(newArray));
  };

  return (
    <div className="w-full h-full flex p-3">
      {showInputs ? (
        <div
          className="w-[300px] h-full text-sm flex flex-col gap-y-3 overflow-y-auto animate-fade"
          style={{
            transition: "width 0.1s",
          }}
        >
          Current dataset
          {_.map(chartData, (attributeVal, attributeKey) =>
            attributeSelector(attributeKey),
          )}
          {addAttributeSelector()}
        </div>
      ) : (
        <div
          className="w-[0px]"
          style={{
            transition: "width 0.1s",
          }}
        ></div>
      )}
      <div
        className="h-full flex animate-fade"
        style={{
          width: showInputs ? "calc(100% - 300px)" : "100%",
          transition: "width 0.1s",
        }}
      >
        {renderCharts()}
      </div>
    </div>
  );
};

export default ResultsPerAttributeChart;
