import _, { isEmpty } from "lodash";
import { useAppSelector } from "store/hooks";

import CategoricalSwitch from "components/Internal/Filters/CategoricalSwitch";
import { FilterModel } from "models/filter.model";
import NumericalFilter from "components/Internal/Filters/NumericalFilter";
import SwitchComp from "components/Internal/Filters/Switch";
import {
  AnnotatableEnum,
  SelectedViewModel,
  TagModel,
  TagTypeModel,
} from "models/global.model";
import TagsSelector from "components/Internal/Inputs/TagsSelector";
import { ReactComponent as ResetIcon } from "assets/reset.svg";
import { useState } from "react";
import IsNotPopover from "components/Internal/Filters/HelperComponents/IsNotPopover";
import IncludingPopover from "components/Internal/Filters/HelperComponents/IncludingPopover";
import SearchByText from "components/Internal/Filters/SearchByText";

interface Props {
  attribute: FilterModel;
  updateFilterData: (
    updatedFilter: FilterModel,
    type: SelectedViewModel
  ) => void;
  isCollapsed?: boolean;
}

const FilterRouter = ({
  attribute,
  updateFilterData,
  isCollapsed = false,
}: Props) => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const tags = useAppSelector((state) =>
    _.keyBy(state.datasetSlice?.tags, "id")
  );

  const subsets = useAppSelector((state) =>
    _.keyBy(
      _.filter(
        state.datasetSlice?.subSets,
        (subset) => subset?.subset_type !== "attribute"
      ),
      "id"
    )
  );

  const [anchorIsNotEl, setAnchorIsNotEl] = useState<HTMLElement | null>(null);
  const [anchorIncludeEdgesEl, setAnchorIncludeEdgesEl] =
    useState<HTMLElement | null>(null);

  // ------------------------------- Methods to handle filter value changes: -------------------------------
  // Main filter handler:
  const handleFilterChange = (newVal: any) => {
    let newObj: FilterModel = { ...attribute };
    switch (attribute?.type) {
      case "NUMERICAL":
        newObj = {
          ...newObj,
          lower_bound: newVal[0],
          upper_bound: newVal[1],
        };
        break;
      case "SUBSET":
      case "FE_SUBSET":
      case "FE_TAG":
      case "BOOLEAN":
        newObj = {
          ...newObj,
          selected_cats: newVal,
        };
        break;
      case "CATEGORICAL": {
        const { selected_cats, not_selected_cats } = newVal;
        newObj = {
          ...newObj,
          selected_cats: selected_cats,
          not_selected_cats: not_selected_cats,
        };
        break;
      }
      case "SEARCH_BY_TEXT": {
        let newValue = newVal;
        if (attribute.FE_cast_value_to_number) {
          newValue = _.map(newVal, (v) => {
            return _.toNumber(v);
          });
        }
        newObj = {
          ...newObj,
          selected_cats: newValue,
          not_selected_cats: [],
        };
        break;
      }
    }
    updateFilterData(newObj, selectedView);
  };

  const handleFilterReset = () => {
    let newObj: FilterModel = { ...attribute };
    newObj = {
      ...newObj?.init,
      init: newObj?.init,
      is_visible: true,
    };
    updateFilterData(newObj, selectedView);
  };

  // Check if the filter have value
  // If the filter don't have values we gray out the title and remove the reset button
  const filterIsActive = (): boolean => {
    // Check numeric
    if (
      attribute?.type === "NUMERICAL" &&
      (attribute?.lower === attribute?.upper ||
        attribute?.lower === null ||
        attribute?.upper === null)
    ) {
      return false;
    }
    // Check character varying filters
    else if (
      attribute?.type === "CATEGORICAL" &&
      isEmpty(attribute?.categories)
    ) {
      return false;
    }
    // Return true as default
    return true;
  };

  const renderFilter = () => {
    switch (attribute?.type) {
      case "NUMERICAL":
        return (
          <NumericalFilter
            attribute={attribute}
            handleFilterChange={handleFilterChange}
          />
        );
      case "CATEGORICAL":
        return (
          <CategoricalSwitch
            attribute={attribute}
            handleFilterChange={handleFilterChange}
          />
        );

      case "SEARCH_BY_TEXT":
        return (
          <SearchByText
            attribute={attribute}
            handleFilterChange={handleFilterChange}
          />
        );

      case "BOOLEAN":
        return (
          <div className="flex flex-col gap-2">
            {_.map(attribute?.buckets, (bucket) => {
              if (_.isArray(bucket) && bucket?.length > 0) {
                const key = bucket?.[0];
                let checked = false;
                _.map(attribute?.selected_cats, (v) => {
                  if (v === key) {
                    checked = true;
                  }
                });
                return (
                  <div className="flex">
                    <SwitchComp
                      checked={checked}
                      name="attributeBool"
                      onChange={(event) => {
                        if (event?.target?.checked == false) {
                          if (checked) {
                            let newVal: unknown[] = [];
                            _.map(attribute?.selected_cats as [], (v) => {
                              if (v !== key) {
                                newVal = [...newVal, v];
                              }
                            });
                            handleFilterChange(newVal);
                          }
                        } else {
                          handleFilterChange([
                            ...(attribute?.selected_cats as []),
                            key,
                          ]);
                        }
                      }}
                    />
                    <div className="pl-4">
                      {_.isNull(key) ? "Null" : key?.toString()}: {bucket?.[1]}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );

      // Attribute tags
      case "SUBSET": {
        const buckets = _.fromPairs(attribute?.buckets);
        let filteredTags: { [key: string]: TagModel } = {};
        if (attribute?.filter_name === "tags") {
          _.map(
            _.pickBy(buckets, (value) => {
              return value ? value > 0 : false;
            }),
            (count: number, tag_id: string) => {
              filteredTags = {
                ...filteredTags,
                [tag_id]: {
                  ...tags[tag_id],
                  // Append name
                  name: tags[tag_id]?.name,
                  // Append count
                  count: count,
                },
              };
            }
          );
        }

        // Selected tags
        const selectedTags = _.keyBy(
          _.filter(filteredTags, (tag) =>
            _.includes(attribute?.selected_cats, tag?.id)
          ),
          "id"
        );
        return (
          <div className="flex justify-center">
            <TagsSelector
              items={filteredTags}
              selectedItems={selectedTags}
              addLabel="Add item"
              handleAddTag={(tag) => {
                if (_.isArray(attribute?.selected_cats)) {
                  handleFilterChange([...attribute?.selected_cats, tag?.id]);
                }
              }}
              handleRemoveTag={(tag) => {
                if (_.isArray(attribute?.selected_cats)) {
                  const oldTagList = [...attribute?.selected_cats];
                  const newTagList = _.remove(oldTagList, (i) => i !== tag?.id);
                  handleFilterChange(newTagList);
                }
              }}
            />
          </div>
        );
      }
      // Select subset on an media or media object
      case "FE_SUBSET": {
        // Selected subsets
        const selectedSubsets = _.keyBy(
          _.filter(subsets, (tag) =>
            _.includes(attribute?.selected_cats, tag?.id)
          ),
          "id"
        );

        return (
          <div className="flex justify-center">
            <TagsSelector
              items={subsets}
              selectedItems={selectedSubsets}
              addLabel="Add item"
              placeholder={"Add a subset"}
              handleAddTag={(tag) => {
                if (_.isArray(attribute?.selected_cats)) {
                  handleFilterChange([...attribute?.selected_cats, tag?.id]);
                }
              }}
              handleRemoveTag={(tag) => {
                if (_.isArray(attribute?.selected_cats)) {
                  const oldTagList = [...attribute?.selected_cats];
                  const newTagList = _.remove(oldTagList, (i) => i !== tag?.id);
                  handleFilterChange(newTagList);
                }
              }}
            />
          </div>
        );
      }
      // Select tags on an media or media object
      case "FE_TAG": {
        // Available tags
        const availableTags = _.pickBy(tags, (tag) => {
          // Select only tags that are of the same type as the selected view
          switch (selectedView) {
            case AnnotatableEnum.Media:
              return tag?.database_object_type === TagTypeModel.Media;
            case AnnotatableEnum.Instance:
              return tag?.database_object_type === TagTypeModel.Instance;
            case AnnotatableEnum.MediaObject:
              return tag?.database_object_type === TagTypeModel.MediaObject;
            default:
              return false;
          }
        });
        // Selected tags
        const selectedTags = _.pickBy(tags, (tag) =>
          _.includes(attribute?.selected_cats, tag?.id)
        );

        return (
          <div className="flex justify-center">
            <TagsSelector
              items={availableTags}
              selectedItems={selectedTags}
              addLabel="Add item"
              handleAddTag={(tag) => {
                if (_.isArray(attribute?.selected_cats)) {
                  handleFilterChange([...attribute?.selected_cats, tag?.id]);
                }
              }}
              handleRemoveTag={(tag) => {
                if (_.isArray(attribute?.selected_cats)) {
                  const oldTagList = [...attribute?.selected_cats];
                  const newTagList = _.remove(oldTagList, (i) => i !== tag?.id);
                  handleFilterChange(newTagList);
                }
              }}
            />
          </div>
        );
      }
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-between">
        <div className="text-paletteBlack-2">
          {_.upperFirst(attribute?.name)}
        </div>
        <div className="flex h-fit gap-x-2">
          {renderResetButton()}
          {renderIncludeEdgesButton()}
          {renderIsOrNot()}
        </div>
      </div>
    );
  };

  const renderResetButton = () => {
    if (filterIsActive()) {
      return (
        <div className="flex justify-center items-center">
          <button
            className="button-select-layer px-1 h-fit py-0 text-paletteGray-10"
            onClick={() => handleFilterReset()}
          >
            <ResetIcon width={16} height={16} />
          </button>
        </div>
      );
    }
  };

  const renderIsOrNot = () => {
    if (
      (filterIsActive() && attribute?.type === "NUMERICAL") ||
      attribute?.type === "FE_SUBSET" ||
      attribute?.type === "FE_TAG" ||
      attribute?.type === "SUBSET" ||
      attribute?.type === "SEARCH_BY_TEXT"
    ) {
      return (
        <IsNotPopover
          anchorEl={anchorIsNotEl}
          handlePopoverOpen={(
            event: React.MouseEvent<HTMLElement, MouseEvent>
          ) => setAnchorIsNotEl(event.currentTarget)}
          handlePopoverClose={() => setAnchorIsNotEl(null)}
          selectedValue={attribute?.FE_is_not ? "not" : "is"}
          onClick={(type) => {
            const FE_is_not = type === "is" ? true : false;
            updateFilterData(
              { ...attribute, FE_is_not: !FE_is_not },
              selectedView
            );
          }}
        />
      );
    }
  };

  const renderIncludeEdgesButton = () => {
    if (filterIsActive() && attribute?.type === "NUMERICAL") {
      const { FE_include_edges } = attribute;
      return (
        <IncludingPopover
          anchorEl={anchorIncludeEdgesEl}
          handlePopoverOpen={(
            event: React.MouseEvent<HTMLElement, MouseEvent>
          ) => setAnchorIncludeEdgesEl(event.currentTarget)}
          handlePopoverClose={() => setAnchorIncludeEdgesEl(null)}
          selectedValue={FE_include_edges ? "≤-≥" : "<->"}
          onClick={(type) => {
            const FE_include_edges = type === "≤-≥" ? true : false;
            updateFilterData(
              { ...attribute, FE_include_edges: FE_include_edges },
              selectedView
            );
          }}
        />
      );
    }
  };

  // --------------------------------------- HTML ------------------------------------------
  return (
    <div className="w-full flex flex-col gap-y-2">
      {renderHeader()}
      {!isCollapsed && filterIsActive() && renderFilter()}
    </div>
  );
};
export default FilterRouter;
