import { Tooltip } from "@mui/material";
import _ from "lodash";

type Props = {
  children: string | number;
  title?: string;
  className?: string;
};

const TooltipTruncateEllipsis = ({ children, title, className }: Props) => {
  if (_.isString(children) || _.isNumber(children)) {
    return (
      <div className="flex items-center overflow-hidden">
        <Tooltip title={title ?? children} arrow>
          <div
            className={`truncate text-ellipsis overflow-hidden 
                ${className ? className : ""}`}
          >
            {children}
          </div>
        </Tooltip>
      </div>
    );
  }

  return children;
};

export default TooltipTruncateEllipsis;
