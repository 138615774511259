import { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { DatasetModel } from "models/dataset.model";
import { resetStoreOnSubsetChange } from "store/util/resetStore";
import { resetDatasetSlice } from "store/datasetSlice";

import { DataTableColumn } from "components/Internal/Table/DataTable";
import EditName from "components/Internal/Inputs/EditName";
import MoreButton from "components/Internal/Buttons/MoreButton";
import StandardPopoverWrapper from "components/Internal/Popovers/StandardPopoverWrapper";
import ConfirmDialog from "components/Internal/Dialogs/ConfirmDialog";
import LicenseDialog from "components/Internal/Dialogs/LicenseDialog";
import { AnnotatableEnum } from "models/global.model";
import { getDownloadFile } from "helpers/apis/download";
import { fetchDashboardInformation } from "store/dashboardSlice";
import { resetNotifications } from "store/userManagementSlice";
import { setSelectedView } from "store/appSlice";
import generateDateString from "helpers/functions/generateDateString";
import DatasetTable from "components/Internal/Table/DatasetTable";
import { deleteDataset, patchDatasetMeta } from "helpers/apis/datasets";
import { getExplorationRouteFromSelectedView } from "routes/routesHelper";
import { useKeycloak } from "@react-keycloak/web";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";

const PopulatedDatasetTable = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});
  const [editInputID, setEditInputID] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [deleteItemID, setDeleteItemID] = useState<string>("");
  const [isEditNameActive, setIsEditNameActive] = useState(false);
  const [isLicenseDialogOpen, setIsLicenseDialogOpen] = useState(false);
  const [activeDataset, setActiveDataset] = useState<DatasetModel | null>(null);
  const [userinfo, setUserInfo] = useState<any>();
  const [userRoles, setUserRoles] = useState<any>();

  const dashboardData = useAppSelector((state) => state.dashboardSlice.data);

  useEffect(() => {
    resetStoreOnSubsetChange(dispatch);
    dispatch(resetDatasetSlice());
    dispatch(fetchDashboardInformation({ subset: false }));
    dispatch(resetNotifications());
    dispatch(setSelectedView(AnnotatableEnum.Media));
  }, []);

  // focus on the input field when the editInputID changes
  useEffect(() => {
    if (editInputID) {
      inputRefArray.current?.[editInputID]?.focus();
    }
    keycloak.loadUserInfo().then((userInfo) => {
      setUserRoles(keycloak.realmAccess?.roles);
      setUserInfo(userInfo);
    });
  }, [editInputID]);

  const handleDatasetClick = (dataset: DatasetModel): void => {
    if (_.isNull(editInputID) && dataset?.id) {
      if (
        !_.isUndefined(dataset?.license) &&
        !_.isNull(dataset?.license) &&
        !keycloak?.idTokenParsed?.accepted_licenses?.includes(dataset?.id)
      ) {
        setActiveDataset(dataset);
        setIsLicenseDialogOpen(true);
        return;
      } else {
        const explorationRoute = getExplorationRouteFromSelectedView(
          selectedView,
          {
            id: dataset?.id,
            subset_id: "main_dataset",
          },
        );
        if (history.location.pathname !== explorationRoute) {
          resetStoreOnSubsetChange(dispatch);
          history.push(explorationRoute);
        }
      }
    }
  };

  const columns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 40,
      className: "font-bold",
      cell: (row) => {
        const dataset = row as DatasetModel;
        return (
          <div className="flex items-center">
            <div
              className={`flex gap-x-1 w-fit px-2 py-[3.5px] rounded-[8px] overflow-hidden pr-3 ${
                !isEditNameActive ? "hover:bg-paletteGray-4" : ""
              }`}
            >
              <EditName
                onFocus={() => setIsEditNameActive(true)}
                onMouseOver={() => setIsEditNameActive(true)}
                item={row as Record<string, any>}
                inputRefArray={inputRefArray}
                editInputID={editInputID}
                setEditInputID={setEditInputID}
                handleRename={(newName: string) => {
                  handleRenameDataset(newName, dataset?.id);
                }}
                fontSize="text-xl"
                withToolTip
              />
            </div>
            {dataset?.export_id && (
              <div
                className="border-paletteOrange h-fit px-[6px] py-[3px] border-[1px] bg-white
            rounded-[10px] items-center flex
             gap-x-2 text-paletteOrange w-fit text-xs"
                onClick={(e) => {
                  e.stopPropagation();
                  getDownloadFile(
                    {
                      datasetID: dataset?.id,
                      fileID: dataset?.export_id,
                    },
                    dispatch,
                    setIsLoading,
                  );
                }}
              >
                READY TO DOWNLOAD
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "num_medias",
      headerName: "Medias",
      span: 20,
      cell: (row) => {
        const dataset = row as DatasetModel;
        return <div>{dataset?.num_medias?.toLocaleString("fi-FI")}</div>;
      },
    },
    {
      field: "num_media_objects",
      headerName: "Objects",
      span: 20,
      cell: (row) => {
        const dataset = row as DatasetModel;
        return <div>{dataset?.num_media_objects?.toLocaleString("fi-FI")}</div>;
      },
    },
    {
      field: "creation_timestamp",
      headerName: "Created",
      span: 15,
      cell: (row) => {
        const dataset = row as DatasetModel;
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {!_?.isNull(dataset?.creation_timestamp)
              ? generateDateString(new Date(dataset?.creation_timestamp), false)
              : "-"}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 5,
      cell: (row) => {
        const dataset = row as DatasetModel;
        const shouldShowDelete =
          checkIfUserHavePermission(keycloak, "accessDeleteDatasetFeature") ||
          String(userinfo?.sub) === dataset.owner;
        const itemsList = [
          {
            label: "Rename",
            onItemClick: () => {
              setEditInputID(dataset?.id);
            },
          },
          ...(shouldShowDelete
            ? [
                {
                  label: "Delete",
                  onItemClick: () => {
                    setIsConfirmDialogOpen(true);
                    setDeleteItemID(dataset?.id);
                  },
                  className: "text-paletteRed",
                  disabled: dataset?.object_category,
                },
              ]
            : []),
        ];

        if (dataset?.license) {
          itemsList.splice(1, 0, {
            label: "License",
            onItemClick: () => {
              const newWnd = window.open(
                dataset?.license,
                "_blank",
                "noopener,noreferrer",
              );
              if (newWnd) {
                newWnd.opener = null;
              }
            },
          });
        }

        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full flex flex-row-reverse"
          >
            <StandardPopoverWrapper
              id={dataset?.id}
              buttonElement={<MoreButton />}
              itemsList={itemsList}
            />
          </div>
        );
      },
    },
  ];

  const handleRenameDataset = (newName: string, datasetID: string) => {
    patchDatasetMeta(
      { datasetID: datasetID, dataset: { name: newName } },
      dispatch,
    ).then(() => {
      // Todo: Replace with dataset specific fetch
      dispatch(fetchDashboardInformation());
    });
  };

  const handleDelete = () => {
    if (!deleteItemID) return;
    deleteDataset({ datasetID: deleteItemID }, dispatch, setIsLoading)
      .then(() => {
        setIsConfirmDialogOpen(false);
        // Todo: Replace with dataset specific fetch
        dispatch(fetchDashboardInformation());
      })
      .catch(() => {
        setIsConfirmDialogOpen(false);
      });
  };

  return (
    <>
      <DatasetTable
        rows={_.map(dashboardData, (dataset: DatasetModel) => {
          return dataset as DatasetModel;
        })}
        columns={columns}
        onRowClick={(row) => handleDatasetClick(row as DatasetModel)}
        defaultSort={{ name: "name", direction: "asc" }}
        enableSearch={true}
      />

      <ConfirmDialog
        isConfirmDialogOpen={isConfirmDialogOpen}
        setIsConfirmDialogOpen={setIsConfirmDialogOpen}
        text={`Are you sure you want to delete the dataset "${dashboardData?.[deleteItemID]?.name}"?`}
        handleOnSuccess={handleDelete}
        isLoading={isLoading}
      />
      {activeDataset && (
        <LicenseDialog
          openDialog={isLicenseDialogOpen}
          setOpenDialog={setIsLicenseDialogOpen}
          dataset={activeDataset}
          targetPath={getExplorationRouteFromSelectedView(selectedView, {
            id: activeDataset?.id,
            subset_id: "main_dataset",
          })}
          title="License information"
        />
      )}
    </>
  );
};

export default PopulatedDatasetTable;
