export enum UploadProcessingStatus {
  created = "created",
  started = "started",
  done = "done",
  failed = "failed",
}

export interface UploadJobsModel {
  id: string;
  name: string;
  s3_dataset_url: string;
  scaleai_json_url?: string;
  gdsm_json_url?: string;
  user_name: string;
  user_groups: string[];
  status: UploadProcessingStatus;
  created_at: string;
  updated_at: string;
}
