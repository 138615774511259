import { Fragment, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CreateNewFile from "components/UtilComponents/SidebarMenu/DownloadDialog/CreateNewFile";
import FilesTable from "components/UtilComponents/SidebarMenu/DownloadDialog/FilesTable";
import { useHotkeys } from "react-hotkeys-hook";
import SidebarIcon from "components/UtilComponents/SidebarMenu/SidebarIcon";
import { useAppSelector } from "store/hooks";
import _ from "lodash";
import { AnnotatableEnum } from "models/global.model";

const DownloadDialog = () => {
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [showCreateNewFile, setShowCreateNewFile] = useState(false);

  const datasetSlice = useAppSelector((state) => state.datasetSlice);
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);

  useHotkeys(
    "d",
    () => {
      setOpenDownloadDialog(!openDownloadDialog);
    },
    [openDownloadDialog],
  );

  return (
    <Fragment>
      <SidebarIcon
        icon="download"
        disabled={
          _.isNull(datasetSlice?.activeDataSet) ||
          selectedView === AnnotatableEnum.Instance
        }
        onClick={() => {
          setOpenDownloadDialog(true);
        }}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openDownloadDialog}
        onClose={() => setOpenDownloadDialog(false)}
      >
        <DialogContent>
          <div className="flex justify-between py-2">
            <div className="text-2xl font-semibold">Export JSON file</div>
            <button
              onClick={() => setShowCreateNewFile(!showCreateNewFile)}
              className="button-layer w-56 h-[42px]"
              data-test="create_new_export_file_button"
            >
              {`${
                showCreateNewFile
                  ? "Show export files table"
                  : "Create new export file"
              } `}
            </button>
          </div>
          <div className={`${showCreateNewFile ? "block" : "hidden"}`}>
            <CreateNewFile />
          </div>
          <div className={`${!showCreateNewFile ? "block" : "hidden"}`}>
            <FilesTable />
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default DownloadDialog;
