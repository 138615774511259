import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import _ from "lodash";
import {
  statisticsStateTypes,
  setObjectsPerMedia,
} from "store/statisticsSlice";
import BarChartComp, {
  BarChartCompDataModel,
} from "components/Statistics/Charts/BarChartComp";

interface Props {
  showInputs: boolean;
}

const ObjectsPerMedia = ({ showInputs }: Props) => {
  const dispatch = useAppDispatch();

  const mediaObjectsFrequency = useAppSelector(
    (state) => state.metaDataSlice.mediaObjectsFrequency
  );

  const chartData = useAppSelector(
    (state) => state.statisticsSlice.charts.objects_per_media
  );

  useEffect(() => {
    // initialize array with length max number of objects per media
    const length =
      Math.max(...Object?.keys(mediaObjectsFrequency)?.map(Number)) + 1;
    // assign count = 0 to all objects
    let objectsPerMedia = Array.from(
      {
        length: length > 0 ? length : 1,
      },
      (_, i) => {
        return { name: i.toString(), count: 0, color: "#8884d8" };
      }
    );

    // assign proper count to instances
    _.map(mediaObjectsFrequency, (value: number, key: number) => {
      objectsPerMedia = [
        ...objectsPerMedia.slice(0, key),
        { ...objectsPerMedia[key], count: value },
        ...objectsPerMedia.slice(Number(key) + 1),
      ];
    });

    const total = _.sumBy(objectsPerMedia, "count");

    dispatch(
      setObjectsPerMedia({
        ...chartData,
        totalCount: total,
        data: objectsPerMedia,
      })
    );
  }, [mediaObjectsFrequency]);

  const renderCharts = () => {
    const data: BarChartCompDataModel = chartData?.data;

    return (
      <BarChartComp
        data={data}
        width={95}
        percentage={{ show: false, totalCount: chartData?.totalCount || 1 }}
        showLegend={false}
        showLabels={true}
      />
    );
  };

  const chartID: keyof statisticsStateTypes["charts"] = "objects_per_media";

  return (
    <div className="w-full h-full flex p-3">
      {showInputs ? (
        <div
          className="w-[300px] h-full text-sm flex flex-col gap-y-3 overflow-y-auto animate-fade"
          style={{
            transition: "width 0.1s",
          }}
        >
          {""}
        </div>
      ) : (
        <div
          className="w-[0px]"
          style={{
            transition: "width 0.1s",
          }}
        ></div>
      )}
      <div
        id={chartID}
        className="h-full flex"
        style={{
          width: showInputs ? "calc(100% - 300px)" : "100%",
          transition: "width 0.1s",
        }}
      >
        {renderCharts()}
      </div>
    </div>
  );
};

export default ObjectsPerMedia;
