// Fixes TS Error Property 'dataLayer' does not exist on type Window
declare global {
  interface Window {
    dataLayer: any;
  }
}

/**
 * Pushes an event to the dataLayer from where it is picked up by Google Tag Manager.
 * @param event Event name
 * @param data Additional data to be sent with the event
 */
export const pushToDataLayer = (
  event: string,
  data: Record<string, any> | undefined = undefined,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    ...(data || {}),
  });
};
