import {
  APIDelete,
  APIFetchAxios,
  APIPatchWithBodyAxios,
  APIPostWithBodyAxios,
} from "components/UtilComponents/Auth";
import { AxiosError } from "axios";
import { SendFilterModel } from "models/filter.model";
import { Dispatch } from "@reduxjs/toolkit";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import { SubsetTypeModel, VisibilityStatus } from "models/global.model";
import { DatasetModel } from "models/dataset.model";

/**
 * An endpoint to create a subset.
 * Used to create a subset with filters applied to it.
 * Used for 3 cases:
 * 1. Create a subset with filters applied to it
 * 2. Create a subset with filters applied to it and a sample size
 * 3. Create a subset with specific object categories
 * @param query
 * @param query.parentDatasetID The id of the parent dataset
 * @param query.newName The name of the new subset
 * @param query.subsetType The type of the subset
 * @param query.sampleSize The size of the sample subset
 * @param body.filters The filters to be applied to the subset
 * @param dispatch
 * @param setIsLoading
 */
export const postCreateSubset = async (
  query: {
    parentDatasetID: string;
    newName: string;
    subsetType: SubsetTypeModel;
    sampleSize?: number;
    visualisation_config_id?: string;
  },
  body: {
    filter_options: SendFilterModel[];
    secondary_filter_options?: SendFilterModel[];
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<string> => {
  const { parentDatasetID, newName, subsetType, visualisation_config_id } =
    query;

  let endpoint = `/subsets:createFiltered?dataset_id=${parentDatasetID}&subset_type=${subsetType}&subset_name=${encodeURIComponent(
    newName,
  )}`;

  if (query.sampleSize) {
    endpoint = `/subsets:sample?dataset_id=${parentDatasetID}&subset_type=${subsetType}&subset_name=${encodeURIComponent(
      newName,
    )}&random_sample_size=${query.sampleSize}`;
  }

  if (visualisation_config_id) {
    endpoint += `&visualisation_config_id=${visualisation_config_id}`;
  }
  setIsLoading && setIsLoading(true);
  const response = await APIPostWithBodyAxios(endpoint, body)
    .then((response) => {
      snackbarHelper(
        dispatch,
        "Your subset is being created. This might take a while. We will notify you once it is done.",
        "info",
      );
      setIsLoading && setIsLoading(false);
      return response;
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        dispatch,
        `Subset creation: ${errorDetail}` || "Subset creation failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
    });
  return response?.data;
};

/**
 * Endpoint to rename a subset
 * @param query
 * @param query.subsetID The id of the subset
 * @param query.newName The new name of the subset
 * @param dispatch Dispatch function from redux
 * @param setIsLoading Set the loading state of the page
 * @returns The response from the server
 */
export const postRenameSubset = async (
  query: { subsetID: string; newName: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  const { subsetID, newName } = query;
  setIsLoading && setIsLoading(true);
  await APIPatchWithBodyAxios(
    `/subsets/${subsetID}/?subset_name=${encodeURIComponent(newName)}`,
    {},
  )
    .then(() => {
      snackbarHelper(dispatch, "Subset renamed successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        dispatch,
        `Subset rename: ${errorDetail}` || "Subset renaming failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

/**
 * An endpoint to delete a subset
 * @param query
 * @param query.subsetID The id of the subset to be deleted
 * @param dispatch Dispatch function from redux
 * @param setIsLoading Set the loading state of the page
 */
export const deleteSubset = async (
  query: { subsetID: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  const { subsetID } = query;
  setIsLoading && setIsLoading(true);
  await APIDelete(`/subsets/${subsetID}`)
    .then(() => {
      snackbarHelper(dispatch, "Subset deleted successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        dispatch,
        `Subset delete: ${errorDetail}` || "Subset deleting failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

/**
 * An endpoint to create a new subset that have specific categories
 *  in its' subset_ids field
 * @param datasetID The id of the dataset
 * @param subsetName Name of the subset to be created
 * @param body
 *  filter_options: The current applied filters
 *  secondary_filter_options: Includes the subset_ids filter,
 *    used to specify which object categories will be included in the new subset
 * @returns The uuid of the newly created subset
 */
export const postCreateSubsetWithSpecificObjectSubset = async (
  datasetID: string,
  subsetName: string,
  body: {
    filter_options: SendFilterModel[];
    secondary_filter_options: [
      {
        attribute: "subset_ids";
        query_operator: "in";
        value: string[];
      },
    ];
  },
): Promise<string> => {
  const response = await APIPostWithBodyAxios(
    `/subsets:createFiltered?dataset_id=${datasetID}&subset_type=media&subset_name=${subsetName}`,
    body,
  );
  return response?.data;
};

/** Get all subsets of a dataset
 * @param datasetID The id of the dataset
 * @param visibility_status The visibility status of the subsets
 * @param dispatch Dispatch function from redux
 * @param setIsLoading Set the loading state of the page
 */
export const getSubsetsForDataset = (
  datasetID: string,
  dispatch?: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
  visibility_status: VisibilityStatus[] = [VisibilityStatus.Visible],
): Promise<DatasetModel[] | null> => {
  //   This part is commented out in case we want to enable visibility status
  // const params = new URLSearchParams();
  //
  // visibility_status.forEach((status) => {
  //   params.append("visibility_statuses", status);
  // });
  return APIFetchAxios(`/datasets/${datasetID}/subsets`)
    .then((response) => response.data)
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      dispatch &&
        snackbarHelper(
          dispatch,
          `Subsets: ${errorDetail}!` || "Subset fetching failed!",
          "error",
        );
      setIsLoading && setIsLoading(false);
      return Promise.reject(error);
    });
};

/**
 * An endpoint to split a subset into two subsets used for AI node
 * @param body
 * @param body.datasetID The id of the dataset
 * @param body.subsetID The id of the subset to be split
 * @param body.aiSubsetName The name of the AI subset
 * @param body.trainingSubsetName The name of the training subset
 * @param body.subsetSize The size of the AI subset
 * @param dispatch
 * @param setIsLoading
 */
export const postSplitSubset = async (
  body: {
    datasetID: string;
    subsetID: string;
    aiSubsetName: string;
    referenceSubsetName: string;
    subsetSize: number;
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);

  await APIPostWithBodyAxios(`/subsets/${body.subsetID}:split`, {
    reference_dataset_size: body?.subsetSize,
    reference_subset_name: body?.referenceSubsetName,
    annotation_subset_name: body?.aiSubsetName,
  })
    .then(() => {
      snackbarHelper(
        dispatch,
        "Subset split is started, this will take a while!",
      );
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        dispatch,
        errorDetail
          ? `Subset split: ${errorDetail}`
          : "Subset splitting failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};
