import AIModelManagerTable from "Pages/AIManager/AIModelManager/AIModelManagerTable";

function AIModelManager() {
  return (
    <div className="w-full flex-1 min-h-0 pt-4">
      <AIModelManagerTable />
    </div>
  );
}

export default AIModelManager;
