import { Label } from "@quality-match/lidar-viewer";
import _ from "lodash";
import { CategoriesFiltersModel } from "models/detailsScreen.model";
import { MediaObjectModel } from "models/exploration.model";

/**
 *  Filter the geometries based on the toggled categories
 * @param geometries The geometries to filter based on the toggled categories
 * @param objectCategories The object categories
 * @returns The filtered geometries based on the toggled categories
 */
export const filterGeometriesBasedOnToggledCategories = (
  geometries: MediaObjectModel[],
  objectCategories: CategoriesFiltersModel
) => {
  let enabledCategories: string[] = [];
  _.map(objectCategories, (cat) => {
    if (cat?.enable) {
      enabledCategories = [...enabledCategories, cat?.id];
    }
  });

  let filteredBBs: MediaObjectModel[] = [];
  _.map(geometries, (bb) => {
    // Check if none of the object categories filters are selected
    if (_.isEmpty(enabledCategories)) {
      filteredBBs = [...filteredBBs, bb];
    } else {
      // Check if the media object belong to an enabled category
      if (
        bb?.object_category &&
        _.includes(enabledCategories, bb?.object_category)
      ) {
        filteredBBs = [...filteredBBs, bb];
      }
      // Handle media objects with no category assigned
      else if (
        _.isUndefined(bb?.object_category) &&
        _.includes(enabledCategories, "no_category")
      ) {
        filteredBBs = [...filteredBBs, bb];
      }
    }
  });

  return filteredBBs;
};

/**
 *  Filter the geometries based on the toggled categories
 * @param geometries The geometries to filter based on the toggled categories
 * @param objectCategories The object categories
 * @returns The filtered geometries based on the toggled categories
 */
export const filterLabelsBasedOnToggledCategories = (
  geometries: Label[],
  objectCategories: CategoriesFiltersModel
) => {
  let enabledCategories: string[] = [];
  _.map(objectCategories, (cat) => {
    if (cat?.enable) {
      enabledCategories = [...enabledCategories, cat?.id];
    }
  });

  let filteredBBs: Label[] = [];
  _.map(geometries, (bb) => {
    // Check if none of the object categories filters are selected
    if (_.isEmpty(enabledCategories)) {
      filteredBBs = [...filteredBBs, bb];
    } else {
      // Check if the media object belong to an enabled category
      if (bb?.class && _.includes(enabledCategories, bb?.class)) {
        filteredBBs = [...filteredBBs, bb];
      }
      // Handle media objects with no category assigned
      else if (
        _.isUndefined(bb?.class) &&
        _.includes(enabledCategories, "no_category")
      ) {
        filteredBBs = [...filteredBBs, bb];
      }
    }
  });

  return filteredBBs;
};
