import { AppDispatch } from "store";

import { setSnackbar } from "store/appSlice";
import _ from "lodash";
import { AlertColor } from "@mui/material";

const snackbarHelper = (
  dispatch: AppDispatch,
  msg: string,
  severity: AlertColor = "success",
  duration?: number
) => {
  let message = "Something went wrong!";
  if (_.isString(msg)) {
    message = msg;
  }
  dispatch(
    setSnackbar({ msg: message, severity: severity, duration: duration })
  );
};

export default snackbarHelper;
