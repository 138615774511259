import { snakeCaseToText } from "components/utilFunctions";
import _ from "lodash";
import { SortingRequestModel } from "models/exploration.model";
import {
  ActiveFilterModel,
  FilterAttributeGroupEnum,
  FilterCategoryEnum,
  FilterModel,
  FiltersModel,
  FiltersTypesModel,
} from "models/filter.model";
import {
  AnnotatableEnum,
  OrderSortModel,
  SelectedViewModel,
} from "models/global.model";
import { ExplorationScreenRouteModel } from "models/routes.model";
import { matchPath } from "react-router-dom";
import { allUsersRoutes } from "routes";
import { getExplorationRouteFromSelectedView } from "routes/routesHelper";
import {
  determineActiveFilterKeyFromParam,
  filterDataStateTypes,
} from "store/filterDataSlice";
import {
  determineActiveSortKeyFromParam,
  sortDataStateTypes,
} from "store/sortDataSlice";

enum FilterTypePrefix {
  CATEGORICAL = "cat",
  NUMERICAL = "num",
  FE_SUBSET = "fe_sub",
  FE_TAG = "fe_tag",
  SUBSET = "sub",
  SELECT_ATTRIBUTE = "sel_attr",
  BOOLEAN = "bool",
  SEARCH_BY_TEXT = "search",
}

enum FilterPreservedKeys {
  NOT = "__not",
  SORT_BY = "__sort_by__",
  NUMBER = "__number",
}

/**
 * A function to get the exploration url with the search params
 * @param selectedView The selected view
 * @param filterData Filter data from the store
 * @param sortData Sort data from the store
 * @param explorationParams Exploration params from the url: id (dataset_id), subset_id
 * @returns The exploration url with the search params
 */
export const getExplorationURLWithSearchParams = (
  selectedView: SelectedViewModel,
  filterData: filterDataStateTypes,
  sortData: sortDataStateTypes,
  explorationParams: ExplorationScreenRouteModel,
): string => {
  const baseRoute = getExplorationRouteFromSelectedView(selectedView, {
    id: explorationParams.id,
    subset_id: explorationParams.subset_id,
  });

  const filterKey = determineActiveFilterKeyFromParam(selectedView);
  const activeFilters = _.map(filterData?.[filterKey], (f) => f);
  const activeSortKey = determineActiveSortKeyFromParam(selectedView);
  const activeSort: SortingRequestModel[] = activeSortKey
    ? sortData?.[activeSortKey]
    : [];

  const searchParams = filtersToSearchParams(activeFilters, activeSort);
  return baseRoute + "?" + searchParams;
};

/**
 * A function to convert the active filters and sort to search params in the url
 * @param filters The filters to convert to search params
 * @param sortBy The sort by to convert to search params
 * @returns The search params string
 */
const filtersToSearchParams = (
  filters: ActiveFilterModel[],
  sortBy: SortingRequestModel[],
): string => {
  const searchParams = new URLSearchParams();

  if (sortBy?.length > 0) {
    searchParams.set(
      FilterPreservedKeys.SORT_BY + sortBy?.[0]?.field,
      sortBy?.[0]?.order,
    );
  }

  _.forEach(filters, (filter) => {
    const type = FilterTypePrefix[filter.type] + "__";
    switch (filter.type) {
      case "CATEGORICAL": {
        const selectedValue = filter.categories_value?.selected_cats;
        const notSelectedValue = filter.categories_value?.not_selected_cats;

        if (_.isArray(selectedValue) && selectedValue.length > 0) {
          // Check if the array is a number array
          const isNumberArray = _.every(selectedValue, _.isNumber);
          const numberKey = isNumberArray ? FilterPreservedKeys.NUMBER : "";
          const selectedKey = type + filter.key + numberKey;
          searchParams.set(selectedKey, JSON.stringify(selectedValue));
        }

        if (_.isArray(notSelectedValue) && notSelectedValue.length > 0) {
          const isNumberArray = _.every(notSelectedValue, _.isNumber);
          const numberKey = isNumberArray ? FilterPreservedKeys.NUMBER : "";
          const notSelectedKey =
            type + filter.key + FilterPreservedKeys.NOT + numberKey;
          searchParams.set(notSelectedKey, JSON.stringify(notSelectedValue));
        }
        break;
      }
      case "NUMERICAL": {
        const key = filter.is_not
          ? type + filter.key + FilterPreservedKeys.NOT
          : type + filter.key;

        const valueArray = filter.value as [number, number];
        const lower = valueArray?.[0];
        const upper = valueArray?.[1];

        const value = filter.include_edges
          ? `[${lower},${upper}]`
          : `(${lower},${upper})`;

        searchParams.set(key, JSON.stringify(value));

        break;
      }
      case "SEARCH_BY_TEXT":
      case "FE_SUBSET":
      case "FE_TAG":
      case "SUBSET":
      case "SELECT_ATTRIBUTE":
      case "BOOLEAN": {
        const key = filter.is_not
          ? type + filter.key + FilterPreservedKeys.NOT
          : type + filter.key;
        const value = filter.value;
        searchParams.set(key, JSON.stringify(value));
        break;
      }
    }
  });

  return searchParams.toString();
};

/**
 * A function to convert the search params to filters
 * @param filterData The filter data from the store
 * @param selectedView The selected view
 * @returns The filters converted from the search params
 */
export const searchParamsToFilters = (
  filterData: FiltersModel,
  selectedView: SelectedViewModel,
): FiltersModel => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const pathname = url.pathname;

  let filtersToUpdate: FiltersModel = {};
  // Only convert the search params to filters if the url is the exploration page
  // and the selected view is the same as the url
  if (
    (matchPath(pathname, allUsersRoutes.explorationMediaPage.path) &&
      selectedView === AnnotatableEnum.Media) ||
    (matchPath(pathname, allUsersRoutes.explorationObjectPage.path) &&
      selectedView === AnnotatableEnum.MediaObject) ||
    (matchPath(pathname, allUsersRoutes.explorationInstancePage.path) &&
      selectedView === AnnotatableEnum.Instance)
  ) {
    _.forEach(Array.from(searchParams), (param) => {
      let newFilter: FilterModel;
      const key = param[0];
      const value = param[1];

      // Skip the sort by key
      if (key.includes(FilterPreservedKeys.SORT_BY)) {
        return;
      }

      const type = getFilterTypeFromPrefix(key);

      const keyWithoutType = key.split("__")[1];
      const filterKey = keyWithoutType.split(FilterPreservedKeys.NOT)[0];
      const isNot = key?.includes(FilterPreservedKeys.NOT);
      const isNumber = key?.includes(FilterPreservedKeys.NUMBER);

      const filterFromStore = filterData?.[filterKey];
      if (!filterFromStore) {
        return;
      }

      // If the filter is an attribute filter, toggle all the filters of the attribute to visible
      if (isFilterAttributeFilter(filterFromStore?.attribute_group)) {
        filtersToUpdate = toggleTheFiltersOfAnAttributeVisible(
          filterData,
          filtersToUpdate,
          filterFromStore?.attribute_id,
        );
      }
      // Handle the filter type and convert the search params to filters
      switch (type) {
        case "CATEGORICAL": {
          let selectedValue = isNot ? [] : JSON.parse(value);
          let notSelectedValue = isNot ? JSON.parse(value) : [];

          if (isNumber) {
            selectedValue = _.map(selectedValue, (v) => Number(v));
            notSelectedValue = _.map(notSelectedValue, (v) => Number(v));
          }

          newFilter = {
            ...filterFromStore,
            is_visible: true,
            selected_cats: selectedValue,
            not_selected_cats: notSelectedValue,
            FE_is_not: isNot,
            FE_cast_value_to_number: isNumber,
          };
          filtersToUpdate = { ...filtersToUpdate, [filterKey]: newFilter };
          break;
        }
        case "SEARCH_BY_TEXT":
        case "FE_SUBSET":
        case "FE_TAG":
        case "SUBSET":
        case "BOOLEAN": {
          let selectedValue = JSON.parse(value);

          if (isNumber) {
            selectedValue = _.map(selectedValue, (v) => Number(v));
          }

          newFilter = {
            ...filterFromStore,
            is_visible: true,
            selected_cats: selectedValue,
            FE_is_not: isNot,
            FE_cast_value_to_number: isNumber,
          };
          filtersToUpdate = { ...filtersToUpdate, [filterKey]: newFilter };
          break;
        }
        case "NUMERICAL": {
          const valueString = JSON.parse(value);
          const includeEdges = valueString[0] === "[";
          const lower = Number(valueString.split(",")[0].slice(1));
          const upper = Number(valueString.split(",")[1].slice(0, -1));

          newFilter = {
            ...filterFromStore,
            is_visible: true,
            FE_is_not: isNot,
            FE_include_edges: includeEdges,
            lower_bound: lower,
            upper_bound: upper,
          };
          filtersToUpdate = { ...filtersToUpdate, [filterKey]: newFilter };
          break;
        }
        case "SELECT_ATTRIBUTE": {
          newFilter = {
            ...filterFromStore,
            is_visible: true,
            FE_value: JSON.parse(value),
            FE_is_not: isNot,
          };
          filtersToUpdate = { ...filtersToUpdate, [filterKey]: newFilter };
          break;
        }
      }
    });
  }

  return filtersToUpdate;
};

/**
 * A function to toggle the visibility of the filters of an attribute
 * @param allFilters All the filters
 * @param previousUpdatedFilters The previous updated filters
 * @param attributeID The attribute id
 * @returns The updated filters
 */
const toggleTheFiltersOfAnAttributeVisible = (
  allFilters: FiltersModel,
  previousUpdatedFilters: FiltersModel,
  attributeID: string,
): FiltersModel => {
  let attributeFilters: FiltersModel = {};
  _.forEach(allFilters, (filter) => {
    // If the filter is already in the filters to update, pass it through as is
    // (this is to prevent the filter from being overwritten by the default one)
    if (_.includes(_.keys(previousUpdatedFilters), filter?.key)) {
      attributeFilters = {
        ...attributeFilters,
        [filter.key]: previousUpdatedFilters[filter.key],
      };
      return;
    }

    // If the filter is not an attribute filter, add it to the attribute filters
    if (filter?.attribute_id === attributeID) {
      attributeFilters = {
        ...attributeFilters,
        [filter.key]: { ...filter, is_visible: true },
      };
    }
  });
  return attributeFilters;
};

/**
 * A function to convert the search params to sort
 * @returns The sort converted from the search params
 */
export const searchParamsToSort = (): SortingRequestModel[] => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;

  let newSort: SortingRequestModel[] = [];
  _.forEach(Array.from(searchParams), (param) => {
    const key = param[0];
    const value = param[1];

    // Skip the sort by key
    if (!key.includes(FilterPreservedKeys.SORT_BY)) {
      return;
    }

    const field = key.split(FilterPreservedKeys.SORT_BY)[1];
    const order = value;

    newSort = [{ field: field, order: order as OrderSortModel }];
  });

  return newSort;
};

/**
 * A function to get the filter type from the filter type prefix
 * @param paramsString The filter type prefix
 * @returns The filter type
 */
const getFilterTypeFromPrefix = (paramsString: string): FiltersTypesModel => {
  const prefix = paramsString.split("__")[0];

  switch (prefix) {
    case FilterTypePrefix.CATEGORICAL:
      return "CATEGORICAL";
    case FilterTypePrefix.SEARCH_BY_TEXT:
      return "SEARCH_BY_TEXT";
    case FilterTypePrefix.NUMERICAL:
      return "NUMERICAL";
    case FilterTypePrefix.FE_SUBSET:
      return "FE_SUBSET";
    case FilterTypePrefix.FE_TAG:
      return "FE_TAG";
    case FilterTypePrefix.SUBSET:
      return "SUBSET";
    case FilterTypePrefix.SELECT_ATTRIBUTE:
      return "SELECT_ATTRIBUTE";
    case FilterTypePrefix.BOOLEAN:
      return "BOOLEAN";
    default:
      throw new Error("Invalid filter type");
  }
};

export default filtersToSearchParams;

/**
 * A function to rename the filter category
 * @param category The selected filter category
 * @returns The renamed filter category string
 */
export const renameFilterCategory = (
  category: FilterCategoryEnum | string,
): string => {
  if (category === FilterCategoryEnum.Annotation_Attribute) {
    return "Attributes";
  }
  if (category === FilterCategoryEnum.ML_Annotation_Attribute) {
    return "AI attributes";
  }
  if (category === FilterCategoryEnum.Text_Filter) {
    return "ID search";
  }
  if (category === FilterCategoryEnum.Subsets_And_Tags) {
    return "Subsets & tags";
  }
  if (category === FilterCategoryEnum.Auto_Attribute) {
    return "Auto attributes";
  }
  if (category === FilterCategoryEnum.Initial_Attribute) {
    return "Initial attributes";
  }
  if (category === FilterCategoryEnum.Saved_Filter) {
    return "Saved filters";
  }
  return snakeCaseToText(category?.toString());
};

const isFilterAttributeFilter = (
  filterCategory: FilterAttributeGroupEnum,
): boolean => {
  return (
    filterCategory === FilterAttributeGroupEnum.Annotation_Attribute ||
    filterCategory === FilterAttributeGroupEnum.ML_Annotation_Attribute
  );
};
