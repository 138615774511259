import LivePreviewComponent from "Pages/PipelineDesigner/LivePreview/LivePreviewComponent";
import Loading from "components/UtilComponents/Loading";
import { postAnnotationRunLivePreview } from "helpers/apis/annotationRun";
import { VisualisationTypeEnum } from "models/pipelines.model";
import { useEffect, useState } from "react";
import { annotationUIs } from "schemas/annotationUIs";
import { UIsEnum } from "schemas/annotationUIs.model";
import { useAppDispatch } from "store/hooks";

interface LivePreviewProps {
  datasetId: string | undefined;
  subsetId: string | undefined;
  sampleSize: number | null;
  uiType: UIsEnum | null;
  visualizationType: VisualisationTypeEnum | null;
  colorMap: Record<string, string> | null;
  guiSettings: Record<string, unknown> | null;
}

const LivePreview = ({
  datasetId,
  subsetId,
  sampleSize,
  uiType,
  visualizationType,
  colorMap,
  guiSettings,
}: LivePreviewProps) => {
  const dispatch = useAppDispatch();
  const [taskInputs, setTaskInputs] = useState<Record<
    string,
    unknown
  > | null>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      !datasetId ||
      !subsetId ||
      !sampleSize ||
      !uiType ||
      !visualizationType ||
      !colorMap ||
      !guiSettings
    ) {
      return;
    }
    const guiType = annotationUIs?.[uiType]?.postProcessingUiKey;

    postAnnotationRunLivePreview(
      {
        dataset_id: datasetId,
        subset_id: subsetId,
        sample_size: sampleSize,
        gui_type: guiType,
        visualisation_type: visualizationType,
        color_map: colorMap,
        gui_settings: guiSettings,
      },
      dispatch,
      setIsLoading,
    ).then((response) => {
      setTaskInputs(response);
    });
  }, [datasetId, subsetId, sampleSize, uiType, visualizationType, colorMap]);

  const renderMainView = () => {
    if (isLoading) {
      return (
        <div className="w-ful h-full flex justify-center items-center">
          <Loading />
        </div>
      );
    }

    if (!taskInputs) {
      return <div>Failed to load live preview</div>;
    }

    if (guiSettings === null) {
      return <div>Missing GUI settings</div>;
    }

    if (datasetId === undefined || subsetId === undefined) {
      return <div>Missing dataset/subset</div>;
    }

    if (colorMap === null) {
      return <div>Missing color map</div>;
    }

    if (uiType === null) {
      return <div>Missing GUI type</div>;
    }

    if (visualizationType === null) {
      return <div>Missing visualization type</div>;
    }

    const getGUISettings = () => {
      const uiSettings = guiSettings["uiSettings"] as Record<string, unknown>;

      // Omits the instructions URL from the GUI settings
      const filteredGUISettings = {
        ...guiSettings,
        uiSettings: {
          ...uiSettings,
          Settings: {
            ...(uiSettings["Settings"] as Record<string, unknown>),
            instructionsUrl: "",
          },
        },
      };
      return filteredGUISettings;
    };

    return (
      <LivePreviewComponent
        taskInputs={taskInputs as Record<string, unknown>}
        guiSettings={getGUISettings()}
      />
    );
  };

  return <div className="h-full px-16 pt-6">{renderMainView()}</div>;
};

export default LivePreview;
