interface Props {
  type?: "text" | "number" | "password";
  value: string | number;
  onChange: (newValue: string | number) => void;
  onBlur?: (newValue: string | number) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
}

const TextField = ({
  type = "text",
  value,
  onChange,
  onBlur,
  placeholder,
  className,
  disabled,
  min,
  max,
}: Props) => {
  return (
    <input
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={(event) => {
        // Check if the input is a number
        if (type === "number") {
          const newValue = parseFloat(event?.currentTarget?.value);
          onChange(newValue);
        }
        // Check if the input is a text
        else if (type === "text" || type === "password") {
          onChange(event?.currentTarget?.value);
        }
      }}
      onBlur={(event) => {
        // Check if the input is a number
        if (type === "number") {
          const newValue = parseFloat(event?.currentTarget?.value);
          onBlur && onBlur(newValue);
        }
        // Check if the input is a text
        else if (type === "text" || type === "password") {
          onBlur && onBlur(event?.currentTarget?.value);
        }
      }}
      className={`
      input-text
        ${className}
      `}
      data-test="filter_input"
      disabled={disabled}
      min={min}
      max={max}
    />
  );
};

export default TextField;
