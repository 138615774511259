import _ from "lodash";
import { FilterCategoryEnum } from "models/filter.model";
import { SelectedViewModel } from "models/global.model";
import {
  determineFilterKeyFromParam,
  determineSavedFilterKeyFromParam,
  filterDataStateTypes,
} from "store/filterDataSlice";

/**
 * A helper function to get the count of the filters in a category
 * @param category The category of the filters to get the count for
 * @param filterData Filter data from redux
 * @param selectedView The selected view from redux
 * @returns The count of the filters in the category
 */
export const getFilterCategoryCount = (
  category: FilterCategoryEnum,
  filterData: filterDataStateTypes,
  selectedView: SelectedViewModel,
) => {
  const filters = filterData?.[determineFilterKeyFromParam(selectedView)];
  const savedFilters =
    filterData?.[determineSavedFilterKeyFromParam(selectedView)];

  switch (category) {
    case FilterCategoryEnum.Auto_Attribute:
    case FilterCategoryEnum.Initial_Attribute: {
      return _.filter(filters, ["attribute_group", category])?.length;
    }
    case FilterCategoryEnum.Annotation_Attribute:
    case FilterCategoryEnum.ML_Annotation_Attribute: {
      const annotationFilters = _.filter(filters, [
        "attribute_group",
        category,
      ]);
      const numberOfGroups = _.groupBy(annotationFilters, "attribute_id");
      return _.size(numberOfGroups);
    }
    case "saved_filter": {
      return savedFilters?.length;
    }
    case FilterCategoryEnum.Subsets_And_Tags:
    case FilterCategoryEnum.Text_Filter: {
      return _.filter(filters, ["attribute_group", category])?.length;
    }
    default:
      return 0;
  }
};
