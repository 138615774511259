import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import BasicButton from "components/Internal/Buttons/BasicButton";

import {
  fetchDashboardInformation,
  fetchSubsetInformationForDataset,
} from "store/dashboardSlice";
import _ from "lodash";
import { DatasetModel } from "models/dataset.model";
import JobsTable from "components/Transformations/JobsTable";
import TileTab from "components/Transformations/TileTab";
import CropTab from "components/Transformations/CropTab";
import AnonymizationTab from "components/Transformations/AnonymizationTab";
import LidarVideoGenerationTab from "components/Transformations/LidarVideoGenerationTab";
import { SubsetTypeModel } from "models/global.model";

enum Tabs {
  tile = "Tile",
  lidarVideoGeneration = "Lidar Video Generation",
  crop = "Crop",
  anonymization = "Anonymization",
  jobs = "Jobs",
}

const Transformations = () => {
  const dispatch = useAppDispatch();
  const dashboardData = useAppSelector((state) => state.dashboardSlice);

  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.tile);
  const [datasetID, setDatasetID] = useState("");
  const [subsetID, setSubsetID] = useState("");

  useEffect(() => {
    updateDashboard();
  }, []);

  const setDatasetHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      fetchSubsetInformationForDataset({ datasetID: event?.target?.value }),
    );
    setDatasetID(event?.target?.value);
  };

  const setSubsetHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setSubsetID(event?.target?.value);
  };

  const updateDashboard = () => {
    dispatch(fetchDashboardInformation({}));
    if (datasetID?.length > 0) {
      setDatasetID(datasetID);
    }
    if (subsetID?.length > 0) {
      setSubsetID(subsetID);
    }
  };

  const renderForm = (selectedTab: Tabs) => {
    const isAnonymized = _.find(dashboardData.data, {
      id: datasetID,
    })?.is_anonymized;
    switch (selectedTab) {
      case Tabs.tile:
        return <TileTab datasetID={datasetID} subsetID={subsetID} />;
      case Tabs.crop:
        return <CropTab datasetID={datasetID} subsetID={subsetID} />;
      case Tabs.jobs:
        return (
          <div className="h-3/4">
            <JobsTable datasetID={datasetID} />
          </div>
        );
      case Tabs.anonymization: {
        return (
          <AnonymizationTab
            datasetID={datasetID}
            subsetID={subsetID}
            isAnonymized={isAnonymized}
            updateDashboard={updateDashboard}
          />
        );
      }
      case Tabs.lidarVideoGeneration:
        return (
          <LidarVideoGenerationTab
            datasetID={datasetID}
            subsetID={subsetID}
            isAnonymized={isAnonymized}
          />
        );
      default:
        return <div></div>;
    }
  };

  const getSubsetList = (): DatasetModel[] => {
    let filterCriteria: Record<string, any> = { parent_dataset: datasetID };
    if (selectedTab === Tabs.tile) {
      filterCriteria = {
        ...filterCriteria,
        subset_type: SubsetTypeModel.Media,
      };
    }
    const resultList = _.filter(
      dashboardData.data?.[datasetID]?.subsets,
      filterCriteria,
    );

    return resultList as DatasetModel[];
  };

  const renderTabButtons = () => {
    return _.map(Tabs, (tab) => {
      return (
        <BasicButton
          className="border-0"
          label={tab}
          onClick={() => {
            setSelectedTab(tab);
          }}
        />
      );
    });
  };

  const renderDatasetSelector = () => {
    return (
      <div className="flex items-center gap-x-2">
        <div>Dataset:</div>
        <select
          id="dataset"
          name="dataset"
          onChange={setDatasetHandler}
          value={datasetID}
        >
          <option value="">--Select dataset--</option>
          {_.chain(dashboardData.data)
            .filter({ parent_dataset: null })
            .sortBy((dataset) => dataset.name.toLowerCase())
            .map((dataset) => (
              <option key={dataset?.id} value={dataset.id}>
                {dataset.name}
              </option>
            ))
            .value()}
        </select>
      </div>
    );
  };

  const renderSubsetSelector = () => {
    if (
      selectedTab === Tabs.tile ||
      selectedTab === Tabs.crop ||
      selectedTab === Tabs.anonymization ||
      selectedTab === Tabs.lidarVideoGeneration
    ) {
      const isAnonymized = dashboardData.data?.[datasetID]?.is_anonymized;
      const isDisabledInAnonymizedTab =
        selectedTab === Tabs.anonymization && isAnonymized === false;

      return (
        <div className="flex items-center gap-x-3 my-4">
          <div>Subset:</div>
          <div>
            <select
              id="subset"
              name="subset"
              onChange={setSubsetHandler}
              value={subsetID}
              disabled={isDisabledInAnonymizedTab}
            >
              <option value="">--Select subset--</option>
              {_.chain(getSubsetList())
                .sortBy((subset) => subset.name.toLowerCase())
                .map((subset) => (
                  <option key={subset?.id} value={subset?.id}>
                    {subset?.name}
                  </option>
                ))
                .value()}
            </select>
            {isDisabledInAnonymizedTab && (
              <div className="text-sm text-paletteYellow">
                Subset selection is disabled for not anonymized datasets
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="w-full h-[90%]">
      <div id="tabButtons" className="flex gap-1 my-4 ml-6">
        {renderTabButtons()}
      </div>
      <div id="totalForm" className="mx-8 h-[90%]">
        {renderDatasetSelector()}
        {renderSubsetSelector()}
        {renderForm(selectedTab)}
      </div>
    </div>
  );
};

export default Transformations;
