import { UiSchema } from "@rjsf/utils";

export const ui_schema_marker_3d: UiSchema = {
  inputType: {
    BoundingBox2DPainter: {
      "ui:widget": "hidden",
    },
  },
};

export const form_data_marker_3d = {
  inputType: {
    BoundingBox2DPainter: {},
    Viewer3D: {
      annotationType: "marker3D",
      markers3DSettings: {
        radius: 0.5,
      },
      labelSettings: {
        enableDepthTest: true,
      },
      marker2DRaySettings: {
        enabled: true,
        limitMarkerToRayArea: true,
        lineWidth: 1,
      },
    },
  },
  toolbars: [
    {
      type: "navigation",
      settings: {
        withUnsolvableButton: true,
        unsolvableCode: "cant_solve",
        withOptions: false,
      },
    },
  ],
};
