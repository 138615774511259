import StandardPopover from "components/Internal/Popovers/StandardPopover";
import { useState } from "react";
import { ReactComponent as SelectVisualisationIcon } from "assets/select_visualisation.svg";
import { InstanceModel } from "models/exploration.model";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { snakeCaseToText } from "components/utilFunctions";
import TooltipTruncate from "components/Internal/Tooltips/TooltipTruncate";
import { setDetailsScreenSelectedVisualization } from "store/detailsScreenSlice";

interface Props {
  currentItem: InstanceModel;
}

const SelectVisualisation = ({ currentItem }: Props) => {
  const dispatch = useAppDispatch();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const visualisationConfigurations = useAppSelector(
    (state) => state.datasetSlice.VisualisationConfiguration
  );

  const detailsScreenSelectedVisualization = useAppSelector(
    (state) => state.detailsScreenSlice.selectedVisualization
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const renderButtonTitle = () => {
    return (
      <div
        className={`icon-button-layer-transparent ${
          anchorEl && "bg-paletteGray-5"
        }`}
      >
        <SelectVisualisationIcon width={18} height={18} />
      </div>
    );
  };

  const renderVisualisation = () => {
    const sortedVisualisations = _.sortBy(
      currentItem?.visualisations,
      (vis) => vis.timestamp
    ).reverse();
    return _.map(sortedVisualisations, (vis) => {
      const visConfig = _.find(visualisationConfigurations, [
        "id",
        vis?.visualisation_configuration_id,
      ]);

      const label = visConfig ? visConfig?.name : "Unknown Visualisation!";
      const id = vis?.visualisation_configuration_id + "_" + vis.timestamp;
      const isSelected =
        id === detailsScreenSelectedVisualization?.[selectedView];
      return (
        <div
          key={id}
          className="button-select-layer justify-between gap-x-2"
          onClick={() =>
            dispatch(
              setDetailsScreenSelectedVisualization({
                key: selectedView,
                value: id,
              })
            )
          }
        >
          <TooltipTruncate key={id} title={id} truncateLength={70}>
            {snakeCaseToText(label) +
              " - " +
              new Date(vis.timestamp).toLocaleString()}
          </TooltipTruncate>
          <input
            type="radio"
            name="visualisation"
            className="checkbox-layer"
            value={vis.id}
            checked={isSelected}
            readOnly
          />
        </div>
      );
    });
  };

  const renderVisualisationNotFound = () => {
    const isSelectedVisualizationFoundInTheCurrentItem = _.find(
      currentItem?.visualisations,
      (vis) =>
        vis?.visualisation_configuration_id + "_" + vis.timestamp ===
        detailsScreenSelectedVisualization?.[selectedView]
    );

    // If the selected visualisation is null or the selected visualisation is
    // found in the current item, then we don't need to show the message.
    if (
      detailsScreenSelectedVisualization?.[selectedView] === null ||
      isSelectedVisualizationFoundInTheCurrentItem
    ) {
      return null;
    }

    return (
      <div
        className="label-layer bg-transparent text-paletteRed-light flex-wrap hover:text-paletteRed cursor-pointer"
        onClick={() =>
          dispatch(
            setDetailsScreenSelectedVisualization({
              key: selectedView,
              value: null,
            })
          )
        }
      >
        Selected visualisation Not Found on this instance! Click to unselect.
      </div>
    );
  };

  return (
    <StandardPopover
      id="select-visualisation-popover"
      anchorEl={anchorEl}
      handlePopoverOpen={(event) => setAnchorEl(event.currentTarget)}
      handlePopoverClose={() => setAnchorEl(null)}
      buttonElement={renderButtonTitle()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div className="w-90">
        {renderVisualisation()}
        {renderVisualisationNotFound()}
      </div>
    </StandardPopover>
  );
};

export default SelectVisualisation;
