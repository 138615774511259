import { DatasetModel } from "models/dataset.model";

type Props = {
  key: string;
  row: DatasetModel;
  onRowClick: (row: DatasetModel) => void;
  onRowHover?: (row: DatasetModel) => void;
  renderRowCells: (row: DatasetModel) => JSX.Element[];
  isSelected?: boolean;
  isDisabled?: boolean;
  disableReason?: string;
};

const DatasetSelectionTableRow = ({
  key,
  row,
  onRowClick,
  onRowHover,
  renderRowCells,
  isSelected = false,
  isDisabled = false,
  disableReason,
}: Props) => {
  return (
    <div
      key={key}
      title={isDisabled ? disableReason : ""}
      className={`w-full flex py-1 px-3 rounded-lg
        border-paletteGray-4 hover:border-paletteGray-5 
      ${isSelected ? "bg-blue-100" : ""}
      ${
        isDisabled
          ? "bg-gray-300 cursor-not-allowed opacity-50"
          : "cursor-pointer hover:bg-gray-100"
      }
      `}
      onClick={(e) => {
        if (!isDisabled && onRowClick) {
          e.preventDefault();
          onRowClick(row);
        }
      }}
      onMouseEnter={() => onRowHover && onRowHover(row)}
    >
      {renderRowCells(row)}
    </div>
  );
};

export default DatasetSelectionTableRow;
