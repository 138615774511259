import { useState } from "react";
import StandardHeader from "components/Internal/Headers/StandardHeader";
import TagsTable from "components/TagsManager/TagsTable";
import TagsTabs from "../TagsManager/TagsTabs";

export enum TagsManagerScreenTabs {
  all = "all",
  Media = "Media",
  MediaObject = "MediaObject",
  Attribute = "Attribute",
}

const TagsManager = () => {
  const [selectedTab, setSelectedTab] = useState<TagsManagerScreenTabs>(
    TagsManagerScreenTabs.all
  );

  // ------------------------------------------- Render Component ------------------------------------
  return (
    <div className="px-[72px] pt-4 mx-auto h-full bg-white flex flex-col self-center">
      <StandardHeader />
      <div className="min-h-0 pt-10 flex-1 flex flex-col">
        <div className="text-3xl pb-2">Tags</div>
        <TagsTabs
          selectedTabValue={selectedTab}
          onTabClick={(tab) => setSelectedTab(tab as TagsManagerScreenTabs)}
        />
        <div className="w-full flex-1 min-h-0">
          <TagsTable selectedTab={selectedTab} />
        </div>
      </div>
    </div>
  );
};

export default TagsManager;
