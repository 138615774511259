import _ from "lodash";
import { AnnotationRunModel } from "models/annotationRun.model";
import { ManagerFiltersModel } from "models/global.model";
import { PipelineModel } from "models/pipelines.model";

export const filterByCreatedAt = (
  items: (PipelineModel | AnnotationRunModel)[],
  appliedCreatedAt: ManagerFiltersModel["createdAt"],
) => {
  let filteredItems: { [key: string]: PipelineModel | AnnotationRunModel } = {};

  _.map(appliedCreatedAt, (dateRange) => {
    const { from, to } = dateRange;
    _.map(items, (annotationRun: PipelineModel | AnnotationRunModel) => {
      const createdAt = annotationRun.created_at;
      const annotationRunCreatedAt = new Date(createdAt).setHours(0, 0, 0, 0);
      const fromDate = new Date(from).setHours(0, 0, 0, 0);
      const toDate = new Date(to).setHours(23, 59, 59, 999);

      if (
        annotationRunCreatedAt >= fromDate &&
        annotationRunCreatedAt <= toDate
      ) {
        filteredItems = {
          ...filteredItems,
          [annotationRun.id]: annotationRun,
        };
      }
    });
  });
  return _.values(filteredItems);
};
