import validateEmail from "helpers/functions/validateEmail";
import _ from "lodash";
import {
  BooleanFieldModel,
  FormFieldModel,
  NumberFieldModel,
  SelectFieldModel,
  SingleSliderFieldModel,
  TextFieldModel,
} from "models/form.model";

export const validateField = (field: FormFieldModel) => {
  switch (field.type) {
    case "text":
    case "textarea":
      return validateTextInput(field as TextFieldModel);
    case "number":
      return validateNumberInput(field as NumberFieldModel);
    case "select":
      return validateSelectInput(field as SelectFieldModel);
    case "single_slider":
      return validateSingleSelectInput(field as SingleSliderFieldModel);
    case "boolean":
      return validateBooleanInput(field as BooleanFieldModel);
    default:
      return null;
  }
};

// A function to validate the text input field in the form
export const validateTextInput = (field: TextFieldModel) => {
  const { value, settings, error } = field;

  if (field.required && _.isEmpty(value)) {
    return "This field is required!";
  }
  if (!_.isUndefined(settings)) {
    const { minimumTextLength, maximumTextLength, isEmail, notEqualTo } =
      settings;

    if (minimumTextLength && value.length < minimumTextLength) {
      return `This field must be at least ${minimumTextLength} characters!`;
    }
    if (maximumTextLength && value.length > maximumTextLength) {
      return `This field must be at most ${maximumTextLength} characters!`;
    }
    if (isEmail && !validateEmail(value)) {
      return "This field must be a valid email!";
    }
    if (notEqualTo && _.includes(notEqualTo, value)) {
      return "This field must be different!";
    }
  }

  // if we have a custom error message, return it
  if (error) return error;

  // No error
  return null;
};

// A function to validate the number input field in the form
export const validateNumberInput = (field: NumberFieldModel) => {
  const { value, settings } = field;
  if (
    (field.required && value === null) ||
    (field.required && Number.isNaN(value))
  ) {
    return "This field is required!";
  }
  if (!_.isUndefined(settings)) {
    const { minimumValue, maximumValue } = settings;

    if (!_.isUndefined(minimumValue) && value < minimumValue) {
      return `This field must be at least ${minimumValue}!`;
    }
    if (!_.isUndefined(maximumValue) && value > maximumValue) {
      return `This field must be at most ${maximumValue}!`;
    }
  }

  // No error
  return null;
};

// A function to validate the select input field in the form
export const validateSelectInput = (field: SelectFieldModel) => {
  const { value } = field;

  if (field?.required && value === "") {
    return "This field is required";
  }

  return null;
};

// A function to validate the number input field in the form
export const validateSingleSelectInput = (field: SingleSliderFieldModel) => {
  const { value, min, max } = field;

  if (field.required && value === null) {
    return "This field is required!";
  }
  if (value < min) {
    return `This field must be at least ${min}!`;
  }
  if (value > max) {
    return `This field must be at most ${max}!`;
  }

  // No error
  return null;
};

// A function to validate the boolean input field in the form
export const validateBooleanInput = (field: BooleanFieldModel) => {
  const { value } = field;

  if (field.required && value === null) {
    return "This field is required!";
  }

  return null;
};
