import { ReactComponent as RefreshIcon } from "assets/reload.svg";

type Props = { callBackFunction: () => void };

const RefreshButton = ({ callBackFunction }: Props) => {
  return (
    <div
      className="button-layer"
      data-test="refresh_button"
      onClick={() => callBackFunction()}
    >
      <RefreshIcon width={16} height={16} />
    </div>
  );
};

export default RefreshButton;
