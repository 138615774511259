import { useEffect, useState } from "react";
import { AnnotationRunModel } from "models/annotationRun.model";
import { fetchAnnotationRuns } from "helpers/apis/annotationRun";
import { useAppDispatch } from "store/hooks";
import AnnotationRunManagerTable from "Pages/AnnotationRunManager/AnnotationRunManagerTable";
import { ManagerFiltersModel } from "models/global.model";
import ManagerFilters from "components/UtilComponents/ManagerFilters";
import { filterByCreatedAt } from "components/UtilComponents/ManagerFilters/mangerFiltersHelpers";
import RefreshButton from "components/Internal/Buttons/refresh";
import PipelineAndAnnotationRunTabs, {
  ManagerTabsOptions,
} from "components/UtilComponents/PipelineAndAnnotationRunTabs";
import SearchBar from "components/Internal/Inputs/SearchBar";
import _ from "lodash";
import { useKeycloak } from "@react-keycloak/web";
import Header from "components/Internal/Headers/GeneralHeader";
import { ReactComponent as AnnotationRunManagerIcon } from "assets/annotation_runs.svg";

const AnnotationRunManager = () => {
  const dispatch = useAppDispatch();
  const keycloak = useKeycloak();

  const [selectedTabValue, setSelectedTabValue] = useState<ManagerTabsOptions>(
    ManagerTabsOptions.all,
  );
  const [annotationRuns, setAnnotationRuns] = useState<AnnotationRunModel[]>(
    [],
  );
  const [appliedFilters, setAppliedFilters] = useState<ManagerFiltersModel>({
    createdAt: null,
  });
  const [searchValue, setSearchValue] = useState<string>("");

  // Fetch annotationRuns
  useEffect(() => {
    fetchAnnotationRuns(dispatch).then((data) => {
      setAnnotationRuns(data);
    });
  }, []);

  const setCreatedDateFilter = (newValue: ManagerFiltersModel["createdAt"]) => {
    setAppliedFilters({
      ...appliedFilters,
      createdAt: newValue,
    });
  };

  const filterAnnotationRuns = () => {
    let filteredAnnotationRuns = annotationRuns;

    if (appliedFilters.createdAt !== null) {
      filteredAnnotationRuns = filterByCreatedAt(
        annotationRuns,
        appliedFilters.createdAt,
      ) as AnnotationRunModel[];

      return filteredAnnotationRuns;
    }

    // Filter by tab
    if (selectedTabValue === ManagerTabsOptions.me) {
      filteredAnnotationRuns = _.filter(
        filteredAnnotationRuns,
        (annotationRun) =>
          annotationRun?.owner === keycloak?.keycloak?.idTokenParsed?.sub,
      );
    } else if (selectedTabValue === ManagerTabsOptions.team) {
      filteredAnnotationRuns = _.filter(
        filteredAnnotationRuns,
        (annotationRun) =>
          annotationRun?.owner !== keycloak?.keycloak?.idTokenParsed?.sub,
      );
    }
    return filteredAnnotationRuns;
  };

  return (
    <div className="mx-auto full-height-with-margin bg-white flex flex-col self-center border-[1px] border-paletteGray-5 rounded-xl m-1 mr-1">
      <Header
        title="Annotation runs"
        icon={
          <AnnotationRunManagerIcon className="w-5 h-5 text-paletteBlack-1" />
        }
        iconBgcolor="bg-gradient-to-b from-[#1DD3B3] to-[#0CB89A]"
      />

      <div className="px-[24px] min-h-0 py-4 flex-1 flex flex-col">
        <div className="w-full flex justify-between">
          <div className="w-fit">
            <PipelineAndAnnotationRunTabs
              items={annotationRuns}
              selectedTabValue={selectedTabValue}
              setSelectedTabValue={setSelectedTabValue}
              labelSuffix="annotation runs"
            />
          </div>

          <div className="min-w-0 flex-1 flex flex-row-reverse items-center gap-x-2">
            <div className="w-[500px]">
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder="Search for name, project ID..."
              />
            </div>
            <ManagerFilters
              appliedFilters={appliedFilters}
              setCreatedDateFilter={setCreatedDateFilter}
            />

            <RefreshButton
              callBackFunction={() => {
                fetchAnnotationRuns(dispatch).then((data) => {
                  setAnnotationRuns(data);
                });
              }}
            />
          </div>
        </div>

        <div className="w-full flex-1 min-h-0 pt-4">
          <AnnotationRunManagerTable
            annotationRuns={filterAnnotationRuns()}
            setAnnotationRuns={setAnnotationRuns}
            searchValue={searchValue}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnotationRunManager;
