import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { copyToClipboard } from "helpers/functions/copyToClipboard";
import CopyIcon from "assets/copy.svg";
import { AppDispatch } from "store";
import { ReactElement } from "react";
import _ from "lodash";
import styled from "@emotion/styled";

interface Props {
  children: ReactElement;
  title: unknown;
  arrow?: boolean;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  enterDelay?: number;
  leaveDelay?: number;
  key?: string;
  dispatch: AppDispatch;
}

const TooltipCopy = ({
  children,
  title,
  arrow,
  placement,
  enterDelay = 600,
  leaveDelay = 150,
  key,
  dispatch,
}: Props) => {
  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 400,
    },
  });

  const renderTitleWithCopy = () => {
    const titleString = _.isString(title) ? title : JSON.stringify(title);
    // Split the title into lines every 40 characters
    const lines: string[] = [];
    _.map(titleString, (value, key) => {
      if (key % 40 === 0) {
        lines.push(titleString.slice(key, key + 40));
      }
    });

    return (
      <div className="flex items-center">
        <div>
          {_.map(lines, (line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
        <img
          className="ml-3 h-4 filter invert"
          alt="copy"
          src={CopyIcon}
          onClick={() => copyToClipboard(titleString, dispatch)}
        ></img>
      </div>
    );
  };

  return (
    <CustomWidthTooltip
      title={renderTitleWithCopy()}
      arrow={arrow}
      placement={placement}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      key={key}
    >
      {children}
    </CustomWidthTooltip>
  );
};
export default TooltipCopy;
