import { UiSchema } from "@rjsf/utils";

export const ui_schema_bounding_box_2d: UiSchema = {
  inputType: {
    Viewer3D: {
      "ui:widget": "hidden",
    },
  },
};

export const form_data_bounding_box_2d = {
  inputType: {
    BoundingBox2DPainter: {
      displayerType: "box",
      boundingBoxProps: {
        opacity: 0,
      },
    },
    Viewer3D: {},
  },
  toolbars: [
    {
      type: "navigation",
      settings: {
        withUnsolvableButton: true,
        unsolvableCode: "cant_solve",
        withOptions: false,
      },
    },
  ],
};
