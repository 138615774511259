import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./index";

interface Props {
  children: any;
}

const ProviderWrapper = ({ children }: Props) => (
  <Provider store={store}>
    <BrowserRouter>{children}</BrowserRouter>
  </Provider>
);

export default ProviderWrapper;
