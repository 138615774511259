import snackbarHelper from "components/Helpers/snackbarHelperFn";
import sendEmailJSForm from "helpers/forms/sendEmailJSForm";
import { Dispatch } from "@reduxjs/toolkit";

const sendFeedbackForm = (
  dispatch: Dispatch,
  feedbackForm: {
    from_email: string;
    name?: string;
    company?: string;
    job_title?: string;
    request: string;
  },
  setIsLoading?: (isLoading: boolean) => void
) => {
  setIsLoading && setIsLoading(true);
  sendEmailJSForm(feedbackForm)
    .then(() => {
      snackbarHelper(dispatch, "Feedback sent successfully");
      setIsLoading && setIsLoading(false);
    })
    .catch(() => {
      snackbarHelper(dispatch, "Feedback sending failed!", "error");
      setIsLoading && setIsLoading(false);
    });
};

export default sendFeedbackForm;
