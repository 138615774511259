import StandardHeader from "components/Internal/Headers/StandardHeader";
import ThresholdAnalysis from "components/InternalUserToolsPage/ThresholdAnalysis";

const InternalUserToolsPage = () => {
  return (
    <div className="px-[72px] pt-4 mx-auto h-full bg-white flex flex-col self-center">
      <StandardHeader />
      <div className="pt-10 min-h-0 flex-1">
        <ThresholdAnalysis />
      </div>
    </div>
  );
};

export default InternalUserToolsPage;
