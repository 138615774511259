import _ from "lodash";
import { VisualisationConfigurationModel } from "models/configurations.model";
import { DefaultVisualisationNames } from "models/global.model";

/**
 * Get the visualisation configuration ID for a given subset.
 * If no visualisation for the subset could be found, return the default crop configuration ID.
 * This is the case when the subset ID is actually the dataset ID.
 * If neither visualisation configuration is found, return null.
 * @param visualisationConfigurations The visualisation configurations for the whole dataset
 * @param subsetId The subset ID to get the visualisation configuration ID from
 * @returns The visualisation configuration ID for the given subset ID
 */
const getCropVisualisationConfigID = (
  visualisationConfigurations: VisualisationConfigurationModel[],
  subsetId: string,
): string | null => {
  let configID: null | string = null;
  let defaultConfigID: null | string = null;
  visualisationConfigurations?.forEach((configuration) => {
    if (configuration?.parameters?.type === "crop") {
      if (_.includes(configuration?.subset_ids, subsetId)) {
        // uses the subset visualisation if it exists
        configID = configuration.id;
      }
      if (configuration?.name === DefaultVisualisationNames.Crop) {
        // uses the default visualization if no subset visualisation exists, e.g. if subsetId is the datasetId
        defaultConfigID = configuration.id;
      }
    }
  });

  return configID || defaultConfigID;
};

export default getCropVisualisationConfigID;
