import { useHistory } from "react-router-dom";

import Divider from "@mui/material/Divider";
import { ArrowBackIos } from "@mui/icons-material";

const classes = {
  text: "text-lg",
  divider: {
    backgroundColor: "black",
    width: "50%",
    marginTop: "50px",
    marginBottom: "25px",
    height: "0.185em",
  },
};

const Imprint = () => {
  const history = useHistory();

  return (
    <div className="w-4/5 px-8 mx-auto pt-6 flex flex-col self-center">
      <div
        onClick={() => {
          history.goBack();
        }}
        className="text-lg font-bold mb-12 cursor-pointer self-start"
      >
        <ArrowBackIos
          style={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
        />
        Back
      </div>

      <div className="flex flex-row" style={{ width: "100%" }}>
        <div style={{ flex: 1 }}>
          <div className={classes.text}>
            <b>Quality Match GmbH</b>
          </div>
          <div className={classes.text}>Häusserstraße 36</div>
          <div className={classes.text}>69115 HEIDELBERG</div>
          <div className={classes.text}>GERMANY</div>
        </div>
        <div
          style={{ flex: 2, color: "rgba(187, 187, 187, 1)" }}
          className="text-2xl font-bold"
        >
          We improve your machine learning models through optimized datasets
          with controlled annotation quality
        </div>
      </div>

      <div className="flex flex-row" style={{ width: "100%" }}>
        <div style={{ flex: 1 }}>
          <Divider sx={classes.divider} />
          <div className={classes.text}>Dr. Daniel Kondermann</div>
          <div className={classes.text}>
            <b>dk@quality-match.com</b>
          </div>
          <div className={classes.text}>+4915141259855</div>
          <div className={classes.text}>www.quality-match.com</div>
        </div>
        <div style={{ flex: 1 }}>
          <Divider sx={classes.divider} />
          <div className={classes.text}>
            <b>Managing Director</b>
          </div>
          <div className={classes.text}>Dr. Daniel Kondermann</div>
        </div>
        <div style={{ flex: 1 }}>
          <Divider sx={classes.divider} />
          <div className={classes.text}>Amtsgericht Mannheim</div>
          <div className={classes.text}>HRB: 738316</div>
          <div className={classes.text}>UStId: DE330116695</div>
        </div>
      </div>

      <div className="text-5xl text-center font-bold mt-32">
        WE CREATE BETTER DATASETS
      </div>
    </div>
  );
};

export default Imprint;
