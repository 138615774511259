import {
  APIDeleteAxios,
  APIFetchAxios,
  APIPatchWithBodyAxios,
  APIPostWithBodyAxios,
} from "components/UtilComponents/Auth";
import { Dispatch } from "@reduxjs/toolkit";
import {
  PipelineModel,
  PipelineNodeConfigBody,
  PipelineNodeModel,
  PipelineRevisionModel,
} from "models/pipelines.model";
import { AxiosError } from "axios";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import _ from "lodash";

/**
 * An endpoint to fetch all pipelines
 * @param {Dispatch} dispatch - The dispatch function
 * @returns {Promise<PipelineModel[]>} - Returns an array of pipelines
 */
export const fetchPipelines = async (
  dispatch: Dispatch,
): Promise<PipelineModel[]> => {
  const response = await APIFetchAxios("/pipelines").catch((error) => {
    snackbarHelper(dispatch, "Pipelines fetching failed!", "error");
    return Promise.reject(error);
  });
  return response?.data;
};

/**
 * An endpoint to fetch a pipeline
 * @param {object} body - The body of the request
 * @param {string} body.pipelineID - The pipeline ID
 * @param {Dispatch} dispatch - The dispatch function
 * @returns {Promise<PipelineModel>} - Returns a pipeline
 */
export const fetchPipeline = async (
  params: { pipelineID: string },
  dispatch: Dispatch,
): Promise<PipelineModel> => {
  const response = await APIFetchAxios(
    `/pipelines/${params?.pipelineID}`,
  ).catch((error) => {
    snackbarHelper(dispatch, "Pipeline fetching failed!", "error");
    return Promise.reject(error);
  });
  return response?.data;
};

/**
 * An endpoint to fetch a pipeline revision
 * @param {object} body - The body of the request
 * @param {string} body.pipelineID - The pipeline ID
 * @param {Dispatch} dispatch - The dispatch function
 * @returns {Promise<PipelineRevisionModel>} - Returns a pipeline revision
 */
export const fetchPipelineRevision = async (
  params: { pipelineRevisionID: string },
  dispatch: Dispatch,
): Promise<PipelineRevisionModel> => {
  const response = await APIFetchAxios(
    `/pipelineRevisions/${params?.pipelineRevisionID}`,
  ).catch((error) => {
    let errorDetail = error?.response?.data?.detail;

    // If the user is not authorized to access the pipeline revision
    // This is to remove the pipeline revision ID from the error message
    if (
      _.includes(
        errorDetail,
        "user is not authorized to access pipeline_revision with id",
      )
    ) {
      errorDetail = "User is not authorized to access this pipeline revision!";
    }

    snackbarHelper(
      dispatch,
      errorDetail
        ? `Pipeline revision fetching: ${errorDetail}`
        : "Pipeline revision fetching failed!",
      "error",
    );
    return Promise.reject(error);
  });
  return response?.data;
};

export const fetchNodesOfPipeline = async (
  params: { pipelineID: string },
  dispatch: Dispatch,
): Promise<PipelineNodeModel[]> => {
  const response = await APIFetchAxios(
    `/pipelines/${params?.pipelineID}/pipelineNodes`,
  ).catch((error) => {
    snackbarHelper(
      dispatch,
      `Fetching pipeline ${params?.pipelineID} nodes failed!!`,
      "error",
    );
    return Promise.reject(error);
  });
  return response?.data;
};

/**
 * An endpoint to delete a pipeline
 * @param {object} body - The body of the request
 * @param {string} body.pipelineID - The pipeline ID
 * @param {Dispatch} dispatch - The dispatch function
 * @param {Function} setIsLoading - The function to set the loading state
 * @returns {Promise<AxiosResponse>} - Returns a promise of the response
 */
export const deletePipeline = async (
  params: { pipelineID: string },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);
  await APIDeleteAxios(`/pipelines/${params.pipelineID}`)
    .then(() => {
      snackbarHelper(dispatch, "Pipeline archived successfully!");
      setIsLoading && setIsLoading(false);
      return Promise.resolve();
    })
    .catch((error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        dispatch,
        `Pipeline archive: ${errorDetail}` || "Pipeline archive failed!",
        "error",
      );
      setIsLoading && setIsLoading(false);
      return Promise.reject();
    });
};

/**
 * This function is used to duplicate a pipeline
 * @param body
 * @param body.name The pipeline name
 * @param body.nodes The pipeline nodes
 * @param body.nodes.name The pipeline node name
 * @param body.nodes.config The pipeline node config
 * @param dispatch The dispatch function
 * @param setIsLoading The function to set the loading state
 * @returns The duplicated pipeline
 */
export const postDuplicatePipeline = async (
  body: {
    name: string;
    nodes: {
      name: string;
      config: PipelineNodeConfigBody;
    }[];
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);

  const response = await APIPostWithBodyAxios("/pipelines", {
    name: body.name + "_clone",
    nodes: _.map(body.nodes, (node) => ({
      name: node.name + "_clone",
      config: node.config,
    })),
  }).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      dispatch,
      `Pipeline duplication: ${errorDetail}` || "Pipeline duplication failed!",
      "error",
    );
    return Promise.reject(error);
  });
  snackbarHelper(dispatch, "Pipeline duplicated successfully!");
  setIsLoading && setIsLoading(false);
  return response?.data;
};

/**
 * An endpoint to create a pipeline
 * @param {object} body - The body of the request
 * @param {string} body.pipelineName - The pipeline name
 * @param {Dispatch} dispatch - The dispatch function
 * @param {Function} setIsLoading - The function to set the loading state
 * @returns {Promise<PipelineModel>} - Returns a promise
 */
export const postCreatePipeline = async (
  body: {
    name: string;
    nodes: {
      name: string;
      config: PipelineNodeConfigBody;
    }[];
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  setIsLoading && setIsLoading(true);

  const response = await APIPostWithBodyAxios("/pipelines", body).catch(
    (error: AxiosError<any>) => {
      const errorDetail = error?.response?.data?.detail;
      snackbarHelper(
        dispatch,
        `Pipeline creation: ${errorDetail}` || "Pipeline created failed!",
        "error",
      );
      return Promise.reject(error);
    },
  );
  snackbarHelper(dispatch, "Pipeline created successfully!");
  setIsLoading && setIsLoading(false);
  return response?.data;
};

/**
 * An endpoint to update a pipeline
 * @param pipelineID The pipeline ID
 * @param body The body of the request
 * @param body.name The updated pipeline name
 * @param body.user_group The updated user group
 * @param dispatch The dispatch function
 * @param setIsLoading The function to set the loading state
 * @returns {Promise<PipelineModel>} - Returns a promise
 */
export const patchPipeline = async (
  pipelineID: string,
  body: {
    name?: string;
    user_group?: string;
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<PipelineModel> => {
  setIsLoading && setIsLoading(true);
  const response = await APIPatchWithBodyAxios(
    `/pipelines/${pipelineID}`,
    body,
  ).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      dispatch,
      `Pipeline update: ${errorDetail}` || "Pipeline update failed!",
      "error",
    );
    setIsLoading && setIsLoading(false);
    return Promise.reject();
  });
  snackbarHelper(dispatch, "Pipeline updated successfully!");
  setIsLoading && setIsLoading(false);
  return response?.data;
};

/**
 * An endpoint to fetch a pipeline nodes
 * @param {Dispatch} dispatch - The dispatch function
 * @returns {Promise<PipelineNodeModel[]>} - Returns a pipeline
 */
export const fetchPipelineNodes = async (
  dispatch: Dispatch,
): Promise<PipelineNodeModel[]> => {
  const response = await APIFetchAxios("/pipelineNodes").catch((error) => {
    snackbarHelper(dispatch, "Pipeline nodes fetching failed!", "error");
    return Promise.reject(error);
  });
  return response?.data;
};

/**
 * An endpoint to create a pipeline node
 * @param body
 * @param body.name The pipeline node name
 * @param body.config The pipeline node config
 * @param dispatch The dispatch function
 * @param setIsLoading The function to set the loading state
 */
export const postCreatePipelineNode = async (
  body: {
    name: string;
    pipeline_id: string;
    gui_type?: string | null;
    config: PipelineNodeModel["config"];
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<PipelineNodeModel> => {
  setIsLoading && setIsLoading(true);

  const response = await APIPostWithBodyAxios("/pipelineNodes", {
    ...body,
    config: {
      ...body?.config,
      should_workpackage_include_task_outputs:
        body?.config?.should_workpackage_include_task_outputs,
    },
  }).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      dispatch,
      `Pipeline node creation: ${errorDetail}` ||
        "Pipeline node creation failed!",
      "error",
    );
    setIsLoading && setIsLoading(false);
    return Promise.reject();
  });
  snackbarHelper(dispatch, "Pipeline node created successfully!");
  setIsLoading && setIsLoading(false);
  return response?.data;
};

/**
 * An endpoint to update a pipeline node
 * @param pipelineNodeID The pipeline node ID
 * @param body
 * @param body.name The updated pipeline node name
 * @param body.user_group The updated user group
 * @param body.config The updated pipeline node config
 * @param dispatch The dispatch function
 * @param setIsLoading Set the loading state
 */
export const patchPipelineNode = async (
  pipelineNodeID: string,
  body: {
    name?: string;
    user_group?: string;
    gui_type?: string | null;
    config?: PipelineNodeModel["config"];
  },
  dispatch: Dispatch,
  setIsLoading?: (isLoading: boolean) => void,
): Promise<PipelineNodeModel> => {
  setIsLoading && setIsLoading(true);
  const response = await APIPatchWithBodyAxios(
    `/pipelineNodes/${pipelineNodeID}`,
    {
      ...body,
      config: {
        ...body?.config,
        should_workpackage_include_task_outputs:
          body?.config?.should_workpackage_include_task_outputs,
      },
    },
  ).catch((error: AxiosError<any>) => {
    const errorDetail = error?.response?.data?.detail;
    snackbarHelper(
      dispatch,
      `Pipeline node update: ${errorDetail}` || "Pipeline node update failed!",
      "error",
    );
    setIsLoading && setIsLoading(false);
    return Promise.reject();
  });

  snackbarHelper(dispatch, "Pipeline node updated successfully!");
  setIsLoading && setIsLoading(false);
  return response?.data;
};
