import { get } from "lodash";
import { IconContext } from "react-icons";
import { IoMdHome } from "react-icons/io";
import {
  MdTune,
  MdDownload,
  MdFormatListBulleted,
  MdEqualizer,
  MdPhotoLibrary,
  MdDirectionsWalk,
  MdNavigateBefore,
  MdNavigateNext,
  MdExpandMore,
  MdExpandLess,
  MdClose,
  MdPolicy,
  MdEdit,
  MdPictureAsPdf,
  MdOutlineDownload,
  MdImage,
  MdFeedback,
} from "react-icons/md";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { BsTagFill, BsFilter } from "react-icons/bs";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { AiOutlineReload } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import { BsArrowLeft, BsKeyboardFill } from "react-icons/bs";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlinePlus,
  AiOutlineMinus,
} from "react-icons/ai";

interface Props {
  icon: keyof typeof iconsList;
  color?: string;
  size?: string;
}
export const iconsList = {
  home: <IoMdHome />,
  tune: <MdTune />,
  download: <MdDownload />,
  plus: <AiOutlinePlus />,
  minus: <AiOutlineMinus />,
  list_bulleted: <MdFormatListBulleted />,
  equalizer: <MdEqualizer />,
  photo_library: <MdPhotoLibrary />,
  directions_walk: <MdDirectionsWalk />,
  before_icon: <MdNavigateBefore />,
  next_icon: <MdNavigateNext />,
  expand_more: <MdExpandMore />,
  expand_less: <MdExpandLess />,
  close: <MdClose />,
  policy: <MdPolicy />,
  object_category: <BiPurchaseTagAlt />,
  filled_object_category: <BsTagFill />,
  switch_horizontal: <HiOutlineSwitchHorizontal />,
  edit: <MdEdit />,
  pdf: <MdPictureAsPdf />,
  download_outline: <MdOutlineDownload />,
  media: <MdImage />,
  reload: <AiOutlineReload />,
  menu: <FiMenu />,
  arrow_left: <BsArrowLeft />,
  eye: <AiFillEye />,
  eye_invisible: <AiFillEyeInvisible />,
  keyboard: <BsKeyboardFill />,
  filter: <BsFilter />,
  feedback: <MdFeedback />,
};

// Wrapper component for react-icons provide responsive as the default size 100% of parent component
// Color is inherited or can be passed from props
const IconComp = ({ icon, color, size = "100%" }: Props) => {
  return (
    <IconContext.Provider value={{ color: color, size: size }}>
      <div className="flex justify-center items-center">
        {get(iconsList, icon, "")}
      </div>
    </IconContext.Provider>
  );
};

export default IconComp;
