import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import { SendFilterModel } from "models/filter.model";
import {
  AnnotatableEnum,
  SelectedTagFrequencyWithoutFreqencyModel,
  SelectedTagsFrequenciesModel,
  SelectedViewModel,
} from "models/global.model";
import { MetaDataCountModel } from "models/metaData.model";
import { fetchMediasCount } from "helpers/apis/medias";
import { fetchMediaObjectsCount } from "helpers/apis/mediaObjects";

export const fetchTagFrequency = createAsyncThunk(
  "tagFrequencies/fetchTagFrequency",
  async (meta: {
    type: SelectedViewModel;
    datasetID: string;
    APIBody: SendFilterModel[];
    tag: SelectedTagFrequencyWithoutFreqencyModel;
    totalCount: number;
    skipLoading?: boolean;
  }) => {
    const { datasetID, APIBody, type } = meta;
    let response!: MetaDataCountModel;
    if (type === AnnotatableEnum.Media) {
      response = await fetchMediasCount(datasetID, APIBody);
    } else if (type === AnnotatableEnum.MediaObject) {
      response = await fetchMediaObjectsCount(datasetID, APIBody);
    }

    return { data: response, meta: meta };
  }
);

export interface tagFrequenciesStateTypes {
  Media: SelectedTagsFrequenciesModel;
  MediaObject: SelectedTagsFrequenciesModel;
  Instance: SelectedTagsFrequenciesModel;
  loading: boolean;
  error: { message: string };
}

const initialState = {
  Media: {},
  MediaObject: {},
  loading: false,
  error: { message: "" },
} as tagFrequenciesStateTypes;

export const tagFrequenciesSlice = createSlice({
  name: "tagFrequencies",
  initialState,
  reducers: {
    removeSelectedTagFrequency: (
      state,
      action: PayloadAction<{
        idOfTagToRemove: string;
        type: SelectedViewModel;
      }>
    ) => {
      const { idOfTagToRemove, type } = action.payload;
      const newSelectedTagsFrequencies = _.omit(state[type], idOfTagToRemove);
      state[type] = newSelectedTagsFrequencies;
    },
    resetTagFrequenciesSlice: () => initialState,
  },
  extraReducers: (builder) => {
    // fetchTagFrequency()
    builder.addCase(
      fetchTagFrequency.pending,
      (state: tagFrequenciesStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      }
    );
    builder.addCase(
      fetchTagFrequency.fulfilled,
      (state: tagFrequenciesStateTypes, action) => {
        const { data, meta } = action.payload;
        const { type, tag, totalCount } = meta;
        state[type] = {
          ...state[type],
          [tag?.id]: {
            id: tag?.id,
            name: tag?.name,
            color: tag?.color,
            frequency: data?.total_count,
            frequencyPercentage:
              (data?.total_count * 100) / (totalCount > 0 ? totalCount : 1),
          },
        };
        state.loading = false;
      }
    );
    builder.addCase(
      fetchTagFrequency.rejected,
      (state: tagFrequenciesStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      }
    );
  },
});

export const { removeSelectedTagFrequency, resetTagFrequenciesSlice } =
  tagFrequenciesSlice.actions;
export default tagFrequenciesSlice.reducer;
