import WarningDialog from "components/Internal/Dialogs/WarningDialog";
import Checkbox from "components/Internal/Inputs/Checkbox";
import { validateTextInput } from "components/Internal/Inputs/Form/formInputsHelpers";
import { postAIAnnotationRun } from "helpers/apis/annotationRun";
import { postCreateMLAnnotationModel } from "helpers/apis/mlAnnotationModel";
import _ from "lodash";
import { AnnotationRunModel } from "models/annotationRun.model";
import { DatasetModel } from "models/dataset.model";
import { TextFieldModel } from "models/form.model";
import {
  MLAnnotationModel,
  MLAnnotationModelCreate,
  MLAnnotationModelStatus,
} from "models/mlAnnotationModel.model";
import { useState } from "react";
import { useAppDispatch } from "store/hooks";

type Props = {
  annotationRun: AnnotationRunModel;
  mlAnnotationModel: MLAnnotationModel | null;
  updateData: (mlAnnotationModel: MLAnnotationModel) => void;
  // Step 1
  modelName: TextFieldModel;
  setModelName: (value: TextFieldModel) => void;
  // Step 2
  annotationRunName: string;
  dataset: DatasetModel | null;
  subset: DatasetModel | null;
  maxRepeatsOverride: number;
  step: number;
  setStep: (step: number) => void;
  showHelpDiv: boolean;
  setShowHelpDiv: (show: boolean) => void;
  onClose: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  updateAnnotationRuns: () => void;
};

const AIModelDialogActions = ({
  annotationRun,
  mlAnnotationModel,
  updateData,
  modelName,
  setModelName,
  annotationRunName,
  dataset,
  subset,
  maxRepeatsOverride,
  step,
  setStep,
  showHelpDiv,
  setShowHelpDiv,
  onClose,
  isLoading,
  setIsLoading,
  updateAnnotationRuns,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

  const renderActionButton = () => {
    if (step === 1) {
      return renderActionButtonAtFirstStep();
    }

    if (step === 2) {
      return renderActionButtonAtSecondStep();
    }
  };

  const renderActionButtonAtFirstStep = () => {
    // Case 1: No AI model is trained yet
    if (mlAnnotationModel === null) {
      const isDisabled = isLoading;
      return (
        <button
          className="button-dark-gray-layer bg-paletteBlack-2"
          onClick={handleLaunchAIModelTraining}
          disabled={isDisabled}
        >
          Train AI Model
        </button>
      );
    }

    // Case 2: AI model is being trained
    if (
      mlAnnotationModel !== null &&
      mlAnnotationModel?.status !== MLAnnotationModelStatus.TRAINING_DONE
    ) {
      return (
        <button
          className="button-dark-gray-layer bg-paletteBlack-2"
          disabled={true}
        >
          Next
        </button>
      );
    }

    // Case 3: AI model is trained (done)
    if (
      mlAnnotationModel !== null &&
      mlAnnotationModel?.status === MLAnnotationModelStatus.TRAINING_DONE
    ) {
      return (
        <button
          className="button-dark-gray-layer bg-paletteBlack-2"
          onClick={() => setStep(2)}
        >
          Next
        </button>
      );
    }
  };

  const renderActionButtonAtSecondStep = () => {
    const isDisabled =
      isLoading ||
      annotationRunName?.length === 0 ||
      maxRepeatsOverride < 1 ||
      !mlAnnotationModel ||
      !subset;
    return (
      <button
        className="button-dark-gray-layer bg-paletteBlack-2"
        onClick={handleLaunchAIAnnotationRun}
        disabled={isDisabled}
      >
        Launch AI annotation run
      </button>
    );
  };

  const handleLaunchAIModelTraining = () => {
    if (modelName.value?.length === 0) {
      const error = validateTextInput(modelName);
      setModelName({
        ...modelName,
        isErrored: true,
        error: error,
      });
      setIsWarningDialogOpen(true);
      return;
    }

    const newMlAnnotationModel: MLAnnotationModelCreate = {
      name: modelName.value,
      reference_set_annotation_run_id: annotationRun?.id,
    };
    postCreateMLAnnotationModel(
      newMlAnnotationModel,
      dispatch,
      setIsLoading,
    ).then((data) => updateData(data));
  };

  const handleLaunchAIAnnotationRun = () => {
    if (!mlAnnotationModel || !subset || !dataset) return;

    postAIAnnotationRun(
      {
        name: annotationRunName,
        ml_annotation_model_id: mlAnnotationModel?.id,
        dataset_id: dataset?.id,
        subset_id: subset?.id,
        max_repeats: maxRepeatsOverride,
      },
      dispatch,
      setIsLoading,
    ).then(() => {
      updateAnnotationRuns();
      onClose();
    });
  };

  return (
    <div className="w-full flex justify-between pt-6">
      <div>
        <Checkbox
          checked={showHelpDiv}
          onChange={(e) => setShowHelpDiv(e.target.checked)}
          size={18}
        />
        Show help
      </div>
      <div>
        <div className="flex gap-x-2">
          <button className="button-layer bg-transparent" onClick={onClose}>
            Close
          </button>
          {renderActionButton()}
        </div>
      </div>
      <WarningDialog
        title="AI model setup incomplete"
        subtitle="Please provide Model name before launching AI model training."
        openDialog={isWarningDialogOpen}
        setOpenDialog={setIsWarningDialogOpen}
      />
    </div>
  );
};

export default AIModelDialogActions;
