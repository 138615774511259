import { Tooltip } from "@mui/material";
import { ReactComponent as InfoIcon } from "assets/info.svg";

const DescriptionComponent = ({ description }: { description: string }) => {
  return description ? (
    <Tooltip
      title={description}
      placement="top"
      classes={{
        tooltip: "bg-paletteGray-10",
      }}
    >
      <div
        className="flex items-center justify-center float-right cursor-pointer
        text-paletteGray-7 hover:text-paletteGray-9"
      >
        <InfoIcon className="w-3 h-3" />
      </div>
    </Tooltip>
  ) : null;
};

export default DescriptionComponent;
