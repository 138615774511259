type Props = {
  title?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  iconBgcolor?: string;
};

const Header = ({ title, icon, children, iconBgcolor }: Props) => {
  const renderIcon = () => {
    if (!icon) return null;

    return (
      <div
        className={`p-[10px] rounded-lg bg-gradient-to-b ${
          iconBgcolor ? iconBgcolor : ""
        }`}
      >
        {icon}
      </div>
    );
  };

  return (
    <div className="flex flex-col px-5 py-3 border-b border-paletteGray-5">
      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-x-[10px]">
          {renderIcon()}
          {title && <div className="text-lg">{title}</div>}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Header;
