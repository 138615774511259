import { ReactComponent as WarningIcon } from "assets/warning.svg";

const warningMessage =
  "The Live Preview shows a maximum of 30 items. The actual work package size displayed to annotators can be larger. Instruction URL is not supported in Live Preview.";

const LivePreviewWarning = () => {
  return (
    <div className="w-full mt-3 flex gap-2 items-center justify-center">
      <div>
        <WarningIcon className="w-5 h-5 text-paletteGray-6" />
      </div>
      <div
        className="text-sm text-paletteGray-11 font-normal overflow-y-scroll max-h-12"
        title={warningMessage}
      >
        {warningMessage}
      </div>
    </div>
  );
};

export default LivePreviewWarning;
