import { useEffect, useState } from "react";
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import _ from "lodash";

import useMediaQuery from "@mui/material/useMediaQuery";
import { PrivateRoute } from "components/UtilComponents/Auth";

import Backdrop from "@mui/material/Backdrop";

import SidebarMenu from "components/UtilComponents/SidebarMenu";
import Exploration from "components/Exploration";
import Imprint from "components/UtilComponents/Imprint";
import PrivacyPolicy from "components/UtilComponents/PrivacyPolicy";
import Transformations from "components/Transformations/index";
import Snackbar from "components/UtilComponents/Snackbar";
import AttributesManagement from "components/AttributeManagement";
import UploadManagement from "components/UploadManagement";
import SubsetsManager from "components/SubsetsManager";
import TagsManager from "components/TagsManager";
import MediaDetailsScreen from "components/DetailsScreen/MediaDetailsScreen";
import MediaObjectDetailsScreen from "components/DetailsScreen/MediaObjectDetailsScreen";
import StatisticsScreen from "components/Statistics";
import CookieWarning from "components/UtilComponents/CookieWarning";
import { allUsersRoles, UserRoleModel } from "models/users.model";
import NotFoundPage from "components/UtilComponents/NotFoundPage";
import DatasetManager from "components/Datasets";
import InstanceDetailsScreen from "components/DetailsScreen/InstanceDetailsScreen";
import { getExplorationMediaPageRoute } from "routes/routesHelper";
import { ExplorationScreenRouteModel } from "models/routes.model";
import InternalUserToolsPage from "components/InternalUserToolsPage";
import PipelineManager from "Pages/PipelineManager";
import PipelineDesigner from "Pages/PipelineDesigner";
import AnnotationRunManager from "Pages/AnnotationRunManager";
import { getAcceptances } from "helpers/analytics/cookieAcceptance";
import { permissionsList } from "helpers/permissions";
import AIManager from "Pages/AIManager";

interface RouteObject {
  path: string;
  component: () => JSX.Element;
  roles: UserRoleModel[];
  isPublic?: boolean;
}

const publicRoutes: {
  imprintPage: RouteObject;
  privacyPolicyPage: RouteObject;
} = {
  imprintPage: {
    path: "/Imprint",
    component: Imprint,
    roles: permissionsList.accessImprintPage.roles,
    isPublic: true,
  },
  privacyPolicyPage: {
    path: "/Privacy-Policy",
    component: PrivacyPolicy,
    roles: permissionsList.accessPrivacyPolicyPage.roles,
    isPublic: true,
  },
};

export const allUsersRoutes: {
  homePage: RouteObject;
  explorationMediaPage: RouteObject;
  explorationObjectPage: RouteObject;
  explorationInstancePage: RouteObject;
  detailsMediaPage: RouteObject;
  detailsInstancePage: RouteObject;
  detailsObjectPage: RouteObject;
  subsetsManagerPage: RouteObject;
  tagsManagerPage: RouteObject;
} = {
  homePage: { path: "/", component: DatasetManager, roles: allUsersRoles },
  explorationMediaPage: {
    path: "/main/media/:id/:subset_id",
    component: Exploration,
    roles: permissionsList.accessExplorationPage.roles,
  },
  explorationObjectPage: {
    path: "/main/mediaObject/:id/:subset_id",
    component: Exploration,
    roles: permissionsList.accessExplorationPage.roles,
  },
  explorationInstancePage: {
    path: "/main/instance/:id/:subset_id",
    component: Exploration,
    roles: permissionsList.accessExplorationPage.roles,
  },
  detailsMediaPage: {
    path: "/main/:id/:subset_id/media/:media_id",
    component: MediaDetailsScreen,
    roles: permissionsList.accessDetailsPage.roles,
  },
  detailsInstancePage: {
    path: "/main/:id/:subset_id/instance/:instance_id",
    component: InstanceDetailsScreen,
    roles: permissionsList.accessDetailsPage.roles,
  },
  detailsObjectPage: {
    path: "/main/:id/:subset_id/mediaObject/:mediaObjectID",
    component: MediaObjectDetailsScreen,
    roles: permissionsList.accessDetailsPage.roles,
  },
  subsetsManagerPage: {
    path: "/subsets/:dataset_id/:subset_id/",
    component: SubsetsManager,
    roles: permissionsList.accessSubsetsManagerPage.roles,
  },
  tagsManagerPage: {
    path: "/tags/:dataset_id/:subset_id/",
    component: TagsManager,
    roles: permissionsList.accessTagsManagerPage.roles,
  },
};

export const customContractUsersRoutes: {
  uploadsPage: RouteObject;
  pipelineManagerPage: RouteObject;
  pipelineDesignerPage: RouteObject;
  annotationRunManagerPage: RouteObject;
} = {
  uploadsPage: {
    path: "/uploads",
    component: UploadManagement,
    roles: permissionsList.accessUploadPage.roles,
  },
  pipelineManagerPage: {
    path: "/pipeline-manager",
    component: PipelineManager,
    roles: permissionsList.accessPipelineManagerPage.roles,
  },
  pipelineDesignerPage: {
    path: "/pipeline-designer/:pipeline_id",
    component: PipelineDesigner,
    roles: permissionsList.accessPipelineDesignerPage.roles,
  },
  annotationRunManagerPage: {
    path: "/annotation-run-manager",
    component: AnnotationRunManager,
    roles: permissionsList.accessAnnotationRunManagerPage.roles,
  },
};

export const internalUsersRoutes: {
  statisticsPage: RouteObject;
  attributesManagerPage: RouteObject;
  aiManagerPage: RouteObject;
  transformationsPage: RouteObject;
  internalUserToolsPage: RouteObject;
} = {
  // Will be custom contract in the future
  statisticsPage: {
    path: "/statistics/:dataset_id/:subset_id/",
    component: StatisticsScreen,
    roles: permissionsList.accessStatisticsPage.roles,
  },
  attributesManagerPage: {
    path: "/attributes/:dataset_id/:subset_id/",
    component: AttributesManagement,
    roles: permissionsList.accessAttributeManagerPage.roles,
  },
  aiManagerPage: {
    path: "/ai-manager",
    component: AIManager,
    roles: permissionsList.accessAIManagerPage.roles,
  },

  // Will always be internal
  transformationsPage: {
    path: "/transformations",
    component: Transformations,
    roles: permissionsList.accessTransformationPage.roles,
  },
  internalUserToolsPage: {
    path: "/internal-tools/:dataset_id/:subset_id/",
    component: InternalUserToolsPage,
    roles: permissionsList.accessInternalToolsPage.roles,
  },
};

const RoutesComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const minWidth = useMediaQuery("(max-width:1050px)"); // production: 1550, testing: 1250
  const minHeight = useMediaQuery("(max-height:350px)"); // production: 850, testing: 650

  const [cookieModalAnswered, setCookieModalAnswered] =
    useState<boolean>(false);

  useEffect(() => {
    const { cookieAcceptance } = getAcceptances();
    if (cookieAcceptance) {
      setCookieModalAnswered(true);
    }
  }, [location, history]);

  const renderCookieWarning = () => {
    // User should be able to read the privacy policy page without cookie warning
    const location = useLocation();
    if (location.pathname.toLowerCase() === "/privacy-policy") return;

    // If cookie warning modal has not been answered yet, show it
    if (!cookieModalAnswered) {
      return <CookieWarning />;
    }
  };

  const renderScreenSizeBackdrop = () => {
    return (
      <Backdrop
        open={minWidth || minHeight}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.85)",
          zIndex: 100,
          backdropFilter: "blur(5px)",
        }}
      >
        <div
          style={{
            color: "white",
            fontFamily: "UntitledSans",
            fontWeight: 700,
            fontSize: "32px",
            textAlign: "center",
            padding: "15px",
          }}
        >
          This layout isn't supported with the current screen size. Please
          expand the window.
        </div>
      </Backdrop>
    );
  };

  const mapRoutes = () => {
    const allRoutes = {
      ...publicRoutes,
      ...allUsersRoutes,
      ...customContractUsersRoutes,
      ...internalUsersRoutes,
    };
    return _.map(allRoutes, (route, key) => {
      return (
        <PrivateRoute
          key={key}
          exact
          path={route?.path}
          component={route?.component}
          roles={route?.roles}
          isPublic={route?.isPublic}
        />
      );
    });
  };

  const oldRoutes: { oldExplorationPage: RouteObject } = {
    oldExplorationPage: {
      path: "/main/:id/:subset_id",
      component: Exploration,
      roles: allUsersRoles,
    },
  };

  const redirectOldExplorationPage = () => {
    const match = matchPath(location.pathname, {
      path: oldRoutes.oldExplorationPage.path,
      exact: true,
      strict: false,
    });
    const params = match?.params as ExplorationScreenRouteModel;
    return (
      <Route exact path={oldRoutes.oldExplorationPage.path}>
        {match?.isExact && (
          <Redirect
            to={getExplorationMediaPageRoute({
              id: params.id,
              subset_id: params.subset_id,
            })}
          />
        )}
      </Route>
    );
  };

  return (
    <div className="App h-screen w-screen flex bg-white">
      <SidebarMenu />
      <div className="min-w-0 h-full flex-1">
        <Switch>
          {redirectOldExplorationPage()}
          {mapRoutes()}
          <Route component={() => <NotFoundPage />} />
        </Switch>
      </div>
      {renderCookieWarning()}
      <Snackbar />
      {renderScreenSizeBackdrop()}
    </div>
  );
};

export default RoutesComponent;
