import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "store/hooks";
import ConfirmDialog from "components/Internal/Dialogs/ConfirmDialog";
import DataTable, {
  DataTableColumn,
} from "components/Internal/Table/DataTable";
import _ from "lodash";
import {
  AnnotationRunModel,
  AnnotationRunStatusEnum,
  AnnotationRunTypeEnum,
} from "models/annotationRun.model";
import {
  deleteAnnotationRun,
  fetchAnnotationRuns,
  patchAnnotationRun,
} from "helpers/apis/annotationRun";

import { snakeCaseToText } from "components/utilFunctions";
import StatusLabel from "components/Internal/Labels/StatusLabel";
import AnnotationRunManagerTableAction, {
  AnnotationRunManagerPopoverConfirmDialogState,
} from "Pages/AnnotationRunManager/AnnotationRunManagerTableAction";
import EditName from "components/Internal/Inputs/EditName";
import { ReactComponent as AIicon } from "assets/ai.svg";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";
import UserGroupSelection from "components/UtilComponents/UserGroupSelection";

type Props = {
  annotationRuns: AnnotationRunModel[];
  setAnnotationRuns: (annotationRuns: AnnotationRunModel[]) => void;
  searchValue: string;
};

const AnnotationRunManagerTable = ({
  annotationRuns,
  setAnnotationRuns,
  searchValue,
}: Props) => {
  const dispatch = useAppDispatch();

  const [confirmDialogState, setConfirmDialogState] =
    useState<AnnotationRunManagerPopoverConfirmDialogState>({
      show: false,
      action: "archive",
      annotationRunID: null,
    });
  const [isLoading, setIsLoading] = useState(false);

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});
  const [renameInputID, setRenameInputID] = useState<string | null>(null);

  // focus on the input field when the editInputID changes
  useEffect(() => {
    if (renameInputID) {
      inputRefArray.current?.[renameInputID]?.focus();
    }
  }, [renameInputID]);

  const tableColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Annotation run name",
      span: 25,
      cell: (row) => {
        const annotationRun = row as AnnotationRunModel;
        return (
          <div className="flex overflow-hidden pr-3">
            <EditName
              item={row as Record<string, any>}
              inputRefArray={inputRefArray}
              editInputID={renameInputID}
              setEditInputID={setRenameInputID}
              handleRename={(newName: string) => {
                handleRenameAnnotationRun(annotationRun?.id, newName);
              }}
              withToolTip={true}
              fontSize="text-normal"
            />
            {annotationRun.annotation_run_type === AnnotationRunTypeEnum.AI && (
              <AIicon className="ml-2 text-palettePurple" />
            )}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 15,
      cell: (row) => {
        const annotationRun = row as AnnotationRunModel;
        const date = new Date(annotationRun?.created_at);
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {date.toLocaleString()}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      span: 15,
      cell: (row) => {
        const annotationRun = row as AnnotationRunModel;
        return renderStatusChip(annotationRun?.status);
      },
    },
    {
      field: "user_group",
      headerName: "Team",
      span: 15,
      cell: (row) => {
        const annotationRun = row as AnnotationRunModel;
        return (
          <UserGroupSelection
            selectedUserGroup={annotationRun?.user_group}
            onChanges={(newUserGroup: string) =>
              patchAnnotationRun(
                annotationRun?.id,
                { user_group: newUserGroup },
                dispatch,
                setIsLoading,
              ).then(() => updateAnnotationRuns())
            }
          />
        );
      },
    },
    {
      field: "",
      headerName: "Actions",
      sortable: false,
      span: 30,
      cell: (row) => {
        const annotationRun = row as AnnotationRunModel;
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full flex flex-row-reverse justify-start"
          >
            <AnnotationRunManagerTableAction
              annotationRun={annotationRun}
              setConfirmDialogState={setConfirmDialogState}
              setRenameInputID={setRenameInputID}
              updateAnnotationRuns={updateAnnotationRuns}
            />
          </div>
        );
      },
    },
  ];

  const renderStatusChip = (status: AnnotationRunStatusEnum) => {
    let color;
    switch (status) {
      case AnnotationRunStatusEnum.STARTED:
      case AnnotationRunStatusEnum.ANNOTATION_DONE:
      case AnnotationRunStatusEnum.PARTIALLY_DONE:
      case AnnotationRunStatusEnum.POST_PROCESSING:
        color = "bg-paletteYellow";
        break;
      case AnnotationRunStatusEnum.CREATION_FAILED:
      case AnnotationRunStatusEnum.AI_ANNOTATION_FAILED:
      case AnnotationRunStatusEnum.POST_PROCESSING_FAILED:
        color = "bg-paletteRed";
        break;
      case AnnotationRunStatusEnum.DONE:
        color = "bg-paletteGreen";
        break;
      case AnnotationRunStatusEnum.CREATED:
      default:
        color = "bg-paletteGray6";
    }

    return <StatusLabel label={snakeCaseToText(status)} colorClass={color} />;
  };

  // A function to call the delete annotationRun endpoint
  const archiveAnnotationRun = () => {
    if (_.isNull(confirmDialogState.annotationRunID)) return;
    deleteAnnotationRun(
      { annotationRunID: confirmDialogState.annotationRunID },
      dispatch,
      setIsLoading,
    ).then(() => {
      updateAnnotationRuns();
      setConfirmDialogState({ ...confirmDialogState, show: false });
    });
  };

  const handleRenameAnnotationRun = (
    annotationRunID: string,
    newName: string,
  ) => {
    patchAnnotationRun(annotationRunID, { name: newName }, dispatch).then(() =>
      updateAnnotationRuns(),
    );
  };

  const updateAnnotationRuns = () => {
    fetchAnnotationRuns(dispatch).then((data) => {
      setAnnotationRuns(data);
    });
  };

  const renderConfirmationDialog = () => {
    return (
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogState.show}
        setIsConfirmDialogOpen={(newState) =>
          setConfirmDialogState({ ...confirmDialogState, show: newState })
        }
        text={`Are you sure you want to ${
          confirmDialogState.action
        } the annotationRun 
                "${_.find(annotationRuns, [
                  "id",
                  confirmDialogState.annotationRunID,
                ])?.name}"?`}
        confirmButtonText="Confirm"
        handleOnSuccess={() => archiveAnnotationRun()}
      />
    );
  };

  return (
    <>
      <DataTable
        rows={annotationRuns as []}
        columns={tableColumns}
        defaultSort={{ name: "created_at", direction: "desc" }}
        isLoading={isLoading}
        searchValue={searchValue}
        extraKeysToSearch={["goliat_project_id"]}
      />
      {renderConfirmationDialog()}
    </>
  );
};

export default AnnotationRunManagerTable;
