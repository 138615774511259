import { useEffect, useRef, useState } from "react";
import {
  Label,
  LidarViewer,
  PointEntity,
  Marker2D,
  Marker3D,
  QmLidarViewLoader,
  LidarCamera,
  Vector3,
} from "@quality-match/lidar-viewer";
import "@quality-match/lidar-viewer/index.css";
import { ReactComponent as ArrowLeftIcon } from "assets/arrow_small_left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/arrow_small_right.svg";
import { MediaModel } from "models/exploration.model";
import { fetchMedias } from "helpers/apis/medias";
import _ from "lodash";
import { fetchMediaObjects } from "helpers/apis";
import { useParams } from "react-router-dom";
import { MediaDetailsScreenRouteModel } from "models/routes.model";
import {
  CuboidCenterPoint,
  GeometriesEnum,
  Point2DXY,
  Point3dXYZAggregation,
} from "models/geometries.model";
import { geometryColors } from "helpers/colors";
import { Mediatype } from "models/dataset.model";
import { PCDLoader } from "three/examples/jsm/loaders/PCDLoader";
import { useAppSelector } from "store/hooks";
import { filterLabelsBasedOnToggledCategories } from "helpers/functions/detailsScreenHelper";

interface LidarViewerContainerProps {
  pcMedia: MediaModel;
  showOnlyOneLabel: {
    show: boolean;
    labelId?: string;
  };
  hoveredLabelId?: string;
  controls?: {
    cameraIndex?: number;
    showAxesHelper?: boolean;
    showFreeView?: boolean;
    showCameraView?: boolean;
    showGeometries?: boolean;
  };
}

const LidarViewerContainer = ({
  pcMedia,
  showOnlyOneLabel,
  hoveredLabelId,
  controls,
}: LidarViewerContainerProps) => {
  const params: MediaDetailsScreenRouteModel = useParams();
  const loader = useRef<QmLidarViewLoader>(new QmLidarViewLoader("stub"));

  const detailsScreenSlice = useAppSelector(
    (state) => state.detailsScreenSlice
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [cameras, setCameras] = useState<LidarCamera[] | null>(null);
  const [points, setPoints] = useState<PointEntity[] | null>(null);
  const [geometries, setGeometries] = useState<Label[]>([]);
  const [markers3D, setMarkers3D] = useState<Marker3D[]>([]);
  const [geometries2D, setGeometries2D] = useState<Marker2D[]>([]);

  const [rgbMedias, setRgbMedias] = useState<MediaModel[] | null>(null);

  const [cameraIndex, setCameraIndex] = useState<number>(
    controls?.cameraIndex ?? 0
  );
  const [showAxesHelper, setShowAxesHelper] = useState<boolean>(
    controls?.showAxesHelper ?? true
  );
  const [showFreeView, setShowFreeView] = useState<boolean>(
    controls?.showFreeView ?? true
  );
  const [showCameraView, setShowCameraView] = useState<boolean>(
    controls?.showCameraView ?? true
  );
  const [showGeometries, setShowGeometries] = useState<boolean>(
    controls?.showGeometries ?? true
  );

  useEffect(() => {
    setIsLoading(true);
    fetchCamerasData();
    fetchPointsData();
    fetch3DGeometries();
  }, [pcMedia]);

  useEffect(() => {
    fetch2DGeometries();
  }, [rgbMedias]);

  // Update the camera index if the showOnlyOneLabel prop is true
  //  and the labelId matches the labelId of the current label
  useEffect(() => {
    if (showOnlyOneLabel && showOnlyOneLabel.show) {
      const selectedLabel = _.filter(geometries2D, (label) => {
        return label.id === showOnlyOneLabel.labelId;
      });
      if (selectedLabel.length === 1) {
        setCameraIndex(selectedLabel[0].cameraIndex - 1);
      }
    }
  }, [pcMedia, showOnlyOneLabel.labelId]);

  const fetchCamerasData = async () => {
    if (
      !_.isUndefined(pcMedia?.scene_id) &&
      !_.isUndefined(pcMedia?.frame_idx)
    ) {
      fetchMedias({
        dataset_id: pcMedia?.dataset_id,
        query: [
          {
            attribute: "scene_id",
            query_operator: "==",
            value: pcMedia?.scene_id,
          },
          {
            attribute: "frame_idx",
            query_operator: "==",
            value: _.toInteger(pcMedia?.frame_idx),
          },
        ],
      })
        .then((camerasRawData) => {
          let cameraViewerList: LidarCamera[] = [];
          _.map(camerasRawData, (camera) => {
            const metadata = camera?.metadata;
            if (!metadata) return;

            const newCameraImageEntity =
              loader.current.transformLoaderCameraImageToThreeCameraImage({
                device_heading: metadata?.camera_extrinsics.heading,
                position: metadata?.camera_extrinsics.position,
                fx: metadata?.camera_intrinsics.focal_length?.[0],
                fy: metadata?.camera_intrinsics.focal_length?.[1],
                cx: metadata?.camera_intrinsics.principal_point?.[0],
                cy: metadata?.camera_intrinsics.principal_point?.[1],
                timestamp: 0,
                image_url: camera.media_url,
                height: metadata?.camera_intrinsics.height_px,
                width: metadata?.camera_intrinsics.width_px,
              });
            const lidarCameraObj = new LidarCamera(newCameraImageEntity);
            cameraViewerList = [...cameraViewerList, lidarCameraObj];
          });
          setCameras(cameraViewerList);
          setRgbMedias(camerasRawData);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  // instantiate a loader
  const pcdLoader = new PCDLoader();
  const fetchPointsData = () => {
    // load a resource
    pcdLoader.load(
      // resource URL
      pcMedia?.media_url,
      // called when the resource is loaded
      function (points) {
        const newPoints: PointEntity[] = [];
        // convert to PointEntity
        for (
          let i = 0;
          i < points.geometry.attributes.position.array.length;
          i += 3
        ) {
          newPoints.push(
            loader.current.transformLoaderPointToThreePoint({
              x: points.geometry.attributes.position.array[i],
              y: points.geometry.attributes.position.array[i + 1],
              z: points.geometry.attributes.position.array[i + 2],
            })
          );
        }
        setPoints(newPoints);
      },
      // called when loading is in progresses
      undefined,
      // called when loading has errors
      function (error) {
        setPoints([]);
        console.error("An error happened at PC data fetching:", error);
      }
    );
  };

  const fetch3DGeometries = () => {
    fetchMediaObjects({
      dataset_id: params?.id,
      query: [
        {
          attribute: "media_id",
          query_operator: "==",
          value: pcMedia?.id,
        },
      ],
    })
      .then((response) => {
        const cuboidsList = _.filter(response, [
          "media_object_type",
          GeometriesEnum.CuboidCenterPoint,
        ]);

        let geometriesList: Label[] = [];
        _.map(cuboidsList, (object) => {
          const cuboid = object?.reference_data as CuboidCenterPoint;
          const newLabel: Label = {
            id: object?.id,
            file: object?.back_reference,
            class: object?.object_category,
            boundingBox3D: {
              cx: cuboid?.position?.[0],
              cy: cuboid?.position?.[1],
              cz: cuboid?.position?.[2],
              w: cuboid?.dimensions?.[0],
              l: cuboid?.dimensions?.[1],
              h: cuboid?.dimensions?.[2],
              rot_x: 0,
              rot_y: 0,
              rot_z: 0,
              heading: cuboid?.heading,
            },
          };
          const labelWithCoord =
            loader.current.transformLoaderLabelToThreeLabel(newLabel);
          geometriesList = [...geometriesList, labelWithCoord];
        });
        setGeometries(geometriesList);

        const markers3DList = _.filter(response, (obj) =>
          _.includes(
            [GeometriesEnum.Point3dXYZAggregation, GeometriesEnum.Point3DXYZ],
            obj?.media_object_type
          )
        );

        let markers3D: Marker3D[] = [];
        _.map(markers3DList, (object) => {
          let marker: Point3dXYZAggregation =
            object?.reference_data as Point3dXYZAggregation;
          if (object?.source === "QM") {
            marker = object?.qm_data?.[
              object?.qm_data.length - 1
            ] as Point3dXYZAggregation;
          }
          const markerPositionTransformed =
            loader.current.transformLoaderPointToThreePoint(marker);
          const newMarker: Marker3D = {
            id: object?.id,
            position: new Vector3(
              markerPositionTransformed?.x,
              markerPositionTransformed?.y,
              markerPositionTransformed?.z
            ),
            radius: 0.5,
          };
          markers3D = [...markers3D, newMarker];
        });

        setMarkers3D(markers3D);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const fetch2DGeometries = () => {
    const rgbMediasID = _.map(
      _.filter(
        rgbMedias,
        (camera) => camera.media_type !== Mediatype.point_cloud
      ),
      (camera) => camera.id
    );

    if (rgbMediasID.length === 0) return;

    setIsLoading(true);
    fetchMediaObjects({
      dataset_id: params?.id,
      query: [
        {
          attribute: "media_id",
          query_operator: "in",
          value: rgbMediasID,
        },
      ],
    })
      .then((response) => {
        let geometriesList: Marker2D[] = [];
        _.map(response, (object) => {
          const keypoint2D = object?.qm_data[
            object?.qm_data.length - 1
          ] as Point2DXY;
          const cameraIndex = _.findIndex(
            rgbMediasID,
            (camera) => camera === object?.media_id
          );
          const newLabel: Marker2D = {
            id: object?.id,
            cameraIndex: cameraIndex + 1,
            position: {
              x: keypoint2D?.x,
              y: keypoint2D?.y,
            },
          };
          geometriesList = [...geometriesList, newLabel];
        });
        setGeometries2D(geometriesList);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const args = {
    showFreeView: showFreeView,
    showCameraView: showCameraView,
    enableDamping: true,
    dampingFactor: 0.5,
    enablePan: true,
    panSpeed: 0.5,
    rotationSpeed: 0.5,
    minCameraDistance: 1,
    maxCameraDistance: 40,
    showAxesHelper: showAxesHelper,
    showStats: false,
    showGUI: false,
    cmapMinClipping: -2,
    cmapMaxClipping: 2,
    markers3DLimit: 9999,
    enableAnnotations3D: false,
    enableAnnotations2D: false,
    labelSettings: {
      distinguishedKeysToRandomColor: false,
      defaultColors: {
        inactiveColorSettings: {
          edge: "#00ff00",
          edgeOpacity: 0.8,
          face: "#00ff00",
          faceOpacity: 0,
        },
        activeColorSettings: {
          edge: "#ff0000",
          edgeOpacity: 1,
          face: "#ff0000",
          faceOpacity: 0,
        },
      },
    },
    displayMarkers3D: true,
    displayMarkers3DOnCamView: false,
    markers3DSettings: {
      color: "#ff0000",
      opacity: 1,
      radius: 0.5,
    },
  };

  const renderControls = () => {
    return (
      <div className="w-full h-1/6 p-4 bg-white">
        Controls:
        <div className="flex items-center w-full justify-between">
          {renderAxesHelperControls()}
          {renderFreeViewControls()}
          {renderCameraViewControls()}
          {renderShowLabelsControls()}
          {renderCameraControls()}
        </div>
      </div>
    );
  };

  const renderCameraControls = () => {
    return (
      <div className="flex gap-x-1">
        <button
          className="icon-button-layer-circle"
          onClick={() => cameraIndex > 0 && setCameraIndex(cameraIndex - 1)}
        >
          <ArrowLeftIcon className="text-black" />
        </button>
        <div>
          Camera: {cameraIndex + 1} / {cameras?.length}
        </div>
        <button
          className="icon-button-layer-circle"
          onClick={() =>
            cameras &&
            cameraIndex + 1 < cameras?.length &&
            setCameraIndex(cameraIndex + 1)
          }
        >
          <ArrowRightIcon className="text-black" />
        </button>
      </div>
    );
  };

  const renderAxesHelperControls = () => {
    return (
      <button
        className="button-layer mt-1"
        onClick={() => setShowAxesHelper(!showAxesHelper)}
      >
        Show axes helper:
        {showAxesHelper ? " Shown" : " Hidden"}
      </button>
    );
  };

  const renderFreeViewControls = () => {
    return (
      <button
        className="button-layer mt-1"
        onClick={() => setShowFreeView(!showFreeView)}
      >
        Show free view:
        {showFreeView ? " Shown" : " Hidden"}
      </button>
    );
  };

  const renderShowLabelsControls = () => {
    return (
      <button
        className="button-layer mt-1"
        onClick={() => setShowGeometries(!showGeometries)}
      >
        Show Labels:
        {showGeometries ? " Shown" : " Hidden"}
      </button>
    );
  };

  const renderCameraViewControls = () => {
    return (
      <button
        className="button-layer mt-1"
        onClick={() => setShowCameraView(!showCameraView)}
      >
        Show camera view:
        {showCameraView ? " Shown" : " Hidden"}
      </button>
    );
  };

  const selectGeometries = () => {
    if (!showGeometries || detailsScreenSlice?.geometries.hide_all) return [];

    const filteredGeometriesList: Label[] =
      filterLabelsBasedOnToggledCategories(
        geometries,
        detailsScreenSlice.objectCategories
      );

    // If showOnlyOneLabel is true, only show the label with the given labelId
    if (showOnlyOneLabel.show) {
      return _.filter(
        filteredGeometriesList,
        (label) => label.id === showOnlyOneLabel.labelId
      );
    }

    // If showOnlyOneLabel is false, show all labels and highlight the
    //  label with the given labelId
    if (!showOnlyOneLabel.show) {
      const geometriesList = filteredGeometriesList?.map((label: Label) => {
        const newLabel =
          label.id === showOnlyOneLabel.labelId || label.id === hoveredLabelId
            ? updateGeometryColor(label)
            : label;
        return newLabel;
      });
      return geometriesList;
    }

    return geometries;
  };

  const updateGeometryColor = (geometry: Label) => {
    return {
      ...geometry,
      colorDef: {
        default: {
          edge: geometryColors.highlighted,
          edgeOpacity: 1,
          face: geometryColors.highlighted,
          faceOpacity: 0.3,
        },
      },
    };
  };

  const select3DMarkers = () => {
    if (!showGeometries || detailsScreenSlice?.geometries.hide_all) return [];

    // If showOnlyOneLabel is true, only show the label with the given labelId
    if (showOnlyOneLabel.show) {
      return _.filter(
        markers3D,
        (label) => label.id === showOnlyOneLabel.labelId
      );
    }

    return markers3D;
  };

  const select2DGeometries = () => {
    if (!showGeometries || detailsScreenSlice?.geometries.hide_all) return [];

    // Get the geometries of the current camera index
    //  Update the camera index to 0 because we only pass one camera at a time.
    let currentCameraGeometries: Marker2D[] = [];
    _.forEach(geometries2D, (label) => {
      if (label.cameraIndex === cameraIndex + 1) {
        currentCameraGeometries = [
          ...currentCameraGeometries,
          {
            ...label,
            cameraIndex: 0,
          },
        ];
      }
    });

    // If showOnlyOneLabel is true, only show the label with the given labelId
    if (showOnlyOneLabel && showOnlyOneLabel.show) {
      return _.filter(currentCameraGeometries, (label) => {
        return label.id === showOnlyOneLabel.labelId;
      });
    }
    return currentCameraGeometries;
  };

  const renderLoadingLayer = () => {
    if (!isLoading) return null;
    return (
      <div className="absolute inset-0 bg-black opacity-30 flex justify-center items-center" />
    );
  };

  return points && cameras && geometries ? (
    <div className="relative w-full h-full">
      <div className="w-full h-5/6">
        <LidarViewer
          {...args}
          points={points}
          cameras={[cameras[cameraIndex]]}
          labels={selectGeometries()}
          markers2D={select2DGeometries()}
          markers3D={select3DMarkers()}
        />
      </div>
      {renderControls()}
      {renderLoadingLayer()}
    </div>
  ) : (
    <div className="w-full h-full flex items-center justify-center">
      Loading...
    </div>
  );
};

export default LidarViewerContainer;
