import DataSelectionDialog from "Pages/AnnotationRunManager/AIModelDialog/DataSelectionDialog";
import NumberInput from "components/Internal/Inputs/Form/NumberInput";
import TextInput from "components/Internal/Inputs/Form/TextInput";
import { AnnotationRunModel } from "models/annotationRun.model";
import { DatasetModel } from "models/dataset.model";
import { NumberFieldModel, TextFieldModel } from "models/form.model";
import { PipelineRevisionModel } from "models/pipelines.model";
import { useEffect, useState } from "react";
import AutomationCorrectnessCurve from "Pages/AnnotationRunManager/AIModelDialog/AutomationCorrectnessCurve";
import { MLAnnotationModel } from "models/mlAnnotationModel.model";
import DescriptionComponent from "components/Internal/RJSF/DescriptionComponent";

type Props = {
  trainingAnnotationRun: AnnotationRunModel;
  trainingPipelineRevision: PipelineRevisionModel | null;
  aiAnnotationRunName: TextFieldModel;
  setAIAnnotationRunName: (value: TextFieldModel) => void;
  dataset: DatasetModel | null;
  setDataset: (value: DatasetModel | null) => void;
  subset: DatasetModel | null;
  setSubset: (value: DatasetModel | null) => void;
  maxRepeats: NumberFieldModel;
  setMaxRepeats: (value: NumberFieldModel) => void;
  mlAnnotationModel: MLAnnotationModel | null;
};

const AIAnnotationRunSetup = ({
  trainingAnnotationRun,
  trainingPipelineRevision,
  aiAnnotationRunName,
  setAIAnnotationRunName,
  dataset,
  setDataset,
  subset,
  setSubset,
  maxRepeats,
  setMaxRepeats,
  mlAnnotationModel,
}: Props) => {
  const [isDataSelectionDialogOpen, setIsDataSelectionDialogOpen] =
    useState(false);

  useEffect(() => {
    if (trainingPipelineRevision) {
      setMaxRepeats({
        ...maxRepeats,
        value: trainingPipelineRevision.node_revisions?.[0]?.config
          ?.max_repeats as number,
      });
    }
  }, [trainingPipelineRevision]);

  const renderAnnotationRunNameField = () => {
    return (
      <div className="w-full flex justify-between items-center">
        <div className="w-1/2 flex items-center">AI annotation run name</div>
        <div className="w-1/2 flex items-center">
          <TextInput
            field={aiAnnotationRunName}
            value={aiAnnotationRunName.value}
            handleOnChange={(event, field, isErrored, error) => {
              setAIAnnotationRunName({
                ...aiAnnotationRunName,
                value: event.target.value,
                isErrored: isErrored || false,
                error: error || null,
              });
            }}
          />
        </div>
      </div>
    );
  };

  const renderDataSelection = () => {
    return (
      <div className="w-full flex justify-between items-center">
        <div className="w-1/2 flex items-center">Select data</div>
        <div className="w-1/2 flex items-center justify-end">
          {renderDataSelectionText()}
        </div>
      </div>
    );
  };

  const renderDataSelectionText = () => {
    if (dataset === null || subset === null) {
      return (
        <button
          className="button-layer"
          onClick={() => setIsDataSelectionDialogOpen(true)}
        >
          Select data
        </button>
      );
    }

    return renderSelectedDataText();
  };

  const renderSelectedDataText = () => {
    return (
      <div
        className="cursor-pointer hover:underline"
        onClick={() => setIsDataSelectionDialogOpen(true)}
      >
        <div className="flex justify-end font-medium">{subset?.name}</div>
        <div className="flex justify-end text-sm text-paletteGray-11">
          {subset?.num_medias} media - {subset?.num_media_objects} objects
        </div>
      </div>
    );
  };

  const renderMinAndMaxRepeatsOverride = () => {
    return (
      <div className="w-full flex justify-between items-center">
        <div className="w-1/2 flex items-center">
          Number of AI annotations per task
        </div>
        <div className="w-1/2 flex items-center justify-end gap-x-1">
          <div className="w-5/12 ">
            <NumberInput
              field={maxRepeats}
              value={maxRepeats.value}
              handleOnChange={(event, field, isErrored, error) => {
                event.target.value = String(parseInt(event?.target?.value, 10));
                setMaxRepeats({
                  ...maxRepeats,
                  value: Number(event.target.value),
                  isErrored: isErrored || false,
                  error: error || null,
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex font-medium gap-x-1">
        <label className="">Automation Correctness Curve</label>
        {
          <DescriptionComponent description="The Automation Correctness Curve plots the degree of automation to be achieved on a dataset against the correctness achieved when an AI Nano-Task model is used for annotation. The curve is created by iteratively increasing the confidence threshold between 0 and 1 and for each such threshold considering how many of the confidence values predicted by the model exceed it. The relative share of these values in the entire data set is the degree of automation. The accuracy achieved during training represents the correctness. Together, the pairing of automation and correctness forms a point on the correctness automation curve." />
        }
      </div>
      <AutomationCorrectnessCurve
        curve={mlAnnotationModel?.automation_correctness_curve}
      />
      <div className=" font-medium">AI annotation setup</div>
      {renderAnnotationRunNameField()}
      {renderDataSelection()}
      {renderMinAndMaxRepeatsOverride()}

      <DataSelectionDialog
        isConfirmDialogOpen={isDataSelectionDialogOpen}
        setIsConfirmDialogOpen={setIsDataSelectionDialogOpen}
        setDataset={setDataset}
        setSubset={setSubset}
      />
    </div>
  );
};

export default AIAnnotationRunSetup;
