import _ from "lodash";
import { ReactComponent as ArrowUpIcon } from "assets/arrow_filled_up.svg";
import { ReactComponent as OkIcon } from "assets/ok.svg";

export interface TabStepperModel {
  number: number;
  label: string;
  value: string;
  active: boolean;
  isDone: boolean;
}

type Props = {
  steps: TabStepperModel[];
  activeStep: number;
  setActiveStep: (step: number) => void;
};

const TabsStepper = ({ steps, activeStep, setActiveStep }: Props) => {
  const renderSteps = () => {
    return _.map(steps, (step) => {
      // If the step is done, render the done step
      if (step.isDone) {
        return (
          <div
            className="flex flex-grow"
            key={`step-${step.number}-${step.label}`}
          >
            {renderDoneStep(step)}
            {renderArrow(step.number)}
          </div>
        );
      }

      // If the step is active, render the active step
      if (step.number === activeStep) {
        return (
          <div
            className="flex flex-grow items-center gap-x-2"
            key={`step-${step.number}-${step.label}`}
          >
            {renderActiveStep(step)}
            {renderArrow(step.number)}
          </div>
        );
      }

      // If the step is not active, render the step (default)
      return (
        <div
          className="flex flex-grow"
          key={`step-${step.number}-${step.label}`}
        >
          {renderStep(step)}
          {renderArrow(step.number)}
        </div>
      );
    });
  };

  const renderStep = (step: TabStepperModel) => {
    // Check if the step is the next step after the last done step
    // If it is, make it clickable
    const lastDoneStep = _.findLast(steps, (s) => s.isDone)?.number;
    const isNextStepAfterLastDoneStep = lastDoneStep
      ? step.number === lastDoneStep + 1
      : false;

    return (
      <div className="flex-grow flex flex-col gap-y-1">
        <div
          className={`text-paletteGray-13 ${
            isNextStepAfterLastDoneStep
              ? "cursor-pointer hover:text-paletteGray-7"
              : ""
          }`}
          onClick={() =>
            isNextStepAfterLastDoneStep && setActiveStep(step.number)
          }
        >
          {step?.label}
        </div>
        <div
          className={`w-full h-[6px] rounded-full border
          ${
            isNextStepAfterLastDoneStep
              ? "border-palettePurple"
              : "border-paletteGray-5"
          }
        `}
        />
        <div className="text-sm text-paletteGray-10">Step {step?.number}</div>
      </div>
    );
  };

  const renderActiveStep = (step: TabStepperModel) => {
    return (
      <div className="flex-grow flex flex-col gap-y-1">
        <div className="text-paletteBlack-2 font-bold">{step?.label}</div>
        <div className="w-full h-[6px] rounded-full border border-palettePurple bg-palettePurple" />
        <div className="text-sm text-paletteGray-10">Step {step?.number}</div>
      </div>
    );
  };

  const renderDoneStep = (step: TabStepperModel) => {
    return (
      <div className="flex-grow flex flex-col gap-y-1">
        <div
          className="flex gap-x-2 items-center cursor-pointer"
          onClick={() => setActiveStep(step.number)}
        >
          <div className="text-paletteBlack-2 hover:text-paletteGray-7">
            {step?.label}
          </div>
          <OkIcon className="w-[18px] h-[18px]" />
        </div>
        <div className="w-full h-[6px] rounded-full border border-paletteBlack-1 bg-paletteBlack-1" />
        <div className="text-sm text-paletteGray-10">Step {step?.number}</div>
      </div>
    );
  };

  const renderArrow = (stepNumber: number) => {
    if (stepNumber === steps.length) {
      return null;
    }

    return (
      <ArrowUpIcon
        className="text-paletteGray-5 w-[16px] h-[16px]"
        transform="rotate(90)"
        key={`arrow-${stepNumber}`}
      />
    );
  };

  return (
    <div className="w-full flex flex-row items-center gap-x-3">
      {renderSteps()}
    </div>
  );
};

export default TabsStepper;
