import _ from "lodash";
import Popover, { PopoverOrigin } from "@mui/material/Popover";

/**
 * StandardPopover is a popover with a button to open it and a list of
 *  items to show in it.
 * @param id: popover id
 * @param anchorEl: popover anchor element
 * @param handlePopoverOpen: popover open handler
 * @param handlePopoverClose: popover close handler
 * If buttonElement is undefined, buttonTitle will be shown in popover
 * @param buttonElement: button element to open popover
 * @param buttonTitle: button title to open popover
 * If itemsList is undefined, children will be shown in popover
 * @param itemsList: list of items to show in popover
 * @param children: children to show in popover
 */
export type StandardPopoverProps = {
  id: string;
  anchorEl: HTMLElement | null;
  handlePopoverOpen: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string,
  ) => void;
  handlePopoverClose: (id: string) => void;
  buttonElement?: JSX.Element;
  buttonTitle?: string;
  itemsList?: {
    label: string;
    className?: string;
    onItemClick?: (label: string) => void;
    icon?: JSX.Element;
    preventPopoverCloseOnClick?: boolean;
    disabled?: boolean;
  }[];
  children?: JSX.Element;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  // Right and bottom are negative values
  offSetPopover?: {
    top?: number;
    left?: number;
  };
};

const StandardPopover = ({
  id,
  anchorEl,
  handlePopoverOpen,
  handlePopoverClose,
  buttonElement,
  buttonTitle = "Open popover",
  itemsList,
  children = <></>,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  offSetPopover,
}: StandardPopoverProps) => {
  // Open popover if anchorEl is not null
  const open = Boolean(anchorEl);

  // Show buttonElement or buttonTitle
  const renderOpenButton = () => {
    if (buttonElement) {
      return (
        <div
          onClick={(event) => {
            event.stopPropagation();
            handlePopoverOpen(event, id);
          }}
        >
          {buttonElement}
        </div>
      );
    } else {
      return (
        <button
          className={`button-layer ${
            !_.isNull(anchorEl) ? "button-layer-active" : ""
          }`}
          onClick={(event) => {
            event.stopPropagation();
            handlePopoverOpen(event, id);
          }}
        >
          {buttonTitle}
        </button>
      );
    }
  };

  // Show itemsList or children
  const renderItems = () => {
    if (!_.isUndefined(itemsList)) {
      return itemsList.map((item, index) => {
        return (
          <button
            key={index}
            className={`button-select-layer ${item.className} gap-x-3`}
            onClick={(event) => {
              event.stopPropagation();
              if (
                _.isUndefined(item.preventPopoverCloseOnClick) ||
                !item.preventPopoverCloseOnClick
              ) {
                handlePopoverClose(id);
              }
              item?.onItemClick && item.onItemClick(item.label);
            }}
            disabled={item?.disabled}
          >
            {item.icon}
            {item.label}
          </button>
        );
      });
    } else {
      return children;
    }
  };

  return (
    <div>
      {renderOpenButton()}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handlePopoverClose(id)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        PaperProps={{
          classes: {
            root: "rounded-lg shadow-dropdown",
          },
        }}
        style={{
          marginLeft: offSetPopover?.left ? `${offSetPopover?.left}px` : "",
          marginTop: offSetPopover?.top ? `${offSetPopover?.top}px` : "",
        }}
      >
        <div className="p-1" data-test="popover">
          {renderItems()}
        </div>
      </Popover>
    </div>
  );
};

export default StandardPopover;
