import { useState } from "react";
import _, { find } from "lodash";
import IsNotPopover, {
  IsNotType,
} from "components/Internal/Filters/HelperComponents/IsNotPopover";
import { useAppSelector } from "store/hooks";
import { AnnotatableEnum } from "models/global.model";
import selectMediatype from "helpers/functions/selectMediatype";
import TooltipTruncate from "components/Internal/Tooltips/TooltipTruncate";

interface Props {
  attribute: any;
  handleFilterChange: (newVal: any) => any;
}

const CategoricalSwitch = ({ attribute, handleFilterChange }: Props) => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype
  );

  const [anchorIsNotEl, setAnchorIsNotEl] = useState<{
    [key: string]: HTMLElement | null;
  }>({});

  const renderCategory = (category: string) => {
    const categoryFreq = find(
      attribute?.histogram,
      (i: any) => i?.bin_str === category
    )?.freq;

    return (
      <div
        key={`${attribute?.id}_${category}`}
        className="p-1 flex justify-between items-center rounded-lg bg-paletteGray-2"
      >
        <div className="flex gap-x-1 items-center">
          <div
            className={`px-2 py-[3.5px] rounded-lg animate-fade ${
              _.includes(attribute?.selected_cats, category) ||
              _.includes(attribute?.not_selected_cats, category)
                ? "bg-paletteOrange text-white"
                : "bg-paletteGray-5 text-paletteBlack-2"
            }`}
          >
            <TooltipTruncate>{_.upperFirst(category)}</TooltipTruncate>
          </div>
          <div className="px-1 text-paletteGray-10">
            {categoryFreq} {renderCategoryLabel()}
          </div>
        </div>
        <IsNotPopover
          anchorEl={anchorIsNotEl?.[category]}
          handlePopoverOpen={(
            event: React.MouseEvent<HTMLElement, MouseEvent>
          ) =>
            setAnchorIsNotEl({
              ...anchorIsNotEl,
              [category]: event.currentTarget,
            })
          }
          handlePopoverClose={() =>
            setAnchorIsNotEl({ ...anchorIsNotEl, [category]: null })
          }
          selectedValue={getValue(category)}
          inCludeOff={true}
          onClick={(type) => handleChange(type, category)}
        />
      </div>
    );
  };

  const renderCategoryLabel = () => {
    switch (selectedView) {
      case AnnotatableEnum.Media:
        return selectMediatype(mediatype) + "s";
      case AnnotatableEnum.Instance:
        return AnnotatableEnum.Instance;
      case AnnotatableEnum.MediaObject:
        return AnnotatableEnum.MediaObject;
    }
  };

  const getValue = (category: string): IsNotType => {
    let defaultVal: IsNotType = "off";
    if (_.includes(attribute?.selected_cats, category)) {
      defaultVal = "is";
    } else if (_.includes(attribute?.not_selected_cats, category)) {
      defaultVal = "not";
    }
    return defaultVal;
  };

  const handleChange = (type: IsNotType, category: string) => {
    let newSelectedCats = attribute?.selected_cats;
    let newNotSelectedCats = attribute?.not_selected_cats;

    // If the value is null, then we want to remove the category from both lists
    if (type === "off") {
      newSelectedCats = _.filter(
        attribute?.selected_cats,
        (cat: string) => cat !== category
      );
      newNotSelectedCats = _.filter(
        attribute?.not_selected_cats,
        (cat: string) => cat !== category
      );
    }
    // If the value is true, then we want to remove the category from the
    // not_selected_cats list and add it to the selected_cats list
    else if (type === "is") {
      newNotSelectedCats = _.filter(
        attribute?.not_selected_cats,
        (cat: string) => cat !== category
      );
      newSelectedCats = [...attribute?.selected_cats, category];
    }
    // If the value is false, then we want to remove the category from the
    // selected_cats list and add it to the not_selected_cats list
    else if (type === "not") {
      newSelectedCats = _.filter(
        attribute?.selected_cats,
        (cat: string) => cat !== category
      );
      newNotSelectedCats = [...attribute?.not_selected_cats, category];
    }

    handleFilterChange({
      selected_cats: newSelectedCats,
      not_selected_cats: newNotSelectedCats,
    });
  };

  return (
    <div className="flex flex-col gap-y-1">
      {_.map(attribute?.categories, (category: string) => {
        return renderCategory(category);
      })}
    </div>
  );
};

export default CategoricalSwitch;
