import Keycloak from "keycloak-js";

process.env.REACT_APP_AUTH_SERVER_URL ??
  alert("Config value 'REACT_APP_AUTH_SERVER_URL' is undefined");

// Setup Keycloak instance as needed
const keycloakConfig = {
  realm: "BBQ",
  url: process.env.REACT_APP_AUTH_SERVER_URL,
  clientId: "baked_beans_frontend",
  sslRequired: "external",
  publicClient: true,
  confidentialPort: 0,
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
