import { Dialog } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { ReactComponent as AiIcon } from "assets/ai.svg";
import { ReactComponent as OkIcon } from "assets/ok.svg";
import { ReactComponent as WarningIcon } from "assets/warning.svg";

import { DatasetModel } from "models/dataset.model";
import { useEffect, useState } from "react";
import { SingleSliderFieldModel } from "models/form.model";
import SingleSliderInput from "components/Internal/Inputs/Form/SingleSliderInput";
import _ from "lodash";
import InfoAccordion from "components/Internal/Accordion/InfoAccordion";
import DescriptionComponent from "components/Internal/RJSF/DescriptionComponent";

interface Props {
  isDialogOpen: boolean;
  setIsDialogOpen: (value: boolean) => void;
  subset: DatasetModel | undefined;
  onSplitSubset: (
    aiSubsetName: string,
    referenceSubsetName: string,
    subsetSize: number,
  ) => void;
  isLoading?: boolean;
}

const SplitDataDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  subset,
  onSplitSubset,
  isLoading,
}: Props) => {
  const [aiSubsetName, setAiSubsetName] = useState<string>("");
  const [referenceSubsetName, setReferenceSubsetName] = useState<string>("");
  const [subsetSize, setSubsetSize] = useState<SingleSliderFieldModel>({
    type: "single_slider",
    key: "subset_size",
    value: 0,
    label: "Reference subset size",
    min: 0,
    max: 1,
    settings: {
      renderNumberInput: true,
      labelUnderSlider: "Objects",
    },
  });

  useEffect(() => {
    setAiSubsetName(`${subset?.name}_ai_annotation_set`);
    setReferenceSubsetName(`${subset?.name}_reference_set`);
    setSubsetSize({
      ...subsetSize,
      value:
        subset?.num_media_objects &&
        subset?.num_media_objects >= subsetSizeThreshold
          ? subsetSizeThreshold
          : 0,
      max: subset?.num_media_objects || 0,
    });
  }, [subset]);

  const renderIsSubsetSizeValid = () => {
    return (
      <div className="flex items-center gap-x-2 text-sm text-paletteGray-12">
        {subsetSize.value >= subsetSizeThreshold ? (
          <>
            <OkIcon /> Subset size is valid
          </>
        ) : (
          <>
            <WarningIcon className="w-[22px] h-[22px] text-paletteYellow" />{" "}
            Subset size is not recommended
          </>
        )}
      </div>
    );
  };

  return (
    <Dialog
      maxWidth="sm"
      open={isDialogOpen}
      onClose={() => !isLoading && setIsDialogOpen(false)}
    >
      <div className=" w-[532px] px-6 py-5 flex flex-col gap-y-5">
        {/* Header */}
        <div className="flex justify-between">
          <div className="text-xl text-paletteBlack-2">AI data preparation</div>
          <div
            className="p-1 cursor-pointer"
            onClick={() => setIsDialogOpen(false)}
          >
            <CloseIcon className="w-3 h-3 text-paletteGray-9" />
          </div>
        </div>

        {/* Info */}
        <InfoAccordion
          alwaysShownSection={infoAlwaysShown}
          expandedSection={infoExpanded}
          icon={<AiIcon className="w-[18px] h-[18px] text-palettePurple " />}
        />

        {/* Subset label */}
        <div className="flex gap-x-2 items-center">
          <div className="label-layer rounded-xl px-3 py-[5.5px]">
            {subset?.name}
          </div>
          <div
            className="text-sm text-paletteGray-11"
            data-test="subset_split_objects_number"
          >
            {subset?.num_media_objects} objects
          </div>
        </div>

        {/* Subset size */}
        <SingleSliderInput
          field={{
            ...subsetSize,
            style: {
              track:
                subsetSize.value >= subsetSizeThreshold
                  ? "!bg-paletteGreen"
                  : "!bg-paletteRed",
            },
            disabled: isLoading,
          }}
          value={subsetSize?.value}
          handleOnChange={(event, field, isErrored, error) => {
            setSubsetSize({
              ...subsetSize,
              value: parseFloat(event.target.value),
              isErrored: isErrored || false,
              error: error || null,
            });
          }}
        />
        {renderIsSubsetSizeValid()}

        {/* Training subset name */}
        <div className="flex justify-between items-center">
          <div
            className="w-1/2 flex text-paletteBlack-2 gap-x-1"
            data-test="training_subset_name_input"
          >
            Reference subset name
            {
              <DescriptionComponent description="Here, one can set the name of the subset that should be used for the AI model training. During the training, this subset will be further split into training, test and validation subsets." />
            }
          </div>
          <input
            type="text"
            placeholder="Reference Subset Name"
            className="!w-1/2"
            value={referenceSubsetName}
            onChange={(e) => setReferenceSubsetName(e.target.value)}
          />
        </div>

        {/* AI annotation subset name */}
        <div className="flex justify-between items-center">
          <div className="w-1/2 flex text-paletteBlack-2 gap-x-1">
            AI Annotation subset name
            {
              <DescriptionComponent description="Here, one can set the name of the subset that should be annotated by the AI model. The size of this subset is the size of the subset before the split minus the size of the reference subset." />
            }
          </div>
          <input
            type="text"
            placeholder="AI Subset Name"
            className="!w-1/2"
            data-test="ai_subset_name_input"
            value={aiSubsetName}
            onChange={(e) => setAiSubsetName(e.target.value)}
          />
        </div>

        {/* Actions */}
        <div className="pt-2 flex justify-end">
          <div className="flex items-center gap-x-1">
            <button
              className="button-layer bg-transparent"
              data-test="cancel_split_button"
              onClick={() => setIsDialogOpen(false)}
            >
              Cancel
            </button>
            <button
              className="button-dark-gray-layer w-fit"
              data-test="split_subset_button"
              onClick={() =>
                onSplitSubset(
                  aiSubsetName,
                  referenceSubsetName,
                  subsetSize.value,
                )
              }
              disabled={
                isLoading ||
                _.isNaN(subsetSize.value) ||
                aiSubsetName === "" ||
                referenceSubsetName === "" ||
                subsetSize.value > subsetSize.max
              }
            >
              Split subset
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SplitDataDialog;

const subsetSizeThreshold = 16000;
const infoAlwaysShown =
  "Before machine annotations can be started, the model must first be trained and evaluated on a small set of human annotated examples. Select below how many of the objects in the subset are to be used for training.";
const infoExpanded =
  "The size of the training data set determines the quality with which the machine can approximate the behavior of the human annotation crowd. A larger number of training examples means that the model can make more accurate predictions for the auto-annotations of the remaining data after it has been trained.";
