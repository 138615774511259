import _ from "lodash";
import {
  ActiveFiltersModel,
  SendFilterComparisonModel,
  SendFilterLogicModel,
  SendFilterModel,
} from "models/filter.model";
import { QueryOperatorModel } from "models/global.model";

const convertActiveFilterToSendFilter = (
  activeFilters: ActiveFiltersModel
): SendFilterModel[] => {
  let sendFilters: SendFilterModel[] = [];
  _.map(activeFilters, (activeFilter) => {
    switch (activeFilter?.type) {
      case "CATEGORICAL": {
        const selected_cats = activeFilter?.categories_value?.selected_cats;
        const not_selected_cats =
          activeFilter?.categories_value?.not_selected_cats;

        if (selected_cats && !_.isEmpty(selected_cats)) {
          const categoricalFilter: SendFilterComparisonModel = {
            attribute: activeFilter?.key,
            query_operator: "in",
            value: selected_cats,
          };
          sendFilters = [...sendFilters, categoricalFilter];
        }
        if (not_selected_cats && !_.isEmpty(not_selected_cats)) {
          const categoricalFilter: SendFilterComparisonModel = {
            attribute: activeFilter?.key,
            query_operator: "not in",
            value: not_selected_cats,
          };
          sendFilters = [...sendFilters, categoricalFilter];
        }
        break;
      }
      case "SEARCH_BY_TEXT":
      case "SUBSET":
      case "FE_SUBSET":
      case "FE_TAG":
      case "BOOLEAN": {
        let operator: QueryOperatorModel = "in";
        if (activeFilter?.is_not === true) {
          operator = "not in";
        }
        const activeFilterToAdd: SendFilterComparisonModel = {
          attribute: activeFilter?.key,
          query_operator: operator,
          value: activeFilter?.value,
        };
        sendFilters = [...sendFilters, activeFilterToAdd];
        break;
      }
      case "NUMERICAL":
        if (_.isArray(activeFilter?.value)) {
          // If the activeFilter is a normal activeFilter
          if (activeFilter?.is_not === false) {
            let lowerOperator: QueryOperatorModel = ">=";
            let upperOperator: QueryOperatorModel = "<=";
            // If the activeFilter is not including the edges
            if (activeFilter?.include_edges === false) {
              lowerOperator = ">";
              upperOperator = "<";
            }
            const lowerNumericalFilter: SendFilterComparisonModel = {
              attribute: activeFilter?.key,
              query_operator: lowerOperator,
              value: activeFilter?.value?.[0],
            };
            const upperNumericalFilter: SendFilterComparisonModel = {
              attribute: activeFilter?.key,
              query_operator: upperOperator,
              value: activeFilter?.value?.[1],
            };
            sendFilters = [
              ...sendFilters,
              lowerNumericalFilter,
              upperNumericalFilter,
            ];
          }
          // If the activeFilter is a NOT activeFilter
          else if (activeFilter?.is_not === true) {
            // We flip the operators on the NOT filter and switch the
            //  equality of the edges to get the correct NOT filter
            let lowerOperator: QueryOperatorModel = "<";
            let upperOperator: QueryOperatorModel = ">";
            // If the activeFilter is not including the edges
            if (activeFilter?.include_edges === false) {
              lowerOperator = "<=";
              upperOperator = ">=";
            }

            const lowerNumericalFilter: SendFilterComparisonModel = {
              attribute: activeFilter?.key,
              query_operator: lowerOperator,
              value: activeFilter?.value?.[0],
            };
            const upperNumericalFilter: SendFilterComparisonModel = {
              attribute: activeFilter?.key,
              query_operator: upperOperator,
              value: activeFilter?.value?.[1],
            };

            let queries: SendFilterComparisonModel[] = [];
            // If the lower edge is not the same as the lower value
            // we add the lower filter
            if (activeFilter?.lower !== activeFilter?.value?.[0]) {
              queries = [...queries, lowerNumericalFilter];
            }
            // If the upper edge is not the same as the upper value
            // we add the upper filter
            if (activeFilter?.upper !== activeFilter?.value?.[1]) {
              queries = [...queries, upperNumericalFilter];
            }

            const orLogicalFilter: SendFilterLogicModel = {
              operator: "or",
              queries: queries,
            };
            sendFilters = [...sendFilters, orLogicalFilter];
          }
        }
        break;

      case "SELECT_ATTRIBUTE": {
        if (!_.isEmpty(activeFilter?.value)) {
          let operator: QueryOperatorModel = "==";
          if (activeFilter?.is_not === true) {
            operator = "!=";
          }
          const selectAttributeFilter: SendFilterComparisonModel = {
            attribute: activeFilter?.key,
            query_operator: operator,
            value: activeFilter?.value,
          };
          sendFilters = [...sendFilters, selectAttributeFilter];
        }
        break;
      }
    }
  });
  return sendFilters;
};

export default convertActiveFilterToSendFilter;
