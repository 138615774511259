// function to parse the date strings to correct format:
const generateDateString = (date: Date, include_time: boolean = true) => {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  // If time should be included, add the time options
  if (include_time) {
    options.hour = "2-digit";
    options.minute = "2-digit";
  }

  return date.toLocaleString("en-GB", options);
};

export default generateDateString;
