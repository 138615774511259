import { FormFieldModel } from "models/form.model";
import DescriptionComponent from "components/Internal/RJSF/DescriptionComponent";
import FormErrorLabel from "components/Internal/Inputs/Form/ErrorLabel";
import { validateField } from "components/Internal/Inputs/Form/formInputsHelpers";
import { transformString } from "components/utilFunctions";

type Props = {
  field: FormFieldModel;
  customLabel?: string;
};

const FormLabel = ({ field, customLabel }: Props) => {
  if (!field?.label && !customLabel) return null;

  const renderLabel = () => {
    return (
      <div className="flex gap-x-1">
        <label className="">
          {transformString(customLabel) || transformString(field?.label)}
        </label>
        {field?.required && !field?.style?.hideRequiredAsterisk && (
          <label className="text-paletteRed">*</label>
        )}
        {field?.description && (
          <DescriptionComponent description={field?.description} />
        )}
      </div>
    );
  };

  const renderError = () => {
    if (!field.style?.labelAndFieldOnTheSameLine) return null;

    return (
      <FormErrorLabel
        error={field?.error ?? null}
        validationError={validateField(field)}
      />
    );
  };

  return (
    <div className="w-full">
      {renderLabel()}
      {renderError()}
    </div>
  );
};

export default FormLabel;
