import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortingRequestModel } from "../models/exploration.model";
import { AnnotatableEnum, SelectedViewModel } from "models/global.model";

export interface sortDataStateTypes {
  [AnnotatableEnum.Media]: SortingRequestModel[];
  [AnnotatableEnum.MediaObject]: SortingRequestModel[];
  [AnnotatableEnum.Instance]: SortingRequestModel[];
  activeMedia: SortingRequestModel[];
  activeMediaObjects: SortingRequestModel[];
  activeInstances: SortingRequestModel[];
}

const initialState = {
  [AnnotatableEnum.Media]: [],
  [AnnotatableEnum.MediaObject]: [],
  [AnnotatableEnum.Instance]: [],
  activeMedia: [],
  activeMediaObjects: [],
  activeInstances: [],
} as sortDataStateTypes;

export const sortDataSlice = createSlice({
  name: "sortData",
  initialState,
  reducers: {
    setSortData: (
      state,
      action: PayloadAction<{
        selectedView: SelectedViewModel;
        data: SortingRequestModel[];
      }>
    ) => {
      state[action.payload.selectedView] = action.payload?.data;
    },
    setActiveSortData: (
      state,
      action: PayloadAction<{
        selectedView: SelectedViewModel;
        data: SortingRequestModel[];
      }>
    ) => {
      const key = determineActiveSortKeyFromParam(
        action?.payload?.selectedView
      );
      if (!key) return;
      state[key] = action.payload?.data;
    },
    resetSortDataField: (
      state,
      action: PayloadAction<
        | AnnotatableEnum.Media
        | AnnotatableEnum.MediaObject
        | AnnotatableEnum.Instance
        | "activeMedia"
        | "activeMediaObjects"
        | "activeInstances"
      >
    ) => {
      state[action?.payload] = initialState[action?.payload];
    },
    resetSortDataSlice: () => initialState,
  },
});

export const {
  setSortData,
  resetSortDataField,
  resetSortDataSlice,
  setActiveSortData,
} = sortDataSlice.actions;
export default sortDataSlice.reducer;

// Helper functions

// Determine which active sort data key to use based on the type param
export const determineActiveSortKeyFromParam = (type: SelectedViewModel) => {
  if (type === AnnotatableEnum.Media) {
    return "activeMedia";
  } else if (type === AnnotatableEnum.MediaObject) {
    return "activeMediaObjects";
  } else if (type === AnnotatableEnum.Instance) {
    return "activeInstances";
  }
};
