import { useAppDispatch, useAppSelector } from "store/hooks";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { setIsEditingUIConfig } from "store/pipelineDesignerSlice";
import { annotationUIs } from "schemas/annotationUIs";
import { UICategoriesEnum } from "schemas/annotationUIs.model";
import _ from "lodash";

const SelectedGUI = () => {
  const dispatch = useAppDispatch();
  const pipelineSlice = useAppSelector((state) => state.pipelineDesignerSlice);
  const uiCategory = pipelineSlice.uiCategory;
  const uiType = pipelineSlice.uiType;
  const mediaType = pipelineSlice.configurationsForm?.["media_type"];
  const visualisationType =
    pipelineSlice.configurationsForm?.["visualisation_type"];

  const renderField = (label: string, value: string) => {
    return (
      <div className="p-1 px-2 border-[1px] first:rounded-l last:rounded-r">
        <div className="text-sm text-paletteGray-9">{label}</div>
        <div className="text-paletteBlack-2">{_.upperFirst(value)}</div>
      </div>
    );
  };

  return (
    <div
      className="w-full pb-2 px-2 flex justify-between items-center gap-x-1 
        border-b-[1px]"
    >
      <div className="w-full flex ">
        {renderField(
          "Category",
          (uiCategory && UICategoriesEnum[uiCategory]) || "",
        )}
        {renderField("Type", (uiType && annotationUIs[uiType]?.name) || "")}
        {renderField("Media type", mediaType?.value as string)}
        {renderField("Visualisation type", visualisationType?.value as string)}
      </div>

      <button
        className="button-layer p-2 h-fit"
        data-test="edit_ui_config_pipeline_designer"
        onClick={() => dispatch(setIsEditingUIConfig(true))}
      >
        <EditIcon className="w-4 h-4" />
      </button>
    </div>
  );
};

export default SelectedGUI;
