import _ from "lodash";

const bytesConvertor = (bytes: number, decimals = 2) => {
  if (_.isNumber(bytes)) {
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const k = 1000;
    // Get the number of Ks in bytes
    let i = _.round(bytes?.toString()?.length / k?.toString()?.length);
    // Make sure i don't go over sizes length
    if (i > sizes?.length) {
      i = sizes?.length;
    }
    const convertedNumber = bytes / Math.pow(k, i);
    const convertedString = `${_.round(convertedNumber, decimals)} ${sizes[i]}`;
    return convertedString;
  }
};

export default bytesConvertor;
