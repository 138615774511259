import PopulatedDatasetTable from "components/Datasets/PopulatedDatasetTable";
import { useHistory } from "react-router";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import { useKeycloak } from "@react-keycloak/web";
import { ReactComponent as UploadIcon } from "assets/upload.svg";
import { useAppSelector } from "store/hooks";

const DatasetManager = () => {
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const dashboardData = useAppSelector((state) => state.dashboardSlice.data);

  // ------------------------------------------- Render Component ------------------------------------
  return (
    <div className="px-[72px] pt-4 mx-auto h-full bg-white flex flex-col self-center">
      <div className="h-[90%] pt-[44px] flex-1 flex flex-col">
        <div className="flex justify-between">
          <div className="flex text-3xl items-center">
            My datasets{" "}
            <div
              className="h-fit text-sm text-white bg-paletteBlack-1 rounded-[4px] ml-3 px-[7px] py-[1.5px]"
              data-test="dataset_number"
            >
              {Object.keys(dashboardData).length}
            </div>
          </div>
          {checkIfUserHavePermission(keycloak, "accessUploadPage") && (
            <button
              data-test="upload_manager"
              className="button-layer px-4 py-[9.5px] text-paletteBlack-2"
              onClick={() => history.push("/uploads")}
            >
              <UploadIcon></UploadIcon>
              Upload manager
            </button>
          )}
        </div>
        <div className="w-full h-[85%] pt-6">
          <PopulatedDatasetTable />
        </div>
      </div>
    </div>
  );
};

export default DatasetManager;
