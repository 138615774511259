import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";

import { ReactComponent as CloseIcon } from "assets/close.svg";

import { NumberFieldModel, TextFieldModel } from "models/form.model";
import {
  MLAnnotationModel,
  MLAnnotationModelStatus,
} from "models/mlAnnotationModel.model";
import TabsStepper from "components/Internal/Stepper/TabsStepper";
import AiModelDialogHelperText from "Pages/AnnotationRunManager/AiModelDialogHelperText";
import { useAppDispatch } from "store/hooks";
import { AnnotationRunModel } from "models/annotationRun.model";
import { PipelineModel, PipelineRevisionModel } from "models/pipelines.model";
import { DatasetModel } from "models/dataset.model";
import { getDataset } from "helpers/apis/datasets";
import { fetchPipeline, fetchPipelineRevision } from "helpers/apis/pipelines";
import AnnotationRunOverview from "Pages/AnnotationRunManager/AIModelDialog/AnnotationRunOverview";
import MLModelInformation from "Pages/AnnotationRunManager/AIModelDialog/MLModelInformation";
import AIModelDialogActions from "Pages/AnnotationRunManager/AIModelDialog/AIModelDialogActions";
import AIAnnotationRunSetup from "Pages/AnnotationRunManager/AIModelDialog/AIAnnotationRunSetup";
import { fetchMLAnnotationModelsOfAnAnnotationRun } from "helpers/apis/mlAnnotationModel";
import Loading from "components/UtilComponents/Loading";

type Props = {
  open: boolean;
  onClose: () => void;
  annotationRun: AnnotationRunModel;
  updateAnnotationRuns: () => void;
};

// eslint-disable-next-line no-empty-pattern
const AiModelDialog = ({
  open,
  onClose,
  annotationRun,
  updateAnnotationRuns,
}: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [showHelpDiv, setShowHelpDiv] = useState(true);

  const [steps, setSteps] = useState([
    {
      label: "Model training",
      number: 1,
      value: "Model training",
      active: true,
      isDone: false,
    },
    {
      label: "AI annotation",
      number: 2,
      value: "AI annotation",
      active: false,
      isDone: false,
    },
  ]);
  const [activeStep, setActiveStep] = useState(1);

  const [subsetOfAnnotationRun, setSubsetOfAnnotationRun] =
    useState<DatasetModel | null>(null);
  const [pipelineRevisionOfAnnotationRun, setPipelineRevisionOfAnnotationRun] =
    useState<PipelineRevisionModel | null>(null);
  const [pipelineOfAnnotationRun, setPipelineOfAnnotationRun] =
    useState<PipelineModel | null>(null);
  const [
    mlAnnotationModelOfAnnotationRun,
    setMlAnnotationModelOfAnnotationRun,
  ] = useState<MLAnnotationModel | null>(null);

  // Step 1 fields
  const [modelName, setModelName] = useState<TextFieldModel>({
    type: "text",
    value: "",
    key: "modelName",
    label: "Model Name",
    placeholder: "Name your model",
    required: true,
    style: {
      field: "h-[44px]",
      labelAndFieldOnTheSameLine: true,
      hideRequiredAsterisk: true,
    },
  });

  // Step 2 fields
  const [aiAnnotationRunName, setAIAnnotationRunName] =
    useState<TextFieldModel>({
      type: "text",
      value: "",
      key: "aiAnnotationRunName",
      placeholder: "Name your AI annotation run",
      required: true,
      style: {
        field: "py-[10px]",
        wrapper: "w-full",
      },
    });

  const [maxRepeats, setMaxRepeats] = useState<NumberFieldModel>({
    type: "number",
    value: 1,
    key: "maxRepeats",
    settings: {
      minimumValue: 1,
    },
    placeholder: "Max. repeats",
    required: true,
    style: {
      field: "h-[44px]",
    },
  });
  const [selectedDataset, setSelectedDataset] = useState<DatasetModel | null>(
    null,
  );
  const [selectedSubset, setSelectedSubset] = useState<DatasetModel | null>(
    null,
  );

  useEffect(() => {
    if (annotationRun) {
      fetchDataForDialog();
    }
  }, []);

  const fetchDataForDialog = () => {
    setIsLoading(true);
    Promise.allSettled([
      getDataset({ datasetID: annotationRun.subset_id }),
      fetchPipelineRevision(
        {
          pipelineRevisionID: annotationRun.pipeline_revision_id,
        },
        dispatch,
      ),
      fetchMLAnnotationModelsOfAnAnnotationRun({
        ann_run_id: annotationRun?.id,
      }),
    ])
      .then((results) => {
        if (results[0].status === "fulfilled") {
          setSubsetOfAnnotationRun(results[0].value);
        }
        if (results[1].status === "fulfilled") {
          setPipelineRevisionOfAnnotationRun(results[1].value);
          fetchPipeline(
            {
              pipelineID: results[1].value?.pipeline_id,
            },
            dispatch,
          ).then((pipeline) => {
            setPipelineOfAnnotationRun(pipeline);
          });
        }
        if (results[2].status === "fulfilled") {
          const latestMLModel = results[2].value?.[0];
          if (latestMLModel) {
            setMlAnnotationModelOfAnnotationRun(latestMLModel);
            setModelName({
              ...modelName,
              value: latestMLModel?.name || "",
              disabled: true,
            });
          }
          if (latestMLModel?.status === MLAnnotationModelStatus.TRAINING_DONE) {
            setActiveStep(2);
            const newSteps = steps.map((step) => {
              if (step.number === 1) {
                return { ...step, isDone: true };
              }
              return step;
            });
            setSteps(newSteps);
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const renderCloseButton = () => {
    return (
      <div className="absolute top-[16px] right-[16px]">
        <button
          className="bg-paletteGray-2 text-grey9 w-[24px] h-[24px] rounded-[4px] p-[6px]"
          onClick={onClose}
        >
          <CloseIcon className="w-3 h-3" />
        </button>
      </div>
    );
  };

  const renderMainBody = () => {
    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className="flex flex-col h-full w-full">
        <p className="text-paletteBlack-2 text-xl pb-[24px]">AI Model setup</p>
        <div className="flex-grow">
          <div className="pb-8">
            <TabsStepper
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </div>
          {activeStep === 1 && renderFirstStep()}
          {activeStep === 2 && renderSecondStep()}
        </div>
        <AIModelDialogActions
          annotationRun={annotationRun}
          mlAnnotationModel={mlAnnotationModelOfAnnotationRun}
          updateData={setMlAnnotationModelOfAnnotationRun}
          modelName={modelName}
          setModelName={setModelName}
          annotationRunName={aiAnnotationRunName?.value}
          dataset={selectedDataset}
          subset={selectedSubset}
          maxRepeatsOverride={maxRepeats?.value}
          step={activeStep}
          setStep={setActiveStep}
          showHelpDiv={showHelpDiv}
          setShowHelpDiv={setShowHelpDiv}
          onClose={onClose}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          updateAnnotationRuns={updateAnnotationRuns}
        />
      </div>
    );
  };

  // Render the first step of the dialog: ML model training.
  const renderFirstStep = () => {
    return (
      <div className="overflow-auto">
        <MLModelInformation
          mlAnnotationModelOfAnnotationRun={mlAnnotationModelOfAnnotationRun}
          modelName={modelName}
          setModelName={setModelName}
        />
        <AnnotationRunOverview
          annotationRun={annotationRun}
          subsetOfAnnotationRun={subsetOfAnnotationRun}
          pipeline={pipelineOfAnnotationRun}
          pipelineRevisionOfAnnotationRun={pipelineRevisionOfAnnotationRun}
        />
      </div>
    );
  };

  const renderSecondStep = () => {
    return (
      <div>
        <AIAnnotationRunSetup
          trainingAnnotationRun={annotationRun}
          trainingPipelineRevision={pipelineRevisionOfAnnotationRun}
          aiAnnotationRunName={aiAnnotationRunName}
          setAIAnnotationRunName={setAIAnnotationRunName}
          dataset={selectedDataset}
          setDataset={setSelectedDataset}
          subset={selectedSubset}
          setSubset={setSelectedSubset}
          maxRepeats={maxRepeats}
          setMaxRepeats={setMaxRepeats}
          mlAnnotationModel={mlAnnotationModelOfAnnotationRun}
        />
      </div>
    );
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={showHelpDiv ? "md" : "sm"}
      open={open}
      onClose={onClose}
      PaperProps={{ style: { borderRadius: "16px" } }}
    >
      <div className="p-6 overflow-auto h-full">
        {renderCloseButton()}

        <div className="h-full w-full flex flex-row gap-x-[24px]">
          {/* Helper text */}
          <div className="w-[350px]" hidden={!showHelpDiv}>
            <AiModelDialogHelperText />
          </div>

          {/* Main Body */}
          <div className=" min-w-0 flex-1">{renderMainBody()}</div>
        </div>
      </div>
    </Dialog>
  );
};

export default AiModelDialog;
