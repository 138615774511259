import { Dialog } from "@mui/material";
import DatasetSelectionTable from "components/Internal/Table/DatasetSelectionTable";
import { DatasetModel } from "models/dataset.model";
import { useState } from "react";

type Props = {
  isConfirmDialogOpen: boolean;
  setIsConfirmDialogOpen: (isOpen: boolean) => void;
  setDataset: (dataset: DatasetModel | null) => void;
  setSubset: (subset: DatasetModel | null) => void;
};

const DataSelectionDialog = ({
  isConfirmDialogOpen,
  setIsConfirmDialogOpen,
  setDataset,
  setSubset,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [localDataset, setLocalDataset] = useState<DatasetModel | null>(null);
  const [localSubset, setLocalSubset] = useState<DatasetModel | null>(null);

  const renderSelectButton = () => {
    const isDisabled = !localDataset || !localSubset;
    return (
      <button
        className="button-layer"
        onClick={handleConfirm}
        disabled={isDisabled}
      >
        Select data
      </button>
    );
  };

  const renderCancelButton = () => {
    return (
      <button
        className="button-layer bg-transparent"
        onClick={() => setIsConfirmDialogOpen(false)}
      >
        Cancel
      </button>
    );
  };

  const handleRowClick = (params: {
    clickedDataset: DatasetModel | null;
    clickedSubset: DatasetModel | null;
  }) => {
    setLocalDataset(params?.clickedDataset);
    setLocalSubset(params?.clickedSubset);
  };

  const handleConfirm = () => {
    setDataset(localDataset);
    setSubset(localSubset);
    setIsConfirmDialogOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isConfirmDialogOpen}
      onClose={() => !isLoading && setIsConfirmDialogOpen(false)}
      PaperProps={{ style: { borderRadius: "16px" } }}
    >
      <div className="w-full h-[650px]">
        <DatasetSelectionTable
          selectedDataset={localDataset}
          setSelectedDataset={setLocalDataset}
          selectedSubset={localSubset}
          setSelectedSubset={setLocalSubset}
          onSubRowClick={handleRowClick}
        />
      </div>
      <div
        className="py-4 px-5 flex items-center justify-end gap-x-4
              border-t-[1px] border-paletteGray-4"
      >
        {renderCancelButton()}
        {renderSelectButton()}
      </div>
    </Dialog>
  );
};

export default DataSelectionDialog;
