import { useState } from "react";
import { useAppSelector } from "store/hooks";
import SelectDataDialog from "components/Internal/Dialogs/SelectDataDialog";

const SelectData = () => {
  const pipelineDesignerData = useAppSelector(
    (state) => state.pipelineDesignerSlice,
  );

  const [showSelectDataDialog, setShowSelectDataDialog] = useState(false);

  const renderInfoText = () => {
    return <div className=" text-paletteBlack-2">Data</div>;
  };

  const renderSelectedData = () => {
    if (
      pipelineDesignerData.dataset === null ||
      pipelineDesignerData.subset === null
    ) {
      return renderSelectDataButton();
    }

    return renderSelectedDataText();
  };

  const renderSelectDataButton = () => {
    return (
      <button
        className="button-layer"
        data-test="select_data_button_pipeline_designer"
        onClick={() => setShowSelectDataDialog(true)}
      >
        Select data
        {pipelineDesignerData.validation.isDataSelected !== true && (
          <span className="text-paletteRed">*</span>
        )}
      </button>
    );
  };

  const renderSelectedDataText = () => {
    return (
      <div
        className="cursor-pointer hover:underline"
        onClick={() => setShowSelectDataDialog(true)}
      >
        <div className="flex justify-end font-medium">
          {pipelineDesignerData.subset?.name}
        </div>
        <div className="flex justify-end text-sm text-paletteGray-11">
          {pipelineDesignerData.subset?.num_medias} medias -{" "}
          {pipelineDesignerData.subset?.num_media_objects} objects
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center justify-between">
        {renderInfoText()}
        {renderSelectedData()}
      </div>

      {showSelectDataDialog && (
        <SelectDataDialog
          openDialog={showSelectDataDialog}
          setOpenDialog={setShowSelectDataDialog}
        />
      )}
    </>
  );
};

export default SelectData;
