import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { Tooltip } from "@mui/material";
import SelectInitialAttributeDialog from "components/Internal/Dialogs/SelectInitialAttributeDialog";

const SelectInitialAttribute = () => {
  const pipelineDesignerData = useAppSelector(
    (state) => state.pipelineDesignerSlice,
  );

  const [showSelectDataDialog, setShowSelectDataDialog] = useState(false);

  const isDataSelected = pipelineDesignerData.dataset !== null;

  const renderInfoText = () => {
    return <div className=" text-paletteBlack-2">Initial attribute</div>;
  };

  // Render select initial attribute button or selected initial attribute text
  const renderSelectedInitialAttribute = () => {
    if (pipelineDesignerData.initialAttribute === null) {
      return renderSelectInitialAttribute();
    }

    return renderSelectedInitialAttributeText();
  };

  // Render selected initial attribute text
  const renderSelectedInitialAttributeText = () => {
    return (
      <div
        className="cursor-pointer hover:underline"
        onClick={() => setShowSelectDataDialog(true)}
      >
        <div className="flex justify-end">
          {pipelineDesignerData.initialAttribute?.name}
        </div>
      </div>
    );
  };

  // Render select initial attribute button or button with tooltip
  const renderSelectInitialAttribute = () => {
    if (!isDataSelected) {
      return renderButtonWithTooltip();
    }
    return renderSelectDataButton();
  };

  // Render select data button
  const renderSelectDataButton = () => {
    return (
      <button
        className="button-layer"
        onClick={() => setShowSelectDataDialog(true)}
      >
        Select initial attribute
      </button>
    );
  };

  // Render button with tooltip
  const renderButtonWithTooltip = () => {
    return (
      <Tooltip placement="left" title={renderTooltipTitle()}>
        <span>
          <button
            className="button-layer"
            onClick={() => setShowSelectDataDialog(true)}
            disabled={!isDataSelected}
          >
            Select initial attribute
          </button>
        </span>
      </Tooltip>
    );
  };

  // Render tooltip title
  const renderTooltipTitle = () => {
    return (
      <div className=" text-sm">
        <div className="text-sm">
          {!pipelineDesignerData.dataset &&
            "Select data first to select initial attribute"}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center justify-between">
        {renderInfoText()}
        {renderSelectedInitialAttribute()}
      </div>

      {showSelectDataDialog && (
        <SelectInitialAttributeDialog
          openDialog={showSelectDataDialog}
          setOpenDialog={setShowSelectDataDialog}
        />
      )}
    </>
  );
};

export default SelectInitialAttribute;
