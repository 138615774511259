import { useState, useRef, useEffect } from "react";
import { isEmpty, map, orderBy, size } from "lodash";
import { FormatListBulleted } from "@mui/icons-material";

import TooltipTruncate from "components/Internal/Tooltips/TooltipTruncate";

const borderPX = 2;
const marginPX = 8;

interface Props {
  row: any;
  columns: any;
  onRowClick?: any;
  subRows?: any;
  subColumns?: any;
  fontSize?: "0" | "1" | "2" | "3" | "4";
  hover?: boolean;
}

const TableRow = ({
  row,
  columns,
  onRowClick,
  subRows,
  subColumns,
  fontSize = "1",
  hover = true,
}: Props) => {
  const [showSubRows, setShowSubRows] = useState(false);

  // Vertical tree line height

  const [treeHeight, setTreeHeight] = useState(1);

  // Height of the subrow (used to find the offset for the Horizontal tree lines)
  const subrowRef: any = useRef(null);
  const [subRowHeight, setSubRowHeight] = useState(0);

  // Calculate the tree height and the subrow height (offset)
  useEffect(() => {
    setSubRowHeight(subrowRef?.current?.clientHeight);

    setTreeHeight(
      size(subRows) * subrowRef?.current?.clientHeight +
        size(subRows) * borderPX +
        size(subRows) * marginPX -
        subrowRef?.current?.clientHeight / 2 -
        borderPX / 2 +
        1
    );
  }, [showSubRows, subRows]);

  const renderSubRow = () => {
    const sortedSubRows = orderBy(subRows, ["name"], ["asc"]);
    let counter = 0;
    return map(sortedSubRows, (subRow, key) => {
      counter += 1;
      return (
        <div
          ref={counter === 1 ? subrowRef : null}
          key={key}
          className={`w-full float-right flex bg-transparent rounded-lg p-3 my-1 border border-paletteGray-4
                                text-base  font-medium cursor-pointer ${
                                  hover && "hover:bg-paletteGray-2 "
                                } animate-fade`}
        >
          {map(subColumns, (col: any, key2: number) => {
            // Subset indent
            let width = col?.span;
            if (key2 === 0) {
              width -= 1.5;
            }
            return (
              <div
                key={key2}
                className={`${col?.className} truncate`}
                style={{ width: `${width}%` }}
                onClick={() => callBackFunction(subRow, col)}
              >
                {col?.cell ? col?.cell(subRow) : subRow[col?.field]}
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderField = (col: any, row: any) => {
    // Key to open/close subset
    if (col?.onClick === "show_hide_subset") {
      if (!isEmpty(subRows)) {
        return (
          <div className="flex justify-center items-center h-full">
            <FormatListBulleted />
          </div>
        );
      }
    }
    // Else render the cell
    else {
      return renderCell(col, row);
    }
  };

  const renderCell = (col: any, row: any) => {
    // Render a custom cell, or return the string
    if (col?.cell) {
      return col?.cell(row);
    }
    return <TooltipTruncate>{row[col?.field]}</TooltipTruncate>;
  };

  const renderTreeLines = () => {
    let counter = 1;
    return map(subRows, (subrow, key) => {
      const y = `${
        counter * (subRowHeight + borderPX + marginPX) -
        subRowHeight / 2 -
        borderPX / 2 -
        marginPX / 2 +
        4
      }px`;
      counter += 1;
      return (
        <line
          key={key}
          x1="50%"
          y1={y}
          x2="100%"
          y2={y}
          stroke="darkgray"
          strokeWidth="2"
        />
      );
    });
  };

  const callBackFunction = (row: any, col: any) => {
    if (col?.onClick) {
      if (col?.onClick === "show_hide_subset") {
        if (!isEmpty(subRows)) {
          setShowSubRows(!showSubRows);
        }
      } else {
        col?.onClick(row);
      }
    }
  };

  return (
    <div>
      <div
        className={`w-full flex bg-transparent rounded-lg p-3 my-1 border border-paletteGray-4 
                            text-base  font-medium ${
                              hover && "hover:bg-paletteGray-2"
                            }`}
        onClick={
          onRowClick
            ? () => onRowClick(row)
            : () => {
                /* do nothing */
              }
        }
      >
        {map(columns, (col: any, key) => {
          return (
            <div
              key={key}
              className={`flex items-center truncate text-base ${
                col?.className
              } ${col?.onClick ? "cursor-pointer" : ""}`}
              style={{ width: `${col?.span}%` }}
              onClick={() => callBackFunction(row, col)}
            >
              {renderField(col, row)}
            </div>
          );
        })}
      </div>
      {showSubRows ? (
        <div className="flex w-full">
          <div
            style={{ width: "2%", maxWidth: "2%", height: `${treeHeight}px` }}
            className=" flex items-center"
          >
            <svg height="100%" width="100%">
              <line
                x1="50%"
                y1="0"
                x2="50%"
                y2={`${treeHeight}px`}
                stroke="darkgray"
                strokeWidth="2"
              />
              {renderTreeLines()}
            </svg>
          </div>
          <div style={{ width: "98%" }}>
            {showSubRows ? renderSubRow() : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TableRow;
