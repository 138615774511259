import { useState } from "react";
import StandardPopover from "components/Internal/Popovers/StandardPopover";
import CreateSubsetDialog from "components/Internal/Dialogs/CreateSubsetDialog";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { SelectedItemsTypeModel } from "models/global.model";
import { getSelectedItemsBasedOnSelectedView } from "helpers/functions/selectedViewHelpers";
import { ReactComponent as SubsetSquareIcon } from "assets/subset_square.svg";
import _ from "lodash";
import { useAppSelector } from "store/hooks";

type Props = {
  anchorEl: HTMLElement | null;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handlePopoverClose: () => void;
};

const SubsetCreationPopover = ({
  anchorEl,
  handlePopoverOpen,
  handlePopoverClose,
}: Props) => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const selectedMedias = useAppSelector(
    (state) => state.explorationMediaSlice.selectedMediaIDs,
  );
  const selectedMediaObjects = useAppSelector(
    (state) => state.explorationMediaObjectsSlice.selectedMediaObjectsIDs,
  );
  const selectedInstances = useAppSelector(
    (state) => state.explorationInstanceSlice.selectedInstanceIDs,
  );

  const [openCreateSubsetDialog, setOpenCreateSubsetDialog] = useState(false);
  const [selectedItemsType, setSelectedItemsType] =
    useState<SelectedItemsTypeModel>(SelectedItemsTypeModel.all);

  const handleOpenDialogAndClosePopover = () => {
    setOpenCreateSubsetDialog(true);
    handlePopoverClose();
  };

  const renderButtonElement = () => {
    return (
      <button
        className={`button-layer py-[9.5px] ${
          !_.isNull(anchorEl) ? "button-layer-active" : ""
        }`}
        data-test="create_subset"
      >
        <SubsetSquareIcon width={16} height={16} />
        Create subset
      </button>
    );
  };
  const selectedItemsLength = getSelectedItemsBasedOnSelectedView(
    selectedView,
    selectedMedias,
    selectedMediaObjects,
    selectedInstances,
  )?.length;

  return (
    <>
      <StandardPopover
        id="create_subset_popover_id"
        anchorEl={anchorEl}
        handlePopoverOpen={handlePopoverOpen}
        handlePopoverClose={handlePopoverClose}
        buttonElement={renderButtonElement()}
        itemsList={[
          {
            label: "Create subset from items",
            onItemClick: () => {
              setSelectedItemsType(SelectedItemsTypeModel.all);
              handleOpenDialogAndClosePopover();
            },
          },
          {
            label: "Create subset from selected items",
            onItemClick: () => {
              setSelectedItemsType(SelectedItemsTypeModel.selected);
              handleOpenDialogAndClosePopover();
            },
            disabled: selectedItemsLength === 0,
          },
        ]}
      />

      {openCreateSubsetDialog && (
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={openCreateSubsetDialog}
          onClose={() => setOpenCreateSubsetDialog(false)}
        >
          <DialogContent>
            <CreateSubsetDialog
              selectedItemsType={selectedItemsType}
              setIsSubsetDialogOpen={setOpenCreateSubsetDialog}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default SubsetCreationPopover;
