import addSubsetToQuery from "helpers/functions/addSubsetToQuery";
import convertActiveFilterToSendFilter from "helpers/functions/filters/convertActiveFilterToSendFilter";
import { SendFilterModel } from "models/filter.model";
import { SelectedViewModel } from "models/global.model";
import {
  determineActiveFilterKeyFromParam,
  filterDataStateTypes,
} from "store/filterDataSlice";

/**
 * This function returns the query from the current selection
 * It also adds the subset id to the query
 * @param selectedView The current selected view
 * @param filterData The current filter data (media or media objects)
 * @param subset_id The current subset id
 * @returns The query from the current selection with the subset id
 */
const getQueryFromCurrentSelection = (
  selectedView: SelectedViewModel,
  filterData: filterDataStateTypes,
  subset_id: string
): SendFilterModel[] => {
  const filters = filterData?.[determineActiveFilterKeyFromParam(selectedView)];
  let query = convertActiveFilterToSendFilter(filters);

  query = addSubsetToQuery(subset_id, query);
  return query;
};

export default getQueryFromCurrentSelection;
