export interface CurvePoints {
  automation_degree: number[];
  accuracy: number[];
}

export interface MLAnnotationModelCreate {
  name: string;
  reference_set_annotation_run_id: string;
}

export interface MLAnnotationModel extends MLAnnotationModelCreate {
  // Base
  id: string;
  created_at: string;
  updated_at: string | null;
  archived_at: string | null;
  owner: string;
  user_group: string;
  // MLAnnotation
  status: MLAnnotationModelStatus;
  validation_subset_id: string;
  test_subset_id: string;
  training_subset_id: string;
  model_weight_location: string;
  automation_correctness_curve: CurvePoints | null;
}

export enum MLAnnotationModelStatus {
  CREATED = "created",
  TRAINING = "training",
  TRAINING_FAILED = "training_failed",
  TRAINING_DONE = "training_done",
}
