import { CroppingArea } from "@quality-match/shared-types";
import { VisualisationsCorpModel } from "models/exploration.model";

/**
 * A function to convert visualisation crop model to cropping area model
 * @param crop The visualisation crop model to convert to cropping area model
 * @returns The cropping area model
 */
const visualisationCropModelToCroppingAreaModel = (
  crop: VisualisationsCorpModel,
): CroppingArea => {
  return {
    left: crop?.[0]?.[0],
    top: crop?.[0]?.[1],
    right: crop?.[1]?.[0],
    bottom: crop?.[1]?.[1],
  };
};

export default visualisationCropModelToCroppingAreaModel;
