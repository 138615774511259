import _ from "lodash";

const renderAttributeLabel = (
  value: string | number | boolean | null | (string | number | boolean | null)[]
) => {
  if (_.isBoolean(value)) {
    return value.toString();
  }
  if (_.isString(value)) {
    return _.truncate(value, { length: 19 });
  }
  if (_.isNull(value)) {
    return "-";
  }
  if (_.isNumber(value)) {
    return _.round(value, 2);
  }
  if (_.isArray(value)) {
    if (_.isObject(value?.[0])) {
      return "{...}";
    } else {
      const arrValue = _.map(value, (v) => (_.isNumber(v) ? _.round(v, 2) : v));
      return _.join(arrValue, ", ");
    }
  }
  if (_.isObject(value)) {
    return "{...}";
  }
};

export default renderAttributeLabel;
