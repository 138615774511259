import { Mediatype } from "models/dataset.model";

const selectMediatype = (mediatype: Mediatype | undefined) => {
  switch (mediatype) {
    case Mediatype.image:
      return "Image";
    case Mediatype.video:
      return "Video";
    default:
      return "Item";
  }
};
export default selectMediatype;
