import addSubsetToQuery from "helpers/functions/addSubsetToQuery";
import convertFilterModelToSendFilterModel from "helpers/functions/filters/convertFilterModelToSendFilterModel";
import _ from "lodash";
import { DatasetModel } from "models/dataset.model";
import { SendFilterModel } from "models/filter.model";
import {
  AnnotatableTypeModel,
  SelectedViewModel,
  SubsetTypeModel,
  VisibilityStatus,
} from "models/global.model";
import { AppDispatch } from "store";

import {
  datasetStateTypes,
  fetchAttributes,
  fetchDataset,
  fetchParentDataset,
  fetchSubsets,
  fetchTags,
  fetchVisualisationConfiguration,
} from "store/datasetSlice";
import {
  determineFilterKeyFromParam,
  filterDataStateTypes,
} from "store/filterDataSlice";
import { fetchCountStore } from "store/metaDataSlice";
import { DatasetIdAndNameModel } from "store/pipelineDesignerSlice";

export const fetchActiveDataset = (
  dispatch: AppDispatch,
  params: {
    datasetId: string;
    subset_id: string;
    visibility_statuses?: VisibilityStatus[];
  },
) => {
  const { datasetId, subset_id } = params;
  let id = datasetId;
  if (subset_id !== "main_dataset") {
    id = subset_id;
  }
  dispatch(
    fetchDataset({
      query: { dataSetID: id },
      visibility_statuses: params.visibility_statuses || [
        VisibilityStatus.Visible,
      ],
    }),
  );
};

// Fetch the parent dataset of the current subset and store it in redux: dataSetData.activeParentDataset
//          (if the current dataset is not subset it fetch the same dataset)
// Fetch all the subsets of the current dataset/subset
export const fetchDatasetSliceData = (
  dispatch: AppDispatch,
  dataSetData: datasetStateTypes,
) => {
  const { activeDataSet, activeParentDataset } = dataSetData;
  if (!_.isEmpty(activeDataSet)) {
    const dataSetID = activeDataSet?.parent_dataset || activeDataSet?.id;
    if (_.isEmpty(activeParentDataset)) {
      dispatch(fetchParentDataset({ query: { dataSetID: dataSetID } }));
    }
    if (_.isEmpty(dataSetData.subSets)) {
      dispatch(fetchSubsets({ query: { parentDataSetID: dataSetID } }));
    }
    if (_.isNull(dataSetData.tags)) {
      dispatch(fetchTags({ query: { parentDataSetID: dataSetID } }));
    }

    if (_.isEmpty(dataSetData?.attributes)) {
      dispatch(fetchAttributes({ query: { datasetID: dataSetID } }));
    }
    if (dataSetData.VisualisationConfiguration === null) {
      dispatch(
        fetchVisualisationConfiguration({ query: { datasetID: dataSetID } }),
      );
    }
    if (dataSetData.VisualisationConfiguration === null) {
      dispatch(
        fetchVisualisationConfiguration({ query: { datasetID: dataSetID } }),
      );
    }
  }
};

/**
 * A function to fetch the count of the selected view
 * @param params
 * @param params.selectedView The selected view
 * @param params.filterData The filter data from redux
 * @param params.runID The runID of the dataset
 * @param params.subset_id The subset_id of the dataset
 * @param dispatch
 * @param reset Whether to reset the filters or not
 */
export const fetchSelectedViewCount = (
  params: {
    selectedView: SelectedViewModel;
    filterData: filterDataStateTypes;
    runID: string;
    subset_id: string;
  },
  dispatch: AppDispatch,
  reset?: boolean,
) => {
  const { selectedView, filterData, runID, subset_id } = params;
  const filters = filterData?.[determineFilterKeyFromParam(selectedView)];
  const APIBodyInit: SendFilterModel[] = reset
    ? []
    : convertFilterModelToSendFilterModel(
        _.filter(filters, (filter) => filter?.is_visible),
      );

  // Add subset to query
  const APIBody = addSubsetToQuery(subset_id, APIBodyInit);

  dispatch(
    fetchCountStore({
      query: { runID: runID },
      APIBody: APIBody,
      selectedView: selectedView,
    }),
  ).then((e) => {
    // TODO: Add fetch tag frequency here when we make it work.
  });
};

/**
 * A function to get the count of the subset based on the subset type
 * @param subset The subset to get the count of the subset type
 * @returns The count of the subset type
 */
export const getSubsetTypeCount = (
  subset: DatasetModel | DatasetIdAndNameModel,
) => {
  if (!subset) return 0;
  switch (subset.subset_type) {
    case SubsetTypeModel.Media:
      return subset.num_medias;
    case SubsetTypeModel.MediaObject:
      return subset.num_media_objects;
    case SubsetTypeModel.Instance:
      return subset.num_instances;
    default:
      return 0;
  }
};

/**
 * Converts a subset type to its corresponding annotatable type.
 * @param subsetType The subset type to convert
 * @returns The corresponding annotatable type
 */
export const getAnnotatableTypeFromSubsetType = (
  subsetType: SubsetTypeModel,
): AnnotatableTypeModel => {
  switch (subsetType) {
    case SubsetTypeModel.Media:
      return "Media";
    case SubsetTypeModel.MediaObject:
      return "MediaObject";
    case SubsetTypeModel.Instance:
      return "Instance";
    default:
      throw new Error(`Unsupported subset type: ${subsetType}`);
  }
};
