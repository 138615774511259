import { UiSchema } from "@rjsf/utils";

export const ui_schema_binary_2d: UiSchema = {
  inputType: {
    Viewer3D: {
      "ui:widget": "hidden",
    },
  },
};

export const form_data_binary_2d = {
  inputType: {
    BoundingBox2DPainter: {
      displayerType: "image",
      boundingBoxProps: {
        opacity: 0,
      },
    },
    Viewer3D: {},
  },
  toolbars: [
    {
      type: "binaryButtonBar",
      settings: {
        withUnsolvableButton: false,
      },
    },
  ],
};

export const answers_binary_2d = {
  toolbarKey: "binaryButtonBar",
};
