import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TagTypeModel } from "models/global.model";

export type CredibilityPerAttributeModel = {
  name: string;
  count: number;
  color: string;
}[];

export type MediasAndInstancesModel = {
  name: string;
  count: number;
}[];

export type InstancesPerMediaModel = {
  name: string;
  count: number;
}[];

export type QaResultsPerAttributeModel = {
  id: string;
  results: { [key: string]: number };
  totalCount: number;
}[];

export type InternalQAResultsModel = {
  id: string;
  results: { [key: string]: { id: string; count: number } };
  totalCount: number;
}[];

export interface statisticsStateTypes {
  charts: {
    credibility_per_attribute: {
      name?: string;
      id: string;
      annotatableType: string;
      numBuckets: number;
      totalCount: number;
      data: CredibilityPerAttributeModel;
    };
    medias_and_objects: {
      name: string;
      data: MediasAndInstancesModel;
    };
    objects_per_media: {
      name: string;
      totalCount: number;
      data: InstancesPerMediaModel;
    };
    qa_results_per_attribute: {
      name: string;
      data: QaResultsPerAttributeModel;
    };
    qa_results_summary: {
      name: string;
      category: TagTypeModel | "";
      Correct: string;
      Incorrect: string;
      Ambiguous: string;
      totalCount: number | null;
      data: { Correct: number; Incorrect: number; Ambiguous: number };
    };
    internal_qa_on_a_subset_of_the_results: {
      name: string;
      data: InternalQAResultsModel;
    };
  };
}

const initialState = {
  charts: {
    credibility_per_attribute: {
      name: "Credibility per attribute",
      id: "",
      annotatableType: "",
      numBuckets: 10,
      totalCount: 0,
      data: [],
    },
    medias_and_objects: {
      name: "Number of medias and Objects",
      data: [],
    },
    objects_per_media: {
      name: "Objects per media",
      totalCount: 0,
      data: [],
    },
    qa_results_per_attribute: { name: "QA results per attribute", data: [] },
    qa_results_summary: {
      name: "QA results summary",
      category: "",
      Correct: "",
      Incorrect: "",
      Ambiguous: "",
      totalCount: null,
      data: { Correct: 0, Incorrect: 0, Ambiguous: 0 },
    },
    internal_qa_on_a_subset_of_the_results: {
      name: "Internal QA on a subset of the results",
      data: [],
    },
  },
} as statisticsStateTypes;

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    setCredibilityPerAttribute: (
      state,
      action: PayloadAction<
        statisticsStateTypes["charts"]["credibility_per_attribute"]
      >
    ) => {
      state.charts.credibility_per_attribute = action.payload;
    },
    setMediasAndObjects: (
      state,
      action: PayloadAction<
        statisticsStateTypes["charts"]["medias_and_objects"]
      >
    ) => {
      state.charts.medias_and_objects = action.payload;
    },
    setObjectsPerMedia: (
      state,
      action: PayloadAction<statisticsStateTypes["charts"]["objects_per_media"]>
    ) => {
      state.charts.objects_per_media = action.payload;
    },
    setQaResultsPerAttribute: (
      state,
      action: PayloadAction<QaResultsPerAttributeModel>
    ) => {
      state.charts.qa_results_per_attribute.data = action.payload;
    },
    setQaResultsSummary: (
      state,
      action: PayloadAction<
        statisticsStateTypes["charts"]["qa_results_summary"]
      >
    ) => {
      state.charts.qa_results_summary = action.payload;
    },
    setInternalQaResults: (
      state,
      action: PayloadAction<InternalQAResultsModel>
    ) => {
      state.charts.internal_qa_on_a_subset_of_the_results.data = action.payload;
    },
    resetStatisticsSlice: () => initialState,
  },
});

export const {
  setCredibilityPerAttribute,
  setMediasAndObjects,
  setObjectsPerMedia,
  setQaResultsPerAttribute,
  setQaResultsSummary,
  setInternalQaResults,
  resetStatisticsSlice,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;
