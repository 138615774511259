import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIFetchAxios } from "../components/UtilComponents/Auth";
import { DatasetModel } from "../models/dataset.model";
import {
  AnnotatableTypeModel,
  TagModel,
  VisibilityStatus,
} from "models/global.model";
import { AttributeMetadataModel } from "models/attributes.model";
import { fetchTagsAPI } from "helpers/apis/tags";
import { fetchAttributesMetaData } from "helpers/apis/attributes";
import { getVisualisationConfiguration } from "helpers/apis/configurations";
import { VisualisationConfigurationModel } from "models/configurations.model";

export const fetchDataset = createAsyncThunk(
  "dataset/fetchDataset",
  async (meta?: {
    query: { dataSetID: string };
    skipLoading?: boolean;
    visibility_statuses?: VisibilityStatus[];
  }) => {
    const response = await APIFetchAxios(
      `/datasets/${meta?.query?.dataSetID}`,
      {
        visibility_statuses: meta?.visibility_statuses || [
          VisibilityStatus.Visible,
        ],
      },
    );
    return { data: response?.data, meta: meta };
  },
);

export const fetchParentDataset = createAsyncThunk(
  "dataset/fetchParentDataset",
  async (meta?: { query: { dataSetID: string }; skipLoading?: boolean }) => {
    const response = await APIFetchAxios(`/datasets/${meta?.query?.dataSetID}`);
    return { data: response?.data, meta: meta };
  },
);

export const fetchSubsets = createAsyncThunk(
  "dataset/fetchSubsets",
  async (meta?: {
    query: { parentDataSetID: string };
    skipLoading?: boolean;
    visibility_statuses?: VisibilityStatus[];
  }) => {
    const response = await APIFetchAxios(
      `/datasets/${meta?.query?.parentDataSetID}/subsets`,
      {
        visibility_statuses: meta?.visibility_statuses || [
          VisibilityStatus.Visible,
        ],
      },
    );
    return { data: response?.data, meta: meta };
  },
);
export const fetchTags = createAsyncThunk(
  "dataset/fetchTags",
  async (meta: {
    query: { parentDataSetID: string };
    skipLoading?: boolean;
  }) => {
    const response = await fetchTagsAPI(meta?.query?.parentDataSetID);
    return { data: response?.data, meta: meta };
  },
);

export const fetchAttributes = createAsyncThunk(
  "dataset/fetchAttributes",
  async (meta: { query: { datasetID: string }; skipLoading?: boolean }) => {
    const { datasetID } = meta?.query;
    const data = await fetchAttributesMetaData({
      datasetID: datasetID,
    });
    return { data: data, meta: meta };
  },
);

export const fetchVisualisationConfiguration = createAsyncThunk(
  "dataset/fetchVisualisationConfiguration",
  async (meta: { query: { datasetID: string }; skipLoading?: boolean }) => {
    const { datasetID } = meta?.query;
    const data = await getVisualisationConfiguration({
      datasetID: datasetID,
    });
    return { data: data, meta: meta };
  },
);

export interface datasetStateTypes {
  activeDataSet: DatasetModel | null;
  activeParentDataset: DatasetModel | null;
  subSets: DatasetModel[];
  tags: TagModel[] | null;
  attributes: AttributeMetadataModel[];
  VisualisationConfiguration: VisualisationConfigurationModel[] | null;
  loading: boolean;
  error: { message: string };
}

const initialState = {
  activeDataSet: null,
  activeParentDataset: null,
  subSets: [],
  tags: null,
  attributes: [],
  VisualisationConfiguration: null,
  loading: false,
  error: { message: "" },
} as datasetStateTypes;

export const datasetSlice = createSlice({
  name: "dataset",
  initialState,
  reducers: {
    resetDatasetSlice: () => initialState,
  },
  extraReducers: (builder) => {
    // fetchDataset()
    builder.addCase(
      fetchDataset.pending,
      (state: datasetStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchDataset.fulfilled,
      (state: datasetStateTypes, action) => {
        state.activeDataSet = action.payload?.data;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchDataset.rejected,
      (state: datasetStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      },
    );
    // fetchParentDataset()
    builder.addCase(
      fetchParentDataset.pending,
      (state: datasetStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchParentDataset.fulfilled,
      (state: datasetStateTypes, action) => {
        state.activeParentDataset = action.payload?.data;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchParentDataset.rejected,
      (state: datasetStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      },
    );

    // fetchSubsets()
    builder.addCase(
      fetchSubsets.pending,
      (state: datasetStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchSubsets.fulfilled,
      (state: datasetStateTypes, action) => {
        state.subSets = action.payload?.data;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchSubsets.rejected,
      (state: datasetStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      },
    );

    // fetchTags()
    builder.addCase(fetchTags.pending, (state: datasetStateTypes, action) => {
      if (!action?.meta?.arg?.skipLoading) {
        state.loading = true;
      }
    });
    builder.addCase(fetchTags.fulfilled, (state: datasetStateTypes, action) => {
      state.tags = action.payload?.data;
      state.loading = false;
    });
    builder.addCase(fetchTags.rejected, (state: datasetStateTypes, action) => {
      state.loading = false;
      state.error.message = action.error.message || "No error provided";
    });

    // fetchAttributes()
    builder.addCase(
      fetchAttributes.pending,
      (state: datasetStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchAttributes.fulfilled,
      (state: datasetStateTypes, action) => {
        state.attributes = action.payload?.data;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchAttributes.rejected,
      (state: datasetStateTypes, action) => {
        state.loading = false;
        state.error.message =
          action.error.message ||
          "No error provided: Attributes failed to load";
      },
    );

    // fetchVisualisationConfiguration reducers
    builder.addCase(
      fetchVisualisationConfiguration.pending,
      (state: datasetStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchVisualisationConfiguration.fulfilled,
      (state: datasetStateTypes, action) => {
        state.VisualisationConfiguration = action.payload?.data;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchVisualisationConfiguration.rejected,
      (state: datasetStateTypes, action) => {
        state.loading = false;
        state.error.message =
          action.error.message ||
          "No error provided: Configurations fetch failed!";
      },
    );
  },
});

export const { resetDatasetSlice } = datasetSlice.actions;
export default datasetSlice.reducer;
