import { AttributeGroupEnum } from "models/attributes.model";

export const isAttributeAnnotationAttribute = (
  attributeGroup: AttributeGroupEnum,
): boolean => {
  return (
    attributeGroup === AttributeGroupEnum.ANNOTATION_ATTRIBUTE ||
    attributeGroup === AttributeGroupEnum.ML_ANNOTATION_ATTRIBUTE
  );
};

export const isAttributeAIAnnotationAttributeOnly = (
  attributeGroup: AttributeGroupEnum,
): boolean => {
  return attributeGroup === AttributeGroupEnum.ML_ANNOTATION_ATTRIBUTE;
};
