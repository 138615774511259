import Configurations from "Pages/PipelineDesigner/PipelineConfiguration/Configuration";
import { PipelineDesignerConfigurationTabsEnum } from "models/pipelines.model";
import { useState } from "react";

const PipelineConfiguration = () => {
  const [selectedTab, setSelectedTab] =
    useState<PipelineDesignerConfigurationTabsEnum>(
      PipelineDesignerConfigurationTabsEnum.UserInterface,
    );

  return (
    <div className="w-[600px] p-[6px]  rounded-r-lg flex flex-col gap-y-[2px] bg-paletteGray-1">
      <div className="w-full min-h-0 flex-1">
        <Configurations
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
    </div>
  );
};

export default PipelineConfiguration;
