import { IndexedPoints } from "@quality-match/shared-types";
import _ from "lodash";
import { Polyline2DFlatCoordinates } from "models/geometries.model";

/**
 * Convert flat polyline to pointwise Polyline
 * @param polyline - flat polyline coordinates i.e: [x1, y1, x2, y2, ...]
 * @returns {IndexedPoints} - pointwise polyline coordinates i.e: {p1: {x: x1, y: y1}, p2: {x: x2, y: y2}, ...}
 */
const convertFlatPolylineToPointWise = (
  polyline: Polyline2DFlatCoordinates,
) => {
  polyline = polyline as Polyline2DFlatCoordinates;
  const chunked = _.chunk(polyline?.coordinates, 2) as [number, number][];

  const taskUIPolyLine = _.reduce(
    chunked,
    (acc, value, index) => {
      const key = `p${index + 1}` as keyof IndexedPoints;
      acc[key] = {
        x: value[0],
        y: value[1],
      };
      return acc;
    },
    {} as IndexedPoints,
  );

  return taskUIPolyLine;
};

export default convertFlatPolylineToPointWise;
