import _ from "lodash";
import React from "react";
import TooltipTruncate from "components/Internal/Tooltips/TooltipTruncate";
import { Tooltip } from "@mui/material";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";

/**
 * @param item - The item to be edited
 * @param editInputID - The ID of the item being edited
 * @param setEditInputID - The function to set the ID of the item being edited
 * @param inputRefArray - The array of input refs
 * @param handleRename - The function to handle the onBlur event after the
 *  input field is blurred
 */
type Props = {
  item: Record<string, unknown>;
  editInputID: string | null;
  setEditInputID: (id: string | null) => void;
  inputRefArray: React.MutableRefObject<Record<string, HTMLInputElement>>;
  handleRename: (newName: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLInputElement>) => void;
  fontSize?: string;
  withToolTip?: boolean;
};

/**
 * The component to edit the name of an item in the table (e.g. attribute,
 * dataset, etc.)
 */
const EditName = ({
  item,
  editInputID,
  setEditInputID,
  inputRefArray,
  handleRename,
  onFocus,
  onMouseOver,
  fontSize = "text-lg",
  withToolTip = false,
}: Props) => {
  /**
   * Handles the onBlur event of the input field to rename the item:
   *  - If the value is the same as the item name, do nothing
   *  - If the value is not the same as the item name, rename the item
   *    and call the handleRename function
   * @param event - The onBlur event
   */
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const newName = event?.target?.value;
    // If the value is the same as the item name, do nothing
    if (event.target.value === item?.name) {
      setEditInputID(null);
      return;
    }
    // If the value is not the same as the item name, rename the item
    handleRename(newName);
    setEditInputID(null);
  };

  // If the item is not being edited, return the name
  if (editInputID !== item?.id && withToolTip) {
    const name = typeof item.name === "string" ? item.name : "";
    return (
      <TooltipTruncateEllipsis className={fontSize}>
        {name}
      </TooltipTruncateEllipsis>
    );
  }

  if (editInputID !== item?.id) {
    return (
      <TooltipTruncateEllipsis className={fontSize + "pr-3"}>
        {item?.name as string}
      </TooltipTruncateEllipsis>
    );
  }
  // If the item is being edited, return the input field
  if (_.isString(item?.id) && _.isString(item?.name)) {
    return (
      <input
        onFocus={onFocus}
        onMouseOver={onMouseOver}
        key={item?.id}
        type="text"
        className={`ìnput-text w-full p-1 bg-transparent focus:px-1 ${fontSize}`}
        defaultValue={item?.name}
        ref={(element) => {
          if (element) {
            inputRefArray.current[item?.id as string] = element;
          }
        }}
        onBlur={(event) => handleOnBlur(event)}
        disabled={editInputID !== item?.id}
      />
    );
  }

  return <span>{item?.name}</span>;
};

export default EditName;
