import { ReactNode, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { AnnotationRunModel } from "models/annotationRun.model";
import { DatasetModel } from "models/dataset.model";
import { PipelineModel, PipelineRevisionModel } from "models/pipelines.model";
import LaunchAnnotationRunDialog from "Pages/PipelineDesigner/PipelineConfiguration/Configuration/LaunchAnnotationRunDialog";
import { copyToClipboard } from "helpers/functions/copyToClipboard";

import { ReactComponent as OpenIcon } from "assets/open.svg";
import { ReactComponent as CopyFilledIcon } from "assets/copy_filled.svg";
import { getEstimateNumberNanoTasks } from "helpers/functions/pipelines/pipelinesHelpers";
import { getExplorationRouteFromSelectedView } from "routes/routesHelper";
import { AnnotatableEnum } from "models/global.model";
import _ from "lodash";
import { Tooltip } from "@mui/material";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";

type Props = {
  annotationRun: AnnotationRunModel;
  subsetOfAnnotationRun: DatasetModel | null;
  pipeline: PipelineModel | null;
  pipelineRevisionOfAnnotationRun: PipelineRevisionModel | null;
};

const AnnotationRunOverview = ({
  annotationRun,
  subsetOfAnnotationRun,
  pipeline,
  pipelineRevisionOfAnnotationRun,
}: Props) => {
  const dispatch = useAppDispatch();

  const [
    isAnnotationRunSummaryDialogOpen,
    setIsAnnotationRunSummaryDialogOpen,
  ] = useState(false);

  const renderAnnotationRunOverview = () => {
    const rowsData: { label: string; value: string | ReactNode }[] = [
      {
        label: "Annotation run name",
        value: annotationRun.name,
      },
      {
        label: "Pipeline name",
        value: pipeline && pipeline.name,
      },
      {
        label: "Data",
        value: renderSubsetLink(),
      },
      {
        label: "Number of Nano-Tasks",
        value: pipelineRevisionOfAnnotationRun
          ? getEstimateNumberNanoTasks(
              pipelineRevisionOfAnnotationRun.node_revisions?.[0].config
                ?.min_repeats,
              pipelineRevisionOfAnnotationRun.node_revisions?.[0].config
                ?.max_repeats,
              subsetOfAnnotationRun as DatasetModel,
            )
          : undefined,
      },
      {
        label: "Project ID",
        value: renderGoliatID(),
      },
    ];

    return (
      <div className="w-full bg-paletteGray-2 rounded-[12px] pl-[16px] pr-[12px] py-[12px]">
        {_.map(rowsData, (rowData) =>
          renderAnnotationRunRow(rowData.label, rowData.value),
        )}

        <button
          className="button-layer w-full mt-2 bg-paletteGray-4"
          onClick={() => setIsAnnotationRunSummaryDialogOpen(true)}
        >
          Open annotation run overview
        </button>
      </div>
    );
  };

  const renderAnnotationRunRow = (label: string, value: string | ReactNode) => {
    return (
      <div
        key={`annotation-run-row-${label}`}
        className="w-full flex items-center py-[4px]"
      >
        <div className="w-[50%] py-[4px] text-paletteGray-13 text-base flex-shrink-0">
          {label}
        </div>
        {_.isString(value) ? (
          <TooltipTruncateEllipsis className="text-paletteBlack-2 text-base">
            {value}
          </TooltipTruncateEllipsis>
        ) : (
          <div className="w-[50%] text-paletteBlack-2 text-base">{value}</div>
        )}
      </div>
    );
  };

  const renderSubsetLink = () => {
    if (!subsetOfAnnotationRun) return null;
    const explorationRoute = getExplorationRouteFromSelectedView(
      AnnotatableEnum.Media,
      {
        id: annotationRun?.dataset_id,
        subset_id: annotationRun?.subset_id,
      },
    );
    return (
      <div className="flex">
        <div className="text-ellipsis overflow-hidden">
          <Tooltip title={subsetOfAnnotationRun.name}>
            <div className="text-ellipsis overflow-hidden">
              {subsetOfAnnotationRun.name}
            </div>
          </Tooltip>
        </div>
        <div className="text-paletteGray-11 pl-[8px]">
          &nbsp;
          <span className="text-sm font-[400]">
            {subsetOfAnnotationRun.num_medias}
          </span>
          &nbsp;&#183;&nbsp;
          <span className="text-sm font-[400]">
            {subsetOfAnnotationRun.num_media_objects}
          </span>
        </div>
        <div className="text-paletteGray-9 pl-[8px]">
          <a href={explorationRoute} target="_blank" rel="noopener, noreferrer">
            <OpenIcon className="w-[18px] h-[18px]" />
          </a>
        </div>
      </div>
    );
  };

  const renderGoliatID = () => {
    return (
      <div className="flex">
        <div>{annotationRun?.goliat_project_id}</div>
        <div
          className="text-paletteGray-9 pl-[8px] cursor-pointer"
          onClick={() =>
            copyToClipboard(annotationRun?.goliat_project_id, dispatch)
          }
        >
          <CopyFilledIcon className="w-[18px] h-[18px]" />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="text-paletteBlack-2 font-[400] pb-[12px] pt-[20px]">
        Annotation run overview
      </div>
      {renderAnnotationRunOverview()}

      {pipeline && pipelineRevisionOfAnnotationRun && subsetOfAnnotationRun && (
        <LaunchAnnotationRunDialog
          viewMode="onlyView"
          isConfirmDialogOpen={isAnnotationRunSummaryDialogOpen}
          setIsConfirmDialogOpen={setIsAnnotationRunSummaryDialogOpen}
          annotationRunName={annotationRun?.name}
          pipeline={pipeline}
          pipelineRevision={pipelineRevisionOfAnnotationRun}
          subset={subsetOfAnnotationRun}
        />
      )}
    </div>
  );
};

export default AnnotationRunOverview;
