import { useState } from "react";
import _ from "lodash";
import { FilterModel } from "models/filter.model";
import DoubleSlider from "components/Internal/Inputs/DoubleSlider";
import TextField from "components/Internal/Inputs/TextField";
import { Equalizer } from "@mui/icons-material";

import Histogram from "components/Internal/Filters/HelperComponents/Histogram";
import { Tooltip } from "@mui/material";
import { ReactComponent as ArrowUpIcon } from "assets/arrow_small_up.svg";

interface Props {
  attribute: FilterModel;
  handleFilterChange: (newValue: number[]) => void;
}

const NumericalFilter = ({ attribute, handleFilterChange }: Props) => {
  const [showHistogram, setShowHistogram] = useState(false);
  const [numberOfBuckets, setNumberOfBuckets] = useState(20);
  const [showStatistics, setShowStatistics] = useState(false);

  const renderToggleHistogram = () => {
    return (
      <button
        className={`button-layer w-auto px-2 py-[6px] float-right ${
          showHistogram ? "text-white bg-paletteBlack-1" : ""
        } `}
        onClick={() => setShowHistogram(!showHistogram)}
      >
        <Equalizer className="w-4 h-4" />
      </button>
    );
  };

  const renderStatisticsButton = () => {
    if (attribute?.statistics) {
      return (
        <div className="px-3 py-2 bg-paletteGray-3 rounded-lg">
          <button
            className="button-layer p-0 w-full justify-between text-sm text-paletteBlack-2 font-medium"
            onClick={() => setShowStatistics(!showStatistics)}
          >
            Distribution details
            <ArrowUpIcon
              className={`w-4 h-4 text-paletteGray-10 transition-all ${
                showStatistics ? "transform -rotate-180" : ""
              }`}
            />
          </button>
          {showStatistics && renderStatistics()}
        </div>
      );
    }
  };

  const renderStatistics = () => {
    if (attribute?.statistics) {
      const {
        average,
        variance,
        quantiles_25,
        quantiles_50,
        quantiles_75,
        interquartile_range,
        shapiro_p_value,
      } = attribute?.statistics;
      return (
        <div className="mt-[6px] flex flex-col gap-y-1 text-paletteBlack-2">
          <div className="w-full flex justify-between">
            <div>Interquartile range</div>
            <div className="text-paletteGray-10">
              {_.round(interquartile_range, 3)}
            </div>
          </div>

          <div className="w-full flex justify-between">
            <div>
              Q <sub>0.25</sub>
            </div>
            <div className="text-paletteGray-10">
              {_.round(quantiles_25, 3)}
            </div>
          </div>

          <div className="w-full flex justify-between">
            <div>
              Q <sub>0.50</sub>
            </div>
            <div className="text-paletteGray-10">
              {_.round(quantiles_50, 3)}
            </div>
          </div>

          <div className="w-full flex justify-between">
            <div>
              Q <sub>0.75</sub>
            </div>
            <div className="text-paletteGray-10">
              {_.round(quantiles_75, 3)}
            </div>
          </div>

          <div className="w-full flex justify-between">
            <div>Average</div>
            <div className="text-paletteGray-10">{_.round(average, 3)}</div>
          </div>

          <div className="w-full flex justify-between">
            <div>Variance</div>
            <div className="text-paletteGray-10">{_.round(variance, 3)}</div>
          </div>

          <div className="w-full flex justify-between">
            <div>
              <Tooltip title="Using Shapiro-Wilk test. Alpha 5%. p-value < 0.05 evidence against the null hypothesis (not Normally distributed)">
                <div>Normality test:</div>
              </Tooltip>
            </div>
            <div className="text-paletteGray-10">
              p-value {_.round(shapiro_p_value, 3)}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderSelectNumberOfBuckets = () => {
    if (!showHistogram) {
      return;
    }

    return (
      <select
        className="select-layer w-fit"
        onChange={(event) => setNumberOfBuckets(parseInt(event?.target?.value))}
        value={numberOfBuckets}
      >
        {/* This assume the max number of buckets is 360 */}
        <option value={90}>4</option>
        <option value={30}>12</option>
        <option value={20}>18</option>
        <option value={10}>36</option>
        <option value={1}>Max</option>
      </select>
    );
  };

  const lowerBoundValue =
    attribute?.lower_bound == null
      ? ""
      : _.floor(attribute.lower_bound, 2).toString();
  const upperBoundValue =
    attribute?.upper_bound == null
      ? ""
      : _.ceil(attribute.upper_bound, 2).toString();

  return (
    <div
      className="flex flex-col gap-y-[20px] content-center"
      data-test="num_filter_input"
    >
      <div className="w-full flex justify-center items-center gap-x-6">
        <TextField
          type="number"
          value={lowerBoundValue}
          placeholder={_.floor(attribute?.lower_bound || 0, 2).toString()}
          className="flex-1 px-3 py-[7.5px] text-center"
          onChange={(newValue: any) =>
            handleFilterChange([newValue, attribute?.upper_bound])
          }
          onBlur={(newValue: any) => {
            let newLowerBound = newValue;
            if (_.isNaN(newLowerBound)) {
              newLowerBound = attribute?.lower;
            }
            if (
              !_.isNull(attribute?.upper) &&
              !_.isNull(attribute?.lower) &&
              (newLowerBound > attribute?.upper ||
                newLowerBound < attribute?.lower)
            ) {
              newLowerBound = attribute?.lower;
            }
            handleFilterChange([newLowerBound, attribute?.upper_bound]);
          }}
          min={attribute?.lower || 0}
          max={attribute?.upper || 0}
        />
        <div className="w-10 border-[1px] border-paletteGray-5" />
        <TextField
          type="number"
          value={upperBoundValue}
          placeholder={_.ceil(attribute?.upper_bound || 0, 2).toString()}
          className="flex-1 px-3 py-[7.5px] text-center"
          onChange={(newValue: any) =>
            handleFilterChange([attribute?.lower_bound, newValue])
          }
          onBlur={(newValue: any) => {
            let newUpperBound = newValue;
            if (_.isNaN(newUpperBound)) {
              newUpperBound = attribute?.upper;
            }
            if (
              !_.isNull(attribute?.upper) &&
              !_.isNull(attribute?.lower) &&
              (newUpperBound > attribute?.upper ||
                newUpperBound < attribute?.lower)
            ) {
              newUpperBound = attribute?.upper;
            }
            handleFilterChange([attribute?.lower_bound, newUpperBound]);
          }}
          min={attribute?.lower || 0}
          max={attribute?.upper || 0}
        />
      </div>

      <div className="w-full">
        {renderSelectNumberOfBuckets()}

        {showHistogram && (
          <div className="w-[345px] -mb-3">
            <Histogram
              attribute={attribute}
              numberOfBuckets={numberOfBuckets}
              selectedRange={[
                attribute?.lower_bound || 0,
                attribute?.upper_bound || 0,
              ]}
            />
          </div>
        )}
        <div className="flex justify-between items-center">
          <div className="w-[293px]">
            <DoubleSlider
              value={[attribute?.lower_bound || 0, attribute?.upper_bound || 0]}
              min={attribute?.lower || 0}
              max={attribute?.upper || 0}
              onChange={(newValue) => handleFilterChange(newValue)}
              numberOfBuckets={360 / numberOfBuckets}
            />
          </div>
          <div className="w-auto float-right">{renderToggleHistogram()}</div>
        </div>
      </div>

      {renderStatisticsButton()}
    </div>
  );
};

export default NumericalFilter;
