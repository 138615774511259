import _ from "lodash";
import React from "react";

export interface UnderlineTabModel {
  label: string;
  value: string;
  count?: number;
}
type Props = {
  tabs: UnderlineTabModel[];
  selectedTabValue: string;
  onTabClick: (tab: UnderlineTabModel) => void;
};

const UnderlineTabs = ({ tabs, selectedTabValue, onTabClick }: Props) => {
  const renderTabs = () => {
    return _.map(tabs, (tab) => {
      return (
        <div
          key={tab.value}
          className="cursor-pointer text-center "
          onClick={() => onTabClick(tab)}
        >
          <div className="flex gap-x-3">
            <div
              className={`${
                selectedTabValue === tab?.value
                  ? "text-paletteBlack-2 font-medium"
                  : "text-paletteGray-10"
              }`}
            >
              {tab?.label}
            </div>
            {tab?.count && (
              <div
                className={`px-1 flex justify-center items-center rounded text-sm  ${
                  selectedTabValue === tab?.value
                    ? "text-white bg-paletteBlack-2"
                    : "text-paletteGray-10 bg-paletteGray-4"
                }`}
              >
                {tab?.count}
              </div>
            )}
          </div>
          {selectedTabValue === tab.value && (
            <div className="w-full mt-3 h-1 rounded-t bg-paletteBlack-1"></div>
          )}
        </div>
      );
    });
  };

  return <div className="flex gap-x-6 border-b-[1px]">{renderTabs()}</div>;
};

export default UnderlineTabs;
