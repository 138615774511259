import { useEffect, useState } from "react";
import { ReactComponent as RefreshIcon } from "assets/reload.svg";

type Props = {
  functionToCall: () => void;
  label?: string;
  disabled?: boolean;
  delay?: number;
  numberOfRuns?: number;
};

const AutoIntervalButton = ({
  functionToCall,
  label = "Auto fetching (10s)",
  disabled = false,
  delay = 10000,
  numberOfRuns = 30,
}: Props) => {
  const [enableAutoFetching, setEnableAutoFetching] = useState(false);

  useEffect(() => {
    const intervalID = fetchInterval(
      enableAutoFetching,
      setEnableAutoFetching,
      functionToCall,
      delay,
      numberOfRuns,
    );
    return () => clearInterval(intervalID);
  }, [enableAutoFetching]);

  const fetchInterval = (
    enableState: boolean,
    setEnableState: (newState: boolean) => void,
    functionToRun: () => void,
    delay: number = 10000,
    numberOfRuns: number = 30,
  ) => {
    if (enableState) {
      // Call the function once immediately after changing the state to enabled
      functionToCall();

      let counter = 1;
      const interval = setInterval(() => {
        // Stop fetching after the number of fetches
        if (counter === numberOfRuns || !enableState) {
          setEnableState(false);
          counter = 1;
          clearInterval(interval);
        }
        // Fetch data
        else {
          functionToRun();
          counter++;
        }
      }, delay);

      return interval;
    }
  };

  return (
    <div className="flex">
      <button
        className={`button-layer  gap-x-2 ${
          enableAutoFetching && "bg-green-500 text-white"
        }`}
        data-test="auto_refresh"
        onClick={() => setEnableAutoFetching(!enableAutoFetching)}
        disabled={disabled}
      >
        {label}
        <RefreshIcon
          className={`w-3 h-3 ${enableAutoFetching && "animate-spin"}`}
        />
      </button>
    </div>
  );
};

export default AutoIntervalButton;
