import Dialog from "@mui/material/Dialog";
import { ReactComponent as WarningIcon } from "assets/warning.svg";
import _ from "lodash";

type Props = {
  title: string;
  subtitle: string;
  errorMessageList?: string[];
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  handleOnDialogClose?: () => void;
};

const WarningDialog = ({
  title,
  subtitle,
  errorMessageList,
  openDialog,
  setOpenDialog,
  handleOnDialogClose,
}: Props) => {
  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleOnDialogClose && handleOnDialogClose();
  };

  const renderErrorMessageList = () => {
    if (errorMessageList && errorMessageList.length > 0) {
      return (
        <div className="mt-4">
          {_.map(errorMessageList, (errorMessage) =>
            renderErrorMessage(errorMessage),
          )}
        </div>
      );
    }
  };

  const renderErrorMessage = (errorMessage: string) => {
    return <div className="text-paletteRed">- {errorMessage}</div>;
  };

  return (
    <Dialog
      fullWidth={true}
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth="xs"
    >
      <div className="w-full p-6 flex flex-col gap-y-[20px]">
        <WarningIcon width={32} height={32} className="text-paletteYellow" />

        <div>
          <div className="text-xl mb-1 text-paletteBlack-2">{title}</div>
          <div className="text-paletteGray-9">{subtitle}</div>
          {renderErrorMessageList()}
        </div>

        <div className="flex justify-end">
          <button
            className="button-layer text-paletteBlack-1"
            data-test="return_button"
            onClick={handleCloseDialog}
          >
            Return
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default WarningDialog;
