import { ChangeEvent, useState } from "react";
import { useAppDispatch } from "store/hooks";
import BasicButton from "components/Internal/Buttons/BasicButton";
import { TilingParamsModel } from "models/tiling.model";
import _ from "lodash";
import { snakeCaseToText } from "components/utilFunctions";
import { sendTilingJobRequest } from "helpers/apis/transformations";

interface Props {
  datasetID: string;
  subsetID: string;
}

const TileTab = ({ datasetID, subsetID }: Props) => {
  const dispatch = useAppDispatch();

  const [tilingParams, setTilingParams] = useState<TilingParamsModel>({
    configuration_name: "default-tiling",
    num_media_tiles_columns: 2,
    num_media_tiles_rows: 2,
    tiles_percentage_overlap: 10,
  });

  const renderSubmitButton = () => {
    return (
      <div className="flex justify-end mt-4">
        <BasicButton
          label="Send"
          onClick={handleSubmit}
          disabled={
            datasetID === "" ||
            subsetID === "" ||
            tilingParams?.configuration_name?.includes(" ")
          }
        />
      </div>
    );
  };

  const handleSubmit = async () => {
    await sendTilingJobRequest(
      { datasetID: datasetID, subsetID: subsetID },
      { tilingParams: tilingParams },
      dispatch,
    );
  };

  const handleTilingInputChange = (
    key: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const value = JSON.parse(event?.target?.value);
    setTilingParams({
      ...tilingParams,
      [key]: value,
    });
  };

  return (
    <div className="w-1/2">
      <div className="h-10">
        <p>
          <b>
            This will create a new task input file with the following config.
          </b>
        </p>
      </div>
      {_.map(tilingParams, (value, key) => {
        return (
          <div className="flex justify-between my-1">
            <label>{snakeCaseToText(key, true)}:</label>
            <input
              name={key}
              value={value.valueOf()}
              min="0"
              className="input-text !w-1/2"
              type={typeof value.valueOf()}
              onChange={(e) => handleTilingInputChange(key, e)}
            />
          </div>
        );
      })}
      {renderSubmitButton()}
      {datasetID === "" && (
        <div className="text-red-500">- select a dataset</div>
      )}
      {subsetID === "" && <div className="text-red-500">- select a subset</div>}
      {tilingParams?.configuration_name?.includes(" ") && (
        <div className="text-red-500">
          - spaces in the configurations name not allowed
        </div>
      )}
    </div>
  );
};

export default TileTab;
