import _ from "lodash";
import { useState } from "react";
import StandardPopover from "components/Internal/Popovers/StandardPopover";
import CustomCheckbox from "components/Internal/Inputs/Checkbox";
import { ManagerFiltersModel } from "models/global.model";

interface Props {
  selectedDates: ManagerFiltersModel["createdAt"] | null;
  setSelectedDates: (newValue: ManagerFiltersModel["createdAt"]) => void;
  setParentPopoverAnchorEl?: (el: HTMLElement | null) => void;
}

const CreatedAtPopover = ({
  selectedDates,
  setSelectedDates,
  setParentPopoverAnchorEl,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const renderTitleButton = () => {
    return (
      <button
        className={`button-layer ${
          !_.isNull(anchorEl) ? "button-layer-active" : ""
        }`}
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        Creation date
      </button>
    );
  };

  const renderListOfOptions = () => {
    const today = new Date();
    const yesterday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1,
    );
    const thisWeekStart = new Date(
      today.setDate(today.getDate() - today.getDay()),
    );
    const lastWeekStart = new Date(
      thisWeekStart.getFullYear(),
      thisWeekStart.getMonth(),
      thisWeekStart.getDate() - 7,
    );
    const lastWeekEnd = new Date(
      lastWeekStart.getFullYear(),
      lastWeekStart.getMonth(),
      lastWeekStart.getDate() + 6,
    );
    const last14DaysStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 13,
    );
    const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1,
    );
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    const overAMonthAgoStart = new Date(
      today.getFullYear() - 10,
      today.getMonth(),
      today.getDate(),
    );

    const options = [
      {
        label: "Today",
        valueFrom: new Date(),
        valueTo: new Date(),
      },
      {
        label: "Yesterday",
        valueFrom: yesterday,
        valueTo: yesterday,
      },
      {
        label: "This week",
        valueFrom: thisWeekStart,
        valueTo: new Date(),
      },
      {
        label: "Last week",
        valueFrom: lastWeekStart,
        valueTo: lastWeekEnd,
      },
      {
        label: "Last 14 days",
        valueFrom: last14DaysStart,
        valueTo: new Date(),
      },
      {
        label: "This month",
        valueFrom: thisMonthStart,
        valueTo: new Date(),
      },
      {
        label: "Last month",
        valueFrom: lastMonthStart,
        valueTo: lastMonthEnd,
      },
      {
        label: "Over a month ago",
        valueFrom: overAMonthAgoStart,
        valueTo: lastMonthEnd,
      },
    ];

    return options?.map((option) => {
      return renderOption(option.label, option.valueFrom, option.valueTo);
    });
  };

  const renderOption = (label: string, valueFrom: Date, valueTo: Date) => {
    const isChecked = selectedDates?.[label] !== undefined;
    return (
      <div
        key={label}
        className="button-select-layer justify-between gap-x-4"
        onClick={() => handleOptionClick(label, valueFrom, valueTo, isChecked)}
      >
        <div>{label}</div>
        <CustomCheckbox
          checked={isChecked}
          onChange={() => {
            //
          }}
        />
      </div>
    );
  };

  const handleOptionClick = (
    label: string,
    valueFrom: Date,
    valueTo: Date,
    isChecked: boolean,
  ) => {
    // Add date
    if (isChecked) {
      setSelectedDates(_.omit(selectedDates, label));
    }
    // Remove date
    else {
      setSelectedDates({
        ...selectedDates,
        [label]: {
          from: valueFrom,
          to: valueTo,
          label: label,
        },
      });
    }

    setAnchorEl(null);
    setParentPopoverAnchorEl && setParentPopoverAnchorEl(null);
  };

  return (
    <div>
      <StandardPopover
        id="dd"
        anchorEl={anchorEl}
        handlePopoverOpen={(event) => setAnchorEl(event.currentTarget)}
        handlePopoverClose={() => setAnchorEl(null)}
        buttonElement={renderTitleButton()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        offSetPopover={{
          left: -8,
          top: -4,
        }}
      >
        <div className="w-auto">{renderListOfOptions()}</div>
      </StandardPopover>
    </div>
  );
};

export default CreatedAtPopover;
