const pipelineNodeSummarySchema = {
  node_settings: {
    title: "Node settings",
    items: [
      {
        key: "config.gui_settings.question.Question.title",
        label: "Node question",
      },
      {
        key: "config.gui_settings.question.Question.subtitle",
        label: "Node subtext",
      },
      { key: "config.gui_type", label: "Annotator UI type" },
    ],
  },
  zoom_settings: {
    title: "Zoom settings",
    items: [
      {
        key: "config.gui_settings.inputType.BoundingBox2DPainter.zoomSettings.zoomMultiplier",
        label: "Zoom multiplier",
      },
      {
        key: "config.gui_settings.inputType.BoundingBox2DPainter.doubleClickZoom",
        label: "Double click zoom",
      },
    ],
  },
  configurations: {
    title: "Configurations",
    items: [
      {
        key: "config.min_repeats",
        label: "Minimum repeats",
      },
      {
        key: "config.max_repeats",
        label: "Maximum repeats",
      },
      {
        key: "config.convergence_threshold",
        label: "Convergence threshold",
      },
      {
        key: "config.wp_tasks",
        label: "Tasks per work package",
      },
      {
        key: "config.wp_timeout",
        label: "Work package timeout",
      },
      {
        key: "config.wp_rd_tasks",
        label: "Reference data tasks per work package",
      },
      {
        key: "config.media_type",
        label: "Media type",
      },
      {
        key: "config.should_workpackage_include_task_outputs",
        label: "Propagate answers",
      },
      {
        key: "config.visualisation_type",
        label: "Visualisation type",
      },
      {
        key: "config.auto_annotate",
        label: "Auto annotate",
      },
    ],
  },
};

export default pipelineNodeSummarySchema;
