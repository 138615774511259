import _ from "lodash";

type Props = { error: string | null; validationError: string | null };

const FormErrorCircle = ({ error, validationError }: Props) => {
  if (!_.isNull(validationError) && error) {
    return <div className="w-1 h-1 mx-2 rounded-full bg-paletteRed" />;
  } else {
    return null;
  }
};

export default FormErrorCircle;
