import StandardPopover from "components/Internal/Popovers/StandardPopover";
import { useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import ConfirmDialog from "components/Internal/Dialogs/ConfirmDialog";
import { useState } from "react";
import AppInfo from "components/UtilComponents/SidebarMenu/AppInfo";
import _ from "lodash";

type Props = {
  anchorEl: HTMLElement | null;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handlePopoverClose: () => void;
};

const AccountPopover = ({
  anchorEl,
  handlePopoverOpen,
  handlePopoverClose,
}: Props) => {
  const history = useHistory();

  const { keycloak } = useKeycloak();

  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const [isAppInfoDialogOpen, setIsAppInfoDialogOpen] = useState(false);

  const initial =
    keycloak?.idTokenParsed?.given_name?.charAt(0) ||
    keycloak?.idTokenParsed?.preferred_username?.charAt(0);

  return (
    <>
      <StandardPopover
        id="add_tags_popover_id"
        anchorEl={anchorEl}
        handlePopoverOpen={handlePopoverOpen}
        handlePopoverClose={handlePopoverClose}
        buttonTitle="Add tags"
        buttonElement={
          <div
            className="text-lg text-white flex bg-paletteGray-9 justify-center
            items-center rounded-[22px] p-3 w-[20px] h-[20px] cursor-pointer box-content"
            data-test="account_popover_button"
            aria-label="Account"
          >
            {_.upperCase(initial)}
          </div>
        }
        itemsList={[
          {
            label: "Privacy policy",
            onItemClick: () => {
              history.push("/privacy-policy");
            },
          },
          {
            label: "Imprint",
            onItemClick: () => {
              history.push("/imprint");
            },
          },
          {
            label: "Version info",
            onItemClick: () => {
              setIsAppInfoDialogOpen(true);
            },
          },
          {
            label: "Documentation",
            onItemClick: () => {
              const newWnd = window.open(
                process.env.REACT_APP_DOCUMENTATION_URL,
                "_blank",
                "noopener,noreferrer",
              );
              if (newWnd) {
                newWnd.opener = null;
              }
            },
          },
          {
            label: "Log out",
            onItemClick: () => {
              setIsLogoutDialogOpen(true);
            },
          },
        ]}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
      <ConfirmDialog
        title="Logging out"
        text="Are you sure you want to log out?"
        confirmButtonText="Log out"
        isConfirmDialogOpen={isLogoutDialogOpen}
        setIsConfirmDialogOpen={setIsLogoutDialogOpen}
        handleOnSuccess={keycloak?.logout}
      />
      <AppInfo
        isOpen={isAppInfoDialogOpen}
        setIsOpen={setIsAppInfoDialogOpen}
        onClose={() => setIsAppInfoDialogOpen(false)}
      />
    </>
  );
};

export default AccountPopover;
