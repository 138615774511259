import _ from "lodash";
import { snakeCaseToText } from "components/utilFunctions";
import { Tooltip } from "@mui/material";

type Props = {
  name: string;
  value: number;
  min?: number;
  max?: number;
  tooltip?: string;
  handleOnChange: (value: number, error?: string | null) => void;
};

const NumberInput = ({
  name,
  value,
  min,
  max,
  tooltip,
  handleOnChange,
}: Props) => {
  const validateNumberInput = (value: number, min?: number, max?: number) => {
    let error = null;
    if (_.isUndefined(value)) error = "Value is required";
    if (isNaN(value)) error = "Value must be a number";
    if (min && value < min) error = `Value must be greater than ${min}`;
    if (max && value > max) error = `Value must be less than ${max}`;

    return error;
  };

  const validationError = validateNumberInput(value, min, max);

  const renderErrorMessage = () => {
    if (!_.isNull(validationError)) {
      return (
        <div className="px-4 text-sm text-paletteRed">{validationError}</div>
      );
    }
  };

  return (
    <div key={name} className="my-1 border-b-[1px]">
      <div className="flex justify-between gap-y-2">
        <label>
          <Tooltip title={tooltip}>
            <div>{snakeCaseToText(name, true)}:</div>
          </Tooltip>
        </label>
        <input
          className={`input-text !w-1/2 ${
            !_.isNull(validationError) &&
            "outline-paletteRed px-1 border-[1px] border-paletteRed "
          }}`}
          type="number"
          value={value}
          onChange={(event) => {
            const newValue = parseFloat(event.target.value);
            handleOnChange(newValue, validateNumberInput(newValue, min, max));
          }}
          min={min}
          max={max}
        />
      </div>
      {renderErrorMessage()}
    </div>
  );
};

export default NumberInput;
