import _ from "lodash";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import chartsColor from "components/Statistics/chartsColors";

export type BarChartCompDataModel = {
  name: string;
  count: number;
  color?: string;
}[];

interface Props {
  data: BarChartCompDataModel;
  dataKey?: string;
  width?: number;
  height?: number;
  yAxis?: {
    hide?: boolean;
    domain?: [number, number];
  };
  showLegend?: boolean;
  maxBarSize?: number | undefined;
  showLabels?: boolean;
  percentage?: {
    show: boolean;
    totalCount: number;
  };
}

const CustomizedTick = (props: any) => {
  const { x, y, payload, height } = props;
  const lines = payload?.value?.split("\n");

  const lineHeight = height / lines.length;

  return (
    <g>
      {lines.map((line: string, index: number) => {
        const textWidth = line?.length * 7;

        return (
          <text
            key={index}
            x={x - textWidth / 2}
            y={y + index * lineHeight}
            dy={lineHeight / 2}
            textAnchor="start"
            fill="#666"
          >
            {line}
          </text>
        );
      })}
    </g>
  );
};

const BarChartComp = ({
  data,
  dataKey = "count",
  width = 100,
  height = 100,
  yAxis,
  showLegend = true,
  maxBarSize = 200,
  showLabels = true,
  percentage,
}: Props) => {
  const BarShape = (props: any) => {
    const { fill, x, y, width, height, value } = props;
    let label = value;
    // Append percentage if it's used
    if (!_.isUndefined(percentage) && percentage?.show) {
      const FNPercentage = _.round(
        (value * 100) / (percentage?.totalCount || 1),
        2
      );
      label = `${value} (${FNPercentage}%)`;
    }
    return (
      <svg x={x} y={y} width={width} height={height} fill={fill}>
        <g>
          <rect width={width} height={height} rx="5" ry="5" />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="white"
          >
            {showLabels ? label : null}
          </text>
        </g>
      </svg>
    );
  };

  return (
    <ResponsiveContainer width={`${width}%`} height={`${height}%`}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" tick={<CustomizedTick />} />
        <YAxis
          domain={yAxis?.domain}
          hide={yAxis?.hide}
          axisLine={false}
          width={80}
        />
        <Tooltip />
        {showLegend && <Legend />}
        <Bar
          key={"bar_"}
          dataKey={dataKey}
          maxBarSize={maxBarSize}
          shape={BarShape}
        >
          {_.map(data, (entry, index) => (
            <Cell
              key={`cell_${index}`}
              fill={entry?.color || chartsColor[index] || "#8884d8"}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComp;
