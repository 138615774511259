import {
  distinctColors,
  taskInputDefaultColor,
  geometryColors,
} from "helpers/colors";
import _ from "lodash";
import { MediaObjectModel } from "models/exploration.model";
import {
  BoundingBox2D,
  GeometriesEnum,
  Point2DXY,
  Polyline2DFlatCoordinates,
} from "models/geometries.model";
import convertFlatPolylineToPointWise from "helpers/functions/hermes2dPainter/convertFlatPolylineToPointWise";
import {
  BaseShape,
  IndexedPoints,
  ShapeType2D,
} from "@quality-match/shared-types";

interface ConvertToBaseShapeOptions {
  allGeometries?: MediaObjectModel[] | null;
  colorByClass?: boolean;
}

export const colorOfCategory = (
  category: string,
  allGeometries: MediaObjectModel[] | undefined,
) => {
  const categories = _.uniq(allGeometries?.map((g) => g?.object_category));
  return distinctColors(categories.length)[categories.indexOf(category)];
};

/**
 * A function to convert MediaObjectModel to BaseShape for Hermes2dPainter
 * @param geometry The geometry to convert to BaseShape
 * @param options Options to consider when converting the geometry
 * @returns The converted geometry as BaseShape
 */
const convertMediaObjectModelToBaseShape = (
  geometry: MediaObjectModel,
  options: ConvertToBaseShapeOptions = {},
) => {
  let coordinates = geometry?.reference_data;
  const strokeWidth = 2;

  // Determine the color of the geometry
  let color = "";
  if (geometry?.source === "QM") {
    color = geometryColors.qm;
  } else {
    color = geometryColors.reference;
  }
  if (options.colorByClass) {
    if (options.allGeometries) {
      color = colorOfCategory(geometry?.object_category, options.allGeometries);
    } else {
      color = taskInputDefaultColor;
    }
  }

  // If geometry is QM data, get the last bounding box
  if (geometry?.source === "QM") {
    const last_bb_index = geometry?.qm_data?.length - 1;
    coordinates = geometry?.qm_data[last_bb_index];
  }

  // If geometry is KeyPoint
  if (
    coordinates?.type === GeometriesEnum.Point2D ||
    coordinates?.type === GeometriesEnum.Point2DAggregation
  ) {
    coordinates = coordinates as Point2DXY;
    const newGeometry: BaseShape = {
      key: geometry?.id,
      type: ShapeType2D.point,
      coordinates: {
        p1: {
          x: coordinates?.x,
          y: coordinates?.y,
        },
      },

      settings: {
        color: color,
        strokeWidth: strokeWidth,
        inactive: true,
        label: geometry?.object_category_name || "",
      },
    };
    if (!_.isEmpty(newGeometry)) {
      return newGeometry;
    }
  }
  // If geometry is BoundingBox
  if (
    coordinates?.type === GeometriesEnum.BoundingBox2D ||
    coordinates?.type === GeometriesEnum.BoundingBox2DAggregation
  ) {
    coordinates = coordinates as BoundingBox2D;
    const newGeometry: BaseShape = {
      key: geometry?.id,
      type: ShapeType2D.rect,
      coordinates: {
        p1: {
          x: coordinates?.x - coordinates?.width / 2,
          y: coordinates?.y - coordinates?.height / 2,
        },
        p2: {
          x: coordinates?.x + coordinates?.width / 2,
          y: coordinates?.y + coordinates?.height / 2,
        },
      },
      settings: {
        color: color,
        strokeWidth: strokeWidth,
        inactive: true,
        label: geometry?.object_category_name || "",
      },
    };
    if (!_.isEmpty(newGeometry)) {
      return newGeometry;
    }
  }
  // If geometry is Polyline
  if (coordinates?.type === GeometriesEnum.Polyline2DFlatCoordinates) {
    coordinates = coordinates as Polyline2DFlatCoordinates;
    const taskUIPolyLine: IndexedPoints =
      convertFlatPolylineToPointWise(coordinates);

    const newGeometry: BaseShape = {
      key: geometry?.id,
      type: ShapeType2D.polyline,
      coordinates: taskUIPolyLine,
      settings: {
        color: color,
        strokeWidth: strokeWidth,
        inactive: true,
        label: geometry?.object_category_name || "",
      },
    };
    if (!_.isEmpty(newGeometry)) {
      return newGeometry;
    }
  }
};

export default convertMediaObjectModelToBaseShape;
