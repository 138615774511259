import subsetQuery from "helpers/functions/subsetQuery";
import { SendFilterModel } from "models/filter.model";

const addSubsetToQuery = (
  subset_id: string,
  query: SendFilterModel[]
): SendFilterModel[] => {
  let newQuery = [...query];
  if (subset_id !== "main_dataset") {
    newQuery = [...newQuery, subsetQuery(subset_id)];
    return newQuery;
  } else {
    return newQuery;
  }
};

export default addSubsetToQuery;
