import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
} from "recharts";
import { nFormatter } from "components/utilFunctions";
import { FilterModel, HistogramModel } from "models/filter.model";
import _ from "lodash";
import { useEffect, useState } from "react";

interface Props {
  attribute: FilterModel;
  numberOfBuckets?: number;
  selectedRange?: [number, number];
}

const Histogram = ({
  attribute,
  numberOfBuckets = 18,
  selectedRange,
}: Props) => {
  const [histogram, setHistogram] = useState<HistogramModel[]>();

  useEffect(() => {
    const chunkedHistogram = _.chunk(attribute?.histogram, numberOfBuckets);
    let histogramFinal: HistogramModel[] = [];
    _.map(chunkedHistogram, (chunk) => {
      const lower_bound = chunk?.[0]?.lower_bound;
      const upper_bound = chunk?.[chunk?.length - 1]?.upper_bound;
      histogramFinal = [
        ...histogramFinal,
        {
          bin_str: `${lower_bound} - ${upper_bound}`,
          freq: _.sumBy(chunk, "freq"),
          lower_bound: lower_bound,
          upper_bound: upper_bound,
        },
      ];
    });
    setHistogram(histogramFinal);
  }, [numberOfBuckets]);

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload?.[0]?.payload;
      return (
        <div className="bg-white px-2 py-1 border-2 border-paletteOrange rounded-md text-sm">
          <div>
            {_.floor(data?.lower_bound, 2)} - {_.ceil(data?.upper_bound, 2)}
          </div>
          <div>Freq: {data?.freq}</div>
        </div>
      );
    }

    return null;
  };

  const BarShape = (props: any) => {
    const { fill, x, y, width, height } = props;

    return (
      <svg x={x} y={y} width={width} height={height} fill={fill}>
        <g>
          <rect width={width} height={height} rx="3" ry="3" />
        </g>
      </svg>
    );
  };

  return (
    <ResponsiveContainer width="100%" minHeight="150px">
      <BarChart
        data={histogram}
        margin={{ top: 0, right: 5.5, left: 6.4, bottom: 2 }}
      >
        <XAxis dataKey="bin_str" tick={false} tickSize={0} height={0} />
        <YAxis
          orientation="right"
          axisLine={false}
          tickSize={7}
          padding={{ top: 10 }}
          tickFormatter={nFormatter}
          fontSize={13}
          width={40}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="freq" shape={BarShape}>
          {_.map(histogram, (entry, index: number) => {
            return (
              <Cell
                key={`cell-${index}`}
                fill={`${
                  selectedRange &&
                  (selectedRange[0] > entry?.lower_bound ||
                    selectedRange[1] < entry?.upper_bound)
                    ? "gray"
                    : "#ff8c00"
                }`}
                className="rounded-sm"
              />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Histogram;
