import FormErrorCircle from "components/Internal/Inputs/Form/ErrorCircle";
import FormErrorLabel from "components/Internal/Inputs/Form/ErrorLabel";
import FormLabel from "components/Internal/Inputs/Form/Label";
import { validateNumberInput } from "components/Internal/Inputs/Form/formInputsHelpers";
import _ from "lodash";
import { NumberFieldModel } from "models/form.model";

interface Props {
  field: NumberFieldModel;
  value: number;
  handleOnChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    field: NumberFieldModel,
    isErrored?: boolean,
    error?: string | null,
  ) => void;
}

const NumberInput = ({ field, value, handleOnChange }: Props) => {
  const { placeholder, disabled, settings } = field;

  const renderBody = () => {
    const sameLine = field?.style?.labelAndFieldOnTheSameLine;
    if (sameLine) {
      return renderSameLineAlignment();
    }

    return renderMultiLineAlignment();
  };

  const renderMultiLineAlignment = () => {
    return (
      <div key={field?.key} className={`w-full ${field?.style?.wrapper}`}>
        <FormLabel field={field} />
        {renderNumberInput()}
        <FormErrorLabel
          error={field?.error ?? null}
          validationError={validateNumberInput(field)}
        />
      </div>
    );
  };

  const renderSameLineAlignment = () => {
    return (
      <div
        key={field?.key}
        className={`flex justify-between items-center
          ${field?.style?.wrapper}
      `}
      >
        <div className="w-1/2">
          <FormLabel field={field} />
        </div>
        <div className="w-1/2 flex items-center">
          {renderNumberInput()}
          <FormErrorCircle
            error={field?.error ?? null}
            validationError={validateNumberInput(field)}
          />
        </div>
      </div>
    );
  };

  const selectStep = () => {
    if (_.isUndefined(settings)) {
      return 1;
    }

    if (!_.isUndefined(settings?.step)) {
      return settings?.step;
    }
    if (
      !_.isUndefined(settings?.maximumValue) &&
      !_.isUndefined(settings?.minimumValue)
    ) {
      return (settings?.maximumValue - settings?.minimumValue) / 100;
    }

    return 1;
  };

  const renderNumberInput = () => {
    const validationError = validateNumberInput(field);
    const isError = !_.isNull(validationError);
    const inputClassName = field?.style?.field;
    return (
      <input
        type="number"
        value={value}
        placeholder={placeholder}
        min={settings?.minimumValue}
        max={settings?.maximumValue}
        step={selectStep()}
        onChange={(event) => handleOnChange(event, field)}
        onBlur={(event) =>
          handleOnChange(event, field, isError, validationError)
        }
        className={`input-text ${inputClassName ? inputClassName : ""}`}
        disabled={disabled}
      />
    );
  };

  return renderBody();
};

export default NumberInput;
