import _ from "lodash";
import { useAppSelector } from "store/hooks";
import { AnnotatableEnum } from "models/global.model";
import { ReactComponent as Plus } from "assets/plus.svg";
import { ReactComponent as Delete } from "assets/delete.svg";
import { useParams } from "react-router-dom";
import { ExplorationScreenRouteModel } from "models/routes.model";
import { FilterModel } from "models/filter.model";
import selectMediatype from "helpers/functions/selectMediatype";
import {
  detectListType,
  ListType,
  parseList,
} from "helpers/functions/listParser";
import { useState } from "react";

interface Props {
  attribute: FilterModel;
  handleFilterChange: (newVal: (string | number)[]) => void;
}

const searchByText = ({ attribute, handleFilterChange }: Props) => {
  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype
  );
  const [text, setText] = useState<string>("");
  const [detectedType, setDetectedType] = useState<ListType>(ListType.Unknown);
  const params: ExplorationScreenRouteModel = useParams();

  const addDatasetID = (text: string | number) => {
    /* Media ids are of format {dataset_id}_{uuid}.ext elsewhere in the frontend this
     *  this is not displayed correctly. To make this more consistent we add the dataset_id
     * */
    if (selectedView === AnnotatableEnum.Media && attribute?.id === "id") {
      return `${params?.id}_${text}`;
    } else {
      return text;
    }
  };

  const cutOutDatasetID = (text: string | number) => {
    if (
      selectedView === AnnotatableEnum.Media &&
      attribute?.id === "id" &&
      _.isString(text)
    ) {
      return text?.replace(params?.id + "_", "");
    } else {
      return text;
    }
  };

  const getPlaceholderText = () => {
    return `Enter ${attribute?.id === "id" ? selectMediatype(mediatype) : ""} ${
      attribute?.name
    }`;
  };
  const handleListChange = (e: any) => {
    const parsedInput: string[] | null = parseList(e.target.value);
    const detectedType = detectListType(e.target.value);

    setDetectedType(detectedType);
    setText(e.target.value);

    if (parsedInput !== null && detectedType !== ListType.Unknown) {
      handleFilterChange(parsedInput);
    }
  };

  return (
    <div className="flex flex-col gap-y-1">
      <textarea
        className={`text-base py-[15.5px] pl-[16px] pr-[6px] focus:outline-none 
        ${
          detectedType !== ListType.Unknown
            ? "focus:ring-paletteOrange"
            : "focus:ring-red-500"
        } focus:border-1 mr-2
        focus:ring-1 mb-2 bg-paletteGray-1 border-0 rounded-[8px] w-full focus:bg-white`}
        value={text}
        placeholder="Enter a list of items"
        onChange={(e) => {
          handleListChange(e);
        }}
      />
      <div className="text-sm">List type:</div>
      <div>{detectedType}</div>
      {_.map(attribute?.selected_cats, (searchText, index: number) => {
        return (
          <div
            className="relative"
            key={`${attribute.id}_search_by_text_${index}`}
          >
            <input
              className="text-base py-[15.5px] pl-[16px] pr-[6px] focus:outline-none focus:ring-paletteOrange focus:border-1 focus:ring-1
                 mb-2 bg-paletteGray-1 border-0 rounded-[8px] w-full focus:bg-white"
              type={attribute?.FE_cast_value_to_number ? "number" : "text"}
              value={cutOutDatasetID(searchText)}
              placeholder={getPlaceholderText()}
              onChange={(e) => {
                const newValue = attribute.FE_cast_value_to_number
                  ? Number(e.target.value)
                  : e.target.value;

                const newSearchTexts: (string | number)[] = [
                  ...(attribute?.selected_cats as (string | number)[]),
                ];
                newSearchTexts[index] = addDatasetID(newValue);
                handleFilterChange(newSearchTexts);
              }}
            />
            <div className="absolute top-[6px] right-[6px]">
              {index > 0 && (
                <button
                  className="button-layer p-[10px]"
                  onClick={() => {
                    const newSearchTexts: string[] = [
                      ...(attribute?.selected_cats as string[]),
                    ];
                    newSearchTexts?.splice(index, 1);
                    handleFilterChange(newSearchTexts);
                  }}
                >
                  <Delete />
                </button>
              )}
            </div>
          </div>
        );
      })}
      {!_.isNull(attribute?.selected_cats) &&
        attribute?.selected_cats?.length < 5 && (
          <button
            className="button-layer w-fit"
            onClick={() => {
              const newSearchTexts: (string | number)[] = [
                ...(attribute?.selected_cats as string[]),
              ];
              newSearchTexts.push(addDatasetID(""));
              handleFilterChange(newSearchTexts);
            }}
          >
            <Plus className={"w-3 h-3"} />
            Add another {attribute?.name}
          </button>
        )}
    </div>
  );
};

export default searchByText;
