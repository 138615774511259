import { ReactComponent as AiIcon } from "assets/ai.svg";

const AiModelDialogHelperText = () => {
  return (
    <div
      className="h-full px-4 py-3 bg-palettePurple/5 text-palettePurple-dark rounded-2xl 
            flex flex-col gap-y-4 overflow-auto"
    >
      {/* Title */}
      <div className="flex gap-x-2 text-lg items-center text-palettePurple-dark">
        <AiIcon className="w-4 h-4" />
        <span>Leverage AI for efficient Nano-Task scaling.</span>
      </div>

      {/* Section 1 */}
      <div className="flex flex-col gap-y-2 text-palettePurple-dark">
        <div className="font-bold">1. Model training</div>
        <div className="font-thin">
          To efficiently tackle Nano-Tasks on a large scale, we employ an AI
          model trained on human annotators' responses. The objective is to
          mimic annotators' behavior and apply it to new data points. Following
          training, we assess the model's performance on a subset of the data,
          gauging prediction accuracy against varying automation levels.
        </div>
      </div>

      {/* Section 2 */}
      <div className="flex flex-col gap-y-2 text-palettePurple-dark">
        <div className="font-bold">2. AI annotation</div>
        <div className="font-thin">
          Each point on the automation correctness curve represents a threshold
          choice dictating prediction confidence. Higher confidence thresholds
          typically yield greater model accuracy but lower automation rates.
          Conversely, lower thresholds sacrifice some accuracy for increased
          automation potential. Depending on desired automation levels (and
          corresponding thresholds), we determine which examples the model
          reliably annotates and which necessitate human intervention.
        </div>
      </div>
    </div>
  );
};

export default AiModelDialogHelperText;
