import _ from "lodash";
import {
  ActiveFilterModel,
  ActiveFiltersModel,
  FilterModel,
} from "models/filter.model";

const convertFiltersModelToActiveFiltersModel = (filters: FilterModel[]) => {
  let newActiveFilters: ActiveFiltersModel = {};
  _.map(filters, (filter) => {
    // Remove empty filters
    switch (filter?.type) {
      case "CATEGORICAL":
        if (
          !_.isEmpty(filter?.selected_cats) ||
          !_.isEmpty(filter?.not_selected_cats)
        ) {
          newActiveFilters = {
            ...newActiveFilters,
            [filter?.key]: convertFilterModelToActiveFilterModel(filter),
          };
        }
        break;
      case "SEARCH_BY_TEXT":
      case "BOOLEAN":
      case "SUBSET":
      case "FE_SUBSET":
      case "FE_TAG":
        if (!_.isEmpty(filter?.selected_cats)) {
          newActiveFilters = {
            ...newActiveFilters,
            [filter?.key]: convertFilterModelToActiveFilterModel(filter),
          };
        }
        break;
      case "NUMERICAL":
        if (
          filter?.lower_bound !== filter?.lower ||
          filter?.upper_bound !== filter?.upper
        ) {
          newActiveFilters = {
            ...newActiveFilters,
            [filter?.key]: convertFilterModelToActiveFilterModel(filter),
          };
        }
        break;
      case "SELECT_ATTRIBUTE":
        if (!_.isEmpty(filter?.FE_value))
          newActiveFilters = {
            ...newActiveFilters,
            [filter?.key]: convertFilterModelToActiveFilterModel(filter),
          };

        break;
    }
  });
  return newActiveFilters;
};

const convertFilterModelToActiveFilterModel = (
  filter: FilterModel
): ActiveFilterModel => {
  const name = `${filter?.attribute_name} - ${filter?.filter_name}`;
  switch (filter?.type) {
    case "CATEGORICAL":
      return {
        key: filter?.key,
        value: null,
        name,
        type: filter?.type,
        is_not: filter?.FE_is_not,
        categories_value: {
          selected_cats: filter?.selected_cats,
          not_selected_cats: filter?.not_selected_cats,
        },
      };
    case "SEARCH_BY_TEXT":
    case "SUBSET":
    case "FE_SUBSET":
    case "FE_TAG":
    case "BOOLEAN":
      return {
        key: filter?.key,
        value: filter?.selected_cats as (string | number)[],
        name,
        type: filter?.type,
        is_not: filter?.FE_is_not,
      };
    case "NUMERICAL":
      if (_.isNumber(filter?.lower_bound) && _.isNumber(filter?.upper_bound)) {
        return {
          key: filter?.key,
          value: [filter?.lower_bound, filter?.upper_bound],
          name,
          type: filter?.type,
          is_not: filter?.FE_is_not,
          include_edges: filter?.FE_include_edges,
          lower: filter?.lower,
          upper: filter?.upper,
        };
      } else {
        return {
          key: filter?.key,
          value: [0, 0],
          name,
          type: filter?.type,
          is_not: filter?.FE_is_not,
          include_edges: filter?.FE_include_edges,
        };
      }
    case "SELECT_ATTRIBUTE":
      return {
        key: filter?.key,
        value: filter?.FE_value || "",
        name,
        type: filter?.type,
        is_not: filter?.FE_is_not,
      };
  }
};

export default convertFiltersModelToActiveFiltersModel;
