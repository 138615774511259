import Switch, { SwitchClassKey, SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

interface Props {
  checked: any;
  name?: any;
  onChange: (e?: any) => any;
  disabled?: boolean;
  size?: "sm" | "base";
}

const SwitchComp = ({
  checked,
  name,
  onChange,
  disabled = false,
  size = "base",
}: Props) => {
  const sizes = {
    base: {
      rootWidth: 42,
      rootHeight: 26,
      thumb: 24,
    },
    sm: {
      rootWidth: 34,
      rootHeight: 18,
      thumb: 16,
    },
  };

  // -------------------------- Custom Switch styled component: --------------------------------
  interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
  }

  interface Props extends SwitchProps {
    classes: Styles;
  }

  const IOSSwitch = styled(Switch)((theme: any) => ({
    width: sizes[size]?.rootWidth,
    height: sizes[size]?.rootHeight,
    padding: 0,
    // margin: theme.spacing(1),

    "& .MuiSwitch-switchBase": {
      padding: 1,
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "white",
        "& + .MuiSwitch-track": {
          backgroundColor: "#ff8c00",
          opacity: 1,
          border: "none",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: sizes[size]?.thumb,
      height: sizes[size]?.thumb,
    },
    "& .MuiSwitch-track": {
      borderRadius: sizes[size]?.rootHeight / 2,
      border: `1px solid ${theme?.palette?.grey[400]}`,
      backgroundColor: "lightgrey",
      opacity: 1,
      transition: theme?.transitions?.create(["background-color", "border"]),
    },
    "&.Mui-checked": {},
  }));

  return (
    <IOSSwitch
      checked={checked}
      name={name}
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
      disabled={disabled}
    />
  );
};

export default SwitchComp;
