import { Mediatype } from "models/dataset.model";
import { VisualisationTypeEnum } from "models/pipelines.model";
import {
  AnnotationUIsModel,
  LegacyUIsEnum,
  UICategoriesModel,
  UIsEnum,
} from "schemas/annotationUIs.model";
import {
  form_data_binary_2d,
  ui_schema_binary_2d,
} from "schemas/uiSchemas/binary_2d";
import {
  form_data_bounding_box_2d,
  ui_schema_bounding_box_2d,
} from "schemas/uiSchemas/bounding_box_2d";
import {
  form_data_discrete_2d,
  ui_schema_discrete_2d,
} from "schemas/uiSchemas/discrete_2d";
import {
  form_data_keypoint_2d,
  ui_schema_keypoint_2d,
} from "schemas/uiSchemas/keypoint_2d";
import {
  form_data_keypoint_3d,
  ui_schema_keypoint_3d,
} from "schemas/uiSchemas/keypoint_3d";
import {
  form_data_marker_3d,
  ui_schema_marker_3d,
} from "schemas/uiSchemas/marker_3d";
import {
  form_data_slider_2d,
  ui_schema_slider_2d,
} from "schemas/uiSchemas/slider_2d";
import { SubsetTypeModel } from "models/global.model";

export const UICategories: UICategoriesModel = {
  QUESTION: [UIsEnum.binary_2d, UIsEnum.discrete_2d, UIsEnum.slider_2d],
  BB: [UIsEnum.bounding_box_2d],
  KEYPOINT: [UIsEnum.keypoint_2d, UIsEnum.keypoint_3d, UIsEnum.marker_3d],
};

export const annotationUIs: AnnotationUIsModel = {
  [UIsEnum.binary_2d]: {
    name: "2D Binary",
    key: UIsEnum.binary_2d,
    postProcessingUiKey: LegacyUIsEnum.binary_2d,
    uiSchema: ui_schema_binary_2d,
    formData: form_data_binary_2d,
    mediaTypeOptions: {
      [Mediatype.image]: {
        [VisualisationTypeEnum.DEFAULT]: [
          SubsetTypeModel.Media,
          SubsetTypeModel.MediaObject,
        ],
        [VisualisationTypeEnum.CROP]: [SubsetTypeModel.MediaObject],
      },
      [Mediatype.video]: {
        [VisualisationTypeEnum.DEFAULT]: [SubsetTypeModel.Media],
      },
    },
  },
  [UIsEnum.discrete_2d]: {
    name: "2D Categorical",
    key: UIsEnum.discrete_2d,
    postProcessingUiKey: LegacyUIsEnum.discrete_2d,
    uiSchema: ui_schema_discrete_2d,
    formData: form_data_discrete_2d,
    mediaTypeOptions: {
      [Mediatype.image]: {
        [VisualisationTypeEnum.DEFAULT]: [
          SubsetTypeModel.Media,
          SubsetTypeModel.MediaObject,
        ],
        [VisualisationTypeEnum.CROP]: [SubsetTypeModel.MediaObject],
      },
      [Mediatype.video]: {
        [VisualisationTypeEnum.DEFAULT]: [SubsetTypeModel.Media],
      },
    },
  },
  [UIsEnum.slider_2d]: {
    name: "2D Slider",
    key: UIsEnum.slider_2d,
    postProcessingUiKey: LegacyUIsEnum.slider_2d,
    uiSchema: ui_schema_slider_2d,
    formData: form_data_slider_2d,
    mediaTypeOptions: {
      [Mediatype.image]: {
        [VisualisationTypeEnum.DEFAULT]: [
          SubsetTypeModel.Media,
          SubsetTypeModel.MediaObject,
        ],
        [VisualisationTypeEnum.CROP]: [SubsetTypeModel.MediaObject],
      },
      [Mediatype.video]: {
        [VisualisationTypeEnum.DEFAULT]: [SubsetTypeModel.Media],
      },
    },
  },
  [UIsEnum.bounding_box_2d]: {
    name: "2D Bounding box",
    key: UIsEnum.bounding_box_2d,
    postProcessingUiKey: LegacyUIsEnum.bounding_box_2d,
    uiSchema: ui_schema_bounding_box_2d,
    formData: form_data_bounding_box_2d,
    mediaTypeOptions: {
      [Mediatype.image]: {
        [VisualisationTypeEnum.DEFAULT]: [SubsetTypeModel.Media],
        [VisualisationTypeEnum.TILE]: [SubsetTypeModel.Media],
      },
    },
  },
  [UIsEnum.keypoint_2d]: {
    name: "2D Keypoint",
    key: UIsEnum.keypoint_2d,
    postProcessingUiKey: LegacyUIsEnum.keypoint_2d,
    uiSchema: ui_schema_keypoint_2d,
    formData: form_data_keypoint_2d,
    mediaTypeOptions: {
      [Mediatype.image]: {
        [VisualisationTypeEnum.DEFAULT]: [SubsetTypeModel.Media],
        [VisualisationTypeEnum.CROP]: [SubsetTypeModel.MediaObject],
        [VisualisationTypeEnum.TILE]: [SubsetTypeModel.Media],
      },
    },
  },
  [UIsEnum.marker_3d]: {
    name: "3D Marker",
    key: UIsEnum.marker_3d,
    postProcessingUiKey: LegacyUIsEnum.marker_3d,
    uiSchema: ui_schema_marker_3d,
    formData: form_data_marker_3d,
    mediaTypeOptions: {
      [Mediatype.point_cloud]: {
        [VisualisationTypeEnum.DEFAULT]: [SubsetTypeModel.MediaObject],
      },
    },
  },
  [UIsEnum.keypoint_3d]: {
    name: "2D Keypoint on 3D space",
    key: UIsEnum.keypoint_3d,
    postProcessingUiKey: LegacyUIsEnum.keypoint_3d,
    uiSchema: ui_schema_keypoint_3d,
    formData: form_data_keypoint_3d,
    mediaTypeOptions: {
      [Mediatype.point_cloud]: {
        [VisualisationTypeEnum.DEFAULT]: [SubsetTypeModel.Media],
      },
    },
  },
};
