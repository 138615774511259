import { SyntheticEvent, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setSnackbar } from "../../store/appSlice";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { isEmpty } from "lodash";
import { SnackbarModel } from "models/global.model";

const SnackbarComp = () => {
  const snackbarState = useAppSelector((state) => state?.appSlice?.snackbar);
  const dashboardError = useAppSelector((state) => state?.dashboardSlice.error);
  const filtersError = useAppSelector((state) => state.filterDataSlice.error);
  const mediaExplorationError = useAppSelector(
    (state) => state?.explorationMediaSlice.error,
  );
  const mediaObjectsExplorationError = useAppSelector(
    (state) => state?.explorationMediaObjectsSlice.error,
  );

  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [localSnackbarState, setLocalSnackbarState] = useState<SnackbarModel>({
    msg: "",
    severity: "info",
  });

  useEffect(() => {
    if (!isEmpty(snackbarState?.msg)) {
      setLocalSnackbarState(snackbarState);
      setOpen(true);
    }
  }, [snackbarState]);

  useEffect(() => {
    if (!isEmpty(dashboardError?.message)) {
      setLocalSnackbarState({
        msg:
          `Dashboard: ${dashboardError?.message}` ||
          "Something went wrong fetching datasets!",
        severity: "error",
      });
      setOpen(true);
    }
  }, [dashboardError]);

  useEffect(() => {
    if (!isEmpty(filtersError?.message)) {
      setLocalSnackbarState({
        msg:
          `Filters: ${filtersError?.message}` ||
          "Something went wrong fetching filters!",
        severity: "error",
      });
      setOpen(true);
    }
  }, [filtersError]);

  useEffect(() => {
    if (!isEmpty(mediaExplorationError?.message)) {
      setLocalSnackbarState({
        msg:
          `Media exploration: ${mediaExplorationError?.message}` ||
          "Something went wrong on media explorations fetching!",
        severity: "error",
      });
      setOpen(true);
    }
  }, [mediaExplorationError]);

  useEffect(() => {
    if (!isEmpty(mediaObjectsExplorationError?.message)) {
      setLocalSnackbarState({
        msg:
          `Media objects exploration: ${mediaObjectsExplorationError?.message}` ||
          "Something went wrong on media objects explorations fetching!",
        severity: "error",
      });
      setOpen(true);
    }
  }, [mediaObjectsExplorationError]);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSnackbar({ msg: "", severity: "info" }));
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={localSnackbarState?.duration || 4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <div>
        <Alert onClose={handleClose} severity={localSnackbarState?.severity}>
          {localSnackbarState?.msg}
        </Alert>
      </div>
    </Snackbar>
  );
};

export default SnackbarComp;
