import { ChangeEvent, useState } from "react";
import { useAppDispatch } from "store/hooks";
import BasicButton from "components/Internal/Buttons/BasicButton";
import {
  CroppingFEParamsModel,
  croppingFEParamsModelToCroppingParamsModel,
} from "models/tiling.model";

import _ from "lodash";
import { snakeCaseToText } from "components/utilFunctions";
import { sendCropRequest } from "helpers/apis/transformations";

interface Props {
  datasetID: string;
  subsetID: string;
}

const CropTab = ({ datasetID, subsetID }: Props) => {
  const dispatch = useAppDispatch();

  const [croppingParams, setCroppingParams] = useState<CroppingFEParamsModel>({
    padding_percent: 40,
    padding_minimum: 40,
    max_size_x: 200,
    max_size_y: 200,
    aspect_ratio_x: 1,
    aspect_ratio_y: 1,
    box_type_qm: true,
    box_type_reference: true,
  });

  const renderSubmitButton = () => {
    return (
      <div className="flex justify-end mt-4">
        <BasicButton
          label="Send"
          onClick={handleSubmit}
          disabled={datasetID === "" || subsetID === ""}
        />
      </div>
    );
  };

  const handleCroppingInputChange = (
    key: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const value = JSON.parse(event?.target?.value);
    setCroppingParams({
      ...croppingParams,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    sendCropRequest(
      { datasetID: datasetID, subsetID: subsetID },
      {
        croppingParams:
          croppingFEParamsModelToCroppingParamsModel(croppingParams),
      },
      dispatch,
    );
  };

  return (
    <div className="w-1/2">
      {_.map(croppingParams, (value, key) => {
        return (
          <div className="flex justify-between my-1">
            <label>{snakeCaseToText(key, true)}:</label>
            {_.isNumber(value.valueOf()) && (
              <input
                name={key}
                className="input-text !w-1/2"
                type={typeof value.valueOf()}
                value={_.isNumber(value) ? value.valueOf() : 0}
                onChange={(e) => handleCroppingInputChange(key, e)}
              />
            )}
            {_.isBoolean(value.valueOf()) && (
              <input
                name={key}
                type={_.isBoolean(value.valueOf()) ? "checkbox" : "checkbox"}
                value={value.valueOf().toString()}
                onChange={(e) => handleCroppingInputChange(key, e)}
              />
            )}
          </div>
        );
      })}
      {renderSubmitButton()}
    </div>
  );
};

export default CropTab;
