import { AnnotatableTypeModel, TagTypeModel } from "models/global.model";

export enum AttributeGroupEnum {
  ANNOTATION_ATTRIBUTE = "annotation_attribute",
  ML_ANNOTATION_ATTRIBUTE = "ml_annotation_attribute",
  AUTO_ATTRIBUTE = "auto_attribute",
  INITIAL_ATTRIBUTE = "initial_attribute",
}
export type AttributeType =
  | "BINARY"
  | "CATEGORICAL"
  | "SLIDER"
  | "POINT2D"
  | "BBOX2D"
  | null;

export interface AttributeMetadataModel {
  id: string;
  name: string;
  question: string;
  subset_ids: string[];
  annotatable_type: AnnotatableTypeModel;
  attribute_type: AttributeType;
  attribute_group: AttributeGroupEnum;
  pipeline_project: string;
  subset_attributes: Record<string, unknown>;
  timestamp: string;
  archived: boolean | undefined;
}

export interface AttributesModel {
  id: string;
  metadata_id: string;
  dataset_ids: string[];
  tags: string[];
  annotatable_id: string;
  database_object_type: TagTypeModel;
  value: unknown;
  modal: unknown;
  credibility: number;
  ambiguity?: number;
  frequency: { [key: string]: unknown }[];
  cant_solves: number;
  min: number | null;
  max: number | null;
}
