import { ReactComponent as NoImageIcon } from "assets/no_image.svg";

const NoPreview = () => {
  return (
    <div
      className="w-full h-full border-[1px] border-paletteGray-4 rounded-xl
        flex flex-col items-center
    "
    >
      <div className="py-6">
        <div className="w-[360px] h-[48px] rounded-xl bg-paletteGray-4" />
      </div>

      <div className="w-full flex-1 min-h-0">
        <div className="w-full h-full rounded-xl bg-paletteGray-4 flex items-center justify-center">
          <div className="flex flex-col items-center gap-3">
            <NoImageIcon className="w-10 h-10 text-paletteGray-8" />
            <div className="text-paletteGray-12 flex flex-col items-center">
              <div>
                To enable the Live Preview, select data, configure annotation
                type and click 'Show preview'.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-6 pt-10">
        <div className="w-[302px] h-[84px] rounded-xl bg-paletteGray-5" />
      </div>
    </div>
  );
};

export default NoPreview;
