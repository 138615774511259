import { Fragment, useState } from "react";
import { useAppDispatch } from "store/hooks";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { FormModel, TextFieldModel } from "models/form.model";
import Form from "components/Internal/Forms/Form";
import sendFeedbackForm from "helpers/forms/sendFeedbackForm";
import SidebarIcon from "components/UtilComponents/SidebarMenu/SidebarIcon";
import keycloak from "keycloak";

const FeedbackDialog = () => {
  const dispatch = useAppDispatch();
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const feedbackForm = {
    mail: {
      type: "text",
      key: "mail",
      value: keycloak?.idTokenParsed?.email,
      label: "Email",
      required: true,
      settings: {
        isEmail: true,
      },
    } as TextFieldModel,
    name: {
      type: "text",
      key: "name",
      value: `${keycloak?.idTokenParsed?.given_name} ${keycloak?.idTokenParsed?.family_name}`,
      label: "Name",
      required: false,
    } as TextFieldModel,
    company: {
      type: "text",
      key: "company",
      value: "",
      label: "Company",
      required: false,
    } as TextFieldModel,
    jobTitle: {
      type: "text",
      key: "jobTitle",
      value: "",
      label: "Job Title",
      required: false,
    } as TextFieldModel,
    request: {
      type: "textarea",
      key: "request",
      value: "",
      label: "Request",
      required: true,
    } as TextFieldModel,
  };
  const [formState, setFormState] = useState<FormModel>(feedbackForm);

  const handleOnSubmit = (formToSubmit: typeof feedbackForm) => {
    const feedbackTemplateParams = {
      from_email: formToSubmit?.mail?.value,
      name: formToSubmit?.name?.value,
      company: formToSubmit?.company?.value,
      job_title: formToSubmit?.jobTitle?.value,
      request: formToSubmit?.request?.value,
    };
    sendFeedbackForm(dispatch, feedbackTemplateParams, setIsLoading);
    setIsFeedbackDialogOpen(false);
  };

  return (
    <Fragment>
      <SidebarIcon
        icon="feedback"
        onClick={() => setIsFeedbackDialogOpen(true)}
        small={true}
      />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isFeedbackDialogOpen}
        onClose={() => setIsFeedbackDialogOpen(false)}
      >
        <DialogContent>
          <div className="text-lg font-semibold">Feedback</div>
          <div className="pb-2">
            Please fill out the form to provide feedback.
          </div>
          <div className="flex flex-col w-full">
            <Form
              formData={formState}
              setFormData={setFormState}
              handleOnSubmit={(formToSubmit) =>
                handleOnSubmit(formToSubmit as typeof feedbackForm)
              }
              isLoading={isLoading}
            />
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default FeedbackDialog;
