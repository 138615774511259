export enum GeometriesEnum {
  BoundingBox2D = "bbox2d_center_point",
  BoundingBox2DAggregation = "bbox2d_center_point_aggregation",
  Point2D = "point2d_xy",
  Point2DAggregation = "point2d_xy_aggregation",
  Polyline2DFlatCoordinates = "polyline_2d_flat_coordinates",
  CuboidCenterPoint = "cuboid_center_point",
  Point3DXYZ = "point3d_xyz",
  Point3dXYZAggregation = "point3d_xyz_aggregation",
}

export interface GeometryModel {
  id: number;
  type: GeometriesEnum;
}

export interface BoundingBox2D extends GeometryModel {
  type: GeometriesEnum.BoundingBox2D | GeometriesEnum.BoundingBox2DAggregation;
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface BoundingBox2DAggregation extends BoundingBox2D {
  type: GeometriesEnum.BoundingBox2DAggregation;
  metrics: {
    iou_to_aggregated_box: {
      aggregate: number[];
      credibility: number;
    };
    distance_to_center_of_aggregated_box: {
      aggregate: number[];
      credibility: number;
    };
    absolute_difference_to_area_of_aggregated_box: {
      aggregate: number[];
      credibility: number;
    };
  };
}

export interface Point2DXY {
  type: GeometriesEnum.Point2D | GeometriesEnum.Point2DAggregation;
  x: number;
  y: number;
}

export interface Polyline2DFlatCoordinates {
  type: GeometriesEnum.Polyline2DFlatCoordinates;
  coordinates: number[];
}

export interface CuboidCenterPoint {
  type: GeometriesEnum.CuboidCenterPoint;
  position: [number, number, number];
  heading: [number, number, number, number];
  dimensions: [number, number, number];
}

export interface Point3dXYZAggregation {
  type: GeometriesEnum.Point3dXYZAggregation;
  x: number;
  y: number;
  z: number;
}

export interface Point3dXYZ {
  type: GeometriesEnum.Point3DXYZ;
  x: number;
  y: number;
  z: number;
}

export type GeometryTypes =
  | BoundingBox2D
  | BoundingBox2DAggregation
  | Point2DXY
  | Polyline2DFlatCoordinates
  | CuboidCenterPoint
  | Point3dXYZAggregation
  | Point3dXYZ;
