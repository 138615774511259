import _ from "lodash";
import { VisualisationConfigurationModel } from "models/configurations.model";
import { InstanceModel } from "models/exploration.model";

/**
 * Get the URL of the instance.
 * - If we have selected a visualisation with a timestamp, use that.
 * - If the visualisation has a subset_id, use that instead.
 * - If the visualisation configuration ID is not found, use the last visualisation.
 * Default: undefined
 * @param instance The instance to get the URL from
 * @param visualisationConfigurations The visualisation configurations
 * @param subsetId The current subset ID
 * @param selectedVisualizationIDWithTimeStamps The selected visualisation ID with timestamps
 * @returns The URL of the instance
 */
const selectInstanceURL = (
  instance: InstanceModel,
  visualisationConfigurations: VisualisationConfigurationModel[] | null,
  subsetId: string | undefined,
  selectedVisualizationIDWithTimeStamps: string | null
): string | undefined => {
  // If we have selected a visualisation with a timestamp, use that
  if (selectedVisualizationIDWithTimeStamps !== null) {
    return getSelectedVisualization(
      instance,
      selectedVisualizationIDWithTimeStamps
    );
  }

  // If the visualisation has a subset_id, use that instead
  if (visualisationConfigurations !== null) {
    // Find the visualisation configuration ID for the subset
    const configID: null | string = findVisualisationConfigurationID(
      visualisationConfigurations,
      subsetId
    );
    // If the visualisation configuration ID is found, use that instead
    if (configID !== null) {
      return getSubsetVisualization(instance, configID);
    }
    // If the visualisation configuration ID is not found, use the last visualisation
    else {
      return getLastVisualization(instance);
    }
  }

  return undefined;
};

const findVisualisationConfigurationID = (
  visualisationConfigurations: VisualisationConfigurationModel[] | null,
  subsetId: string | undefined
): string | null => {
  // Find the visualisation configuration ID for the subset
  let configID: null | string = null;
  _.map(visualisationConfigurations, (configuration) => {
    if (_.includes(configuration?.subset_ids, subsetId)) {
      configID = configuration.id;
    }
  });
  return configID;
};

const getSelectedVisualization = (
  instance: InstanceModel,
  selectedVisualizationIDWithTimeStamps: string | null
): string | undefined => {
  const visualisationForSubset = _.find(
    instance?.visualisations,
    (visualisation) => {
      return (
        visualisation?.visualisation_configuration_id +
          "_" +
          visualisation.timestamp ===
        selectedVisualizationIDWithTimeStamps
      );
    }
  );

  return visualisationForSubset?.media_url;
};

const getSubsetVisualization = (
  instance: InstanceModel,
  configID: string
): string | undefined => {
  const visualisationForSubset = _.find(
    instance?.visualisations,
    (visualisation) => {
      return visualisation?.visualisation_configuration_id === configID;
    }
  );

  return visualisationForSubset?.media_url;
};

const getLastVisualization = (instance: InstanceModel): string | undefined => {
  if (_.isUndefined(instance?.visualisations)) {
    return undefined;
  }
  return instance?.visualisations[instance?.visualisations?.length - 1]
    ?.media_url;
};

export default selectInstanceURL;
