import React, { useEffect, useState } from "react";
import { TagTypeModel } from "models/global.model";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  setQaResultsSummary,
  statisticsStateTypes,
} from "store/statisticsSlice";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { StatisticsScreenRouteModel } from "models/routes.model";
import addSubsetToQuery from "helpers/functions/addSubsetToQuery";
import { SendFilterModel } from "models/filter.model";
import BarChartComp, {
  BarChartCompDataModel,
} from "components/Statistics/Charts/BarChartComp";
import chartsColor from "components/Statistics/chartsColors";
import { fetchMediaObjectsCount } from "helpers/apis/mediaObjects";
import { MetaDataCountModel } from "models/metaData.model";

interface Props {
  showInputs: boolean;
}

const ResultsSummary = ({ showInputs }: Props) => {
  const dispatch = useAppDispatch();
  const params: StatisticsScreenRouteModel = useParams();
  const chartData = useAppSelector(
    (state) => state.statisticsSlice.charts.qa_results_summary
  );
  const tags = useAppSelector((state) => state.datasetSlice.tags);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getMediaObjectCount().then((response) => {
      if (response) {
        dispatch(
          setQaResultsSummary({
            ...chartData,
            totalCount: response?.total_count,
          })
        );
      }
    });
  }, [params?.dataset_id, params?.subset_id]);

  const renderCorrectnessSelector = (
    type: "Correct" | "Incorrect" | "Ambiguous"
  ) => {
    const filteredTags = _.filter(
      tags,
      (tag) => tag.database_object_type === TagTypeModel.MediaObject
    );
    return (
      <div
        key={`selector_${type}`}
        className="pl-3 py-1 pr-2 bg-paletteGray-3 flex items-center justify-between gap-x-3 rounded"
      >
        {`${type} tag `}
        <div className="w-[150px]">
          <select
            className="w-full"
            value={chartData?.[type]}
            onChange={(event) => {
              const id = event?.target?.value;
              getMediaObjectCount(id).then((response) => {
                dispatch(
                  setQaResultsSummary({
                    ...chartData,
                    [type]: id,
                    data: { ...chartData.data, [type]: response?.total_count },
                  })
                );
              });
            }}
            disabled={isLoading}
          >
            <option value="">--Select {type}--</option>
            {_.map(filteredTags, (tag) => (
              <option key={tag?.id} value={tag?.id}>
                {tag?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const getMediaObjectCount = async (tagID?: string) => {
    let sendFilters: SendFilterModel[] = [];
    if (tagID) {
      sendFilters = [
        ...sendFilters,
        {
          attribute: "tags",
          query_operator: "in",
          value: [tagID],
        },
      ];
    }
    const body = addSubsetToQuery(params.subset_id, sendFilters);

    setIsLoading(true);
    let responseHolder: MetaDataCountModel | null = null;

    responseHolder = await fetchMediaObjectsCount(
      params.dataset_id,
      body,
      dispatch
    );
    setIsLoading(false);
    return responseHolder;
  };

  const renderChart = () => {
    let data: BarChartCompDataModel = [];
    let maxValue = 1;
    _.map(chartData.data, (count, name) => {
      if (count > maxValue) {
        maxValue = count;
      }
      data = [...data, { name: name, count: count }];
    });

    const CorrectPercentage = _.round(
      (chartData?.data?.Correct * 100) / (chartData?.totalCount || 1),
      2
    );
    const IncorrectPercentage = _.round(
      (chartData?.data?.Incorrect * 100) / (chartData?.totalCount || 1),
      2
    );
    const AmbiguousPercentage = _.round(
      (chartData?.data?.Ambiguous * 100) / (chartData?.totalCount || 1),
      2
    );
    const chartID: keyof statisticsStateTypes["charts"] = "qa_results_summary";
    return (
      <div className="w-full h-full flex flex-col items-center" id={chartID}>
        <BarChartComp
          data={data}
          showLegend={false}
          height={90}
          width={60}
          yAxis={{ domain: [0, _.ceil(maxValue * 1.1)] }}
          percentage={{ show: true, totalCount: chartData?.totalCount || 2 }}
        />
        <div className="flex gap-x-4 pl-16 text-sm ">
          <div
            className="border-2 rounded px-2 py-1"
            style={{ color: chartsColor[0], borderColor: chartsColor[0] }}
          >
            {`Correct: (${
              _.find(tags, (tag) => tag?.id === chartData.Correct)?.name || ""
            }) - 
                ${chartData?.data?.Correct} (${CorrectPercentage}%)`}
          </div>
          <div
            className="border-2 rounded px-2 py-1"
            style={{ color: chartsColor[1], borderColor: chartsColor[1] }}
          >
            {`Incorrect: (${
              _.find(tags, (tag) => tag?.id === chartData.Incorrect)?.name || ""
            }) - 
                ${chartData?.data?.Incorrect} (${IncorrectPercentage}%)`}
          </div>
          <div
            className="border-2 rounded px-2 py-1"
            style={{ color: chartsColor[2], borderColor: chartsColor[2] }}
          >
            {`Ambiguous: (${
              _.find(tags, (tag) => tag?.id === chartData.Ambiguous)?.name || ""
            }) - 
                ${chartData?.data?.Ambiguous} (${AmbiguousPercentage}%)`}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-full flex p-3">
      {showInputs && (
        <div
          className="w-[300px] h-full animate-fade overflow-y-auto"
          style={{
            transition: "width 0.1s",
          }}
        >
          <div className="text-sm flex flex-col gap-y-3 relative">
            <div className="pl-1">
              {chartData?.totalCount &&
                `Total Objects - ${chartData?.totalCount}`}
            </div>
            {renderCorrectnessSelector("Correct")}
            {renderCorrectnessSelector("Incorrect")}
            {renderCorrectnessSelector("Ambiguous")}
            {isLoading && (
              <div className="absolute w-full h-full bg-paletteGray-3 opacity-50"></div>
            )}
          </div>
        </div>
      )}

      <div
        className="h-full flex animate-fade"
        style={{
          width: showInputs ? "calc(100% - 300px)" : "100%",
          transition: "width 0.1s",
        }}
      >
        {renderChart()}
      </div>
    </div>
  );
};

export default ResultsSummary;
