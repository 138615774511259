import getQueryFromCurrentSelection from "helpers/functions/getQueryFromCurrentSelection";
import getQueryFromSelectedItems from "helpers/functions/getQueryFromSelectedItems";
import { SendFilterModel } from "models/filter.model";
import { SelectedItemsTypeModel, SelectedViewModel } from "models/global.model";
import { explorationInstanceStateTypes } from "store/explorationInstancesSlice";
import { explorationMediaObjectsStateTypes } from "store/explorationMediaObjectsSlice";
import { explorationMediaStateTypes } from "store/explorationMediaSlice";
import { filterDataStateTypes } from "store/filterDataSlice";

/**
 * This function returns the query based on the selected items type
 * It also adds the subset id to the query if the selected items type is all
 * @param selectedItemsType Selected items type (all or selected)
 * @param selectedView Selected view (Medias or Objects)
 * @param all
 * @param all.filterData The current filter data (media or media objects)
 * @param all.subset_id The current subset id
 * @param selected
 * @param selected.selectedMediaIDs The selected medias IDs (manually selected)
 * @param selected.selectedMediaObjectsIDs The selected media objects IDs (manually selected)
 * @param selected.selectedInstancesIDs The selected instances IDs (manually selected)
 * @returns The query based on the selected items type
 */
const getQueryBasedOnType = (
  selectedItemsType: SelectedItemsTypeModel,
  selectedView: SelectedViewModel,
  all: {
    filterData: filterDataStateTypes;
    subset_id: string;
  },
  selected: {
    selectedMediaIDs: explorationMediaStateTypes["selectedMediaIDs"];
    selectedMediaObjectsIDs: explorationMediaObjectsStateTypes["selectedMediaObjectsIDs"];
    selectedInstancesIDs: explorationInstanceStateTypes["selectedInstanceIDs"];
  }
) => {
  let filters: SendFilterModel[] = [];

  if (selectedItemsType === SelectedItemsTypeModel.all) {
    filters = getQueryFromCurrentSelection(
      selectedView,
      all?.filterData,
      all?.subset_id
    );
  } else if (selectedItemsType === SelectedItemsTypeModel.selected) {
    filters = getQueryFromSelectedItems(
      selectedView,
      selected?.selectedMediaIDs,
      selected?.selectedMediaObjectsIDs,
      selected?.selectedInstancesIDs
    );
  }
  return filters;
};

export default getQueryBasedOnType;
