import MoreButton from "components/Internal/Buttons/MoreButton";
import StandardPopoverWrapper from "components/Internal/Popovers/StandardPopoverWrapper";
import { AnnotationRunModel } from "models/annotationRun.model";
import { PipelineModel } from "models/pipelines.model";

export type DuplicateArchivePopoverConfirmDialogState = {
  show: boolean;
  action: "duplicate" | "archive";
  itemId: string | null;
};

/**
 * DuplicateArchivePopover component
 * A popover component that contains the actions for duplicating and archiving
 * a pipeline or an annotation run in the pipeline manager and annotation run manager tables
 */
type Props = {
  item: PipelineModel | AnnotationRunModel;
  setConfirmDialogState: (
    newState: DuplicateArchivePopoverConfirmDialogState,
  ) => void;
};

const DuplicateArchivePopover = ({ item, setConfirmDialogState }: Props) => {
  const getPipelineActionsList = () => {
    return [
      {
        label: "Duplicate",
        onItemClick: () =>
          setConfirmDialogState({
            show: true,
            action: "duplicate",
            itemId: item?.id,
          }),
      },
      {
        label: "Archive",
        onItemClick: () =>
          setConfirmDialogState({
            show: true,
            action: "archive",
            itemId: item?.id,
          }),

        className: "text-paletteRed",
      },
    ];
  };

  return (
    <StandardPopoverWrapper
      id={item?.id}
      buttonElement={<MoreButton />}
      itemsList={getPipelineActionsList()}
    />
  );
};

export default DuplicateArchivePopover;
