import { useKeycloak } from "@react-keycloak/web";
import StandardTabs from "components/Internal/Tabs/StandardTabs";
import _ from "lodash";
import { AnnotationRunModel } from "models/annotationRun.model";
import { PipelineModel } from "models/pipelines.model";

// Pipelines and annotation runs filter models
export enum ManagerTabsOptions {
  all = "All",
  me = "My",
  team = "Team",
}

type Props = {
  items: PipelineModel[] | AnnotationRunModel[];
  selectedTabValue: ManagerTabsOptions;
  setSelectedTabValue: (tab: ManagerTabsOptions) => void;
  labelSuffix?: string;
};

const PipelineAndAnnotationRunTabs = ({
  items,
  selectedTabValue,
  setSelectedTabValue,
  labelSuffix,
}: Props) => {
  const keycloak = useKeycloak();

  return (
    <div className="w-full ">
      <StandardTabs
        tabs={_.map(ManagerTabsOptions, (tab) => {
          return {
            label: tab + (labelSuffix ? ` ${labelSuffix}` : ""),
            value: tab,
            count: _.filter(
              items,
              (item: PipelineModel | AnnotationRunModel) => {
                if (tab === ManagerTabsOptions.me) {
                  return item?.owner === keycloak?.keycloak?.idTokenParsed?.sub;
                }
                if (tab === ManagerTabsOptions.team) {
                  return item?.owner !== keycloak?.keycloak?.idTokenParsed?.sub;
                }
                return true;
              },
            )?.length,
          };
        })}
        selectedTab={selectedTabValue}
        setSelectedTab={(tab) => setSelectedTabValue(tab as ManagerTabsOptions)}
      />
    </div>
  );
};

export default PipelineAndAnnotationRunTabs;
