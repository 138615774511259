import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { nFormatter } from "components/utilFunctions";

interface Props {
  value: number[];
  min: number;
  max: number;
  step?: number;
  numberOfBuckets?: number;
  onChange: (newValue: number[]) => void;
  showHorizontalLines?: boolean;
}

const DoubleSlider = ({
  value,
  min,
  max,
  step,
  numberOfBuckets = 20,
  onChange,
  showHorizontalLines = false,
}: Props) => {
  const [localSliderNewValue, setLocalSliderNewValue] =
    useState<number[]>(value);

  // Update local value when new value is provided
  useEffect(() => {
    setLocalSliderNewValue(value);
  }, [value]);

  const renderHorizontalLine = () => {
    if (showHorizontalLines) {
      return (
        <Divider className="h-[1px] w-full ml-[2px] bg-black opacity-90" />
      );
    }
  };

  return (
    <div className="flex flex-col content-center ">
      {renderHorizontalLine()}
      <Slider
        value={localSliderNewValue}
        defaultValue={value}
        onChange={(event: any, newValue: number | number[]) => {
          setLocalSliderNewValue(newValue as number[]);
        }}
        onChangeCommitted={() => {
          onChange(localSliderNewValue);
        }}
        slots={{
          valueLabel: ValueLabelComponent,
        }}
        valueLabelDisplay="auto"
        valueLabelFormat={nFormatter}
        step={step || (max - min) / numberOfBuckets}
        min={min}
        max={max}
        marks
        slotProps={{
          root: {
            className: "w-full  mt-[4px] mr-[0px] mb-[4px] ml-[6px]",
          },
          track: {
            className: "text-paletteOrange h-[12px]",
          },
          thumb: {
            className: "rounded-sm h-[28px] w-[12px] text-paletteBlack-2",
          },
          mark: {
            className: `h-[12px] ${
              numberOfBuckets > 36 ? "text-black w-[0px]" : "text-white"
            }`,
          },
          rail: {
            className: "h-[12px] text-paletteGray-5 opacity-100",
          },
        }}
      />
      {renderHorizontalLine()}
    </div>
  );
};

export default DoubleSlider;

function ValueLabelComponent(props: {
  children: React.ReactElement;
  open: boolean;
  value: number;
}) {
  const { children, open, value } = props;
  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
      TransitionProps={{ timeout: 0 }}
      classes={{
        tooltip: "bg-black",
      }}
    >
      {children}
    </Tooltip>
  );
}
