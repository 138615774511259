export enum ListType {
  CSV = "CSV",
  Newline = "Newline",
  Tab = "Tab",
  Space = "Space",
  Semicolon = "Semicolon",
  Pipe = "Pipe",
  JSON = "JSON",
  Markdown = "Markdown",
  Unknown = "Unknown",
}

// Note: Order is important here because some formats are subsets of others
export function detectListType(input: string): ListType {
  // Check for JSON (validating entire structure would need a try-catch with JSON.parse)
  try {
    JSON.parse(input);
    return ListType.JSON;
  } catch {
    //not json");
  }

  // Check for Markdown (ensure all lines start with '- ')
  if (/^\s*- [^\n]*(\n\s*- [^\n]*)*$/.test(input)) return ListType.Markdown;

  // Check for CSV (look for lines with commas, but not other delimiters)
  if (/^[^|\t;\n]*,[^|\t;\n]*(\n[^|\t;\n]*,[^|\t;\n]*)*$/.test(input))
    return ListType.CSV;

  // Similar checks for Pipe, Tab, Semicolon, making sure other delimiters aren't present
  if (/^[^,\t;\n]*\|[^,\t;\n]*(\n[^,\t;\n]*\|[^,\t;\n]*)*$/.test(input))
    return ListType.Pipe;
  if (/^[^,|\n]*\t[^,|\n]*(\n[^,|\n]*\t[^,|\n]*)*$/.test(input))
    return ListType.Tab;
  if (/^[^,|\t\n]*;[^,|\t\n]*(\n[^,|\t\n]*;[^,|\t\n]*)*$/.test(input))
    return ListType.Semicolon;

  // Check for Newline-separated (make sure no other delimiters are present)
  if (/^[^,|;\t]*\n[^,|;\t]*$/.test(input)) return ListType.Newline;

  // Check for Space-separated
  if (/^\s*[\S]+\s+[\S]+\s*/.test(input)) return ListType.Space;

  // will probably never be reached
  return ListType.Unknown;
}

export function parseList(input: string): string[] | null {
  const type = detectListType(input);

  switch (type) {
    case ListType.CSV:
      return input.split(",").map((item) => item.trim());
    case ListType.Newline:
      return input.split(/\r?\n/).map((item) => item.trim());
    case ListType.Tab:
      return input.split("\t");
    case ListType.Space:
      return input.split(" ").filter((item) => item.trim() !== "");
    case ListType.Semicolon:
      return input.split(";").map((item) => item.trim());
    case ListType.Pipe:
      return input.split("|");
    case ListType.JSON:
      try {
        return JSON.parse(input);
      } catch (error) {
        console.error("Failed to parse JSON", error);
        return null;
      }
    case ListType.Markdown:
      return input.split("\n").map((item) => item.replace(/^- /, "").trim());
    default:
      console.warn("Unknown list format");
      return null;
  }
}
