import { getSelectedItemsBasedOnSelectedView } from "helpers/functions/selectedViewHelpers";
import {
  SendFilterComparisonModel,
  SendFilterModel,
} from "models/filter.model";
import { SelectedViewModel } from "models/global.model";
import { explorationInstanceStateTypes } from "store/explorationInstancesSlice";
import { explorationMediaObjectsStateTypes } from "store/explorationMediaObjectsSlice";
import { explorationMediaStateTypes } from "store/explorationMediaSlice";

/**
 * Function to get the query of the selected items (Medias or Objects)
 * @param selectedView The current selected view (Medias or Objects)
 * @param selectedMediaIDs The selected medias IDs
 * @param selectedMediaObjectsIDs The selected media objects IDs
 * @param selectedInstancesIDs The selected instances IDs
 * @returns The query of the selected items
 */
const getQueryFromSelectedItems = (
  selectedView: SelectedViewModel,
  selectedMediaIDs: explorationMediaStateTypes["selectedMediaIDs"],
  selectedMediaObjectsIDs: explorationMediaObjectsStateTypes["selectedMediaObjectsIDs"],
  selectedInstancesIDs: explorationInstanceStateTypes["selectedInstanceIDs"]
): SendFilterModel[] => {
  const selectedItemsQuery: SendFilterComparisonModel = {
    attribute: "id",
    query_operator: "in",
    value: getSelectedItemsBasedOnSelectedView(
      selectedView,
      selectedMediaIDs,
      selectedMediaObjectsIDs,
      selectedInstancesIDs
    ),
  };
  return [selectedItemsQuery];
};

export default getQueryFromSelectedItems;
