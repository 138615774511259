import FormErrorCircle from "components/Internal/Inputs/Form/ErrorCircle";
import FormErrorLabel from "components/Internal/Inputs/Form/ErrorLabel";
import FormLabel from "components/Internal/Inputs/Form/Label";
import { validateBooleanInput } from "components/Internal/Inputs/Form/formInputsHelpers";
import _ from "lodash";
import { BooleanFieldModel } from "models/form.model";

interface Props {
  field: BooleanFieldModel;
  value: boolean;
  handleOnChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    field: BooleanFieldModel,
    isErrored?: boolean,
    error?: string | null,
  ) => void;

  autoFocus?: boolean;
}

const BooleanInput = ({ field, value, handleOnChange, autoFocus }: Props) => {
  const { disabled } = field;

  const renderBody = () => {
    const sameLine = field?.style?.labelAndFieldOnTheSameLine;
    if (sameLine) {
      return renderSameLineAlignment();
    }

    return renderMultiLineAlignment();
  };

  const renderMultiLineAlignment = () => {
    return (
      <div key={field?.key} className={`w-full ${field?.style?.wrapper}`}>
        <FormLabel field={field} />
        {renderInput()}
        <FormErrorLabel
          error={field?.error ?? null}
          validationError={validateBooleanInput(field)}
        />
      </div>
    );
  };

  const renderSameLineAlignment = () => {
    return (
      <div
        key={field?.key}
        className={`flex justify-between items-center
          ${field?.style?.wrapper}
      `}
      >
        <div className="w-1/2">
          <FormLabel field={field} />
        </div>
        <div className="w-1/2 flex items-center justify-end">
          {renderInput()}
          <FormErrorCircle
            error={field?.error ?? null}
            validationError={validateBooleanInput(field)}
          />
        </div>
      </div>
    );
  };

  const renderInput = () => {
    const validationError = validateBooleanInput(field);
    const isError = !_.isNull(validationError);
    const inputClassName = field?.style?.field;

    const size = field?.style?.size ? getSize() : 24;
    return (
      <input
        type="checkbox"
        className={`checkbox-layer ${inputClassName ? inputClassName : ""}`}
        style={{
          width: size + "px",
          height: size + "px",
          padding: size / 4 + "px",
          borderRadius: size / 4 + "px",
        }}
        checked={value}
        onChange={(event) => handleOnChange(event, field)}
        onBlur={(event) =>
          handleOnChange(event, field, isError, validationError)
        }
        disabled={disabled}
        autoFocus={autoFocus}
      />
    );
  };

  const getSize = () => {
    switch (field?.style?.size) {
      case "small":
        return 16;
      case "medium":
        return 24;
      case "large":
        return 32;
      default:
        return 24;
    }
  };

  return renderBody();
};

export default BooleanInput;
