import { useHistory } from "react-router-dom";
import { getPipelineDesignerPageRoute } from "routes/routesHelper";
import { ReactComponent as Plus } from "assets/plus.svg";

const CreateNewPipelineButton = () => {
  const history = useHistory();

  return (
    <div
      className="button-dark-gray-layer w-fit"
      data-test="create_new_pipeline_button"
      onClick={() =>
        history.push(getPipelineDesignerPageRoute({ pipeline_id: "new" }))
      }
    >
      <Plus className={"w-4 h-4"} />
      Create new pipeline
    </div>
  );
};

export default CreateNewPipelineButton;
