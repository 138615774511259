import IsNotPopover, {
  IsNotType,
} from "components/Internal/Filters/HelperComponents/IsNotPopover";
import _ from "lodash";
import { FilterModel } from "models/filter.model";
import { ExplorationScreenRouteModel } from "models/routes.model";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";

type Props = {
  filter: FilterModel;
  handleFilterChange: (newValue: FilterModel) => void;
};

const SelectAttributeFilter = ({ filter, handleFilterChange }: Props) => {
  const params: ExplorationScreenRouteModel = useParams();

  const datasetData = useAppSelector((state) => state?.datasetSlice);

  const [anchorIsNotEl, setAnchorIsNotEl] = useState<HTMLElement | null>(null);

  const getName = () => {
    const filterName =
      _.find(datasetData.attributes, ["id", filter.attribute_id])?.name || "";
    return filterName;
  };

  const getValue = () => {
    let defaultVal: IsNotType = "off";
    if (_.isEmpty(filter?.FE_value)) {
      defaultVal = "off";
    } else if (!_.isEmpty(filter?.FE_value)) {
      if (filter?.FE_is_not === true) {
        defaultVal = "not";
      } else if (filter?.FE_is_not === false) {
        defaultVal = "is";
      }
    }
    return defaultVal;
  };

  const handleChange = (type: IsNotType) => {
    let newObj: FilterModel = {
      ...filter,
    };

    // If the user selects a new attribute, we need to reset the filter:
    if (type === "off") {
      newObj = {
        ...newObj,
        FE_value: "",
      };
    }
    // If the value is true, the filter checks for the presence of the attribute
    else if (type === "is") {
      newObj = {
        ...newObj,
        FE_value: params?.id,
        FE_is_not: false,
      };
    }
    // If the value is false, the filter checks for the absence of the attribute
    // (i.e. the attribute is not present)
    // We set the FE_is_not flag to true to indicate this
    else if (type === "not") {
      newObj = {
        ...newObj,
        FE_value: params?.id,
        FE_is_not: true,
      };
    }
    handleFilterChange(newObj);
  };

  return (
    <div className="p-3 flex justify-between bg-paletteGray-1">
      <div className="flex items-center text-paletteBlack-2">{getName()}</div>
      <IsNotPopover
        anchorEl={anchorIsNotEl}
        handlePopoverOpen={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
          setAnchorIsNotEl(event.currentTarget)
        }
        handlePopoverClose={() => setAnchorIsNotEl(null)}
        selectedValue={getValue()}
        inCludeOff={true}
        onClick={(type) => handleChange(type)}
      />
    </div>
  );
};

export default SelectAttributeFilter;
