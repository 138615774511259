import {
  LogicalQueryOperatorsModels,
  QueryOperatorModel,
} from "./global.model";

export type FiltersTypesModel =
  | "NUMERICAL"
  | "BOOLEAN"
  | "CATEGORICAL"
  | "SUBSET"
  | "SELECT_ATTRIBUTE"
  | "FE_SUBSET"
  | "FE_TAG"
  | "SEARCH_BY_TEXT";

export enum FilterCategoryEnum {
  Annotation_Attribute = "annotation_attribute",
  ML_Annotation_Attribute = "ml_annotation_attribute",
  Auto_Attribute = "auto_attribute",
  Initial_Attribute = "initial_attribute",
  Saved_Filter = "saved_filter",
  Text_Filter = "text_filter",
  Subsets_And_Tags = "subsets_and_tags",
}

export enum FilterAttributeGroupEnum {
  Annotation_Attribute = "annotation_attribute",
  ML_Annotation_Attribute = "ml_annotation_attribute",
  Auto_Attribute = "auto_attribute",
  Initial_Attribute = "initial_attribute",
  Text_Filter = "text_filter",
  Subsets_And_Tags = "subsets_and_tags",
}

// Model of filter response from API
export interface ResponseFilterModel {
  attribute_name: string;
  attribute_id: string;
  attribute_group: FilterAttributeGroupEnum;
  type: FiltersTypesModel;
  lower: number | null;
  upper: number | null;
  statistics: {
    variance: number;
    average: number;
    quantiles_25: number;
    quantiles_50: number;
    quantiles_75: number;
    interquartile_range: number;
    shapiro_p_value: number;
  } | null;
  categories: [string | number] | never[] | null;
  num_buckets: number;
  buckets: [string | number, number | null][];
  interval: number | null;
  filter_name: string;
  cant_solves: number;
  corrupt_data: number;
  dataset_id: string;
  subset_id: string;
  // FE is for added helper fields for the frontend
  // FE_value is used for when we pass an value of a frontend only filter
  //  for example in "Select Attribute" filter we pass the dataset id
  FE_value?: string;
  // FE_is_not_filter_on_attribute is used for filters that search in fields not in
  //  the attribute field e.g (search by item id, tags, subsets, source, mediaType, etc)
  FE_is_not_filter_on_attribute?: boolean;
  // FE_case_value_to_number is used for search by text filters to convert the value
  //  to a number
  FE_cast_value_to_number?: boolean;
}

// Model of filter to store in redux without the init value
export interface FilterModelWithoutInit extends ResponseFilterModel {
  name: string;
  id: string;
  key: string;
  is_visible: boolean;
  lower_bound: number | null;
  upper_bound: number | null;
  filter_name: string;
  boolean_val: boolean;
  selected_cats: (string | number)[] | never[] | null;
  not_selected_cats: (string | number)[] | never[] | null;
  histogram: HistogramModel[];
  group_name: string;
  // Wether the filter is normal or a NOT filter
  FE_is_not: boolean;
  // Wether to include edges in the filter (ex: numerical filter edges)
  FE_include_edges: boolean;
}

export interface HistogramModel {
  bin_str: string;
  freq: number;
  lower_bound: number;
  upper_bound: number;
}

// Model of filter to store in redux
export interface FilterModel extends FilterModelWithoutInit {
  init: FilterModelWithoutInit;
}

// Model of filters to store in redux
export interface FiltersModel {
  [key: string]: FilterModel;
}

// Model of saved filter response from API

export interface ResponseSavedFilterModel {
  filter: SendFilterModel[];
  id: string;
  name: string;
  is_global: boolean;
  subset_filter: boolean;
}

// Model of saved filter to store in redux
export interface SavedFilterModel extends ResponseSavedFilterModel {
  FE_filter_type: "saved_filter";
}

// Model of saved filters to store in redux
export interface SavedFiltersModel {
  [key: string]: SavedFilterModel;
}

// Active Filters
export interface ActiveFilterModel {
  key: string;
  value: string | number | boolean | (string | number)[] | never[] | null;
  name: string;
  type: FiltersTypesModel;
  is_not: boolean;
  // Used for categorical filters
  categories_value?: {
    selected_cats:
      | string
      | number
      | boolean
      | (string | number)[]
      | never[]
      | null;
    not_selected_cats:
      | string
      | number
      | boolean
      | (string | number)[]
      | never[]
      | null;
  };
  // Used for numerical filters
  include_edges?: boolean;
  lower?: number | null;
  upper?: number | null;
}
export interface ActiveFiltersModel {
  [key: string]: ActiveFilterModel;
}

// Model of filter request to send to the backend
export type SendFilterModel = SendFilterComparisonModel | SendFilterLogicModel;

export interface SendFilterComparisonModel {
  attribute: string;
  query_operator: QueryOperatorModel;
  value: string | number | boolean | (string | number)[] | never[] | null;
}

export interface SendFilterLogicModel {
  operator: LogicalQueryOperatorsModels;
  queries: SendFilterModel[];
}
