import { ReactComponent as MoreIcon } from "assets/more.svg";

type Props = {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const MoreButton = ({ onClick }: Props) => {
  return (
    <div
      className="icon-button-layer-transparent"
      onClick={onClick}
      data-test="options_button"
    >
      <MoreIcon className="h-[20px] w-[20px]" />
    </div>
  );
};

export default MoreButton;
