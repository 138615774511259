import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { isUndefined } from "lodash";
import {
  InstancesPerMediaModel,
  MetaDataCountModel,
} from "models/metaData.model";
import { SendFilterModel } from "../models/filter.model";
import {
  fetchMediaObjectsFrequency,
  fetchMediaObjectsCount,
} from "helpers/apis/mediaObjects";
import { fetchMediasCount } from "helpers/apis/medias";
import { fetchInstancesCount } from "helpers/apis/instances";
import { AnnotatableEnum, SelectedViewModel } from "models/global.model";

// Fetch count for selected view (Media, MediaObject, Instances)
// and store it in the redux store
export const fetchCountStore = createAsyncThunk(
  "metaData/fetchCountStore",
  async (meta?: {
    query: { runID: string; subset_id?: string };
    APIBody: SendFilterModel[];
    selectedView: SelectedViewModel;
    skipLoading?: boolean;
  }) => {
    let response;
    switch (meta?.selectedView) {
      case AnnotatableEnum.Media:
        response = await fetchMediasCount(meta?.query?.runID, meta?.APIBody);
        break;
      case AnnotatableEnum.MediaObject:
        response = await fetchMediaObjectsCount(
          meta?.query?.runID,
          meta?.APIBody
        );
        break;
      case AnnotatableEnum.Instance:
        response = await fetchInstancesCount(meta?.query?.runID, meta?.APIBody);
        break;
    }

    return { data: response, meta: meta };
  }
);

// Fetch media objects frequency for current dataset/subset
export const fetchMediaObjectsFrequencyStore = createAsyncThunk(
  "metaData/fetchMediaObjectsFrequencyStore",
  async (meta: {
    query: { runID: string; subset_id: string };
    APIBody: SendFilterModel | SendFilterModel[];
    skipLoading?: boolean;
  }) => {
    const response = await fetchMediaObjectsFrequency(
      meta?.query?.runID,
      meta?.query?.subset_id
    );

    return { data: response, meta: meta };
  }
);

interface metaDataStateTypes {
  mediaCount: MetaDataCountModel;
  mediaObjectsCount: MetaDataCountModel;
  instancesCount: MetaDataCountModel;
  mediaObjectsFrequency: InstancesPerMediaModel;
  loading: boolean;
  error: { message: string };
}

const initialState = {
  mediaCount: {},
  mediaObjectsCount: {},
  instancesCount: {},
  mediaObjectsFrequency: {},
  loading: false,
  error: { message: "" },
} as metaDataStateTypes;

export const metaDataSlice = createSlice({
  name: "metaData",
  initialState,
  reducers: {
    resetMetaDataField: (
      state,
      action: PayloadAction<
        | "mediaCount"
        | "mediaObjectsCount"
        | "instancesCount"
        | "mediaObjectsFrequency"
      >
    ) => {
      state[action?.payload] = initialState[action?.payload] as any;
    },
    resetMetaDataSlice: () => initialState,
  },
  extraReducers: (builder) => {
    // fetchCountStore reducer
    builder.addCase(
      fetchCountStore.pending,
      (state: metaDataStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      }
    );
    builder.addCase(
      fetchCountStore.fulfilled,
      (state: metaDataStateTypes, action) => {
        const key = determineCountKeyFromParam(action?.meta?.arg?.selectedView);
        const data = action.payload?.data;
        if (key && !isUndefined(data)) {
          state[key] = data;
        }
        state.loading = false;
      }
    );
    builder.addCase(
      fetchCountStore.rejected,
      (state: metaDataStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      }
    );

    // fetchMediaObjectsFrequencyStore reducer
    builder.addCase(
      fetchMediaObjectsFrequencyStore.pending,
      (state: metaDataStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      }
    );
    builder.addCase(
      fetchMediaObjectsFrequencyStore.fulfilled,
      (state: metaDataStateTypes, action) => {
        state.mediaObjectsFrequency = action.payload?.data;
        state.loading = false;
      }
    );
    builder.addCase(
      fetchMediaObjectsFrequencyStore.rejected,
      (state: metaDataStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      }
    );
  },
});

export const { resetMetaDataField, resetMetaDataSlice } = metaDataSlice.actions;
export default metaDataSlice.reducer;

// Helper functions

// Determine the count key from the param
export const determineCountKeyFromParam = (
  type: AnnotatableEnum | undefined
): "mediaCount" | "mediaObjectsCount" | "instancesCount" | undefined => {
  if (type === AnnotatableEnum.Media) {
    return "mediaCount";
  } else if (type === AnnotatableEnum.MediaObject) {
    return "mediaObjectsCount";
  } else if (type === AnnotatableEnum.Instance) {
    return "instancesCount";
  }
};
