import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DatasetModel } from "models/dataset.model";
import _ from "lodash";
import { getSubsetsForDataset } from "helpers/apis/subsets";
import { fetchDatasets } from "helpers/apis/datasets";
import { VisibilityStatus } from "models/global.model";

export const fetchDashboardInformation = createAsyncThunk(
  "dashboard/fetchDashboardInformation",
  async (meta?: {
    skipLoading?: boolean;
    subset?: boolean;
    visibility_statuses?: VisibilityStatus[];
  }) => {
    const data = await fetchDatasets(meta?.subset, meta?.visibility_statuses);
    return { data: convertToObject(data), meta: meta };
  },
);

export const fetchSubsetInformationForDataset = createAsyncThunk(
  "dashboard/fetchSubsetInformationForDataset",
  async (meta?: { skipLoading?: boolean; datasetID: string }) => {
    const response = await getSubsetsForDataset(meta?.datasetID || "");
    return { data: response, meta: meta };
  },
);

/**
 * Converts the array of datasets into a Record<string, DatasetModel> object.
 * With a field subsets in which the subsets are stored.
 * Subsets are removed from the main object.
 * @param data
 */
const convertToObject = (
  data: DatasetModel[],
): Record<string, DatasetModel> => {
  _.map(data, (item) => {
    return { ...item, subsets: [] };
  });

  return _.keyBy(_.filter(data, { parent_dataset: null }), "id");
};

interface dashboardStateTypes {
  data: Record<string, DatasetModel>;
  loading: boolean;
  error: { message: string };
}

const initialState = {
  data: {},
  loading: false,
  error: { message: "" },
} as dashboardStateTypes;

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetDashboardSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchDashboardInformation.pending,
      (state: dashboardStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchDashboardInformation.fulfilled,
      (state: dashboardStateTypes, action) => {
        state.data = action.payload?.data;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchDashboardInformation.rejected,
      (state: dashboardStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      },
    );
    builder.addCase(
      fetchSubsetInformationForDataset.pending,
      (state: dashboardStateTypes, action) => {
        if (!action?.meta?.arg?.skipLoading) {
          state.loading = true;
        }
      },
    );
    builder.addCase(
      fetchSubsetInformationForDataset.fulfilled,
      (state: dashboardStateTypes, action) => {
        const datasetID = action.payload?.meta?.datasetID;

        if (!_.isUndefined(datasetID) && !_.isNull(action.payload?.data)) {
          state.data = {
            ...state?.data,
            [datasetID]: {
              ...state?.data[datasetID],
              subsets: action.payload?.data,
            },
          };
        }
        state.loading = false;
      },
    );
    builder.addCase(
      fetchSubsetInformationForDataset.rejected,
      (state: dashboardStateTypes, action) => {
        state.loading = false;
        state.error.message = action.error.message || "No error provided";
      },
    );
  },
});

export const { resetDashboardSlice } = dashboardSlice.actions;
export default dashboardSlice.reducer;
