import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";

import ProviderWrapper from "./store/provider";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import Loading from "components/UtilComponents/Loading";
import { ReactComponent as Logo } from "assets/logo_big.svg";

//import { useKeycloak } from '@react-keycloak/web'

// these could be useful for debugging keycloak issues but should be removed before publishing
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const eventLogger = (event: unknown, error: unknown) => {
  //console.log('onKeycloakEvent', event, error)
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tokenLogger = (tokens: unknown) => {
  //console.log('onKeycloakTokens', tokens)
};

// this will be displayed to the user when the page is first loading
// and determining if they are logged in or not
const LoadingComponent = (
  <div className="w-screen h-screen flex justify-center items-center">
    <div>
      <Logo />
      <Loading size={80} />
    </div>
  </div>
);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ProviderWrapper>
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      LoadingComponent={LoadingComponent}
      initOptions={{
        onLoad: "login-required",
        // SSO is not supported currently on our keycloak
        checkLoginIframe: false,
      }}
    >
      <App />
    </ReactKeycloakProvider>
  </ProviderWrapper>
);
