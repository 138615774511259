import { UiSchema } from "@rjsf/utils";

export const ui_schema_discrete_2d: UiSchema = {
  inputType: {
    Viewer3D: {
      "ui:widget": "hidden",
    },
  },
};

export const form_data_discrete_2d = {
  inputType: {
    BoundingBox2DPainter: {
      displayerType: "image",
      boundingBoxProps: {
        opacity: 0,
      },
    },
    Viewer3D: {},
  },
  toolbars: [
    {
      type: "discreteButtonBar",
      settings: {
        answers: [
          {
            key: "a",
            label: "Answer A",
          },
          {
            key: "b",
            label: "Answer B",
          },
        ],
        unsolvableButtonLabel: "Can't Solve",
        withUnsolvableButton: true,
        unsolvableCode: "cant_solve",
        withOptions: false,
        optionDescription: "Please choose the most relevant option below",
        optionsTitle: "Why is the task unsolvable?",
        unsolvableOptions: [
          {
            key: "cant_solve",
            label: "Can not solve the problem",
          },
          {
            key: "corrupt_data",
            label: "Something looks wrong with the data",
          },
          {
            key: "wrong_data",
            label: "Content seems wrong or inappropriate",
          },
          {
            key: "else",
            label: "Some other reason",
          },
        ],
      },
    },
  ],
};

export const answers_discrete_2d = {
  toolbarKey: "discreteButtonBar",
};
