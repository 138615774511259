import _ from "lodash";
import IconComp, { iconsList } from "../../Helpers/IconComp";

interface Props {
  label: string;
  onClick: () => void;
  icon?: keyof typeof iconsList;
  iconSize?: string;
  className?: string;
  disabled?: boolean;
}

const BasicButton = ({
  label,
  onClick,
  icon,
  iconSize,
  className,
  disabled = false,
}: Props) => {
  return (
    <button
      className={` BasicButtonLayer
                    ${!_.isUndefined(className) && className}
                    `}
      onClick={() => !disabled && onClick()}
      disabled={disabled}
    >
      {label}
      {icon && <IconComp icon={icon} size={iconSize} />}
    </button>
  );
};

export default BasicButton;
