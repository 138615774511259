import Slider, { SliderValueLabelProps } from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import FormErrorLabel from "components/Internal/Inputs/Form/ErrorLabel";
import FormLabel from "components/Internal/Inputs/Form/Label";
import { validateSingleSelectInput } from "components/Internal/Inputs/Form/formInputsHelpers";
import NumberInput from "components/Internal/Inputs/Form/NumberInput";
import _ from "lodash";
import { SingleSliderFieldModel } from "models/form.model";
import FormErrorCircle from "components/Internal/Inputs/Form/ErrorCircle";

interface Props {
  field: SingleSliderFieldModel;
  value: number;
  handleOnChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    field: SingleSliderFieldModel,
    isErrored?: boolean,
    error?: string | null,
  ) => void;
}

const SingleSliderInput = ({ field, value, handleOnChange }: Props) => {
  const { min = 0, max = 100 } = field;

  const renderBody = () => {
    const sameLine = field?.style?.labelAndFieldOnTheSameLine;
    if (sameLine) {
      return renderSameLineAlignment();
    }

    return renderMultiLineAlignment();
  };

  const renderMultiLineAlignment = () => {
    return (
      <div key={field?.key} className={`w-full ${field?.style?.wrapper}`}>
        <FormLabel field={field} />
        {renderSlider()}
        <FormErrorLabel
          error={field?.error ?? null}
          validationError={validateSingleSelectInput(field)}
        />
      </div>
    );
  };

  const renderSameLineAlignment = () => {
    return (
      <div
        key={field?.key}
        className={`flex justify-between items-center
          ${field?.style?.wrapper}
      `}
      >
        <div className="w-1/2">
          <FormLabel field={field} />
        </div>
        <div className="w-1/2 flex items-center">
          {renderSlider()}
          <FormErrorCircle
            error={field?.error ?? null}
            validationError={validateSingleSelectInput(field)}
          />
        </div>
      </div>
    );
  };

  const steps = () => {
    const step = field?.settings?.step;
    const stepInteger = field?.settings?.stepInteger || true;
    const numberOfSteps = field?.settings?.numberOfSteps || 20;

    // Defined step from props
    if (!_.isUndefined(step)) {
      return stepInteger ? _.toInteger(step) : step;
    }

    // No defined step passed from props
    const range = max - min;
    // If the range is greater than 100 then we can calculate the step
    // This is to prevent the slider from having too many steps
    if (range >= 100) {
      const calculatedStep = range / numberOfSteps;
      return stepInteger ? _.toInteger(calculatedStep) : calculatedStep;
    }

    // If the range is less than 1 then we use a default step
    if (range <= 1) {
      return 0.01;
    }

    // If the range is more than 1 and less than 100
    return stepInteger ? 1 : range / numberOfSteps;
  };

  const handleChange = (event: any, newValue: number) => {
    if (_.isNumber(newValue)) {
      const validationError = validateSingleSelectInput(field);
      const isError = !_.isNull(validationError);
      handleOnChange(event, field, isError, validationError);
    }
  };

  const renderSlider = () => {
    return (
      <div className="w-full flex gap-x-4 items-center justify-center">
        <div className="min-w-0 flex-1 relative">
          <Slider
            data-test="grid_slider"
            value={value}
            defaultValue={value}
            onChange={(event: any, newValue: number | number[]) =>
              handleChange(event, newValue as number)
            }
            slots={{
              valueLabel: ValueLabelComponent,
            }}
            valueLabelDisplay="auto"
            step={steps()}
            min={min}
            max={max}
            slotProps={{
              root: { className: "h-[6px] rounded-lg" },
              track: {
                className: `h-[6px] text-black border-0 ${field?.style?.track}`,
              },
              rail: {
                className: `h-[6px] opacity-50 bg-paletteGray-6 ${field?.style?.rail}`,
              },
              thumb: {
                className: "h-[16px] w-[24px] rounded-lg bg-white shadow",
              },
              mark: { className: "h-[0px] w-[0px]" },
              markLabel: { className: "opacity-100 bg-current" },
            }}
            disabled={field?.disabled || false}
          />
          {renderUnderSlider()}
        </div>
        {renderNumberInput()}
      </div>
    );
  };

  const renderNumberInput = () => {
    if (!field?.settings?.renderNumberInput) {
      return null;
    }

    return (
      <div className="w-1/5" data-test="number_input">
        <NumberInput
          key={"number-input-" + field?.key}
          field={{
            ...field,
            type: "number",
            settings: {
              minimumValue: min,
              maximumValue: max,
              step: steps(),
            },
            error: null,
            isErrored: false,
            label: "",
            required: false,
            style: {
              field: "py-1",
            },
          }}
          value={value as number}
          handleOnChange={(event, field) => handleChange(event, field?.value)}
        />
      </div>
    );
  };

  const renderUnderSlider = () => {
    if (field?.settings?.hideUnderSliderLabels) {
      return null;
    }

    return (
      <div className="w-full absolute top-6 flex justify-between">
        <div className="text-sm text-paletteGray-8">{min}</div>
        {field?.settings?.labelUnderSlider && (
          <div className="text-sm text-paletteGray-8">
            {field?.settings?.labelUnderSlider}
          </div>
        )}
        <div className="text-sm text-paletteGray-8">{max}</div>
      </div>
    );
  };

  return renderBody();
};

export default SingleSliderInput;

function ValueLabelComponent(props: SliderValueLabelProps) {
  const { children, value } = props;
  return (
    <Tooltip
      placement="top"
      title={value}
      TransitionProps={{ timeout: 0 }}
      classes={{
        tooltip: "bg-black",
      }}
    >
      {children}
    </Tooltip>
  );
}
