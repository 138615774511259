import TextInput from "components/Internal/Inputs/Form/TextInput";
import SavePipelinePopover from "Pages/PipelineDesigner/SavePipelinePopover";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setPipelineName } from "store/pipelineDesignerSlice";

const PipelineDesignerHeader = () => {
  const dispatch = useAppDispatch();

  const pipelineDesignerData = useAppSelector(
    (state) => state.pipelineDesignerSlice,
  );

  const renderPipelineName = () => {
    const pipelineName = pipelineDesignerData.pipelineName;
    return (
      <TextInput
        field={{
          ...pipelineName,
          style: {
            ...pipelineName.style,
            field:
              "py-1 !bg-transparent border-none hover:!bg-paletteGray-3 focus:!bg-paletteGray-3 w-[270px] text-center",
            wrapper: "h-[48px] w-[270px] text-center",
          },
        }}
        value={pipelineName.value}
        handleOnChange={(event, field, isErrored, error) => {
          dispatch(
            setPipelineName({
              ...pipelineName,
              value: event.target.value,
              isErrored: isErrored || false,
              error: error || null,
            }),
          );
        }}
      />
    );
  };

  return (
    <div className="w-full h-[64px] flex justify-between items-center">
      <div>
        <div className="font-medium">Pipeline Designer</div>
        <div className="text-sm text-paletteGray-11">HARI X-Ray</div>
      </div>
      {renderPipelineName()}
      <SavePipelinePopover />
    </div>
  );
};

export default PipelineDesignerHeader;
