import { FieldTemplateProps } from "@rjsf/utils";
import RequiredTemplate from "components/Internal/RJSF/RequiredTemplate";

const CustomFieldTemplate = (props: FieldTemplateProps) => {
  const {
    id,
    classNames,
    style,
    label,
    help,
    required,
    description,
    errors,
    children,
    hidden,
  } = props;
  if (hidden) {
    return null;
  }

  // If the type is object or array, use the default template
  if (props.schema.type === "object" || props.schema.type === "array") {
    return children;
  }

  if (props.schema.type === "string" || props.schema.type === "number") {
    return (
      <div className={classNames} style={style}>
        <div className="flex items-center gap-x-1">
          <div className="w-2/5 flex items-center">
            <div className="flex gap-x-1 pl-1 text-paletteBlack-2">
              {label}
              <RequiredTemplate required={props.required} />
              {description}
            </div>
          </div>
          <div className="w-3/5">{children}</div>
        </div>
        {errors}
        {help}
      </div>
    );
  }

  return children;
};

export default CustomFieldTemplate;
