import React from "react";

type Props = {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: number;
};

const CustomCheckbox = ({ checked, onChange, size = 24 }: Props) => {
  return (
    <input
      type="checkbox"
      className="checkbox-layer"
      data-test="selectionbox"
      style={{
        width: size + "px",
        height: size + "px",
        padding: size / 4 + "px",
        borderRadius: size / 4 + "px",
      }}
      checked={checked}
      onChange={(event) => {
        event.stopPropagation();
        onChange(event);
      }}
    />
  );
};

export default CustomCheckbox;
