import { useHistory } from "react-router-dom";

const NoAccessPage = () => {
  const history = useHistory();

  return (
    <div className="w-full h-full pt-24 flex flex-col gap-y-2 items-center text-lg">
      <div>Sorry, you don't have access to this page.</div>
      <div>If you think this could be a mistake, please contact us!</div>
      <div>You can press the home button to go back.</div>
      <button
        className="button-layer text-lg mt-2"
        onClick={() => history.push("/")}
      >
        Take me to home page
      </button>
    </div>
  );
};

export default NoAccessPage;
