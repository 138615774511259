import CircularProgress from "@mui/material/CircularProgress";

interface props {
  size?: number;
}
const Loading = ({ size }: props) => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <CircularProgress size={size && size} disableShrink={true} />
    </div>
  );
};

export default Loading;
