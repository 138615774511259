import { ReactComponent as SortUP } from "assets/sort_up.svg";
import { ReactComponent as SortDown } from "assets/sort_down.svg";

export interface SortButtonModel {
  name: string | null;
  direction: "asc" | "desc" | null;
}

type Props = {
  sortBy: SortButtonModel;
  setSortBy: (newSortBy: SortButtonModel) => void;
  selected?: boolean;
};

const SortButton = ({ sortBy, setSortBy, selected = true }: Props) => {
  // Should be highlighted if the column is selected and the direction is the same
  const shouldBeHighlighted = (direction: "asc" | "desc" | null) => {
    return selected && sortBy?.direction === direction;
  };

  const renderSortedBy = () => {
    if (sortBy?.direction === "desc") {
      return renderSortDown();
    }

    return renderSortUp();
  };

  const renderSortUp = () => {
    return (
      <SortUP
        className={`w-[10px] cursor-pointer 
        text-paletteGray-8 hover:text-black opacity-30
        ${shouldBeHighlighted("asc") ? "opacity-100" : ""}
         `}
        onClick={() =>
          setSortBy({
            name: sortBy?.name,
            direction: sortBy?.direction === null ? "asc" : "desc",
          })
        }
      />
    );
  };

  const renderSortDown = () => {
    return (
      <SortDown
        className={`w-[10px] cursor-pointer 
        text-paletteGray-8 hover:text-black opacity-30
        ${shouldBeHighlighted("desc") ? "opacity-100" : ""}
         `}
        onClick={() =>
          setSortBy({
            name: sortBy?.name,
            direction: null,
          })
        }
      />
    );
  };

  return (
    <div className="flex items-center justify-center">{renderSortedBy()}</div>
  );
};

export default SortButton;
