import _ from "lodash";
import {
  FilterModel,
  FiltersModel,
  SendFilterComparisonModel,
  SendFilterLogicModel,
  SendFilterModel,
} from "models/filter.model";

const convertSendFilterModelToFilterModel = (
  sendFilter: SendFilterModel,
  oldFilters: FiltersModel
): FilterModel | null => {
  let oldFilter: FilterModel;
  // Check if the sendFilter is a comparison or logic
  // If it is a comparison, then we can just use the attribute to find the old filter
  if ("attribute" in sendFilter && !_.isUndefined(sendFilter?.attribute)) {
    const sendFilterComparison = sendFilter as SendFilterComparisonModel;
    oldFilter = oldFilters[sendFilterComparison?.attribute];
  }
  // If it is a logic, then we need to find the old filter by looking at the first query
  else if ("queries" in sendFilter && !_.isUndefined(sendFilter?.queries)) {
    const sendFilterComparison = sendFilter as SendFilterLogicModel;
    const firstQuery = sendFilterComparison
      ?.queries[0] as SendFilterComparisonModel;
    const attribute = firstQuery?.attribute;
    oldFilter = oldFilters[attribute];
  }
  // If it is neither, then we can just return the old filters
  else {
    return null;
  }

  switch (oldFilter?.type) {
    case "BOOLEAN":
    case "SUBSET":
    case "FE_SUBSET":
    case "FE_TAG": {
      const sendFilterComparison = sendFilter as SendFilterComparisonModel;
      return {
        ...oldFilter,
        selected_cats: sendFilterComparison?.value as any,
        FE_is_not: sendFilterComparison?.query_operator === "in" ? false : true,
      };
    }
    case "SEARCH_BY_TEXT":
    case "CATEGORICAL": {
      const sendFilterComparison = sendFilter as SendFilterComparisonModel;
      if (sendFilterComparison?.query_operator === "in") {
        return {
          ...oldFilter,
          selected_cats: sendFilterComparison?.value as any,
          FE_is_not: false,
        };
      } else if (sendFilterComparison?.query_operator === "not in") {
        return {
          ...oldFilter,
          not_selected_cats: sendFilterComparison?.value as any,
          FE_is_not: true,
        };
      }
      break;
    }
    case "NUMERICAL": {
      // Not filter: SendFilterLogicModel
      if ("queries" in sendFilter && !_.isUndefined(sendFilter?.queries)) {
        const sendFilterLogic = sendFilter as SendFilterLogicModel;
        if (sendFilterLogic?.operator === "or") {
          const queries =
            sendFilterLogic?.queries as SendFilterComparisonModel[];
          const lowerBound = queries[0]?.value;
          const upperBound = queries[1]?.value;
          let include_edges = true;
          if (
            queries[0]?.query_operator === ">" ||
            queries[0]?.query_operator === "<" ||
            queries[1]?.query_operator === ">" ||
            queries[1]?.query_operator === "<"
          ) {
            include_edges = false;
          }

          return {
            ...oldFilter,
            lower_bound: lowerBound as number,
            upper_bound: upperBound as number,
            FE_include_edges: include_edges,
            FE_is_not: true,
          };
        }
      }
      // Is filter: SendFilterComparisonModel
      else {
        const sendFilterComparison = sendFilter as SendFilterComparisonModel;
        let include_edges = true;

        if (
          sendFilterComparison?.query_operator === ">" ||
          sendFilterComparison?.query_operator === "<"
        ) {
          include_edges = false;
        }

        let newFilter = {
          ...oldFilter,
          FE_include_edges: include_edges,
          FE_is_not: false,
        };

        if (
          sendFilterComparison?.query_operator === ">" ||
          sendFilterComparison?.query_operator === ">="
        ) {
          newFilter = {
            ...newFilter,
            lower_bound: sendFilterComparison?.value as number,
          };
        } else if (
          sendFilterComparison?.query_operator === "<" ||
          sendFilterComparison?.query_operator === "<="
        ) {
          newFilter = {
            ...newFilter,
            upper_bound: sendFilterComparison?.value as number,
          };
        }
        return newFilter;
      }
      break;
    }
    case "SELECT_ATTRIBUTE": {
      const sendFilterComparison = sendFilter as SendFilterComparisonModel;

      const operator = sendFilterComparison?.query_operator;
      let FE_is_not = false;
      if (operator === "!=") {
        FE_is_not = true;
      } else if (operator === "==") {
        FE_is_not = false;
      }

      return {
        ...oldFilter,
        FE_value: sendFilterComparison?.value as string,
        FE_is_not: FE_is_not,
      };
    }
  }

  return oldFilter;
};

export default convertSendFilterModelToFilterModel;
