import { permissionsList } from "helpers/permissions";
import Keycloak from "keycloak-js";
import _ from "lodash";
import { UserRoleModel } from "models/users.model";

export const checkIfUserHavePermission = (
  user: Keycloak,
  permissionKey: keyof typeof permissionsList,
) => {
  const allowedRoles = permissionsList?.[permissionKey]?.roles;
  const isUserAuthorized = _.some(
    allowedRoles,
    (role) => user?.hasRealmRole(role),
  );

  return isUserAuthorized;
};

export const checkIfUserHaveRole = (
  user: Keycloak,
  roles?: UserRoleModel[],
) => {
  const isUserAuthorized = _.some(roles, (role) => user?.hasRealmRole(role));

  return isUserAuthorized;
};
