export interface TilingParamsModel {
  num_media_tiles_rows: number;
  num_media_tiles_columns: number;
  num_zoom_levels: number;
  tiles_percentage_overlap: number;
}

export interface TransformationJobModel {
  job_id: string;
  dataset_id: string;
  subset_id: string;
  name: string;
  routine_type: string;
  progress: number;
  status: {
    level: StatusLevelModel;
    msg: string;
    operation_name: string;
  };
  num_tasks: number;
  done: number;
  failed: number;
  in_flight: number;
  created_at: string;
  started_at: string;
  last_task_done_at: string;
}

export enum StatusLevelModel {
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
  Information = "Information",
}

export interface AnonymizationModel {
  face_threshold: number;
  plate_threshold: number;
  kernel_size: number;
  sigma: number;
  box_kernel_size: number;
}
