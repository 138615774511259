import {
  allUsersRoles,
  customContractRoles,
  internalOnlyRoles,
} from "models/users.model";

export const permissionsList = {
  // Page access permissions

  // -- Public pages --
  accessImprintPage: {
    roles: [],
  },
  accessPrivacyPolicyPage: {
    roles: [],
  },

  // -- All users --
  accessExplorationPage: {
    roles: allUsersRoles,
  },
  accessDetailsPage: {
    roles: allUsersRoles,
  },
  accessSubsetsManagerPage: {
    roles: allUsersRoles,
  },
  accessTagsManagerPage: {
    roles: allUsersRoles,
  },

  // -- Custom contract users --
  accessUploadPage: {
    roles: customContractRoles,
  },
  accessPipelineManagerPage: {
    roles: customContractRoles,
  },
  accessPipelineDesignerPage: {
    roles: customContractRoles,
  },
  accessAnnotationRunManagerPage: {
    roles: customContractRoles,
  },

  // -- Internal users --
  accessStatisticsPage: {
    roles: internalOnlyRoles,
  },
  accessAttributeManagerPage: {
    roles: internalOnlyRoles,
  },
  accessAIManagerPage: {
    roles: internalOnlyRoles,
  },
  accessTransformationPage: {
    roles: internalOnlyRoles,
  },
  accessInternalToolsPage: {
    roles: internalOnlyRoles,
  },

  // Feature access permissions
  accessDeleteDatasetFeature: {
    roles: internalOnlyRoles,
  },
  accessDownloadFeature: {
    roles: internalOnlyRoles,
  },
  accessFeedBackFeature: {
    roles: allUsersRoles,
  },
  accessCreateSubsetWithFilteredObjectsFeature: {
    roles: internalOnlyRoles,
  },
  accessCropConfigFeature: {
    roles: allUsersRoles,
  },
  accessSetGeometryColorFeature: {
    roles: internalOnlyRoles,
  },
  accessPipelineReferenceDataFeature: {
    roles: internalOnlyRoles,
  },
  accessPipelineReferenceDataTasksFeature: {
    roles: internalOnlyRoles,
  },
  accessPipelineAutoAnnotateFeature: {
    roles: internalOnlyRoles,
  },
  accessSplitSubsetFeature: {
    roles: customContractRoles,
  },
  accessAnnotationRunAIModelFeature: {
    roles: customContractRoles,
  },
};
