import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  ExplorationAPIRequestModel,
  InstanceModel,
} from "models/exploration.model";
import { SendFilterModel } from "models/filter.model";
import subsetQuery from "helpers/functions/subsetQuery";
import { fetchInstances, fetchOneInstanceAPI } from "helpers/apis/instances";
import { MLAnnotationModel } from "models/mlAnnotationModel.model";
import { TrainingSetModel } from "models/trainingSet.model";
import { fetchMLAnnotationModels } from "helpers/apis/mlAnnotationModel";

export const fetchAIModelsStore = createAsyncThunk(
  "aiManager/fetchAIModelsStore",
  async () => {
    const response = await fetchMLAnnotationModels();
    return { data: response };
  },
);

export enum AIMangerViewEnum {
  AIModels = "AI Models",
  TrainingSets = "Training sets",
}

export interface aiManagerSliceStateTypes {
  view: AIMangerViewEnum;
  aiModels: MLAnnotationModel[];
  trainingSets: TrainingSetModel[];
  searchValue: string;
  loadingAIModels: boolean;
  loadingTrainingSets: boolean;
  error: { message: string };
}

const initialState = {
  view: AIMangerViewEnum.AIModels,
  aiModels: [],
  trainingSets: [],
  searchValue: "",
  loadingAIModels: false,
  loadingTrainingSets: false,
  error: { message: "" },
} as aiManagerSliceStateTypes;

export const aiManagerSlice = createSlice({
  name: "aiManager",
  initialState,
  reducers: {
    setAIMangerView: (state, action: PayloadAction<AIMangerViewEnum>) => {
      state.view = action?.payload;
    },
    setAIModels: (state, action: PayloadAction<MLAnnotationModel[]>) => {
      state.aiModels = action?.payload;
    },
    setTrainingSets: (state, action: PayloadAction<TrainingSetModel[]>) => {
      state.trainingSets = action?.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action?.payload;
    },
    resetAIManagerSlice: () => initialState,
  },
  extraReducers: (builder) => {
    // fetchAIModelsStore reducer
    builder.addCase(
      fetchAIModelsStore.pending,
      (state: aiManagerSliceStateTypes) => {
        state.loadingAIModels = true;
      },
    );
    builder.addCase(
      fetchAIModelsStore.fulfilled,
      (state: aiManagerSliceStateTypes, action) => {
        if (action?.payload?.data) {
          state.aiModels = action.payload.data;
        }
        state.loadingAIModels = false;
      },
    );
    builder.addCase(
      fetchAIModelsStore.rejected,
      (state: aiManagerSliceStateTypes, action) => {
        state.loadingAIModels = false;
        state.error.message =
          action.error.message || "Error loading AI models!";
      },
    );
  },
});

export const {
  setAIMangerView,
  setAIModels,
  setTrainingSets,
  setSearchValue,
  resetAIManagerSlice,
} = aiManagerSlice.actions;
export default aiManagerSlice.reducer;
