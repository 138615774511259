import _ from "lodash";
import { useAppDispatch } from "store/hooks";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import { validateForm } from "components/Internal/Forms/formHelper";
import { FormModel, TextFieldModel } from "models/form.model";
import { postThresholdAnalysis } from "helpers/apis/datasets";
import { useParams } from "react-router-dom";
import { InternalScreenRouteModel } from "models/routes.model";
import { validateTextInput } from "components/Internal/Inputs/Form/formInputsHelpers";

type Props = {
  tagName: TextFieldModel;
  setTagName: (tagName: TextFieldModel) => void;
  attributesList: {
    [key: string]: FormModel;
  };
  setAttributesList: (attributesList: { [key: string]: FormModel }) => void;
  handleResetAll: () => void;
};

const ThresholdAnalysisSubmit = ({
  tagName,
  setTagName,
  attributesList,
  setAttributesList,
  handleResetAll,
}: Props) => {
  const params: InternalScreenRouteModel = useParams();
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    const isValidationSuccessful = isTagNameValid() && isAttributeFormsValid();
    if (!isValidationSuccessful) return;

    // Call API
    postThresholdAnalysis(
      {
        datasetID: params?.dataset_id,
        base_name: tagName.value,
        attribute_inputs: _.map(attributesList, (attribute, key) => {
          const answerField = attribute?.answer as TextFieldModel;
          return {
            attribute_id: key,
            threshold: parseFloat(attribute?.ambiguity?.value as string),
            expected_value: answerField?.value,
          };
        }),
      },
      dispatch,
    ).then(() => {
      handleResetAll();
    });
  };

  // Validate tag name
  const isTagNameValid = () => {
    let isValid = true;
    const error = validateTextInput(tagName);
    if (error !== null) {
      setTagName({
        ...tagName,
        isErrored: true,
        error: error,
      });
      isValid = false;
    }
    return isValid;
  };

  // Validate all attribute forms and update the state with the error messages
  // If there is at least one invalid form, return false
  const isAttributeFormsValid = () => {
    if (_.isEmpty(attributesList)) {
      snackbarHelper(dispatch, "Please add at least one attribute!", "error");
      return false;
    }

    let isAllFormsValid = true;

    let updatedAttributesListWithErrors = {};
    _.forEach(attributesList, (attribute, key) => {
      const fromValidation = validateForm(attribute);
      updatedAttributesListWithErrors = {
        ...updatedAttributesListWithErrors,
        [key]: fromValidation.newFormState,
      };
      if (!fromValidation.isFormValid) {
        isAllFormsValid = false;
      }
    });
    !isAllFormsValid && setAttributesList(updatedAttributesListWithErrors);
    return isAllFormsValid;
  };

  return (
    <button className="button-layer" onClick={handleSubmit}>
      Submit
    </button>
  );
};

export default ThresholdAnalysisSubmit;
