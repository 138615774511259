import { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  addInstanceIDToSelectedInstanceIDs,
  fetchInstanceData,
  removeInstanceIDFromSelectedInstanceIDs,
  setInstanceExplorationCurrentInstance,
  updateInstanceExplorationHasMore,
} from "store/explorationInstancesSlice";
import _ from "lodash";

import {
  ExplorationAPIRequestModel,
  InstanceModel,
} from "models/exploration.model";

import InfiniteScroll from "react-infinite-scroll-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import CircularProgress from "@mui/material/CircularProgress";
import ThumbnailCard from "components/Exploration/ThumbnailCard";
import { AnnotatableEnum, VisibilityStatus } from "models/global.model";
import ExplorationHeader from "components/Exploration/ExplorationHeader";
import { ExplorationScreenRouteModel } from "models/routes.model";
import InfoPanel from "components/UtilComponents/InfoPanel";
import {
  fetchInstancesDataScroll,
  fetchOneInstanceAPI,
} from "helpers/apis/instances";
import convertActiveFilterToSendFilter from "helpers/functions/filters/convertActiveFilterToSendFilter";
import { searchParamsToSort } from "helpers/functions/filters/filtersHelpers";
import { fetchSelectedViewCount } from "helpers/functions/datasets/datasetHelpers";
import { memoizedFindAppropriateThumbnail } from "components/utilFunctions";
import { SingleSliderFieldModel } from "models/form.model";
import SubsetCreationLoading from "components/Exploration/SubsetCreationLoading";

const scrollLoadAmount = Number(
  process.env.REACT_APP_SCROLL_LOAD_AMOUNT ??
    alert("Config value 'scroll load amount' is not defined"),
);

const ExplorationInstances = () => {
  const params: ExplorationScreenRouteModel = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const explorationInstanceData = useAppSelector(
    (state) => state.explorationInstanceSlice,
  );
  const totalCount = useAppSelector(
    (state) => state.metaDataSlice?.instancesCount?.total_count,
  );
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const instanceSortData = useAppSelector(
    (state) => state.sortDataSlice?.activeInstances,
  );
  const dataSetData = useAppSelector((state) => state.datasetSlice);

  const [numOfItemPerRow, setNumOfItemPerRow] =
    useState<SingleSliderFieldModel>({
      type: "single_slider",
      key: "sampleSize",
      value: 6,
      min: 4,
      max: 10,
      settings: {
        step: 1,
        stepInteger: true,
        hideUnderSliderLabels: true,
      },
    });

  const currentInstanceRef = useRef<HTMLDivElement>(null);

  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [pinnedIndex, setPinnedIndex] = useState<null | number>(null);

  useEffect(() => {
    currentInstanceRef.current?.scrollIntoView();
  }, [currentInstanceRef]);

  useEffect(() => {
    if (
      explorationInstanceData?.skip + scrollLoadAmount >= totalCount ||
      totalCount === 0
    ) {
      dispatch(updateInstanceExplorationHasMore(false));
    }
  }, [explorationInstanceData?.data, totalCount]);

  // Fetch instances data for exploration
  // Update when: The URL id or subset_id change
  useEffect(() => {
    if (
      _.isEmpty(explorationInstanceData?.data) &&
      // Need to check if the data is not loading
      // Fix for issue when the data is empty and then apply filter function triggers a fetch for
      //  new data and also update active filter.
      //  The new active filter will trigger this useEffect.
      //  If the previous data was 0, then this will trigger simultaneously with the fetch from the apply filter.
      explorationInstanceData.loading === false &&
      !_.isEmpty(filterData.instanceFilterData)
    ) {
      const APIBody: ExplorationAPIRequestModel = {
        dataset_id: params.id,
        limit: scrollLoadAmount,
        skip: 0,
        query: convertActiveFilterToSendFilter(filterData.activeInstanceFilter),
        sort: searchParamsToSort(),
      };
      dispatch(
        fetchInstanceData({
          runId: params.id,
          reqBody: APIBody,
          subSetId: params?.subset_id,
        }),
      );
      fetchSelectedViewCount(
        {
          selectedView: selectedView,
          filterData: filterData,
          runID: params.id,
          subset_id: params?.subset_id,
        },
        dispatch,
      );
    }
  }, [params?.id, params?.subset_id, filterData.activeInstanceFilter]);

  const fetchInstanceByInstanceID = async (instanceID: string) => {
    return await fetchOneInstanceAPI(instanceID, params?.id);
  };

  const handleOnCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    instance: InstanceModel,
  ) => {
    const newVal = event?.target?.checked;
    if (newVal) {
      dispatch(addInstanceIDToSelectedInstanceIDs(instance?.id));
    } else {
      dispatch(removeInstanceIDFromSelectedInstanceIDs(instance?.id));
    }
  };

  const handleOnExpandButtonClick = (instance: InstanceModel) => {
    dispatch(setInstanceExplorationCurrentInstance(instance));
    const pushURL = `/main/${params?.id}/${params?.subset_id}/instance/${instance?.id}`;
    history.push(pushURL);
  };
  if (
    dataSetData.activeDataSet?.visibility_status ===
    VisibilityStatus.CreatingSubset
  ) {
    return <SubsetCreationLoading subset={dataSetData.activeDataSet} />;
  }
  return (
    <div className="flex h-full">
      <div className="min-w-0 flex-1 h-full flex flex-col gap-y-4">
        {/* Header section */}
        <ExplorationHeader
          numOfItemPerRow={numOfItemPerRow}
          setNumOfItemPerRow={(newVal) => setNumOfItemPerRow(newVal)}
        />

        {/* Infinite Scroll */}
        <div
          className="flex-1 min-h-0 overflow-y-auto"
          id="instances-scrollable-div"
        >
          {explorationInstanceData.loading &&
          explorationInstanceData?.hasMore ? (
            <div className="h-full flex justify-center items-center">
              <CircularProgress size={60} />
            </div>
          ) : (
            <InfiniteScroll
              dataLength={explorationInstanceData?.data?.length || 0}
              next={() =>
                fetchInstancesDataScroll(
                  dispatch,
                  explorationInstanceData,
                  totalCount,
                  params?.id,
                  params?.subset_id,
                  filterData.activeInstanceFilter,
                  instanceSortData,
                )
              }
              hasMore={explorationInstanceData?.hasMore}
              loader={
                <div className="h-full flex justify-center items-center">
                  Loading...
                </div>
              }
              initialScrollY={Math.floor(hoveredIndex / 5) * 262}
              scrollThreshold={0.5}
              scrollableTarget="instances-scrollable-div"
            >
              <div
                className={`h-full grid gap-[1px] grid-cols-${numOfItemPerRow?.value} pr-2`}
              >
                {_.map(
                  explorationInstanceData?.data,
                  (instance, key: number) => {
                    const isSelected = _.includes(
                      explorationInstanceData.selectedInstanceIDs,
                      instance?.id,
                    );
                    let url;
                    const thumbnailDimensions = _.keys(instance?.thumbnails);
                    if (thumbnailDimensions?.length === 1) {
                      url =
                        instance?.thumbnails?.[
                          thumbnailDimensions?.[thumbnailDimensions?.length - 1]
                        ];
                    } else {
                      url =
                        instance?.thumbnails?.[
                          memoizedFindAppropriateThumbnail(
                            currentInstanceRef,
                            instance?.thumbnails,
                          ) ?? "140x113"
                        ];
                    }

                    return (
                      <div
                        key={instance?.id}
                        ref={
                          instance?.id ===
                          explorationInstanceData.currentInstance?.id
                            ? currentInstanceRef
                            : null
                        }
                      >
                        <ThumbnailCard
                          type={AnnotatableEnum.Instance}
                          item={instance}
                          url={url}
                          itemIndex={key}
                          isSelected={isSelected}
                          hoveredIndex={hoveredIndex}
                          setHoveredIndex={setHoveredIndex}
                          pinnedIndex={pinnedIndex}
                          setPinnedIndex={setPinnedIndex}
                          onExpandClick={() =>
                            handleOnExpandButtonClick(instance)
                          }
                          onCheckboxClick={(event) =>
                            handleOnCheckboxClick(event, instance)
                          }
                          onItemURLExpire={fetchInstanceByInstanceID}
                        />
                      </div>
                    );
                  },
                )}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>

      <InfoPanel
        showMediaOrMediaObject={AnnotatableEnum.Instance}
        currentMedia={undefined}
        currentMediaObject={undefined}
        currentInstance={
          explorationInstanceData?.data?.[
            !_.isNull(pinnedIndex) ? pinnedIndex : hoveredIndex
          ]
        }
      />
    </div>
  );
};

export default ExplorationInstances;
