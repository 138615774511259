import html2canvas from "html2canvas";

/**
 * Export an element to an media file
 * @param elementID Element ID to be exported
 * @param mediaFileName File name of the exported media
 * @returns Returns a promise that resolves to a base64 media
 */
const exportElementToMedia = async (
  elementID: string,
  mediaFileName: string
) => {
  // Get the element to be exported
  const element = document.getElementById(elementID);
  // If the element does not exist, return
  if (!element) return;

  //   Create a style to fix the offset issue (partially) of the exported media
  const style = document.createElement("style");
  document.head.appendChild(style);
  style.sheet?.insertRule(
    "body > div:last-child img { display: inline-block; }"
  );

  // Export the element to an media
  const canvas = await html2canvas(element).then((canv) => {
    // Remove the style after the media is exported
    style.remove();
    return canv;
  });
  // Convert the media to a base64 media
  const media = canvas.toDataURL("media/png", 1.0);
  // Download the media
  downloadMedia(media, mediaFileName);
};
export default exportElementToMedia;

/**
 * Function to download a base64 media
 * @param blob The base64 media to be downloaded
 * @param fileName The name of the file to be downloaded
 */
const downloadMedia = (blob: string, fileName: string) => {
  // Create a fake link to download the media
  const link = window.document.createElement("a");
  link.style.display = "none";
  link.download = fileName;
  link.href = blob;

  // Add the fake link to the document body and click it
  document.body.appendChild(link);
  link.click();

  // Remove the fake link from the document body
  document.body.removeChild(link);
  link.remove();
};
