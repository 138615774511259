import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { DatasetModel } from "models/dataset.model";
import { fetchDatasets } from "helpers/apis/datasets";

import DataTable, {
  DataTableColumn,
} from "components/Internal/Table/DataTable";
import { getSubsetsForDataset } from "helpers/apis/subsets";
import {
  DatasetIdAndNameModel,
  setColorMap,
  setDataset,
  setReferenceData,
  setSubset,
  setVisualisationConfig,
} from "store/pipelineDesignerSlice";

import { Dialog } from "@mui/material";
import { SelectFieldModel } from "models/form.model";
import SimpleSelect from "components/Internal/Inputs/Form/SimpleSelect";
import { distinctColors, taskInputDefaultColor } from "helpers/colors";
import { getVisualisationConfiguration } from "helpers/apis/configurations";
import { VisualisationConfigurationModel } from "models/configurations.model";
import getCropVisualisationConfigID from "helpers/functions/hermes2dPainter/getCropVisualisationConfigID";
import TooltipTruncate from "components/Internal/Tooltips/TooltipTruncate";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";

type Props = {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
};

enum SelectDataStepsEnum {
  "Select Dataset" = "Select dataset",
  "Select Subset" = "Select subset",
  "Select Geometry Colors" = "Select geometry colors",
}

const SelectDataDialog = ({ openDialog, setOpenDialog }: Props) => {
  const dispatch = useAppDispatch();

  const dataset = useAppSelector(
    (state) => state.pipelineDesignerSlice.dataset,
  );
  const subset = useAppSelector((state) => state.pipelineDesignerSlice.subset);

  const subsetTypeWhitelist = useAppSelector(
    (state) => state.pipelineDesignerSlice.subsetTypeWhitelist,
  );
  const [currentStep, setCurrentStep] = useState<SelectDataStepsEnum>(
    SelectDataStepsEnum["Select Dataset"],
  );

  const [isLoading, setIsLoading] = useState(false);

  const [datasets, setDatasets] = useState<DatasetModel[] | null>(null);
  const [datasetId, setDatasetId] = useState<string | null>(
    dataset?.id || null,
  );

  const [subsets, setSubsets] = useState<DatasetModel[] | null>(null);
  const [subsetId, setSubsetId] = useState<string | null>(subset?.id || null);

  const [visualisationConfigs, setVisualisationConfigs] = useState<
    VisualisationConfigurationModel[] | null
  >(null);

  const [geometryColorSelect, setGeometryColorSelect] =
    useState<SelectFieldModel>({
      type: "select",
      key: "color_map",
      value: "all have the same color",
      label: "Geometry colors",
      placeholder: "Select geometries color...",
      options: [
        {
          label: "All classes have the same color",
          value: "all have the same color",
        },
        {
          label: "Each class has a different color",
          value: "different colors",
        },
      ],
      required: true,
    });

  // Fetch datasets
  useEffect(() => {
    fetchDatasets(false).then((data) => {
      setDatasets(data);
    });
    if (dataset) {
      updateSubsets(dataset);
    }
    if (!dataset) {
      setCurrentStep(SelectDataStepsEnum["Select Dataset"]);
    } else if (dataset && !subset) {
      setCurrentStep(SelectDataStepsEnum["Select Subset"]);
    } else if (dataset && subset) {
      setCurrentStep(SelectDataStepsEnum["Select Geometry Colors"]);
    }
  }, []);

  const updateSubsets = (dataset: DatasetIdAndNameModel): void => {
    getSubsetsForDataset(dataset?.id, dispatch, setIsLoading).then((data) => {
      setSubsets(data);
    });
  };

  // Fetch subsets and visualisations for the selected dataset
  const updateDataset = (datasetUpdate: DatasetIdAndNameModel): void => {
    setDatasetId(datasetUpdate?.id);
    updateSubsets(datasetUpdate);
    getVisualisationConfiguration({ datasetID: datasetUpdate.id }).then(
      (data) => {
        setVisualisationConfigs(data);
      },
    );

    // Reset subset and reference data
    dispatch(setSubset(null));
    dispatch(setReferenceData(null));
    dispatch(setVisualisationConfig(null));
  };

  const datasetsOrSubsetsColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 30,
      className: "font-bold",
      cell: (row) => {
        const Dataset = row as DatasetModel;
        return <TooltipTruncate>{Dataset?.name}</TooltipTruncate>;
      },
    },

    {
      field: "num_medias",
      headerName: "Medias",
      span: 15,
    },
    {
      field: "num_media_objects",
      headerName: "Objects",
      span: 15,
    },
    {
      field: "creation_timestamp",
      headerName: "Created",
      span: 40,
      cell: (row: unknown) => {
        const pipeline = row as DatasetModel;
        const date = new Date(pipeline?.creation_timestamp);
        return <div className="flex">{date.toLocaleString()}</div>;
      },
    },
  ];

  const renderStepsSection = () => {
    const datasetObject = datasetId && _.find(datasets, { id: datasetId });
    const subsetObject = subsetId && _.find(subsets, { id: subsetId });

    return (
      <div className="w-full px-6 pb-4 flex justify-between items-center border-b-[1px] border-paletteGray-4">
        <div className="flex flex-col justify-center items-center">
          {renderStep(
            SelectDataStepsEnum["Select Dataset"],
            datasetObject && datasetObject?.name,
            true,
          )}
        </div>
        {renderStepLine()}
        <div className="flex flex-col justify-center items-center">
          {renderStep(
            SelectDataStepsEnum["Select Subset"],
            subsetObject && subsetObject?.name,
            true,
            !datasetObject,
          )}
        </div>
        {renderStepLine()}
        <div className="flex flex-col justify-center items-center">
          {renderStep(
            SelectDataStepsEnum["Select Geometry Colors"],
            geometryColorSelect.value,
            false,
            !subsetObject,
          )}
        </div>
      </div>
    );
  };

  const renderStep = (
    step: SelectDataStepsEnum,
    activeItem: string | null | undefined,
    required: boolean = false,
    disabled: boolean = false,
  ) => {
    if (disabled) {
      return (
        <div
          className=" button-layer bg-paletteGray-3 hover:bg-paletteGray-3
          cursor-not-allowed flex flex-col justify-center items-center
          text-paletteGray-11"
        >
          <div>
            {step}
            {required && <span className="pl-1 text-paletteRed">*</span>}
          </div>
          <div className=" text-paletteGray-11 underline">{activeItem}</div>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => setCurrentStep(step)}
          className={` button-layer
      cursor-pointer flex flex-col justify-center items-center
      ${currentStep === step ? "text-paletteGreen" : ""}`}
        >
          <div>
            {step}
            {required && <span className="pl-1 text-paletteRed">*</span>}
          </div>
          <div className=" text-paletteGray-11 underline">{activeItem}</div>
        </div>
      );
    }
  };

  const renderStepLine = () => {
    return <div className="w-2/12 h-0 border border-paletteGray-5" />;
  };

  const renderTable = () => {
    switch (currentStep) {
      case SelectDataStepsEnum["Select Dataset"]:
        return renderDatasetsTable();
      case SelectDataStepsEnum["Select Subset"]:
        return renderSubsetsTable();
      case SelectDataStepsEnum["Select Geometry Colors"]:
        return renderGeometryColorSelect();
      default:
        return renderDatasetsTable();
    }
  };

  const renderDatasetsTable = () => {
    return (
      <DataTable
        key="datasets-table"
        rows={datasets as []}
        columns={datasetsOrSubsetsColumns}
        onRowClick={(row) => {
          const dataset = row as DatasetModel;
          updateDataset(dataset);
          setCurrentStep(SelectDataStepsEnum["Select Subset"]);
        }}
        defaultSort={{ name: "name", direction: "asc" }}
        enableSearch={true}
        searchPlaceholder={"Search datasets..."}
        isLoading={isLoading}
      />
    );
  };

  const renderSubsetsTable = () => {
    const subsetColumns = [
      ...datasetsOrSubsetsColumns,
      {
        field: "subset_type",
        headerName: "Subset type",
        cell: (row: unknown) => {
          const subset = row as DatasetModel;
          return (
            <TooltipTruncateEllipsis>
              {subset.subset_type === "media_object"
                ? "Object"
                : _.upperFirst(subset.subset_type)}
            </TooltipTruncateEllipsis>
          );
        },
        span: 30,
      },
    ];
    const mappedSubsets = subsets?.map((subset) => ({
      ...subset,
      disabled: !subsetTypeWhitelist?.includes(subset.subset_type),
    }));
    return (
      <DataTable
        key="subsets-table"
        rows={mappedSubsets as []}
        columns={subsetColumns}
        onRowClick={(row) => {
          const subset = row as DatasetModel;
          setSubsetId(subset?.id);
          setCurrentStep(SelectDataStepsEnum["Select Geometry Colors"]);
        }}
        defaultSort={{ name: "name", direction: "asc" }}
        enableSearch={true}
        searchPlaceholder={"Search subsets..."}
        isLoading={isLoading}
        disableReason={"Subset type not compatible with current configuration."}
      />
    );
  };

  const renderGeometryColorSelect = () => {
    return (
      <SimpleSelect
        field={geometryColorSelect}
        value={geometryColorSelect?.value}
        handleOnChange={(e) =>
          setGeometryColorSelect({
            ...geometryColorSelect,
            value: e.target.value,
          })
        }
      />
    );
  };

  const renderActions = () => {
    return (
      <div className="px-6 py-4  flex justify-end gap-x-2 border-t-[1px] border-paletteGray-4">
        {renderConfirmButton()}
      </div>
    );
  };

  const renderConfirmButton = () => {
    const datasetObject = datasetId && _.find(datasets, { id: datasetId });
    const subsetObject = subsetId && _.find(subsets, { id: subsetId });

    const isDisabled = !datasetObject || !subsetObject;
    return (
      <button
        className="button-layer"
        data-test="confirm_button_pipeline_designer"
        onClick={() => {
          if (!datasetObject || !subsetObject) return;
          handleConfirm(datasetObject, subsetObject);
        }}
        disabled={isDisabled}
      >
        Confirm
      </button>
    );
  };

  const handleConfirm = (dataset: DatasetModel, subset: DatasetModel) => {
    if (!dataset || !subset) return;

    // Set the selected dataset
    dispatch(
      setDataset({
        id: dataset?.id,
        name: dataset?.name,
        num_medias: dataset?.num_medias,
        num_media_objects: dataset?.num_media_objects,
        num_instances: dataset?.num_instances,
        subset_type: dataset?.subset_type,
      }),
    );

    // Set the selected subset
    dispatch(
      setSubset({
        id: subset?.id,
        name: subset?.name,
        num_medias: subset?.num_medias,
        num_media_objects: subset?.num_media_objects,
        num_instances: subset?.num_instances,
        subset_type: subset?.subset_type,
      }),
    );

    // Set the visualisation config for the selected subset
    const visualisationConfigId = getCropVisualisationConfigID(
      visualisationConfigs || [],
      subset?.id,
    );
    dispatch(
      setVisualisationConfig({
        id: visualisationConfigId,
      }),
    );

    // Set the selected geometry colors
    dispatch(setColorMap(getGeometryColors()));

    setOpenDialog(false);
  };

  const getGeometryColors = () => {
    const objectCategories = _.filter(subsets, { object_category: true });
    if (!objectCategories) return {};

    let newColorMap: Record<string, string> = {};
    // Set all geometries to have the same color
    if (geometryColorSelect.value === "all have the same color") {
      newColorMap = _.mapValues(
        _.keyBy(objectCategories, "id"),
        () => taskInputDefaultColor,
      );
    }
    // Set each class to have a different color
    else if (geometryColorSelect.value === "different colors") {
      const colors = distinctColors(objectCategories?.length);
      _.map(objectCategories, (category, index) => {
        newColorMap = {
          ...newColorMap,
          [category.id]: colors[index],
        };
      });
    }
    return newColorMap;
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      fullWidth={true}
      maxWidth="lg"
    >
      <div className="h-full w-full flex flex-col gap-y-2">
        <div className="p-6 text-xl text-paletteBlack-2">Select data</div>
        {renderStepsSection()}

        <div className="h-[550px] px-6">{renderTable()}</div>
        {renderActions()}
      </div>
    </Dialog>
  );
};

export default SelectDataDialog;
