import selectMediatype from "helpers/functions/selectMediatype";
import _ from "lodash";
import { DatasetModel } from "models/dataset.model";
import {
  AnnotatableEnum,
  SelectedViewModel,
  SubsetTypeModel,
  TagTypeModel,
} from "models/global.model";

export const getExplorationTabs = (
  activeDataSet: DatasetModel
): SelectedViewModel[] => {
  let tabs: SelectedViewModel[] = [];
  if (activeDataSet?.num_medias > 0) {
    tabs = [...tabs, AnnotatableEnum.Media];
  }
  if (activeDataSet?.num_instances > 0) {
    tabs = [...tabs, AnnotatableEnum.Instance];
  }
  if (activeDataSet?.num_media_objects > 0) {
    tabs = [...tabs, AnnotatableEnum.MediaObject];
  }

  return tabs;
};

export const getLabelFromSelectedView = (
  selectedView: SelectedViewModel,
  activeDataSet: DatasetModel | null
) => {
  if (activeDataSet === null) return "";

  const { mediatype } = activeDataSet;
  switch (selectedView) {
    case AnnotatableEnum.Media:
      return `${_.toLower(selectMediatype(mediatype))}s`;
    case AnnotatableEnum.Instance:
      return "instances";
    case AnnotatableEnum.MediaObject:
      return "objects";
    default:
      return "";
  }
};

/**
 * A helper function to get the selected items in the exploration tab
 * @param selectedView The selected view in the exploration tab
 * @param media The media selected in the exploration tab
 * @param mediaObject The media object selected in the exploration tab
 * @param instance The instance selected in the exploration tab
 * @returns The selected items in the exploration tab based on the selected view
 */
export const getSelectedItemsBasedOnSelectedView = (
  selectedView: SelectedViewModel,
  media: string[],
  mediaObject: string[],
  instance: string[]
): string[] => {
  switch (selectedView) {
    case AnnotatableEnum.Media:
      return media;
    case AnnotatableEnum.MediaObject:
      return mediaObject;
    case AnnotatableEnum.Instance:
      return instance;
    default:
      return [];
  }
};

export const getTagTypeFromBasedOnSelectedView = (
  selectedView: SelectedViewModel
): TagTypeModel => {
  switch (selectedView) {
    case AnnotatableEnum.Media:
      return TagTypeModel.Media;
    case AnnotatableEnum.MediaObject:
      return TagTypeModel.MediaObject;
    case AnnotatableEnum.Instance:
      return TagTypeModel.Instance;
  }
};

export const getSubsetTypeFromBasedOnSelectedView = (
  selectedView: SelectedViewModel
): SubsetTypeModel => {
  switch (selectedView) {
    case AnnotatableEnum.Media:
      return SubsetTypeModel.Media;
    case AnnotatableEnum.MediaObject:
      return SubsetTypeModel.MediaObject;
    case AnnotatableEnum.Instance:
      return SubsetTypeModel.Instance;
  }
};

export const getSelectedViewFromSubsetType = (subsetType: SubsetTypeModel) => {
  switch (subsetType) {
    case SubsetTypeModel.Media:
      return AnnotatableEnum.Media;
    case SubsetTypeModel.MediaObject:
      return AnnotatableEnum.MediaObject;
    case SubsetTypeModel.Instance:
      return AnnotatableEnum.Instance;
    default:
      console.error("Invalid subset type");
  }
};
