import { useState } from "react";
import StandardPopover from "components/Internal/Popovers/StandardPopover";

import { AnnotatableEnum, SelectedItemsTypeModel } from "models/global.model";
import NewSubsetDialog, {
  NewSubsetOptionModel,
} from "components/Internal/Dialogs/CreateSubsetDialog/NewSubset";
import { ReactComponent as SubsetIcon } from "assets/subset.svg";
import { useKeycloak } from "@react-keycloak/web";
import { checkIfUserHavePermission } from "helpers/keycloakHelpers";
import _ from "lodash";
import { useAppSelector } from "store/hooks";

type Props = {
  anchorEl: HTMLElement | null;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handlePopoverClose: () => void;
};

const CreateSubsetPopover = ({
  anchorEl,
  handlePopoverOpen,
  handlePopoverClose,
}: Props) => {
  const { keycloak } = useKeycloak();

  const [openCreateSubsetDialog, setOpenCreateSubsetDialog] = useState(false);
  const [subsetOption, setSubsetOption] =
    useState<NewSubsetOptionModel>("normal");
  const selectedView = useAppSelector((state) => state.appSlice?.selectedView);

  const handleOpenDialogAndClosePopover = () => {
    setOpenCreateSubsetDialog(true);
    handlePopoverClose();
  };

  const renderButtonElement = () => {
    return (
      <button
        className={`button-layer ${
          !_.isNull(anchorEl) ? "button-layer-active" : ""
        }`}
        data-test="create_subset_popover"
      >
        <SubsetIcon width={16} height={16} />
        Create subset
      </button>
    );
  };

  const getItemsList = () => {
    let itemsList = [
      {
        label: `Create subset from all ${_.lowerCase(
          getLabelOfSelectedView(),
        )}`,
        onItemClick: () => {
          setSubsetOption("normal");
          handleOpenDialogAndClosePopover();
        },
      },
      {
        label: `Create random subset from all ${_.lowerCase(
          getLabelOfSelectedView(),
        )}`,
        onItemClick: () => {
          setSubsetOption("random");
          handleOpenDialogAndClosePopover();
        },
      },
    ];
    const createSubsetWithFilteredObjects =
      addCreateSubsetWithFilteredObjects();
    if (createSubsetWithFilteredObjects) {
      itemsList = [...itemsList, createSubsetWithFilteredObjects];
    }
    return itemsList;
  };

  const getLabelOfSelectedView = () => {
    switch (selectedView) {
      case AnnotatableEnum.Media:
        return "Medias";
      case AnnotatableEnum.Instance:
        return "Instances";
      case AnnotatableEnum.MediaObject:
        return "Objects";
      default:
        return "Items";
    }
  };

  // Check if user is allowed to create subset with filtered objects
  const addCreateSubsetWithFilteredObjects = () => {
    const isUserInternal = checkIfUserHavePermission(
      keycloak,
      "accessCreateSubsetWithFilteredObjectsFeature",
    );
    if (isUserInternal) {
      return {
        label: "Create subset with filtered objects",
        onItemClick: () => {
          setSubsetOption("specificObjects");
          handleOpenDialogAndClosePopover();
        },
      };
    }
  };

  return (
    <>
      <StandardPopover
        id="create_subset_popover_id"
        anchorEl={anchorEl}
        handlePopoverOpen={handlePopoverOpen}
        handlePopoverClose={handlePopoverClose}
        buttonElement={renderButtonElement()}
        itemsList={getItemsList()}
      />

      {openCreateSubsetDialog && (
        <NewSubsetDialog
          subsetOption={subsetOption}
          selectedItemsType={SelectedItemsTypeModel.all}
          openCreateSubset={openCreateSubsetDialog}
          setOpenCreateSubset={setOpenCreateSubsetDialog}
        />
      )}
    </>
  );
};

export default CreateSubsetPopover;
