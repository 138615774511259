import Popover from "@mui/material/Popover";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/delete.svg";
import MoreButton from "components/Internal/Buttons/MoreButton";

type Props = {
  anchorEl: HTMLElement | null;
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handlePopoverClose: () => void;
  itemID: string;
  handleOnDelete?: (itemID: string) => void;
  handleOnEdit?: (itemID: string) => void;
  handleOnHide?: (itemID: string) => void;
  isLoading?: boolean;
};

const EditHideDeletePopover = ({
  anchorEl,
  handlePopoverOpen,
  handlePopoverClose,
  itemID,
  handleOnDelete,
  handleOnEdit,
  handleOnHide,
  isLoading,
}: Props) => {
  const open = Boolean(anchorEl);

  return (
    <div className="">
      <MoreButton onClick={handlePopoverOpen} />

      <Popover
        id="media_objects_filter_id"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        PaperProps={{
          classes: {
            root: "rounded-lg shadow-dropdown",
          },
        }}
      >
        <div className="p-1">
          <button
            className="button-select-layer"
            onClick={() => handleOnEdit && handleOnEdit(itemID)}
            disabled={handleOnEdit === undefined}
          >
            <EditIcon
              className="mr-2"
              width="14px"
              height="14px"
              color="inherit"
            />
            Edit
          </button>
          <button
            className="button-select-layer"
            onClick={() => handleOnDelete && handleOnDelete(itemID)}
            disabled={isLoading || handleOnDelete === undefined}
          >
            <DeleteIcon className="mr-2" width="14px" height="14px" /> Delete
          </button>
        </div>
      </Popover>
    </div>
  );
};

export default EditHideDeletePopover;
