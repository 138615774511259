import { applyShapeModifiers } from "@quality-match/hermes";
import { BaseShape } from "@quality-match/shared-types";
import convertMediaObjectModelToBaseShape from "helpers/functions/hermes2dPainter/convertMediaObjectModelToBaseShape";
import visualisationCropModelToCroppingAreaModel from "helpers/functions/hermes2dPainter/visualisationCropModelToCroppingAreaModel";
import _ from "lodash";
import { VisualisationConfigurationModel } from "models/configurations.model";
import { MediaObjectModel } from "models/exploration.model";
import getCropVisualisationConfigID from "helpers/functions/hermes2dPainter/getCropVisualisationConfigID";

/**
 * A function to get the geometry of the crop from a media object
 * @param mediaObject The media object to get the geometry of the crop from
 * @returns The geometry of the crop as a BaseShape
 */
const getObjectGeometryOfCrop = (
  mediaObject: MediaObjectModel,
  visualisationConfigurations: VisualisationConfigurationModel[] | null,
  subsetId: string,
): BaseShape[] | undefined => {
  // Convert the media object to a base shape
  const baseShapeGeometry = convertMediaObjectModelToBaseShape(mediaObject);
  if (!baseShapeGeometry) {
    return;
  }

  // Check if the media object has visualisations and if it does, get the last visualisation
  if (
    mediaObject?.visualisations &&
    mediaObject?.visualisations?.length > 0 &&
    visualisationConfigurations !== null
  ) {
    // Find the visualisation configuration ID for the subset
    const configID = getCropVisualisationConfigID(
      visualisationConfigurations,
      subsetId,
    );

    // If the visualisation configuration ID is found, use that instead
    if (configID !== null) {
      const visualisationForSubset = _.find(
        mediaObject?.visualisations,
        (visualisation) => {
          return visualisation?.visualisation_configuration_id === configID;
        },
      );

      // Apply the shape modifiers to the base shape to get the crop position
      // relative to the cropping area of the actual image
      const newGeometry = visualisationForSubset
        ? applyShapeModifiers(
            [baseShapeGeometry],
            visualisationCropModelToCroppingAreaModel(
              visualisationForSubset?.parameters.crop,
            ),
          )
        : [];
      return newGeometry;
    }
  }
};

export default getObjectGeometryOfCrop;
