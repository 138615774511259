import React from "react";

type Props = {
  alwaysShownSection: string;
  expandedSection: string;
  icon?: React.ReactNode;
  defaultExpanded?: boolean;
};

const InfoAccordion = ({
  alwaysShownSection,
  expandedSection,
  icon,
  defaultExpanded = true,
}: Props) => {
  const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded);

  return (
    <div
      className="w-full py-4 px-3 pb-2 rounded-lg border border-palettePurple
            flex gap-x-3"
    >
      {icon && icon}
      <div className="flex flex-col gap-y-2 min-w-0 flex-1">
        <div className=" text-paletteBlack-2">{alwaysShownSection}</div>
        <div className=" text-paletteBlack-2">
          {expanded && expandedSection}
        </div>

        <div className="flex justify-end">
          <button
            className="button-layer py-1 px-2 text-sm"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Show less" : "Show more"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfoAccordion;
